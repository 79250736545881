import { Badge, Card, Col, Input, Row, Spin, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import constants from "../../Constants/constants";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { fetchStaffApi } from "../../Services/Api";
import { CustomSortIcon, HighlightedText, getNextAndPreviousButtons, performSearch, showTotalItems } from "../../GlobalFunctions/GlobalFunctions";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";

const MyCourses = () => {
  const pageTitle = constants.MyCourses;
  const breadcrumbItems = [
    { label: constants.MyCourses, active: true },
  ];
  const facultyID = getCookie(COOKIE.UserId);
  const [listData, setListData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredHistoryData, setFilteredHistoryData] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    myCourseList();
    historyList();
  }, []);
  const myCourseList = () => {
    setLoading(true);
    fetchStaffApi(`/get-assigned-courses/${facultyID}`, "GET")
      .then((res) => {
        const manipulatedData = manipulateData(res.data);
        if (res?.code === "200") {
          setListData(manipulatedData);
          setFilteredData(manipulatedData);
          setLoading(false);
        } else {
          setListData([]);
          setFilteredData([]);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const historyList = () => {
    setLoading(true);
    const payload = {
      faculty_id: facultyID,
    };
    fetchStaffApi("/get-faculty-courses-history", "POST", payload)
      .then((res) => {
        setLoading(false);
        if (res.code === "200") {
          setHistoryData(res.data);
          setFilteredHistoryData(res.data);
        } 
        else {
          setHistoryData([]);
          setFilteredHistoryData([]);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const manipulateData = (data) => {
    const uniqueData = {};
    data.forEach((item) => {
      const key = `${item.course_code}-${item.course_name}-${item.course_type}`;
      if (!uniqueData[key]) {
        uniqueData[key] = {
          ...item,
          section_no: [item.section_no],
        };
      } else {
        if (!uniqueData[key].section_no.includes(item.section_no)) {
          uniqueData[key].section_no.push(item.section_no);
        }
      }
    });
    // Convert section_no arrays to comma-separated strings
    Object.values(uniqueData).forEach((item) => {
      item.section_no.sort((a, b) => a - b);
      item.section_no = item.section_no.join(", ");
    });

    return Object.values(uniqueData);
  };

  const MyCourseData = [
    {
      title: `${constants.CourseNo}`,
      dataIndex: "course_code",
      key: "course_code",
      width: "9%",
      sorter: {
        compare: (a, b) => (a.course_code || "").localeCompare(b.course_code || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (course_code) => (
        <HighlightedText text={course_code} highlight={searchValue} />
      ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.CourseName}`,
      dataIndex: "course_name",
      key: "course_name",
      sorter: {
        compare: (a, b) => (a.course_name || "").localeCompare(b.course_name || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (course_name) => (
        <HighlightedText text={course_name} highlight={searchValue} />
      ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.SectionCourses}`,
      dataIndex: "section_no",
      key: "section_no",
      width: "9%",
      sorter: {
        compare: (a, b) => (a.section_no || "").localeCompare(b.section_no || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (section_no) => (
        <span className="numbers-alignment">
          <HighlightedText text={section_no} highlight={searchValue} />
        </span>
      ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.Type}`,
      dataIndex: "type",
      key: "type",
      width: "14%",
      sorter: {
        compare: (a, b) => (a.type || "").localeCompare(b.type || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (type) => <HighlightedText text={type} highlight={searchValue} />,
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.StartYear}`,
      dataIndex: "start_year",
      key: "start_year",
      width: "12%",
      sorter: {
        compare: (a, b) => (a.start_year || "").localeCompare(b.start_year || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (start_year) => <HighlightedText text={start_year} highlight={searchValue} />,
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.StudentCount}`,
      dataIndex: "count",
      key: "count",
      width: "10%",
      sorter: {
        compare: (a, b) => a.count - b.count,
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (count) => (
        count?.trim() ? 
          <span className="numbers-alignment">
            <HighlightedText text={count} highlight={searchValue} />
          </span> 
        : '-'
      ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.StudentNames}`,
      dataIndex: "student_names",
      key: "student_names",
      sorter: {
        compare: (a, b) => (a.student_names || "").localeCompare(b.student_names || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (student_names) => (
        student_names?.trim() ? <HighlightedText text={student_names} highlight={searchValue} /> : '-'
      ),
      showSorterTooltip: { show: true, placement: 'bottom' },
    },
  ];

  const historyListData = [
    {
      title: `${constants.CourseNo}`,
      dataIndex: "course_code",
      key: "course_code",
      width: "10%",
      sorter: {
        compare: (a, b) => (a.course_code || "").localeCompare(b.course_code || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (course_code) => (
        <HighlightedText text={course_code} highlight={searchValue} />
      ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.CourseName}`,
      dataIndex: "course_name",
      key: "course_name",
      sorter: {
        compare: (a, b) => (a.course_name || "").localeCompare(b.course_name || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (course_name, record) =>
        record.discontinue === "1" ? (
          <>
            <Row>
              <Col span={12}>
                <HighlightedText text={course_name} highlight={searchValue} />
              </Col>
              <Col span={12}>
                <Badge.Ribbon
                  text={constants.discontinue}
                  color="#337AB7"
                  className="top-0 !important me-3 text-xs"
                ></Badge.Ribbon>
              </Col>
            </Row>
          </>
        ) : (
          <HighlightedText text={course_name} highlight={searchValue} />
        ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.SectionCourses}`,
      dataIndex: "section",
      key: "section",
      width: "10%",
      sorter: {
        compare: (a, b) => (a.section || "").localeCompare(b.section || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (section) => (
        <span className="numbers-alignment">
          <HighlightedText text={section} highlight={searchValue} />
        </span>
      ),
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.Type}`,
      dataIndex: "type",
      key: "type",
      sorter: {
        compare: (a, b) => (a.type || "").localeCompare(b.type || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (type) => <HighlightedText text={type} highlight={searchValue} />,
      showSorterTooltip: { show: true, placement: "bottom" },
    },
    {
      title: `${constants.Year}`,
      dataIndex: "year",
      key: "year",
      sorter: {
        compare: (a, b) => (a.year || "").localeCompare(b.year || ""),
      },
      sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      render: (year) => <HighlightedText text={year} highlight={searchValue} />,
      showSorterTooltip: { show: true, placement: "bottom" },
    },
  ];

  const onSearch = (event) => {
    const search = event.target.value;
    const fieldNames = [
      "course_code",
      "course_name",
      "section_no",
      "section",
      "type",
      "year",
      "start_year",
      "student_names",
      "count",
    ];

    if (activeTab === "1") {
      const searchedValue = performSearch(search, filteredData, fieldNames);
      setListData(searchedValue);
    } else if (activeTab === "2") {
      const searchedValue = performSearch(search, filteredHistoryData, fieldNames);
      setHistoryData(searchedValue);
    }
    setSearchValue(search);
  };

  const clearSearch = () => {
    setSearchValue("");
    if (activeTab === "1") {
      setListData(filteredData);
    } else if (activeTab === "2") {
      setHistoryData(filteredHistoryData);
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    clearSearch();
  };

  const getRowClassName = (record) => {
		return record?.student_names && 'advisor-row';
	};

  const items = [
    {
      key: "1",
      label: `${constants.Courses}`,
      children: (
        <div className="mt-2">
          <Spin tip={constants.PleaseWait} spinning={loading}>
            <Table
              className="records-table overflow-auto data_table"
              rowSelectedBg="#e6f4ff"
              columns={MyCourseData}
              dataSource={listData}
              rowKey={(record) => record.id}
              scroll={{ x: 768 , y: 'calc(100vh - 350px)'}}
              bordered
              size="small"
              pagination={{
                pageSize: constants.TablePageSize,
                showTotal: (total, range) =>
                  showTotalItems(total, range, listData),
                itemRender: getNextAndPreviousButtons,
              }}
              searchableProps={{ fuzzySearch: true }}
              rowClassName={(record)=> getRowClassName(record)}
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "2",
      label: `${constants.History}`,
      children: (
        <div className="mt-2">
          <Spin tip={constants.PleaseWait} spinning={loading}>
            <Table
              className="records-table overflow-auto data_table"
              rowSelectedBg="#e6f4ff"
              columns={historyListData}
              dataSource={historyData}
              rowKey={(record) => record.id}
              scroll={{ x: 768 , y: 'calc(100vh - 350px)'}}
              bordered
              size="small"
              pagination={{
                pageSize: constants.TablePageSize,
                showTotal: (total, range) =>
                  showTotalItems(total, range, historyData),
                itemRender: getNextAndPreviousButtons,
              }}
              searchableProps={{ fuzzySearch: true }}
              rowClassName={(record) =>
                record.discontinue === "1" ? "discontinued-course-row" : ""
              }
            />
          </Spin>
        </div>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbHeader
        pageTitle={pageTitle}
        breadcrumbItems={breadcrumbItems}
      />
      <div>
        <Card className="container-border-custom">
          <Input
            className="w-full block lg:hidden mb-2"
            placeholder={constants.Search}
            onChange={onSearch}
            value={searchValue}
          />
          <Tabs
            bordered
            type="card"
            size={"middle"}
            defaultActiveKey="1"
            items={items}
            className="newTab"
            onChange={handleTabChange}
            tabBarExtraContent={
              <Input
                className="w-48 hidden lg:block"
                placeholder={constants.Search}
                onChange={onSearch}
                value={searchValue}
              />
            }
          />
        </Card>
      </div>
    </>
  );
};

export default MyCourses;
