import React, { useEffect, useState } from "react";
import constants from "../../../Constants/constants";
import BreadcrumbHeader from "../../Component/BreadcrumbHeader";
import { Button, Card, Input, Space, Spin, Table } from "antd";
import { getNextAndPreviousButtons, handleRowSelect, performSearch, showTotalItems, CustomSortIcon, HighlightedText, surveyRedirectRoute } from "../../../GlobalFunctions/GlobalFunctions";
import { fetchApi } from "../../../Services/Api";
import { COOKIE, getCookie } from "../../../Services/Cookie";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const sorterTooltipStyle = {
	placement: 'top',
	align: { offset: [10, 5] },
}

const SurveyList = ({ type }) => {
  const userType = type
  const pageTitle = constants.MySurveys;
  const breadcrumbItems = [{ label: constants.MySurveys, active: true }];

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [semId, setSemId] = useState(null);
  const [listData, setListData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
  const [viewButtonDisabled, setViewButtonDisabled] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const ID = parseInt(getCookie(COOKIE.UserId))
  const navigate = useNavigate();

  useEffect(() => {
    getSemesterList()
  }, [])

  const getSemesterList = () => {
    setLoading(true)
    fetchApi('/semester-data', 'get').then((res) => {
			if (res?.code === '200') {
				let data = res?.data
				let findIndex = data?.findIndex((val) => val?.semester_is_ended === '0');
				if(findIndex > -1) {
          let id = parseInt(data[findIndex]?.id)
          getSurveyList(id)
				}
        return
			}
      setLoading(false)
		}).catch(() => setLoading(false));
  }

  const getSurveyList = (sem_id) => {
    setSemId(sem_id)
    let key = userType === constants.Faculty ? 'faculty_id' : 'student_id'
    let payload = { 
      [key] : ID,
      semester_id: sem_id,
    }
		fetchApi('/get-surveys', 'post', payload).then((res)=> {
      setLoading(false)
      if(res?.code === '200') {
        let modifiedData = res?.data?.map((item) => {
          return {
            ...item,
            id: item.survey_id,
            deadline: item?.deadline ? dayjs(item.deadline).format("MMM DD, YYYY") : '',
          }
        })
        setListData(modifiedData)
        setFilteredData(modifiedData)
        localStorage.removeItem('surveyUrl')
        return
      }
      setListData([])
      setFilteredData([])
    }).catch(() => {
      setLoading(false)
      setListData([])
      setFilteredData([])
    })
  }

  const onSearch = (event) => {
    const search = event.target.value;
    setSearchValue(search);
    const fieldNames = [
      "survey_name",
      "deadline",
    ];
    const searchedValue = performSearch(search, [...filteredData], fieldNames);
    setListData(searchedValue);
  };

  const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null) => {
    handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
		)
    setSelectedRecord(record)
    setViewButtonDisabled(newSelectedRowKeys?.length === 0)
  };

  const rowSelection = {
    hideSelectAll: true,
    type: "checkbox",
    selectedRowKeys: rowKeys,
    onSelect: (record, selected, selectedRows) => onSelectChange( 
			selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			record, //CURRENT SELECTED RECORD
			listData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
  };

  const pagination = {
    pageSize: constants.TablePageSize,
    showTotal: (total, range) => showTotalItems(total, range, listData),
    itemRender: getNextAndPreviousButtons,
  }

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => onSelectChange(null, null, record, rowIndex),
      onDoubleClick: () => handleDoubleRowClick(record),
    }
  }

  const columns = [
    {
      title: `${constants.SurveyTitle}`,
      dataIndex: "survey_name",
      key: "survey_name",
      width: '80%',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
      render:(_, { survey_name })=> survey_name ? <HighlightedText text={survey_name} highlight={searchValue} /> : '-'
    },
    {
      title: `${constants.SentDate}`,
      dataIndex: "deadline",
      key: "deadline",
      width: '20%',
      sorter: (a, b) => a?.deadline?.localeCompare(b?.deadline),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
      render:(_, { deadline })=> deadline ? <HighlightedText text={deadline} highlight={searchValue} /> : '-'
    },
  ]

  const handleDoubleRowClick = (record) => {
    let route = surveyRedirectRoute(record, userType === constants.Student)
    navigate(route);
  }

  return (
    <>
      <BreadcrumbHeader
        pageTitle={pageTitle}
        breadcrumbItems={breadcrumbItems}
      />
      <div>
        <Card className="container-border-custom">
          <div className="d-flex justify-content-between">
            <Space wrap>
              <Link 
                to={surveyRedirectRoute(selectedRecord, userType === constants.Student)} 
                state={{ 
                  record: selectedRecord,
                  userType: userType,
                }}>
                <Button
                  className="text-capitalize btn-info"
                  disabled={viewButtonDisabled}
                >
                  {constants.View}
                </Button>
							</Link>
            </Space>
            <Input
              className="w-48"
              placeholder={constants.Search}
              onChange={onSearch}
              value={searchValue}
            />
          </div>
          <div className="mt-2">
            <Spin tip={constants.PleaseWait} spinning={loading}>
              <Table
                onRow={(record, rowIndex) => onRow(record, rowIndex)}
                className="records-table overflow-auto data_table"
                rowSelectedBg="#e6f4ff"
                columns={columns}
                dataSource={listData}
                rowKey='id'
                bordered
                size="small"
                rowSelection={rowSelection}
                scroll={{ x: 768 ,y: 'calc(100vh - 350px)'}}
                pagination={pagination}
                searchableProps={{ fuzzySearch: true }}
              />
            </Spin>
          </div>
        </Card>
      </div>
    </>
  );
}
 
export default SurveyList;