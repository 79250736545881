import React from 'react';
import { Input, Select, Button, Form, Spin} from 'antd';
import constants from '../../../Constants/constants';
import { checkEmptySpace } from '../../../GlobalFunctions/GlobalFunctions';


const ModalContent = ({ type, form, closeModal, setShowAddModal, setShowEditModal, submitNewSurveyDetails, submitEditedDetails, selectedRecord, setShowDeleteModal, deleteSelectedSurvey, clearKeys, saveDisabled }) => {

  const getDeleteModalContent = () => {
    let content;
    if (selectedRecord && selectedRecord?.is_delete === 1) {
      content = <>
        <span className="font-bold text-sm">{constants.DeleteConfirmationMessage}</span>
        <div className="flex justify-end">
          <Button type='primary' className='text-sm me-2 mb-3' onClick={() => { closeModal(setShowDeleteModal); clearKeys();}} danger>{constants.Cancel}</Button>
          <Button disabled={saveDisabled} type='primary' className='mb-3' onClick={deleteSelectedSurvey}>{constants.Delete}</Button>
        </div>
      </>
    } else {
      content = <>
        <span className="font-bold text-sm">{constants.SurveyInUseMessage}</span>
        <div className='flex justify-end'>
          <Button type="primary" className='mb-3' onClick={() => { closeModal(setShowDeleteModal); clearKeys();}} danger>{constants.Cancel}</Button>
        </div>
      </>
    }
    return content;
  }

  return (
    <Spin tip={constants.PleaseWait} spinning={saveDisabled}>
      <div>
        {type === 1 || type === 2 ? (
          <div>
            <Form form={form} layout='vertical' onFinish={type === 1 ? submitNewSurveyDetails : submitEditedDetails}>
              <div className='d-flex'>
                <Form.Item
                  className='w-50'
                  rules={[{ 
                    required: true, 
                    message: 'This field is required',
                    whitespace: true,
                  }]}
                  label='Survey Type'
                  name='survey_title'
                  initialValue={type === 1 ? '' : selectedRecord?.content}
                >
                  <Input 
                    placeholder={'Survey Type'}
                    onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('survey_title'))}
                  />
                </Form.Item>
                <Form.Item 
                  name="survey_sent" 
                  className='w-50 ms-2' 
                  label='Survey send to' 
                  initialValue={type === 1 ? null : selectedRecord?.survey_send_to}
                  rules={constants.requiredFieldRules}
                >
                  <Select mode="multiple" placeholder='Survey send to' maxTagCount='responsive' allowClear>
                    {constants.SurveySentToOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="flex justify-end">
                <Button type="primary" className="mb-3" onClick={() =>{closeModal(type === 1 ? setShowAddModal : setShowEditModal);clearKeys()}} danger>{constants.Cancel}</Button>
                <Button disabled={saveDisabled} type="primary" className='ms-2 mb-3' htmlType='submit'>{constants.Save}</Button>
              </div>
            </Form>
          </div>
        ) : (
          <div>{getDeleteModalContent()}</div>
        )}
      </div>
    </Spin>
  );
}

export default ModalContent
