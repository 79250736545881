import React, { useEffect, useState } from 'react';
import { useMatch, Outlet } from 'react-router-dom';
import "../../index.css";
import "../../App.scss";
import Headers from "./Header";
import Sidemenu from "./Sider";
import { Layout, FloatButton, message } from 'antd';
import { COOKIE, getCookie } from '../../Services/Cookie';
import { fetchApi } from '../../Services/Api';
import { isArray } from 'highcharts';
import constants from '../../Constants/constants';

const capitalizeFirstLetter = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

function uppercaseWordInString(string, targetWord) {
  const words = string.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i].toLowerCase() === targetWord.toLowerCase()) {
      words[i] = words[i].toUpperCase();
    }else{
      words[i] = capitalizeFirstLetter(words[i]);
    }
  }
  return words.join(' ');
}

const Layouts = () => {
  const match = useMatch('/:page'); // Adjust the route pattern as needed
  const userType = getCookie(COOKIE.UserId);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (match) {
      const pageTitle = match.params.page.replace(/-/g, ' '); // Replace "-" with space
      const gpaWordExists = pageTitle.includes('gpa'); 
      const slpWordExists = pageTitle.includes('slp'); 
      const formattedTitle = gpaWordExists ? uppercaseWordInString(pageTitle,'gpa') :slpWordExists?uppercaseWordInString(pageTitle,'slp'):capitalizeFirstLetter(pageTitle);
      document.title = `${formattedTitle} | Clin-Ed`;
    }
  }, [match]);
	useEffect(() => {
    notificationsList();
  }, []);
  const notificationsList = () => {
    const payload = { isFaculty: userType };
    fetchApi(`/get-all-notifications`, "POST", payload).then((res) => {
      if(res?.code === "200") {
        if(isArray(res?.data) && res?.data?.length > 0) {
          setData(res?.data);
          return
        }
      }
      setData([]);
    }).catch(() => {
      message.error(constants.ErrorMessage);
    });
  };
  const updateNotificationData = () => {
    notificationsList();
  };
  const countData = data?.length;
  return (
    <Layout>
      <Sidemenu countData={countData}/>
      <Layout>
        <Headers countData={data}/>
        <div className="h-[calc(100vh-50px)] p-3 overflow-y-auto">
          <Outlet context={[data, updateNotificationData]}/>
          <FloatButton.BackTop className='bottom-24 right-2.5' />
        </div>
      </Layout>
    </Layout>
  );
};
export default Layouts;