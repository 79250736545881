import React, { useState, useEffect } from 'react';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import constants from '../../Constants/constants';
import { Flex, Table, Button, Card, Checkbox, Space, Input, Modal, Form, Row, Col, Select, Tag, Tooltip, message, Tabs, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { performSearch, CustomSortIcon, HighlightedText, getNextAndPreviousButtons, showTotalItems, handleRowSelect } from '../../GlobalFunctions/GlobalFunctions';
import { fetchStaffApi } from '../../Services/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;
const { TabPane } = Tabs;

function getSectionNo(sectionNumber) {
	let section = parseInt(sectionNumber)
	let sectionNo = section > 8 ?
	constants.extraSections?.find((sec) => sec.id === section)?.name : 
	sectionNumber
	return sectionNo
}

function FacultyCoursesAssignment() {
	const [breadcrumbItems, setBreadcrumbItems] = useState([
		{ label: 'Faculty Courses Assignment', active: true },
	]);
	
	const navigate = useNavigate();
	const [assignForm] = Form.useForm();
	const [reAssignForm] = Form.useForm();
	const [dataSource, setDataSource] = useState([]);
	const [isDisable, setIsDisable] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRow, setSelectedRow] = useState([]);
	const [selectedReassignRowKeys, setSelectedReassignRowKeys] = useState([]);
	const [selectedReassignRow, setSelectedReassignRow] = useState([]);
	const [tabKey, setTabKey] = useState("1");
	const [historyTabKey, setHistoryTabKey] = useState("1");
	const [showTab, setShowTab] = useState(true);
	const [showAssignModal, setShowAssignModal] = useState(false)
	const [showReAssignModal, setShowReAssignModal] = useState(false)
	const [showViewModal, setShowViewModal] = useState(false)
	const [filterTableData, setFilterTableData] = useState([]);
	const [historyView, setHistoryView] = useState(false);
	const [isActive, setIsActive] = useState(1);
	const [sectionData, setSectionData] = useState([])
	const [sectionOptions, setSectionOptions] = useState([]);
	const [selectedCourseId, setSelectedCourseId] = useState()
	const [assignedCourses, setAssignedCourses] = useState([])
	const [viewCourses, setViewCourses] = useState([])
	const [facultyList, setFacultyList] = useState()
	const [historyData, setHistoryData] = useState([])
	const [indiHistoryData, setIndiHistoryData] = useState([])
	const [facultyHistVisible, setFacultyHistVisible] = useState(false)
	const [historyClicked, setHistoryClicked] = useState(false)
	const [assignSemOpt, setAssignSemOpt] = useState([])
	const [endSemOptions, setEndSemOptions] = useState([]);
	const [startSemOptions, setStartSemOptions] = useState([]);
	const [startYearOptions, setStartYearOptions] = useState()
	const [isSection, setIsSection]=useState(null)
	const [disableStartYear, setDisableStartYear]=useState(true)
	const [disableStartSem, setDisableStartSem]=useState(true)
	const [rowData, setRowData] = useState([])
	const [searchValue, setSearchValue] = useState({
		listing: null,
		reassign: null,
		history: null,
		indi_history: null,
	});

	const currentYear = new Date().getFullYear();
	const nextYear = currentYear + 1;
	const fromYearOptions = [currentYear.toString(), nextYear.toString()];

	const handleEndYearChange = (value) => {
		reAssignForm.setFieldsValue({
			end_sem: undefined
		})
		if (value) {
			//handle start year dropdown optioons
			const startSemNextYear = parseInt(value) + 1;
			setStartYearOptions([value.toString(), startSemNextYear.toString()]);
			//handle end sem dropdown optioons
			getSemesterList(value,"end_sem");
			//reset if changes are made
			reAssignForm.resetFields(["start_year", "start_sem"]);
		}
	};

	const handleEndSemChange = (value) => {
		setDisableStartYear(false)
		// Reset start sem and year if end sem changes
		reAssignForm.resetFields(["start_year", "start_sem"]);
	}

	const handleStartYearChange = (value) => {
		setDisableStartSem(false)
		getSemesterList(value,"start_sem");
		reAssignForm.resetFields(["start_sem"]);
	}

	useEffect(() => {
		// Fetch data from API
		getFacultyCourses();
	}, []);

	const getFacultyCourses = (tabKey = '1', isActive = 1) => {
		const payload = {
			is_active: isActive,
			faculty_type: tabKey === "1" ? 0 : 4
		}
		fetchStaffApi('/get-faculty-courses', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				const Data = res?.data
				setDataSource(Data)
				setFilterTableData(Data)
			} else {
				message.error(res?.message)
				setDataSource([])
				setFilterTableData([])
			}
		});
	}
	const rowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
			onSelect: (recordData, selected, selectedRows) =>{ 
			onSelectAssignChange(
				// selectedRows?.map((item) => item.id) || [], // Ensure it is an array
				// selectedRows || [], // Ensure it is an array
				null,
				null,
				recordData, // Current selected record
				dataSource?.findIndex((item) => item.id === recordData.id) // Index of the currently selected record from table data
		);},
		selectedRowKeys: selectedRowKeys
	};

	const onSelectAssignChange = (newSelectedRowKeys = [], selectedRowData = [], recordData = null, index = null) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			recordData, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedRowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'single' //SELECTION TYPE
		)
		setSelectedRow(recordData)
  };

	// Custom render function for 'Courses' column
	const renderCourses = (text, record) => {
		const courseInfoArray = record?.course_info;
		const courses = courseInfoArray?.map((course, index) => {

			if (record?.discontinue?.[index] === "1") {
				return (
					<Tooltip key={index} title="Course has been Discontinued">
						<Tag className='m-0 rounded-xl text-white !bg-neutral-500' icon={<FontAwesomeIcon className='text-yellow-500 mr-1.5' icon={faExclamationTriangle} />}>
							{course}
						</Tag>
					</Tooltip>
				);
			} else {
				return (
					<span key={index}>
						{course}
					</span>
				);
			}
		});

		// Join courses with comma and space
		const coursesWithCommas = courses?.map((course, index) => (
			<React.Fragment key={index}>
				{index > 0 && <span>, </span>}
				{course}
			</React.Fragment>
		));
		return <div>{coursesWithCommas}</div>;
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'faculty_name',
			width: "15%",
			sorter: (a, b) => a.faculty_name.localeCompare(b.faculty_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (faculty_name) => <div onDoubleClick={handleOpenAssign}><HighlightedText text={faculty_name} highlight={searchValue.listing} /></div>
		},
		{
			title: 'Sections Assigned',
			dataIndex: 'section_length',
			width: "15%",
			sorter: (a, b) => a.section_length - b.section_length,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (section_length) => (
				<Flex className="table-number-align numbers-alignment">
					<HighlightedText text={section_length} highlight={searchValue.listing} />
				</Flex>
			)
		},
		{
			title: 'Total Credits',
			dataIndex: 'course_credits',
			width: "12%",
			sorter: (a, b) => a.course_credits - b.course_credits,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_credits) => (
				<Flex className="table-number-align numbers-alignment">
					<HighlightedText text={course_credits} highlight={searchValue.listing} />
				</Flex>
			)
		},
		{
			title: 'Courses',
			dataIndex: 'course_val',
			width: "58%",
			// render: renderCourses,
			sorter: (a, b) => a.course_val.localeCompare(b.course_val),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_val) => course_val?.trim() ? <HighlightedText text={course_val} highlight={searchValue.listing} /> : '-'
		},
	]

	const getfacultyHistory = (key) => {
		const payload = {
			faculty_type: key === "1" ? 0 : 4
		}

		fetchStaffApi('/get-faculty-courses-history', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				const HistoryData = res?.data
				setHistoryData(HistoryData)
				setFilterTableData(HistoryData)
			} else {
				message.error(res?.message)
			}
		});
	}

	const getIndividualfacultyHistory = (id) => {
		const payload = {
			faculty_id: id
		}
		fetchStaffApi(`/get-faculty-courses-history`, 'POST',payload).then((res) => {
			if (res?.code === "200") {
				setIndiHistoryData(res?.data)
				setFilterTableData(res?.data)
			} else {
				message.error(res?.message)
				setIndiHistoryData([])
				setFilterTableData([])
			}
		});
	}

	const handleHistoryView = () => {
		setHistoryClicked(true)
		navigate("/faculty-courses-assignment-history");
		setBreadcrumbItems([
			{ label: 'Faculty Courses Assignment', href: "/faculty-courses-assignment" },
			{ label: 'Faculty Courses Assignment History', active: true }
		]);
		if (selectedRowKeys.length == 0) {
			setHistoryView(true);
			handleHistoryTab(historyTabKey)
		}
		else {
			const id= selectedRow?.id
			setFacultyHistVisible(true)
			getIndividualfacultyHistory(id)
		}
	};
	const handleBackToRegularView = () => {
		clearSearch()
		setHistoryClicked(false)
		setFacultyHistVisible(false)
		setHistoryView(false);
		clearSelectRecord()
		navigate("/faculty-courses-assignment");
		// window.location.reload()
		setBreadcrumbItems([
			{ label: 'Faculty Courses Assignment', active: true },
		]);
		getFacultyCourses(tabKey, isActive)
	};
	const onSearch = (event, type) => {
		const search = event.target.value;
		clearSearch(search, type)
		const searchFieldNames = ["faculty_name", "course_val", "course_credits", "section_length"];
		const searchHistoryFieldNames = ["faculty", "course_val"];
		const searchReassignFieldNames = ["course_code", "course_name", "section_no","start_year","type"];
		const searchFacultyHistory=["course_code", "course_name", "section","start_year","end_year"]

		let fields = []
		let setter = null
		switch (type) {
			case 'listing':
				fields = searchFieldNames
				setter = setDataSource
				break;
			case 'reassign':
				fields = searchReassignFieldNames
				setter = setAssignedCourses
				break;
			case 'history':
				fields = searchHistoryFieldNames
				setter = setHistoryData
				break;
			case 'indi_history':
				fields = searchFacultyHistory
				setter = setIndiHistoryData
				break;
			default:
				break;
		}
		let searchedValue = performSearch(search, filterTableData, fields);
		setter(searchedValue);
	}
	const clearSelectRecord = () => {
		setSelectedRow([])
		setSelectedRowKeys([])
		// setrowkeyLength(0)
	}

	const clearSearch = (fill = '', search = '') => {
		setSearchValue({
			listing: search === 'listing' ? fill : null,
			reassign: search === 'reassign' ? fill : null,
			history: search === 'history' ? fill : null,
			indi_history: search === 'indi_history' ? fill : null,
		})
	}

	const handleTab = (key) => {
		setTabKey(key);
		setHistoryTabKey(key);
		clearSelectRecord()
		setIsActive(1)
		getFacultyCourses(key, 1)
		clearSearch()
	};
	const handleHistoryTab = (key) => {
		clearSelectRecord()
		setHistoryTabKey(key);
		setTabKey(key);
		getfacultyHistory(key)
		clearSearch()
	};
	const handleCheckbox = (event) => {
		let isActive = event.target.checked ? 0 : 1
		clearSelectRecord()
		setIsActive(isActive);
		getFacultyCourses(tabKey, isActive)
	}

	//Assign Modal
	const handleOpenAssign = () => {
		assignForm.resetFields()
		setShowAssignModal(true)

		fetchStaffApi('/get-sections', 'GET').then((res) => {

			if (res?.code === "200") {
				const SectionData = res?.data
				setSectionData(SectionData)
			} else {
				message.error(res?.message)
			}
		})
	}

	const SaveAssign = (values) => {
		setIsDisable(true);
		const payload = {
			course_id: selectedCourseId,
			section_no: values.section_no ? values.section_no : '',
			faculty_id: selectedRow.id,
			start_sem: values.start_sem,
			start_year: values.start_year,
		}
		fetchStaffApi('/assign-course-to-faculty', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setIsDisable(false);
				handleCloseAssign()
				message.success("Course(s) Assigned Succeefully!")
				getFacultyCourses(tabKey, isActive)
			} else {
				setIsDisable(false);
				message.error(res?.message)
			}
		}).catch((error) => {
			setIsDisable(false);
		})
		
	}
	const handleCourseChange = (courseName) => {
		assignForm.setFieldsValue({section_no:undefined})
		
		const selectedRecord = sectionData.find(record => record.course_name === courseName);
		//used to send selected course id to the payload on save of assign
		setSelectedCourseId(selectedRecord.course_id);

		if (selectedRecord) {
			const options = selectedRecord.sections_unassigned?.map((sec) => {
				return {
					id: sec,
					name: constants.extraSections.find((it) => it.id === parseInt(sec))?.name || sec,
				}
			})
			// options.push(...constants.extraSections)
			setSectionOptions(options);
			if(options?.length === 1) {
				assignForm.setFieldsValue({ section_no:options[0]?.id })
			}
		}
	};
	const handleCloseAssign = () => {
		setShowAssignModal(false)
		clearSelectRecord()
		setSectionOptions([])
		setFilterTableData(dataSource)
	}

	const handleSection=(value)=>{
		setIsSection(value)
	}

	const onAssignYear = (value) =>{
		assignForm.setFieldsValue({start_sem:undefined})
		getSemesterList(value,"from_sem");
	}
	const getSemesterList = (value,type) => {
		let payload = {};
		if(type==="from_sem"||type==="end_sem"){
			payload={year:value};
		}else if(type==="start_sem"){
			let form = reAssignForm.getFieldsValue();
			payload.end_year=parseInt(form.end_year);
			payload.end_sem=form.end_sem;
			payload.start_year=parseInt(form.start_year);
		}
		fetchStaffApi(`/get-course-semester`, 'POST', payload).then((res) => {
			if (res?.data?.length>0){
				if(type==="from_sem"){setAssignSemOpt(res?.data)}
				if(type==="end_sem"){setEndSemOptions(res?.data)}
				if(type==="start_sem"){setStartSemOptions(res?.data)}
			} else {
				if(type==="from_sem"){setAssignSemOpt([])}
				if(type==="end_sem"){setEndSemOptions([])}
				if(type==="start_sem"){setStartSemOptions([])}
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const AssignModal = (
		<Modal title={`Assign Course(s) to Faculty - ${selectedRow?.faculty_name}`}
			open={showAssignModal} onCancel={handleCloseAssign} width={600} footer={null}>
			<Form form={assignForm} layout="vertical" onFinish={SaveAssign}>
				<Row gutter={[10, 0]}>
					<Col span={12}>
						<Form.Item label="Course Name" name="course_name"
							rules={[{ required: true, message: constants.FieldRequired }]}>
							<Select placeholder="Select Course" popupClassName="select-dropdown" onChange={handleCourseChange} showSearch>
								{Array.isArray(sectionData) &&
									sectionData?.map((record) =>
										record.discontinue === "0" && record?.sections_unassigned.length !== 0 ? (
											<Select.Option key={record.course_id} value={record.course_name}>
												{record.course_name}
											</Select.Option>
										) : null
									)}
							</Select>
						</Form.Item>
					</Col>

						<Col span={12}>
							<Form.Item label="Section" name="section_no"
								rules={[{
									required: true,
									message: constants.FieldRequired
								}]}>
								<Select 
									onChange={handleSection} 
									disabled={sectionOptions.length === 1} 
									value={isSection} 
									popupClassName="select-dropdown" 
									placeholder="Select section"
								>
									{
										sectionOptions.map((item) => (
											<Option key={item.id} value={item.id}>{item.name}</Option>
										))
									}
								</Select>
							</Form.Item>
						</Col>

					<Col span={12}>
						<Form.Item label="From Year" name="start_year"
							rules={[{ required: true, message: constants.FieldRequired }]}>
							<Select placeholder="From Year" popupClassName="select-dropdown" onChange={onAssignYear}>
								{fromYearOptions?.map(option => (
									<Option key={option} value={option} >{option}</Option>))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="From Sem " name="start_sem"
							rules={[{ required: true, message: constants.FieldRequired }]}>
							<Select placeholder="From Sem" popupClassName="select-dropdown">
								{assignSemOpt?.map(option => (
									<Option key={option.id} value={option.id} >{option.label}</Option>))}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Flex align='flex-end' gap="small" wrap="wrap" className='action-button-container '>
					<Button className=" adjust-margin text-capitalize btn-info !mb-0" disabled={isDisable} htmlType='submit'>Assign</Button>
				</Flex>
			</Form>
		</Modal>
	);

	// //handle Re-assign Modal
	// const reassignRowSelection = {
	// 	type: 'checkbox',
	// 	selectedReassignRowKeys,
	// 	columnWidth: '5%',
	// 	onChange: (selectedReassignRowKeys, selectedReassignRow) => {
	// 		// let recidx= selectedReassignRow.length-1
	// 		setSelectedReassignRowKeys(selectedReassignRowKeys);
	// 		setSelectedReassignRow(selectedReassignRow)
	// 	},
	// };
	const reassignRowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
		onSelect: (recordData, selected, selectedRows) =>{ 
			onSelectChange(
				// selectedRows?.map((item) => item.id) || [], // Ensure it is an array
				// selectedRows || [], // Ensure it is an array
				null,
				null,
				recordData, // Current selected record
				assignedCourses?.findIndex((item) => item.id === recordData.id) // Index of the currently selected record from table data
		);},
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? assignedCourses : []
			onSelectChange(
				data?.filter((it) => it?.end_year?.trim() === '')?.map((item) => item.id),
				data?.filter((it) => it?.end_year?.trim() === ''),
				null,
				null
			)
		},
		selectedRowKeys: selectedReassignRowKeys,
		getCheckboxProps: (record) => ({
			disabled: record?.end_year?.trim(),
		}),
	};

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], recordData = null, index = null) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			recordData, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedReassignRowKeys, rowData: selectedReassignRow }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedReassignRowKeys, setRowData: setSelectedReassignRow }, //SET STATES FOR KEYS AND RECORDS
			'multi' //SELECTION TYPE
		)
  };

	const ReAssignModalColumns = [
		{
			title: 'Course No.',
			dataIndex: 'course_code',
			width: "15%",
			sorter: (a, b) => a.course_code.localeCompare(b.course_code),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_code) => <HighlightedText text={course_code} highlight={searchValue.reassign} />
		},
		{
			title: 'Course Name',
			dataIndex: 'course_name',
			width: "30%",
			sorter: (a, b) => a.course_name.localeCompare(b.course_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_name) => <HighlightedText text={course_name} highlight={searchValue.reassign} />
		},
		{
			title: 'Section ',
			dataIndex: 'section_no',
			width: "12%",
			sorter: (a, b) => a.section_no - b.section_no,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_, record) => (
				<Flex className="table-number-align numbers-alignment">
					<HighlightedText text={getSectionNo(record?.section_no)} highlight={searchValue.reassign} />
				</Flex>
			)
		},
		{
			title: ' Course Type ',
			dataIndex: 'type',
			width: "23%",
			render: (type) => (
				<HighlightedText text={type} highlight={searchValue.reassign} />
			),
			sorter: (a, b) => a.type.localeCompare(b.type),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Start Year',
			dataIndex: 'start_year',
			width: "25%",
			sorter:(a, b) => a.year?.localeCompare(b.year, 'en', { numeric: true }),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (start_year) => (
				<div>
				  <HighlightedText text={start_year} highlight={searchValue.reassign} />
				</div>
			  )
		}
	]

	const getAssignedCourses = () => {
		fetchStaffApi(`/get-assigned-courses/${selectedRow.id}`, 'GET').then((res) => {
			if (res?.code === "200") {
				const ViewData = res?.data
				// Filtering out records with discontinue equal to "1"
				const assignData = res?.data.filter(course => course.discontinue !== "1");
				setAssignedCourses(assignData);
				setFilterTableData(assignData)
				setViewCourses(ViewData)
			} else {
				message.error(res?.message)
				setAssignedCourses([]);
				setFilterTableData([])
				setViewCourses([])
			}
		})
	}

	const handleOpenReAssign = () => {
		reAssignForm.resetFields()
		setShowReAssignModal(true)
		reAssignForm.setFieldsValue({ current_faculty: selectedRow?.faculty_name })
		getAssignedCourses();

		fetchStaffApi('/get-faculty-list', 'GET').then((res) => {
			if (res?.code === "200") {
				const FacultyList = res?.data.faculties
				// Filtering out the selected faculty name from the list 
				const filteredFacultyList = FacultyList.filter(faculty => faculty.id !== selectedRow?.id && faculty.faculty !== selectedRow?.faculty_name && faculty.is_active !== "3" && faculty.is_active !== "2" && faculty.user_role !== "Admin");
				setFacultyList(filteredFacultyList)
			} else {
				message.error(res?.message)
			}
		})
	}
	const SaveReassign = (values) => {
		setIsDisable(true);
		//Data payload
		const oldDataObject = {
			faculty_id: parseInt(selectedRow.id),
			end_sem: parseInt(values.end_sem),
			end_year: parseInt(values.end_year),
		}
		const payloadDataArray = [];

		selectedReassignRow?.map((record) => {
			let obj = {
				...oldDataObject,
				course_id: parseInt(record.course_id),
				id: parseInt(record.id)
			}
			payloadDataArray.push(obj)
		})

		//new_data payload
		const NewDataObject = {
			faculty_id: parseInt(values.faculty_id),
			start_sem: parseInt(values.start_sem),
			start_year: parseInt(values.start_year)
		}
		const payloadNewDataArray = [];

		selectedReassignRow?.map((record) => {
			let obj = {
				...NewDataObject,
				course_id: parseInt(record.course_id),
				section_no: record.section_no === "-" ? 0 : parseInt(record.section_no)
			}
			payloadNewDataArray.push(obj)
		})
		const payload = {
			data: payloadDataArray,
			new_data: payloadNewDataArray
		}
		fetchStaffApi('/re-assign-course-to-faculty ', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setIsDisable(false);
				const ReassignCourses = res?.data
				setFilterTableData(ReassignCourses)
				handleCancelReassign()
				message.success("Course(s) re-assigned successfully")
				getFacultyCourses(tabKey, isActive)
			} else {
				setIsDisable(false);
				message.error(res?.message)
			}
		}).catch((error) => {
			setIsDisable(false);
		})
	}
	const handleCancelReassign = () => {
		setDisableStartYear(true)
		setDisableStartSem(true)
		setShowReAssignModal(false)
		clearSearch()
		setSelectedReassignRowKeys([]);
		setSelectedReassignRow([])
		clearSelectRecord()
		setFilterTableData(dataSource)
	}
	const layout = {
		labelCol: { span: 6 },
		wrapperCol: { offset: 2, span: 16 },
	};
	const handleReassignRowClick = (record) => {
    let newSelectedReassignRowKeys = [...selectedReassignRowKeys];
    let newSelectedReassignRow = [...selectedReassignRow];
    const index = newSelectedReassignRowKeys.indexOf(record.id);

    if (index === -1) {
      newSelectedReassignRowKeys.push(record.id);
      newSelectedReassignRow.push(record);
    } else {
      newSelectedReassignRowKeys.splice(index, 1);
      newSelectedReassignRow = newSelectedReassignRow.filter(row => row.id !== record.id);
    }

    setSelectedReassignRowKeys(newSelectedReassignRowKeys);
    setSelectedReassignRow(newSelectedReassignRow);
  };
	const getRowClassName = (record) => {
		return record?.student_names && 'advisor-row';
	};
	const ReAssignModal = (
		<Modal className='!top-5' title={`Re-Assign Course(s) - ${selectedRow?.faculty_name}`} 
		open={showReAssignModal} onCancel={handleCancelReassign} width={800} footer={null}>
			<Form {...layout}
				form={reAssignForm} layout="horizontal" onFinish={SaveReassign} >
				<Row gutter={20}>
					<Col span={12}>
						<Card bordered title={<span className='font-normal'>Current Details</span>} width={500} size='small' className='cardColor' >
							
									<Form.Item className="!mb-2" label="Faculty" name="current_faculty">
										<Input disabled></Input>
									</Form.Item>
							
							
									<Form.Item className="!mb-2" label="End Year:" name="end_year" rules={[{ required: true, message: constants.FieldRequired }]}>
										<Select onChange={handleEndYearChange} allowClear placeholder="From Year" popupClassName="select-dropdown">
											{fromYearOptions?.map(option => (
												<Option key={option} value={option} >{option}</Option>))}
										</Select>
									</Form.Item>
								
									<Form.Item  className='!mb-2' label="End Sem:" name="end_sem" rules={[{ required: true, message: constants.FieldRequired }]}>
										<Select onChange={handleEndSemChange} allowClear placeholder="From Sem" popupClassName="select-dropdown" >
											{endSemOptions?.sort((a, b) => a.semId - b.semId) // Sort options based on semId
												.map(option => (
													<Option key={option.id} value={option.id}>{option.label}</Option>
												))}
										</Select>
									</Form.Item>
							
						</Card>
					</Col>

					<Col span={12}>
						<Card bordered title={<span className='font-normal'>Re-Assign Details</span>} width={500} size='small' className='cardColor'>
							
									<Form.Item className="!mb-2" label="Faculty" name="faculty_id"
										rules={[{
											required: true,
											message: constants.FieldRequired
										}]}>
										<Select allowClear={true} placeholder="Select Faculty" popupClassName="select-dropdown"
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{facultyList?.map((record) =>
												<Select.Option key={record.id} value={record.id}>
													{record.faculty}
												</Select.Option>)}
										</Select>
									</Form.Item>
								
									<Form.Item className="!mb-2" label="Start Year:" name="start_year" rules={[{ required: true, message: constants.FieldRequired }]}>
										<Select disabled={disableStartYear} onChange={handleStartYearChange} allowClear placeholder="From Year" popupClassName="select-dropdown">
											{startYearOptions?.map(option => (
												<Option key={option} value={option} >{option}</Option>))}
										</Select>
									</Form.Item>
								
									<Form.Item className='!mb-2' label="Start Sem: " name="start_sem" rules={[{ required: true, message: constants.FieldRequired }]}>
										<Select disabled={disableStartSem}  allowClear placeholder="From Sem" popupClassName="select-dropdown">
											{startSemOptions?.map(option => (
												<Option key={option.id} value={option.id} >{option.label}</Option>))}
										</Select>
									</Form.Item>
								
						</Card>
					</Col>
				</Row>


				<div className='d-flex justify-content-end !mb-0'>
					<Input value={searchValue.reassign} allowClear className='w-48 adjustMargin ' placeholder="Search" onChange={(e) => onSearch(e, 'reassign')} />
				</div>

				<div className='mt-3' >
					<Table 
						rowSelection={reassignRowSelection}
						className="records-table data_table !p-0 !mb-0"
						columns={ReAssignModalColumns}
						dataSource={assignedCourses}
						rowKey={(record) => record.id}
						bordered size="small"
						pagination={{
							pageSize: constants.TablePageSize,
							showTotal: (total, range) => showTotalItems(total, range, assignedCourses),
							itemRender: getNextAndPreviousButtons,
						}}
						scroll={{ x: 768,y: '30vh' }}
						onRow={(record, rowIndex) => {
							if(!record?.end_year?.trim()) {
								return {
									onClick: () => onSelectChange(null, null, record, rowIndex),
								}
							}
						}}
						rowClassName={(record)=> getRowClassName(record)}
					/>
				</div>

				<Flex align='flex-end' gap="small" wrap="wrap" className='action-button-container'>
					<Button className=" text-capitalize btn-info" disabled={(isDisable||selectedReassignRow?.length===0)} htmlType='submit'>Re-Assign</Button>
				</Flex>

			</Form>
		</Modal>
	)

	//view modals

	const viewModalColumns = [{
		title: 'Course No.',
		dataIndex: 'course_code',
		width: "15%",
		sorter:(a, b) => a.course_code?.localeCompare(b.course_code, 'en', { numeric: true }),
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
	},
	{
		title: 'Course Name',
		dataIndex: 'course_name',
		width: "35%",
		sorter: (a, b) => a.course_name.localeCompare(b.course_name),
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		render: (course_name, record) =>
			record.discontinue === "1" ? (
				<>
					<div className="d-flex justify-content-between">
						<div className='!mb-0 '>{course_name}</div>
					
						<div >
							<Badge.Ribbon
								text={constants.discontinue}
								color="#337AB7"
								className="top-0 !mr-1 text-xs">
							</Badge.Ribbon>
						</div>
					</div>
					
				</>
			) : (
				<div className='!mb-0'>{course_name}</div>
			),

	},
	{
		title: 'Section ',
		dataIndex: 'section_no',
		width: "13%",
		sorter: (a, b) => a.section_no - b.section_no,
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		render: (_, record) => <Flex className="table-number-align numbers-alignment">{getSectionNo(record?.section_no)}</Flex>
	},
	{
		title: ' Course Type ',
		dataIndex: 'type',
		width: "23%",
		sorter: (a, b) => a.type.localeCompare(b.type),
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
	},
	{
		title: 'Start Year',
		dataIndex: 'start_year',
		width: "25%",
		sorter:(a, b) => a.year?.localeCompare(b.year, 'en', { numeric: true }),
		sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
	}
	]

	const handleOpenView = () => {
		setShowViewModal(true)
		getAssignedCourses();
	}
	const handleCancelView = () => {
		setShowViewModal(false)
		clearSelectRecord()
		setFilterTableData(dataSource)
	}

	const handleRowClick = (record) => {
    const newSelectedRowKeys = [record.id];
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(record);
  };

	const ViewModal = (
		<Modal title={`Faculty Course(s) Assigned - ${selectedRow?.faculty_name}`} 
		open={showViewModal} onCancel={handleCancelView} width={800} footer={null}>
			<div className='mt-3' >
				<Table
					className="records-table data_table"
					columns={viewModalColumns}
					dataSource={viewCourses}
					bordered size="small"
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, viewCourses),
						itemRender: getNextAndPreviousButtons,
					}}
					scroll={{ x: 768,y: '50vh' }}
					rowClassName={(record) => record.discontinue === '1' ? 'discontinued-course-row' : ''}
				/>
			</div>
		</Modal>
	)

	const tableComponent = (
		<>

			{!historyView && (
				<div className="flex flex-wrap justify-between items-center">
					<Space wrap>
						<Button className=" text-capitalize btn-info "
							disabled={selectedRowKeys.length === 0}
							onClick={handleOpenAssign}>Assign</Button>

						<Button className=" text-capitalize btn-info"
							disabled={selectedRowKeys.length === 0 || selectedRow.course_val === ""}
							onClick={handleOpenReAssign}>Re-Assign</Button>

						<Button className="text-capitalize btn-info"
							disabled={selectedRowKeys.length === 0 || selectedRow.course_val === ""}
							onClick={handleOpenView}>View</Button>

						<Button type='text' className="text-capitalize btn-info" onClick={handleHistoryView}> History </Button>
					</Space>

					<Space wrap>
						<Checkbox className='checkbox-align' checked={isActive === 0} onChange={(e) => handleCheckbox(e)}>{tabKey === "1" ? "Show Inactive Faculty" : "Show Inactive Adjunct Faculty"}</Checkbox>
						<Input value={searchValue.listing} className="w-48" placeholder="Search" onChange={(e) => onSearch(e, 'listing')} />
					</Space>
				</div>
			)}

			<div className='mt-2' >
				<Table rowSelection={{ ...rowSelection, hideSelectAll: true }}
					className="records-table data_table"
					columns={columns}
					rowKey={(selectedRow) => selectedRow.id}
					dataSource={dataSource}
					bordered size="small"
					pagination={{
						className:"!mb-0",
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, dataSource),
						itemRender: getNextAndPreviousButtons,
					}}
					scroll={{ x: 768, y: 'calc(100vh - 335px)' }}
					onRow={(record, rowIndex) => {
						return {
							onClick: () => onSelectAssignChange(null, null, record, rowIndex),
						}
					}}
				/>
			</div>
		</>
	);
	const Historycolumns = [
		
		{
			title: 'Course Code',
			dataIndex: 'course_code',
			width:"10%",
			sorter:(a, b) => a?.course_code?.localeCompare(b?.course_code, 'en', { numeric: true }),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_code) => <HighlightedText text={course_code} highlight={searchValue.indi_history} />
		},
		{
			title: 'Course Name',
			dataIndex: 'course_name',
			width:"32%",
			sorter: (a, b) => a?.course_name?.localeCompare(b?.course_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_name) => <HighlightedText text={course_name} highlight={searchValue.indi_history} />
		},
		{
			title: 'Section',
			dataIndex: 'section',
			width:"8%",
			sorter: (a, b) => a?.section - b?.section,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (section) => (
				<Flex className="table-number-align numbers-alignment">
					<HighlightedText text={section} highlight={searchValue.indi_history} />
				</Flex>
			)
		},
		{
			title: 'Start Year',
			dataIndex: 'start_year',
			width:"15%",
			sorter:(a, b) => a?.s_year?.localeCompare(b?.s_year, 'en', { numeric: true }),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (start_year) => <HighlightedText text={start_year} highlight={searchValue.indi_history} />
		},
		{
			title: 'End Year',
			dataIndex: 'end_year',
			width:"15%",
			sorter:(a, b) => a?.e_year?.localeCompare(b?.e_year, 'en', { numeric: true }),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (end_year) => <HighlightedText text={end_year} highlight={searchValue.indi_history} />
		},
		
	];
	const facultyHistory = (
		<div>
			<Row className='d-flex justify-content-between'>
				<Col className='d-flex flex-row'>
					<Button onClick={handleBackToRegularView}>Back</Button>
			  		<div className='facultyName-style'>Faculty: {selectedRow?.faculty_name}</div>
				</Col>
				<Col>
					<Input value={searchValue.indi_history} className="w-48" placeholder="Search" onChange={(e) => onSearch(e, 'indi_history')} />
				</Col>
			</Row>
			

			<div className='mt-2' >
				<Table 
					className="records-table data_table"
					columns={Historycolumns}
					dataSource={indiHistoryData}
					bordered size="small"
					pagination={{
						className:"!mb-0",
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, indiHistoryData),
						itemRender: getNextAndPreviousButtons,
					}}
					scroll={{ x: 768,y: 'calc(100vh - 320px)' }} 
				/>
			</div>
		</div>
	)
	const HistoryTabcolumns = [
		{
			title: 'Name',
			dataIndex: 'faculty',
			width: "20%",
			sorter: (a, b) => a.faculty.localeCompare(b.faculty),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (faculty) => <HighlightedText text={faculty} highlight={searchValue.history} />
		},
		{
			title: 'Courses',
			dataIndex: 'course_val',
			sorter: (a, b) => a.course_val.localeCompare(b.course_val),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_val) => <HighlightedText text={course_val} highlight={searchValue.history} />
		},
	]

	const historyTableComponent = (
		<div>
			<div className="d-flex justify-content-between">
				<Space wrap>
					<Button onClick={handleBackToRegularView}>Back</Button>
				</Space>
				<Input value={searchValue.history} className="w-48" placeholder="Search" onChange={(e) => onSearch(e, 'history')} />
			</div>

			<div className='mt-2' >
				<Table
					className="records-table data_table"
					columns={HistoryTabcolumns}
					dataSource={historyData}
					bordered size="small"
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, historyData),
						itemRender: getNextAndPreviousButtons,
					}}
					scroll={{ x: 768,y: 'calc(100vh - 350px)' }}
				/>
			</div>
		</div>
	);

	return (
		<div>
			<BreadcrumbHeader pageTitle={historyClicked ? constants.FacultyCoursesAssignmentHistory : constants.FacultyCoursesAssignment} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
				{facultyHistVisible ? (
					facultyHistory
				) : (
					historyView ? (
						showTab ? (
							<Tabs
								activeKey={historyTabKey}
								onChange={handleHistoryTab}
								type="card"
								className="card-tabs tab-margin"
							>
								<TabPane key="1" tab={<span className="text-capitalize fs-7">Faculty</span>} className="py-2">
									{historyTableComponent}
								</TabPane>
								<TabPane key="2" tab={<span className="text-capitalize fs-7">Adjunct Faculty</span>} className="py-2">
									{historyTableComponent}
								</TabPane>
							</Tabs>
						) : (
							historyTableComponent
						)
					) : (
						showTab ? (
							<Tabs
								activeKey={tabKey}
								onChange={handleTab}
								type="card"
								className="card-tabs tab-margin"
							>
								<TabPane key="1" tab={<span className="text-capitalize fs-7">Faculty</span>} className="py-2">
									{tableComponent}
								</TabPane>
								<TabPane key="2" tab={<span className="text-capitalize fs-7">Adjunct Faculty</span>} className="py-2">
									{tableComponent}
								</TabPane>
							</Tabs>
						) : (
							tableComponent
						)
					)
				)}

			</Card>
			{AssignModal}
			{ReAssignModal}
			{ViewModal}
		</div>
	)
}

export default FacultyCoursesAssignment