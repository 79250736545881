/*
File Name: Background.js
Author: Akshay Lotlikar
Modified On: 30/04/2024
Description: Display list of tabs based on user roles.
API's used:
*/
import { Button, Card, Col, Form, Row, Upload, message, Input  } from 'antd';
import React, { useEffect } from 'react';
import constants from '../../../Constants/constants';
import { UploadOutlined,DislikeOutlined,LikeOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const Background = () => {
	useEffect(() => {}, [])
	const props = {
		name: 'file',
		action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};
	return (
		<div>
			<div className="row">
				<div className="col">
					<Card className="container-border-custom">
						<Form>
							<Row>
								<Col>
								<Form.Item
									name="approve" 
									className='form-text-deco' 
								>
									<LikeOutlined />
								</Form.Item>
								<Form.Item
									name="disapprove" 
									className='form-text-deco' 
								>
									<DislikeOutlined />
								</Form.Item>
								<Form.Item
									name="criminal_background" 
									className='form-text-deco' 
									label = {constants.FormItemCriminalBackground}
								>
									<Upload {...props}>
										<Button icon={<UploadOutlined />}>Upload</Button>
									</Upload>
								</Form.Item>
								
								<Form.Item
									name="criminal_background_comments" 
									className='form-text-deco' 
								>
									<TextArea/>
								</Form.Item>
								</Col>
							</Row>
						</Form>
					</Card>
				</div>
			</div>
		</div>
	)
};
export default Background
