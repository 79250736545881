import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Card, Input } from "antd";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { fetchApi } from "../../Services/Api";
import constants from "../../Constants/constants";
import { HighlightedText } from "../../GlobalFunctions/GlobalFunctions";

const pageTitle = constants.ActiveCourses;
const breadcrumbItems = [{ label: constants.ActiveCourses, href: '/active-course-list', active: true },]
const columns = ['Course', 'Section', 'Faculty Assigned', 'Students']
const userRoleId = getCookie(COOKIE.Role);
const isStudent = userRoleId === '0'

const ActiveCourseList = () => {
  const userId = getCookie('userId')
  const [activeCourseList, setActiveCourseList] = useState([])
  const [filteredActiveCourseList, setFilteredActiveCourseList] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    getActiveCourses()
  }, [])

  const getHighlightedValue = (string) => {
    return <HighlightedText text={string} highlight={search} />
  }

  const dataSetters = (data = []) => {
    setActiveCourseList(data)
		setFilteredActiveCourseList(data)
  }

  const getActiveCourses = async () => {
    let key = isStudent ? 'student_id' : 'admin_id'
    let payload = {
			[key]: userId,
		}
		fetchApi('/view-course-details', 'POST', payload).then((res) => {
			if (res?.code === "200") {
        let data = res?.data?.map((item) => {
          return {
            ...item,
            full_course_name: `${item?.course_code} - ${item?.course_name}`
          }
        })
				dataSetters(data)
        return
			}
			dataSetters()
		}).catch(() => {
      dataSetters()
    });
  }

  const onSearch = (value) => {
    const searchData = (data) => {
      const result = [];
      data.forEach(course => {
        let matchFound = false; // Flag to track if match is found     
        // Searching by full_course_name
        if (course?.full_course_name?.toLowerCase()?.includes(value?.toLowerCase())) {
          matchFound = true;
        }
        // Searching by faculty_name
        course?.sections?.forEach(section => {
          if (section?.faculty_name?.toLowerCase()?.includes(value?.toLowerCase())) {
            matchFound = true;
          }
          // Searching by student_names
          section?.student_names?.forEach(studentGroup => {
            studentGroup?.list?.forEach(student => {
              if (student?.toLowerCase()?.includes(value?.toLowerCase())) {
                matchFound = true;
              }
            });
          });
        });
        // If any match is found, add the entire course object to the result
        if (matchFound) {
          result.push(course);
        }
      });
      return result;
    }
		setSearch(value);
    let filteredCourse = searchData(filteredActiveCourseList)
    setActiveCourseList(filteredCourse)
	};

  return (
    <div>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="mb-3 container-border-custom dashboard-card">
        <Card
            title={constants.ActiveSemesterCourses}
            className="dashboard-card-head"
          >
            <div className="flex justify-end mb-2">
					    <Input 
                className="w-48" 
                placeholder={constants.Search} 
                onChange={(e) => onSearch(e.target.value)} 
                value={search} 
              />
				    </div>
            <table className="w-full border-collapse">
              <tr>
                {
                  columns.map((column, index) => (
                    <th key={index} className="border border-gray-500 bg-[#3c8dbc] text-white font-medium p-2">{column}</th>
                  ))
                }
              </tr>
              {
                activeCourseList?.map((course) => (
                  <>
                    {
                      course?.sections?.map((section, index) => (
                        <tr key={index}>
                          {index === 0 && <th rowspan={course.sections?.length} className="border border-gray-500 p-2 font-semibold">
                            {getHighlightedValue(course.full_course_name)}
                          </th>}
                          <td className="border border-gray-500 p-2 text-right w-16">{section.section_no}</td>
                          <td className="border border-gray-500 p-2">
                            {getHighlightedValue(section.faculty_name)}
                          </td>
                          <td className="border border-gray-500 p-2">
                            {section?.student_names?.map((student) => (
                              <div title={`${constants.StudentCount}: ${section?.student_names?.length}`}>
                                <strong>{getHighlightedValue(student.year)}: </strong><>{getHighlightedValue(student.list?.join(', '))}</><br />
                              </div>
                            ))}
                          </td>
                        </tr>
                      ))
                    }
                  </>
                ))
              }
          </table>
        </Card>
      </Card>
    </div>
  );
}
 
export default ActiveCourseList;