import React,{useState,useEffect} from 'react'
import logo from '../../Assets/header-logo4.png'
import Glyphicon from '@strongdm/glyphicon';
import Form from 'react-bootstrap/Form';
import { Button, Space, message } from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons'
import { Link,useLocation,useNavigate } from 'react-router-dom';
import { fetchApi } from '../../Services/Api';
import constants from '../../Constants/constants';
import Spinner from 'react-bootstrap/Spinner';

const ForgotPassword = () => {
  
	const [email, setEmail] = useState('');
	const [maskedEmail, setMaskedEmail] = useState('');
	const location =useLocation()
	let userInfo = location.state ? location.state.userInfo : null;
	const [loading,setLoading] = useState(false)
	const navigate = useNavigate()
	const handleEmailChange = () => {
		const inputValue = userInfo.email;
		const maskedValue = inputValue.replace(/^(.{4})(.*)(@.*)$/, (match, p1, p2, p3) => {
		  const maskedPart = p2.replace(/./g, '*');
		  return p1 + maskedPart + p3;
		});
		setEmail(inputValue);
		setMaskedEmail(maskedValue);
	}

	useEffect(()=>{
		if(userInfo){
			handleEmailChange()
		}
	},[userInfo])

	const handleForgotPassword = (e) =>{
    e.preventDefault()
		setLoading(true)
    if (email) {
			let payload = null;
			payload = {user:userInfo.username,type:userInfo.type};
			if(userInfo.type==='staff' && userInfo.facultyRole==='4' && userInfo.emailType==='1'){
				payload={...payload,email_type:userInfo.emailType}
			}
      fetchApi(`/forgot-password`, 'POST', payload)
      .then((res) => {
        if (res?.code === "200") {
					setLoading(false)
					message.success(constants.ForgotSuccess)
					const routeText= userInfo.type==='staff'?'/staff-login':'/student-login'
					setTimeout(()=>{
						navigate(routeText)
					})
				}else{
					setLoading(false)
					message.error('An error occured')
				}
      }).catch((error) => {
      })
  }
}
  return ( 
    <div className="mx-auto min-vh-100 d-flex justify-content-center align-items-center p-3 p-md-0">
			{
				loading && <Spinner animation="border" role="status" className="d-flex justify-content-center align-items-center position-absolute z-3"></Spinner>
			}
			<div className="card shadow-lg login-card mb-5 z-2 rounded-0">
				<img src={logo} className="card-img-top rounded-0" alt="Logo" />			
						<Form>
							<div className="card-body pt-0">
								<div className="px-0 px-md-4 pt-3">
									
									<Form.Group md="4" controlId="validationCustomUsername">
										<div className="input-group mb-3">
											<span className="input-group-text" id="basic-addon1">
												<Glyphicon glyph='user' />
											</span>
											<Form.Control
												type="email"
												placeholder="Username"
												aria-describedby="inputGroupPrepend"
												className="rounded-end shadow-none no-exclamation"
												value={maskedEmail} 
												disabled
											/>
											<Form.Control.Feedback type="invalid">
												This field is required.
											</Form.Control.Feedback>
										</div>
									</Form.Group>
									
								</div>
								<div className="d-grid gap-2 px-0 px-md-3">
									<button className="btn btn-primary text-capitalize" onClick={handleForgotPassword}>Reset</button>
								</div>
								<br></br>
								<div className='d-grid gap-2 px-0 px-md-3'>
								
									<Button type="link" className='back-login-btn'>
										<Link to={userInfo.type==='staff'?'/staff-login':'/student-login'} >	<h6>
										<Space><ArrowLeftOutlined />Back To Login</Space> </h6></Link> </Button>
									
								</div>
							</div>
						</Form>
					
				
			</div>
		</div>
  )
}

export default ForgotPassword