import React from 'react'
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Card } from 'antd';
import PlanOfStudy from '../Students/PlanOfStudy';

const StudentStudyPlan = () => {
  const pageTitle = 'Plan of Study';
	const breadcrumbItems = [
		{ label: 'Plan of Study', href: '/', active: true },
	]
  return (
    <div>
      <BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card
				className="container-border-custom"
			>
        <PlanOfStudy/>
      </Card>
    </div>
  )
}

export default StudentStudyPlan