import React, { useEffect, useState } from "react";
import "../../../index.css";
import "../../../App.scss";
import { Outlet } from 'react-router-dom';
import Headers from "./Header";
import Sidemenu from "./Sider";
import { Layout, FloatButton, message } from 'antd';
import { COOKIE, getCookie } from "../../../Services/Cookie";
import { fetchApi } from "../../../Services/Api";
import { isArray } from "highcharts";
import constants from "../../../Constants/constants";

const Layouts = () => {
  const userType = getCookie(COOKIE.UserId);
  const [data, setData] = useState([]);
	useEffect(() => {
    notificationsList();
  }, [userType]);
  const notificationsList = () => {
    const payload = { isStudent: userType };
    fetchApi(`/get-all-notifications`, "POST", payload).then((res) => {
      if(res?.code === "200") {
        if(isArray(res?.data) && res?.data?.length > 0) {
          setData(res?.data);
          return
        }
      }
      setData([]);
    }).catch(() => {
      message.error(constants.ErrorMessage);
    })
  };
  
  const updateNotificationData = () => {
    notificationsList();
  };

  const countData = data?.length;

  return (
    <Layout>
      <Sidemenu countData={countData} />
      <Layout>
        <Headers countData={data} updateNotificationData={updateNotificationData} />
        <div className="h-[calc(100vh-50px)] p-3 overflow-y-auto">
          <Outlet context={[data, updateNotificationData]}/>
          <FloatButton.BackTop className='bottom-24 right-2.5' />
        </div>
      </Layout>
    </Layout>
  );
};
export default Layouts;