import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Card, Select, Table, Alert, Flex, message, Modal, Skeleton } from 'antd';
import BreadcrumbHeader from "./Component/BreadcrumbHeader";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { fetchStaffApi } from '../Services/Api';
import constants from "../Constants/constants";
import { Chart } from "react-google-charts";
import { WarningFilled } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import { generateAdviseeMenteeListColumn, removeExtraSpaces, surveyRedirectRoute } from '../GlobalFunctions/GlobalFunctions';
import Cookie from "../Services/Cookie";

const renderYearInProgram = (year) => {
	let displayText;
	switch (year) {
		case 1:
			displayText = constants.Freshman;
			break;
		case 2:
			displayText = constants.Sophomore;
			break;
		case 3:
			displayText = constants.Junior;
			break;
		case 4:
			displayText = constants.Senior;
			break;
		case 5:
			displayText = constants.Graduate;
			break;
		case 6:
			displayText = constants.PartTime;
			break;
		default:
			displayText = 'Year in program undefined.';
	}
	return displayText;
};

const Dashboard = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [data, updateNotificationData] = useOutletContext();
	const [semesterList, setSemesterList] = useState([]);
	const [listColumn, setListColumn] = useState([]);
	const [redirectNotificationPopupOpen, setRedirectNotificationPopupOpen] = useState(false);
  const [notificationRedirectUrl, setNotificationRedirectUrl] = useState('');
	const [semesterId, setSemesterId] = useState({
		gpaSemesterId: "",
		advisingSemesterId: ""
	})
	const [tableData, setTableData] = useState({
		gpaData: [],
		advisingData: [],
	});
	const [chartData, setChartData] = useState({
		gpaChartData: [],
		advisingChartData: [],
	});
	const [loading, setLoading] = useState(true);
	const isAdvisor = Cookie.getCookie('isAdvisor') === '1'
	const userId = Cookie.getCookie('userId')
	
	useEffect(() => {
		getSemType();
	}, []);

	//Get semester type data API
	const getSemType = () => {
		fetchStaffApi('/get-semester-type', 'GET').then((res) => {
			if (res?.code === "200") {
				let sem_list = res?.data;
				// Sort array of objects based on semester in descending order
				let descendingSortedArray = sem_list?.sort((a, b) => Number(b.semester) - Number(a.semester));
				gpaList(descendingSortedArray?.[0]?.semester, descendingSortedArray?.[0]?.is_ended);
				advisingList(descendingSortedArray?.[0]?.semester, descendingSortedArray?.[0]?.is_ended);
				if(isAdvisor) fetchAdviseeList();
				setSemesterId()
				setSemesterList(sem_list);
				setSemesterId({
					gpaSemesterId: descendingSortedArray?.[0]?.semester,
					advisingSemesterId: descendingSortedArray?.[0]?.semester
				});
				checkURLRedirect();
			} else {
				setSemesterList([]);
			}
		});
	};

	//get list of advisees for advisor
	const fetchAdviseeList = async () => {
		let payload = {
			year: 0,
			type: 0,
			semester: 0,
			faculty_id: userId,
		}
		fetchStaffApi('/get-advising-student-details', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				generateAdviseeMenteeListColumn(
					res?.data?.length > 0 ? res.data : [],
					'student',
					setListColumn
				)
				return
			}
			generateAdviseeMenteeListColumn([], '', setListColumn)
		});
	};

	// GPA list API
	const gpaList = (semester_id, is_ended) => {
		setLoading(true);
		let payload = {
			semester: semester_id,
			is_ended: is_ended
		};
		fetchStaffApi('/avg_gpa', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				const yearInProgramArray = res?.data?.alldata?.map(item => renderYearInProgram(item.year_in_program));
				let gfData = {
					xAxis: yearInProgramArray,
					yAxis: res?.data?.avg_gpa
				};
				setTableData(prevState => ({
					...prevState,
					gpaData: res?.data?.alldata
				}));
				setChartData(prevState => ({
					...prevState,
					gpaChartData: gfData
				}));
			} else {
				setTableData(prevState => ({
					...prevState,
					gpaData: []
				}));
				setChartData(prevState => ({
					...prevState,
					gpaChartData: []
				}));
			}
			setLoading(false);
		});
	};

	//REDIRECT TO SURVEY IF URL MATCHES
	const checkURLRedirect = () => {
    try {
      const URL = window.location.search
      if(!URL) return
      const urlParams = new URLSearchParams(URL);
      let survey_id = urlParams.get('survey_id')
      let survey_type = urlParams.get('survey_type')

      if(survey_id && survey_type) {
        let obj = {
          survey_id: survey_id,
          survey_type: survey_type,
        }
        setRedirectNotificationPopupOpen(true)
        setNotificationRedirectUrl(surveyRedirectRoute(obj, false));
      } else message.error(constants.ErrorMessage)
    } catch (error) {
      message.error(constants.ErrorMessage)
    }
  }

	// Advising list report API;
	const advisingList = (semester_id, is_ended) => {
		setLoading(true);
		let payload = {
			semester: semester_id,
			is_ended: is_ended
		};
		fetchStaffApi('/advising-list-report', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setTableData(prevState => ({
					...prevState,
					advisingData: res?.data?.alldata
				}));
				setChartData(prevState => ({
					...prevState,
					advisingChartData: res?.data?.graphdata
				}));
			} else {
				setTableData(prevState => ({
					...prevState,
					advisingData: []
				}));
				setChartData(prevState => ({
					...prevState,
					advisingChartData: []
				}));
			}
			setLoading(false);
		});
	};

	// GPA Chart
	const ChartOptionSingle = {
		chart: {
			type: 'column',
			height: 250,
		},
		credits: {
			enabled: false
		},
		title: {
			text: 'Average GPA'
		},
		xAxis: {
			categories: chartData.gpaChartData?.xAxis,
			crosshair: true,
			title: {
				text: 'Year',
				style: { fontWeight: 'bold' },
				margin: 20
			}
		},
		yAxis: {
			min: 0,
			max: 4,
			allowDecimals: true,
			title: {
				text: 'GPA',
				style: { fontWeight: 'bold' },
				margin: 20
			},
			tickInterval: 1,
		},
		tooltip: {
			headerFormat: '<b>Year: </b><span>{point.key}</span><table>',
			pointFormat: '<tr>' +
				'<td style="padding:0">{point.y:.2f}</td></tr>',
			footerFormat: '</table>',
			shared: true,
			useHTML: true,
			backgroundColor: "rgb(255,255,255)",
		},
		plotOptions: {
			column: {
				minPointLength: 2
			},
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					formatter: function () {
						var val = this.y;
						if (val === 0) {
							this.point.color = '#ffffff';
						}
						return val.toFixed(2);
					},
				},
			}
		},
		series: [{
			color: '#00a65a',
			showInLegend: false,
			data: chartData?.gpaChartData?.yAxis ? chartData?.gpaChartData?.yAxis : []
		}]
	};

	// Advising Chart 
	const options = {
		title: "Advising List",
		vAxis: { title: "Number of Advisee(s)" },
		hAxis: { title: "Year" },
		seriesType: "bars",
		isStacked: true,
		legend: { position: 'bottom' }
	};

	const handleSemesterChange = (val, type) => {
		if (type === "gpa") {
			setSemesterId(prevState => ({
				...prevState,
				gpaSemesterId: val.value
			}));
			gpaList(val?.value, val?.data?.is_ended)
		} else {
			setSemesterId(prevState => ({
				...prevState,
				advisingSemesterId: val.value
			}));
			advisingList(val.value, val?.data?.is_ended);
		}
	};

	const pageTitle = "Dashboard";
	const breadcrumbItems = []
	// Function to add space before opening round bracket if it doesn't exist
	const addSpaceBeforeBracket = (text) => {
		return text.replace(/([^\s])\(/g, '$1 (');
	};
	// Average GPA table 
	const AverageGPAcolumns = [
		{
			title: 'Year',
			dataIndex: 'year_in_program',
			key: 'year_in_program',
			render: (text, record) => renderYearInProgram(Number(record.year_in_program)),
		},
		{
			title: 'GPA',
			dataIndex: 'gpa',
			key: 'gpa',
			align: 'center'
		}
	];
	// Advising list table 
	const AdvisingListCols = [
		{
			title: 'Year',
			dataIndex: 'year_in_program',
			key: 'year_in_program',
			render: (text, record) => renderYearInProgram(Number(record.year_in_program)),
		},
		{
			title: 'Faculty',
			dataIndex: 'faculties',
			key: 'faculties',
			render: (text) => <span>{addSpaceBeforeBracket(text)}</span>,
		},
		{
			title: 'Advisee(s)',
			dataIndex: 'count',
			key: 'count',
			align: 'center'
		}
	];
	const renderMessage = (item) => (
		<Row>
			<Col span={12}>
				{item.notification_url ? (
					<span className="noti-mb-0" dangerouslySetInnerHTML={{ __html: removeExtraSpaces(item.notification_text) }}></span>
				) : (
					<span className="noti-mb-0" dangerouslySetInnerHTML={{ __html: removeExtraSpaces(item.notification_text) }}></span>
				)}
			</Col>
			<Col span={12}>
				<Flex justify="end">
					{dayjs(item.dt_created).format("MMM DD, YYYY [at] h:mm A")}
				</Flex>
			</Col>
		</Row>
	);

	return (
		<div>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<div>
				{data?.length > 0 ? (
					<div>
						{data
							?.filter((item) => item.priority === "1")
							.slice(0, 2)
							.map((item) => (
								<Alert
									className="mb-3"
									key={item.id}
									showIcon
									icon={<WarningFilled />}
									type="error"
									message={renderMessage(item)}
								/>
							))}
					</div>
				) : null}
			</div>
			<Card
				title="Average GPAs by class"
				className="mb-3 container-border-custom dashboard-card"
				extra={<Select
					className="w-52"
					placeholder="Select"
					onChange={(e, val) => handleSemesterChange(val, "gpa")}
					value={semesterId.gpaSemesterId}
				>
					{semesterList?.map((options) => (
						<Select.Option key={options.semester} value={options.semester} data={options}>
							{options.type}
						</Select.Option>
					))}
				</Select>}
			>
				<Row gutter={[8, 8]}>
					<Col xs={24} lg={8}>
						<Table
							columns={AverageGPAcolumns}
							dataSource={tableData.gpaData}
							className="records-table"
							size="small"
							pagination={false}
						/>
					</Col>
					<Col xs={24} lg={16}>
						{loading ? (
							<Skeleton className="my-3" active />
						) : (
						<HighchartsReact
							highcharts={Highcharts}
							options={ChartOptionSingle}
						/>)}
					</Col>
				</Row>
			</Card>

			<Card
				title="Advising List"
				className="mb-3 container-border-custom dashboard-card"
				extra={<Select
					className="w-52"
					placeholder={constants.Select}
					onChange={(e, val) => handleSemesterChange(val, "advising")}
					value={semesterId.advisingSemesterId}
				>
					{semesterList?.map((options) => (
						<Select.Option key={options.semester} value={options.semester} data={options}>
							{options.type}
						</Select.Option>
					))}
				</Select>}
			>
				<Row gutter={[8, 8]}>
					<Col xs={24} lg={8}>
						<Table
							columns={AdvisingListCols}
							dataSource={tableData.advisingData}
							className="records-table"
							size="small"
							pagination={false}
						/>
					</Col>
					<Col xs={24} lg={16}>
						{loading ? (
							<Skeleton className="my-3" active />
						) : (
						<Chart
							chartType="ComboChart"
							width="100%"
							height="400px"
							data={chartData?.advisingChartData}
							options={options}
						/>)}
					</Col>
				</Row>
			</Card>
			{listColumn?.length > 0 && 
			<Card className="mb-3 container-border-custom dashboard-card">
				<Card
					title='My Advisee(s)'
					className="dashboard-card-head"
				>
					<Row gutter={[16, 16]}>
						{listColumn}
					</Row>
				</Card>
			</Card>}
			<Modal
				centered
        maskClosable={false}
				title={constants.PleaseNote}
				open={redirectNotificationPopupOpen}
				onOk={() => navigate(notificationRedirectUrl)}
				onCancel={() => setRedirectNotificationPopupOpen(false)}
				footer={(_, { OkBtn, CancelBtn }) => (
					<>
						<CancelBtn />
						<OkBtn />
					</>
				)}
			>
				<p>{`You will be redirected to survey, do you want to proceed?`}</p>
			</Modal>
		</div>
	);
};
export default Dashboard;
