import React, { useEffect, useState } from 'react';
import { Card, Collapse, Modal, Button, Flex, Spin } from 'antd';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import FailedImportFormItem from './FailedImportFormItem';
import constants from '../../Constants/constants';
import { DownCircleFilled } from '@ant-design/icons'
const { Panel } = Collapse;
const FailedImport = () => {
	const pageTitle = 'Failed Import';
	const breadcrumbItems = [];
	const location = useLocation()
	const importForm = location.state?.importForm
	const navigate = useNavigate()
	const [importData, setImportData] = useState(location.state?.importData)
	const [showModal, setShowModal] = useState(true)
	const [loading, setLoading] = useState(false)

	const handleCloseModal = () => {
		setShowModal(false)
	}

	useEffect(() => {
		if (importData.length < 1) {
			navigate('/student-listing')
		}
	}, [importData])

	return (
		<div>
			<BreadcrumbHeader breadcrumbItems={breadcrumbItems} />
			<div className='failed-import-header'>
				<div style={{ padding: 10 }}>
					<BreadcrumbHeader pageTitle={pageTitle} />
				</div>
			</div>
			<Card>
				<div><strong>Note : </strong> List of records failed to insert. Click on the students name to view details.</div>
				{
					importData.map((record, idx) =>
						<Collapse expandIconPosition="right" className='collapse-custom' key={idx}
							expandIcon={({ isActive }) => <DownCircleFilled className='text-white' rotate={isActive ? 180 : 0} />} >
							<Panel header={`Record ${idx + 1}`} key={idx.toString()}>
								<p className='input-alert'>{record.errorResponse}</p>
								<FailedImportFormItem importForm={importForm} currentData={record.data}
									formIdx={idx.toString()} importData={importData} setImportData={setImportData} setLoading={setLoading}
								/>
							</Panel>
						</Collapse>
					)
				}
			</Card>
			<Modal title={constants.Error} open={showModal} width={400} footer={null} onCancel={handleCloseModal}>
				<p>Failed to insert some records</p>
				<Flex gap="small" align="flex-end" vertical >
					<Button type="primary" onClick={handleCloseModal}>
						{constants.Close}
					</Button>
				</Flex>
				<br></br>
			</Modal>
			{loading && (
				<div className='res-loader'>
					<Spin tip={constants.PleaseWait} size="large" />
				</div>
			)}
		</div>
	);
};
export default FailedImport;
