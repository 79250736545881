import { fetchApi } from '../../../Services/Api';
import { useState } from 'react';

const useFetchCountries = () => {
    const [countries, setCountries] = useState([]);
    const fetchCountries = () => {
        fetchApi('/get-countries', 'GET')
            .then((res) => {
                setCountries(res.data.countries);
            })
            .catch((error) => {
            });
    };
    return {
        countries,
        fetchCountries
    };
};
export { useFetchCountries };
