import { useState } from 'react';
import { fetchApi } from '../../../Services/Api';

const useFetchStates = () => {
    const [states, setStates] = useState([]);
    const fetchStates = async (countryId) => {
        try {
            const res = await fetchApi(`/get-states/${countryId}`, 'GET');
            setStates(res?.data || []);
        } catch (error) {
        }
    };
    return {
        states,
        fetchStates
    };
};

const useFetchPermaStates = () => {
    const [permaStates, setPermaStates] = useState([]);
    const fetchPermaStates = async (permaCountryId) => {
        try {
            const res = await fetchApi(`/get-states/${permaCountryId}`, 'GET');
            setPermaStates(res?.data || []);
        } catch (error) {
        }
    };
    return {
        permaStates,
        fetchPermaStates
    };
};
export { useFetchStates, useFetchPermaStates };
