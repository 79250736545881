import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Select, Button, Space, Table, Divider, message } from 'antd';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import constants from '../../../Constants/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchStaffApi, fetchStaffDownloadApi } from '../../../Services/Api';
import { COOKIE, getCookie } from '../../../Services/Cookie';
import dayjs from 'dayjs';

const MentorDetailsResults = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [questions,setQuestions]=useState(location?.state?.questions);
	const [refresh,setRefresh] = useState(false);
	const [mentorAndStudentList,setMentorAndStudentList] = useState(null);
	const [defaultOpenIds,setDefaultOpenIds] = useState([]);
	const [disableDownload,setDisabledDownload] = useState(true);
	const selectedRecord = location?.state?.selectedRecord;

	useEffect(() => {
		updateQuestionArray();
		setRefresh(!refresh);
		getMentorAndStudentDetails();
	}, []);
	const updateQuestionArray = () =>{
		let questions = location?.state?.questions;
		if(questions.length>0){
			let defaultOpen = [];
			for (let index = 0; index < questions.length; index++) {
				if(questions[index]?.question_type!=="2"){
					defaultOpen.push(questions[index]?.id);
				}
				questions[index].selectedOption = null;
				questions[index].studentsList = [];
				questions[index].mentorsList = [];
				questions[index].extraOptionText = null;
			}
			setDefaultOpenIds(defaultOpen);
		}
		setQuestions(questions);
	}
	const breadcrumbItems = [
		{
			label: constants.ManageSurveys,
			href: '/manage-surveys',
			active: false
		},
		{
			label: constants.SurveyResultDetails,
			active: true
		}
	];
	const tableColumns = [
	{
		title: constants.Questions,
		key: '1',
		render:(text,record,index)=>{
			return	<span>{(index+1)}. {record?.question_text}</span>
		}
	},
	{
		title: constants.Options,
		key:'2',
		render: (record) => {
			let display = "";
			if(record?.question_type!=="2"){
				display = record?.options?.length>0?
				<Select className='w-52' allowClear placeholder={constants.Select} onChange={(e,children)=>{selectedIds(e,record,children)}}>
					{record?.options.map((item)=><Select.Option key={item?.id} value={item?.id} item={item}>{item?.option}</Select.Option>)}
				</Select>
				:"-";
			}
			return display;
		},
	},
	]
	const downloadPDF=()=>{
		let selectedOptions = [];
		let questionOfCommentType = [];
		let userId = getCookie(COOKIE.UserId);
		if(questions.length>0){
			for (let index = 0; index < questions.length; index++) {
				if(questions[index]?.selectedOption){
					selectedOptions.push(questions[index]?.selectedOption)
				}
				if(questions[index]?.question_type==="2"){
					questionOfCommentType.push(parseInt(questions[index]?.id));
				}
			}
		}
		let payload = {
			survey_id: selectedRecord?.id,
			semester_id: location?.state?.semesterId,
		}
		if(questionOfCommentType.length>0){
			payload.questions=questionOfCommentType;
		}
		if(selectedOptions.length>0){
			payload.options=selectedOptions;
		}
		payload.admin_id=userId;
		fetchStaffDownloadApi(`/download-mentor-result`, 'POST', payload).then((res) => {
			if(res) {
				const currentDate = dayjs().format('MM-DD-YYYY');
				const modifiedString = selectedRecord?.survey_name.replace(/ /g, '_');
				var downloadLink = window.document.createElement('a');
				downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: "application/pdf"}));
				downloadLink.download = modifiedString+"_"+currentDate;
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
				return
			}
			message.error(constants.ErrorMessage);
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const selectedIds = (e,record,children) =>{
		record.selectedOption = e;
		record.extraOptionText=children?.item?.option_detail;
		let openId = defaultOpenIds;
		if(e){
			addOrRemoveIds(true,record,openId);
		}else{
			addOrRemoveIds(false,record,openId);
		}
		setRefresh(!refresh);
		getMentorAndStudentDetails();
	}
	const getMentorAndStudentDetails =()=>{
		let selectedOptions = [];
		let questionOfCommentType = [];
		if(questions.length>0){
			for (let index = 0; index < questions.length; index++) {
				if(questions[index]?.selectedOption){
					selectedOptions.push(questions[index]?.selectedOption)
				}
				if(questions[index]?.question_type==="2"){
					questionOfCommentType.push(parseInt(questions[index]?.id));
				}
			}
		}
		let payload={
			survey_id:selectedRecord?.id,
			semester_id:location?.state?.semesterId,
		}
		if(questionOfCommentType.length>0){
			payload.questions=questionOfCommentType;
		}
		if(selectedOptions.length>0){
			setDisabledDownload(false);
			payload.options=selectedOptions;
		}else{
			setDisabledDownload(true);
		}
		fetchStaffApi(`/mentor-details`, 'POST', payload).then((res) => {
			if(res&&res.data){
				let questionsList = questions;
				updateQuestionsWithComments(res?.data?.comments,questionsList,"mentors","mentorsList");
				updateQuestionsWithComments(res?.data?.comments,questionsList,"students","studentsList");
				updateQuestionsWithOptionComments(res?.data?.optionAnswers,questionsList,"mentors","mentorsList");
				updateQuestionsWithOptionComments(res?.data?.optionAnswers,questionsList,"students","studentsList");
				setMentorAndStudentList(res.data?.options);
			}else{
				setMentorAndStudentList(null);
			}
		});
	}
	const updateQuestionsWithComments = (comments,questionsList,type,typeList)=>{
		if(questionsList?.length>0){
			for (let i = 0; i < questionsList?.length; i++) {
				if(questionsList[i]?.question_type==="2"){
					questionsList[i][typeList] = [];
					if(comments[0]?.[type]?.length>0){
						for (let j = 0; j < comments[0]?.[type]?.length; j++) {
							if(comments[0]?.students[j]?.question_id===questionsList[i]?.id){
								questionsList[i]?.[typeList].push(comments[0]?.[type][j]);
							}						
						}
					}else{
						questionsList[i][typeList] = [];
					}
				}
			}
		}
		setQuestions(questionsList);
		setRefresh(!refresh);
	}
	const updateQuestionsWithOptionComments = (optionAnswers,questionsList,type,typeList)=>{
		if(questionsList?.length>0){
			for (let i = 0; i < questionsList?.length; i++) {
				if(questionsList[i]?.question_type!=="2"){
					questionsList[i][typeList] = [];
					if(optionAnswers?.[type]?.length>0){
						for (let j = 0; j < optionAnswers?.[type]?.length; j++) {
							if(optionAnswers?.students[j]?.question_id===questionsList[i]?.id){
								questionsList[i]?.[typeList].push(optionAnswers?.[type][j]);
							}				
						}
					}else{
						questionsList[i][typeList] = [];
					}
				}
			}
		}
		setQuestions(questionsList);
		setRefresh(!refresh);
	}
	const redirectToBackPage = () =>{
		navigate(`/survey-result`, { state: { selectedRecord: selectedRecord } });
	}
	const onExpandRows =(expanded,record)=>{
		let openId = defaultOpenIds;
		addOrRemoveIds(expanded,record,openId);
		setRefresh(!refresh);
	}
	const addOrRemoveIds = (expanded,record,openId) =>{
		if(expanded){
			let open = openId.findIndex((item) => item === record?.id);
			if(open===-1){
				openId.push(record?.id);
			}
		}else{
			const index = openId.indexOf(record?.id);
			if (index > -1) {
				openId.splice(index, 1);
			}
		}
		setDefaultOpenIds(openId);
		setRefresh(!refresh);
	}
	return (
		<div>
			<BreadcrumbHeader pageTitle={`${constants.SurveyResultDetails} - ${selectedRecord?.survey_name}`} breadcrumbItems={breadcrumbItems} />
			<Row>
				<Col span={24}>
					<Card className='container-border-custom position-relative'>
						<div className='flex justify-between flex-wrap gap-2 mb-2.5'>
							<div>
								<Space wrap>
									<Button type='primary' disabled={disableDownload} onClick={() => {downloadPDF()}}>{constants.DownloadPDF}</Button>
									<Button className='backButton' /* size='large' */ onClick={redirectToBackPage}>{constants.Back}</Button>
								</Space>
							</div>
						</div>
						<Table
							bordered
							className='records-table data_table mb-3'
							dataSource={questions}
							columns={tableColumns}
							size="small"
							pagination={false}
							rowKey={(record) => record?.id}
							expandable={
								questions?.some(record => record?.studentsList?.length>0 || record?.mentorsList?.length>0) ? {
									expandedRowRender: (record) => (
										<div>
											{record?.extraOptionText?<div className='mb-1 text-base'>{record?.extraOptionText}</div>:""}
											<Row>
												{record?.studentsList?.length>0&&
													<Col span={12}>
														<div className='underline underline-offset-4'>{constants?.StudentsListTitle}</div>
														<ol>
															{record?.studentsList?.map((student)=>(
																<li key ={student?.id}>{student?.student_details}</li>
															))}
														</ol>
													</Col>
												}
												{record?.mentorsList?.length>0&&
													<Col span={12}>
														<div className='underline underline-offset-4'>{constants?.MentorsListTitle}</div>
														<ol>
															{record?.mentorsList?.map((mentor)=>(
																<li key ={mentor?.id}>{mentor?.student_details}</li>
															))}
														</ol>
													</Col>
												}
											</Row>
										</div>
									),
									expandedRowKeys: defaultOpenIds,
									expandedRowClassName: (record) => (record?.studentsList?.length>0 || record?.mentorsList?.length>0 ? '!bg-white' : ''),
									rowExpandable: (record) => (record?.studentsList?.length>0 || record?.mentorsList?.length>0),
									onExpand:onExpandRows,
								} : undefined
							}
						/>
							<Divider className='mt-0 mb-3' />
						<Row gutter={[16, 16]}>
							{mentorAndStudentList?.students?.length>0&&
								<Col span={12}>
									<Card 
										className="dashboard-card-head"
										title={<div className='text-left font-medium text-base'>{constants?.StudentsListTitle}</div>}
									>
										<ol>
											{mentorAndStudentList?.students?.map((student)=>(
												<li key ={student?.id}>{student?.student_fullname}</li>
											))}
										</ol>
									</Card>
								</Col>
							}
							{mentorAndStudentList?.mentors?.length>0&&
								<Col span={12}>
									<Card
										className="dashboard-card-head"
										title={<div className='text-left font-medium text-base'>{constants?.MentorsListTitle}</div>}
									>
										<ol>
											{mentorAndStudentList?.mentors?.map((mentor)=>(
												<li key ={mentor?.id}>{mentor?.student_fullname}</li>
											))}
										</ol>
									</Card>
								</Col>
							}
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	)
}
export default MentorDetailsResults
