import React from 'react'
import { Col, Row, Form, Input, Select } from 'antd'
import PropTypes from 'prop-types';
import constants from '../../../Constants/constants';
import { checkEmptySpace } from '../../../GlobalFunctions/GlobalFunctions';
const FacultyAddressField = ({ form, countries, states, handleChange, field, title, disableAll }) => {
	return (
		<>
			<div>
				<strong>{title} Address</strong>
			</div>
			<br></br>
			<Row gutter={24}>

				<Col xl={8} lg={8} md={8} sm={24} xs={24}>
					<Form.Item
						label="Street Address"
						name={field + "_address"}
						rules={[
							{
								required: true,
								message: constants.RequiredField,
								whitespace: true,
							},
						]}
					>
						<Input 
						 placeholder='Street Address' 
						 disabled={disableAll} 
						 onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field}_address`))}
						/>
					</Form.Item>
				</Col>
				<Col xl={8} lg={8} md={8} sm={24} xs={24}>
					<Form.Item
						label="City"
						name={field + "_city_text"}
						rules={[
							{
								required: true,
								message: constants.RequiredField,
								whitespace: true,
							},
						]}
					>
						<Input 
							placeholder='City' 
							disabled={disableAll} 
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field}_city_text`))}
						/>
					</Form.Item>
				</Col>
				<Col xl={8} lg={8} md={8} sm={24} xs={24}>
					<Form.Item
						label="Zip"
						name={field + "_zip"}
						rules={[
							{
								required: true,
								message: constants.RequiredField,
								whitespace: true,
							},
						]}
					>
						<Input 
							placeholder='Zip' 
							disabled={disableAll} 
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field}_zip`))}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col xl={8} lg={8} md={8} sm={24} xs={24}>
					<Form.Item
						label="Country"
						name={field + "_country"}
						rules={[
							{
								required: true,
								message: constants.RequiredField,
							},
						]}

					>
						<Select placeholder="Select your country" onChange={handleChange} className='w-100'
							showSearch optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							disabled={disableAll}
						>
							{
								countries && countries.map((record) =>
									<Select.Option value={record.id} key={record.id}>{record.country_name}</Select.Option>
								)
							}
						</Select>
					</Form.Item>
				</Col>
				<Col xl={8} lg={8} md={8} sm={24} xs={24}>
					<Form.Item
						label="State"
						name={field + "_state"}
						rules={[
							{
								required: true,
								message: constants.RequiredField,
							},
						]}
					>
						<Select className='w-100'
							showSearch
							optionFilterProp="children"
							disabled={!states || disableAll}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							} placeholder="Select your state" >
							{states && states.map((record) =>
								<Select.Option value={record.id} key={record.id}>{record.state_name}</Select.Option>
							)
							}
						</Select>
					</Form.Item>
				</Col>

			</Row>
		</>
	)
}

FacultyAddressField.propTypes = {
	countries: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			country_name: PropTypes.string.isRequired
		})
	).isRequired,
	states: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			state_name: PropTypes.string.isRequired
		})
	).isRequired,
	handleChange: PropTypes.func.isRequired,
	field: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	disableAll: PropTypes.bool.isRequired
};

export default FacultyAddressField