import React from "react";
import constants from "../../Constants/constants";
import { Button, Flex, Modal, Result } from "antd";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { useNavigate } from "react-router-dom";

const StudentMentorRequest = ({ open, closeModal, handleSave }) => {
  const navigate = useNavigate();
  const userRoleId = getCookie(COOKIE.Role);
  const isStudent = userRoleId === '0'

  const getReturnRoute = () => {
    if(isStudent)
      return "/student-dashboard"
    return "/dashboard"
  }

  return (
    !isStudent ?
      <Result
        status="403"
        title={constants.NotAuthorizedText}
        extra={<Button onClick={() => navigate(getReturnRoute())} type="primary">Back to dashboard</Button>}
      />
    : (<Modal
        title={constants.MentorRequest}
        open={open}
        onCancel={closeModal}
        footer={null}
      >
        <div>
          <div className="mentor-request-style ">{constants.GPAMessage}</div>
          <div className="action-button-container"></div>
          <div className="ant-table-wrapper flex flex-row-reverse">
            <Flex gap="small" wrap="wrap">
              <Button
                type="primary"
                className="text-capitalize btn-info mb-2"
                onClick={() => handleSave(1)}
              >
                {constants.Yes}
              </Button>
              <Button
                type="primary"
                className="text-capitalize mb-2"
                danger
                onClick={() => handleSave(3)}
              >
                {constants.No}
              </Button>
            </Flex>
          </div>
        </div> 
      </Modal>)
  );
};

export default StudentMentorRequest;
