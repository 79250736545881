import React, { useEffect } from 'react'
import { Form, Row, Col, Input, Flex, Button, DatePicker, Select, message, InputNumber } from 'antd'
import constants from '../../Constants/constants';
import dayjs from 'dayjs';
import { handleKeyPressHandler, removeExtraSpaces, allowAlphabets, disableSpaceBar } from '../../GlobalFunctions/GlobalFunctions';
import PropTypes from 'prop-types';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { useFetchCountries } from '../Component/FacultyComponents/FetchCountries';
import { useFetchStates } from '../Component/FacultyComponents/FetchStates';
import { fetchStaffFormData } from '../../Services/Api';
import { getCookie, COOKIE } from '../../Services/Cookie';
import { useForm } from 'antd/es/form/Form';
const FailedImportFormItem = ({ importForm, currentData, formIdx, importData, setImportData }) => {
  const { countries, fetchCountries } = useFetchCountries();
  const { states, fetchStates } = useFetchStates();
  const [form] = useForm()
  const SubmitBtn = <Flex gap="small" align="flex-end" vertical >
    <Button type="primary" className='action-btn' htmlType='submit' >
      {constants.Save}
    </Button>
  </Flex>;

  const formatPhoneNumber = (phoneNumber) => {
    // Check if the phone number is already formatted
    const regex = /^\d{3}-\d{3}-\d{4}$/;
    if (!regex.test(phoneNumber)) {
      // If not, format the phone number
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return phoneNumber;
  };
  const deleteData = (formIdx) => {
    setTimeout(() => {
      const updatedImportData = [...importData];
      updatedImportData.splice(formIdx, 1);
      setImportData(updatedImportData);
    }, 3000)
  }
  const handleSubmit = (formData) => {
    let payload
    switch (importForm) {
      case 1:
        payload = {
          initial_import: 1,
          admin_id: getCookie(COOKIE.UserId),
          student_first_name: removeExtraSpaces(formData[`student_first_name${formIdx}`]),
          student_last_name: removeExtraSpaces(formData[`student_last_name${formIdx}`]),
          student_personal_email: formData[`student_personal_email${formIdx}`],
          student_middle_name: removeExtraSpaces(formData[`student_middle_name${formIdx}`]),
          student_dob: formData[`student_dob${formIdx}`],
          student_zip: formData[`student_zip${formIdx}`],
          student_mobile_number: formData[`student_mobile_number${formIdx}`],
          student_home_number: formData[`student_home_number${formIdx}`],
          student_gpa: formData[`student_gpa${formIdx}`],
          student_rank: formData[`student_rank${formIdx}`],
          student_sat_combined_super: formData[`student_sat_combined_super${formIdx}`],
          student_sat_reading_super: formData[`student_sat_reading_super${formIdx}`],
          student_sat_math_super: formData[`student_sat_math_super${formIdx}`],
          student_act_composite_super: formData[`student_act_composite_super${formIdx}`],
          student_current_country: formData[`student_current_country${formIdx}`],
          student_current_state: formData[`student_current_state${formIdx}`],
          student_permanent_country: formData[`student_current_country${formIdx}`],
          student_permanent_state: formData[`student_current_state${formIdx}`],
          student_address: removeExtraSpaces(formData[`student_address${formIdx}`]),
          student_permanent_address: removeExtraSpaces(formData[`student_address${formIdx}`]),
          student_current_city_text: removeExtraSpaces(formData[`student_current_city_text${formIdx}`]),
          student_permanent_city_text: removeExtraSpaces(formData[`student_permanent_city_text${formIdx}`]),
          student_social_security_number: formData[`student_social_security_number${formIdx}`],
        }
        break;
      case 2:
        payload = {
          id_import: 1,
          student_first_name: removeExtraSpaces(formData[`student_first_name${formIdx}`]),
          student_last_name: removeExtraSpaces(formData[`student_last_name${formIdx}`]),
          student_personal_email: formData[`student_personal_email${formIdx}`],
          student_university_email: formData[`student_university_email${formIdx}`] ? formData[`student_university_email${formIdx}`] : '',
          student_id_number: formData[`student_id_number${formIdx}`] ? formData[`student_id_number${formIdx}`] : ''
        }
        break;
      case 3:
        payload = {
          student_first_name: removeExtraSpaces(formData[`student_first_name${formIdx}`]),
          student_last_name: removeExtraSpaces(formData[`student_last_name${formIdx}`]),
          student_personal_email: formData[`student_personal_email${formIdx}`],
          student_asha_number: formData[`student_asha_number${formIdx}`],
          student_psha_number: formData[`student_psha_number${formIdx}`]
        }
        break;
      default:
        break;
    }
    fetchStaffFormData(`/save-student-record`, 'POST', payload)
      .then((res) => {
        if (res?.code === "200" && (res.message === "Data updated successfully" ||
          res.message === 'Data added successfully')) {
          message.success(constants.ImportedSuccessfully)
          deleteData(formIdx)
        } else {
          message.error(res?.message[0])

        }
      }).catch((error) => {
      })
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  useEffect(() => {
    if (currentData.student_current_country) {
      fetchStates(currentData.student_current_country)
      form.setFieldsValue({
        [`student_current_country${formIdx}`]: currentData.student_current_country,
        [`student_current_state${formIdx}`]: currentData?.student_current_state,
      })
    }
    let homePhn = currentData.student_home_number ? formatPhoneNumber(currentData?.student_home_number.toString()) : undefined;
    let cellPhn = currentData.student_mobile_number ? formatPhoneNumber(currentData?.student_mobile_number.toString()) : undefined;
    form.setFieldsValue({
      [`student_first_name${formIdx}`]: currentData.student_first_name,
      [`student_last_name${formIdx}`]: currentData.student_last_name,
      [`student_middle_name${formIdx}`]: currentData.student_middle_name,
      [`student_home_number${formIdx}`]: homePhn,
      [`student_mobile_number${formIdx}`]: cellPhn,
      [`student_dob${formIdx}`]: currentData.student_dob ? dayjs(currentData.student_dob) : undefined,
      [`student_gpa${formIdx}`]: Number(currentData.student_gpa),
      [`student_rank${formIdx}`]: Number(currentData.student_rank),
      [`student_sat_combined_super${formIdx}`]: currentData.student_sat_combined_super,
      [`student_sat_math_super${formIdx}`]: currentData.student_sat_math_super,
      [`student_sat_reading_super${formIdx}`]: currentData.student_sat_reading_super,
      [`student_zip${formIdx}`]: currentData.student_zip,
      [`student_act_composite_super${formIdx}`]: currentData.student_act_composite_super,
      [`student_personal_email${formIdx}`]: currentData.student_personal_email,
      [`student_university_email${formIdx}`]: currentData.student_university_email,
      [`student_asha_number${formIdx}`]: currentData.student_asha_number,
      [`student_psha_number${formIdx}`]: currentData.student_psha_number,
      [`student_id_number${formIdx}`]: currentData.student_id_number,
      [`student_address${formIdx}`]: currentData.student_address,
      [`student_current_city_text${formIdx}`]: currentData.student_current_city_text,
      [`student_social_security_number${formIdx}`]: currentData.student_social_security_number,
    });
  }, [currentData, form])

  const disabledDate = current => {
    // If the current date is after the date being checked, disable it
    return current && current > dayjs().endOf('day');
  };

  const handleCellPhoneInputChange = (e, formval) => {
    const inputValue = e.target.value;
    // const formattedInput = phoneNumberFormatter(inputValue);
    form.setFieldsValue({ [formval]: inputValue })
  };

  const handleChange = (e) => {
    form.setFieldsValue({ [`student_current_state${formIdx}`]: undefined });
    fetchStates(e)
  }

  const ashaPshaImport = <Form layout="vertical" form={form} onFinish={handleSubmit}>
    <Row gutter={24}>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="First Name"
          name={`student_first_name${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)}/>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="Last Name"
          name={`student_last_name${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)}/>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.PersonalEmail}
          name={`student_personal_email${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
            {
              type: 'email',
              message: constants.ValidEmailAddress
            }
          ]}
        >
          <Input onKeyDown={disableSpaceBar} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="ASHA Number"
          name={`student_asha_number${formIdx}`}
          rules={[
            {
              min: 8,
              message: constants.AshaValidateMessage,
            },
          ]}
        >
          <Input onKeyPress={(e) => handleKeyPressHandler(e, 8)} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="PSHA Number"
          name={`student_psha_number${formIdx}`}
          rules={[
            {
              min: 5,
              message: constants.PshaValidateMessage,
            },
          ]}
        >
          <Input onKeyPress={(e) => handleKeyPressHandler(e, 5)} />
        </Form.Item>
      </Col>
    </Row>
    {SubmitBtn}
  </Form>;

  const initialImportForm = <Form layout="vertical" form={form} onFinish={handleSubmit}>
    <Row gutter={24}>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="First Name"
          name={`student_first_name${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="Middle Name"
          name={`student_middle_name${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)}/>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="Last Name"
          name={`student_last_name${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)}/>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.DOB}
          name={`student_dob${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
          ]}
        >
          <DatePicker placeholder='Start Date' format={constants.DateFormat}
            disabledDate={disabledDate} className='date-input' />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.CellPhone}
          name={`student_mobile_number${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.requiredFieldRules,
              whitespace: true,
            },
            // {
            //   min: 12,
            //   message: constants.MobileValidateMessage
            // },
            // {
            //   max: 12,
            //   message: constants.MobileValidateMessage
            // }
          ]}
        >
          <Input 
            onKeyPress={(e) => handleKeyPressHandler(e, null)}
            onChange={(e) => handleCellPhoneInputChange(e, `student_mobile_number${formIdx}`)}
          />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.HomePhn}
          name={`student_home_number${formIdx}`}
          rules={[
            {
              required: false,
            },
            // {
            //   min: 12,
            //   message: constants.HomePhone
            // },
            // {
            //   max: 12,
            //   message: constants.HomePhone
            // }
          ]}
        >
          <Input 
            onKeyPress={(e) => handleKeyPressHandler(e, null)}
            onChange={(e) => handleCellPhoneInputChange(e, `student_home_number${formIdx}`)} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.PersonalEmail}
          name={`student_personal_email${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
            {
              type: 'email',
              message: constants.ValidEmailAddress
            }
          ]}
        >
          <Input onKeyDown={disableSpaceBar} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.StreetAddress}
          name={`student_address${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.City}
          name={`student_current_city_text${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="Zip Code"
          name={`student_zip${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.Country}
          name={`student_current_country${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
          ]}

        >
          <Select placeholder="Select your country" onChange={handleChange} className='w-100'
            showSearch optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              countries && countries.map((record) =>
                <Select.Option value={record.id} key={record.id}>{record.country_name}</Select.Option>
              )
            }
          </Select>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24} >
        <Form.Item
          label={constants.State}
          name={`student_current_state${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField
            },
          ]}
        >
          <Select className='w-100'
            showSearch
            optionFilterProp="children"
            disabled={!states || states.length === 0}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            } placeholder="Select your state" >
            {states && states.map((record) =>
              <Select.Option value={record.id} key={record.id}>{record.state_name}</Select.Option>
            )
            }
          </Select>
        </Form.Item>
      </Col>
      <Col xl={4} lg={4} md={4} sm={24} xs={24}>
        <Form.Item
          label={constants.Gpa}
          name={`student_gpa${formIdx}`}
          rules={[
            {
              type: 'number',
            },
            {
              required: true,
              message: constants.RequiredField
            },
          ]}
        >
          <InputNumber step={0.01} min={0.01} max={4} />
        </Form.Item>
      </Col>
      <Col xl={4} lg={4} md={4} sm={24} xs={24}>
        <Form.Item
          label={constants.Rank}
          name={`student_rank${formIdx}`}
          rules={[
            {
              validator: (_, value) => {
                if (!value || !isNaN(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Not a valid number.'));
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.SocialSecurityNumber}
          name={`student_social_security_number${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            onKeyPress={(e) => handleKeyPressHandler(e, 6)} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="SAT Combined Super"
          name={`student_sat_combined_super${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            onKeyPress={(e) => handleKeyPressHandler(e, 6)} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="SAT Reading Super"
          name={`student_sat_reading_super${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            onKeyPress={(e) => handleKeyPressHandler(e, 6)} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="Sat Math Super"
          name={`student_sat_math_super${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            onKeyPress={(e) => handleKeyPressHandler(e, 6)}
          />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.ActCompositeSuper}
          name={`student_act_composite_super${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            onKeyPress={(e) => handleKeyPressHandler(e, 6)} />
        </Form.Item>
      </Col>
    </Row>
    {SubmitBtn}
  </Form>;

  const StudentIdMuEmailForm = <Form layout="vertical" form={form} className='failedImportCard' onFinish={handleSubmit}>
    <Row gutter={24}>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="First Name"
          name={`student_first_name${formIdx}`}
          rules={[
            {
              required: false,
              message: constants.RequiredField,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)}/>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="Last Name"
          name={`student_last_name${formIdx}`}
          rules={[
            {
              required: false,
              message: constants.RequiredField,
            },
          ]}
        >
          <Input onKeyPress={(e)=>allowAlphabets(e)}/>
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label="ID"
          name={`student_id_number${formIdx}`}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.PersonalEmail}
          name={`student_personal_email${formIdx}`}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
            },
            {
              type: 'email',
              message: constants.ValidEmailAddress
            }
          ]}
        >
          <Input onKeyDown={disableSpaceBar} />
        </Form.Item>
      </Col>
      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={constants.MuEmail}
          name={`student_university_email${formIdx}`}
          rules={[
            {
              required: false,
            },
            {
              type: 'email',
              message: constants.ValidEmailAddress
            }
          ]}
        >
          <Input onKeyDown={disableSpaceBar} />
        </Form.Item>
      </Col>
    </Row>
    {SubmitBtn}
  </Form>;

  return (
    <>
      {
        (() => {
          switch (importForm) {
            case 1:
              return initialImportForm;
            case 2:
              return StudentIdMuEmailForm;
            default:
              return ashaPshaImport;
          }
        })()
      }
    </>
  )
}
FailedImportFormItem.propTypes = {
  importForm: PropTypes.number.isRequired,
  currentData: PropTypes.object.isRequired,
  formIdx: PropTypes.string.isRequired,
  importData: PropTypes.array.isRequired,
  setImportData: PropTypes.func.isRequired,
};

export default FailedImportFormItem
