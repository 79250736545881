import React, { useEffect, useState } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { Card, Input, Flex, Table, Row, Col, Typography, Tooltip, Modal, Badge, Spin, Collapse, 
	Button,message } from 'antd'
import { fetchStaffApi } from '../../Services/Api'
import DOMPurify from 'dompurify';
import dayjs from 'dayjs'
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { performSearch, CustomSortIcon, HighlightedText, getNextAndPreviousButtons, showTotalItems, sortTwoNumbers } from '../../GlobalFunctions/GlobalFunctions';
import { scrollUp } from '../Component/FacultyComponents/FacultyFunctions';
import constants from '../../Constants/constants';
import { sendEmail } from '../Component/MailComponents/SendEmailApi';
import {DownCircleFilled} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
const { Panel } = Collapse;
const { Title } = Typography;
const EmailReports = () => {
	const pageTitle = 'Email Reports'
	const breadcrumbItems = [{ label: 'Email Reports', active: true }]
	const [showDetailModal, setShowDetailModal] = useState(false)
	const [emailData, setEmailData] = useState([])
	const [searchMailText, setSearchMailText] = useState('')
	const [modalText, setModalText] = useState('')
	const [selectedRecord, setSelectedRecord] = useState([])
	const [emailRecords, setEmailRecords] = useState([])
	const [mailId, setMailId] = useState(null)
	const [len, setLen] = useState(0)
	const [filteredMailData, setFilteredMailData] = useState([])
	const [filteredRecords, setFilteredRecords] = useState([])
	const [loading, setLoading] = useState(false)
	const [onSaveLoad, setOnSaveLoad] = useState(false)
	const [resendLoading, setResendLoading] = useState(false)
	const isMobileDesktop = useMediaQuery({
    query: '(min-width: 576px)'
  })
	const form = null;
	//Function to fetch a list of mails
	const getEmailList = async () => {
		setLoading(true)
		await fetchStaffApi(`/get-mail-reports`, 'GET').then((res) => {
			if (res?.code === '200') {
				/**Checking the recipient count. If more than 1 recipient then display Freshman, sophomore etc
				 else display their name in table**/ 
				if (res?.data?.length > 0) {
					res?.data?.forEach(record => {
						if (parseInt(record.count_recipient) < 2) {
							record.recipient = `${record.name ? record.name : '-'}`;
						} else {
							record.recipient = `${record.email_recipient ? record.email_recipient : '-'}`;
						}
					});
				}
				setEmailData(res?.data)
				setFilteredMailData(res?.data)
				setLoading(false)
			}else{
				setLoading(false)
			}
		}).catch((error) => {
		})
	}
	//Fecthing details of an selected email in reports in table
	const getDetails = (id) => {
		setLoading(true)
		fetchStaffApi(`/get-mail-details?email_id=${id}`, 'GET').then((res) => {
			if(res?.code ==='200'){
				setLen(res.data.length)
					setEmailRecords(res.data)
				setFilteredRecords(res.data)
				setLoading(false)
			}
			else if(res?.code === 400){
				setLoading(false)
				message.error('No details found!')
				setEmailRecords([])
				setFilteredRecords([])
			}else{
				message.error(constants.ErrorMessage)
				setLoading(false)
			}
		}).catch((error) => {
		})
	}
	//Serach function to search records in email reports table
	const onSearchMail = (event) => {
		const search = event.target.value;
		const fieldNames = ['email_subject', 'recipient', 'dt_updated', 'status']
		setSearchMailText(search);
		let searchedValue = performSearch(search, filteredMailData, fieldNames);
		setEmailData(searchedValue);
	};
	
	//Email reports table columns
	const columns = [
		{
			title: constants.Subject,
			dataIndex: 'email_subject',
			width: '40%',
			render: (subject) => <HighlightedText text={subject} highlight={searchMailText} />
		},
		{
			title: constants.Recipient,
			dataIndex: 'recipient',
			render: (recipient, record) => {
				const recipientContent = parseInt(record?.count_recipient) < 2 ? (
					<Row ><Col span={21}><HighlightedText text={recipient} highlight={searchMailText} /></Col>
					{parseInt(record?.count)<2?'':<Col span={3}><Flex align="flex-end" vertical >
					<Badge count={record?.count} color="#54626F" /></Flex></Col>}
					</Row>
				) : (<Row ><Col span={21}><HighlightedText text={recipient?.replace(/,/g, ", ").replace(/\s+/g, ' ')} highlight={searchMailText} /></Col>
					<Col span={3}>
						<Flex align="flex-end" vertical >
							<Badge count={record?.count} color="#54626F" /></Flex>
					</Col>
				</Row>);
				return recipientContent;
			},
			width: '30%',
			sorter: (a, b) => {
				const recipientA = a?.recipient.toLowerCase();
				const recipientB = b?.recipient.toLowerCase();
				return recipientA.localeCompare(recipientB);
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: constants.Date,
			dataIndex: `dt_updated`,
			render: (dt_updated) => <HighlightedText text={dt_updated} highlight={searchMailText} />,
			// sorter: (a, b) => a.dt_updated.localeCompare(b.dt_updated),
			sorter: (a, b) => sortTwoNumbers(a,b,"dt_updated",true),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
			width: '20%',
		},
		{
			title: constants.Status,
			dataIndex: 'status',
			width: '10%',
			render: (status) => <HighlightedText text={status} highlight={searchMailText} />
		},
	]
//Appending another key value in email details
	emailRecords.forEach(record => {
		record.full_name = `${record.first_name} ${record.last_name}`;
	});
	filteredRecords.forEach(record => {
		record.full_name = `${record.first_name} ${record.last_name}`;
	});
	//Search function to search in email details column
	const onSearchRecord = (event) => {
		const search = event.target.value;
		const fieldNames = ['full_name', 'email_status', 'personal_email','roles','year']
		setModalText(search);
		let searchedValue = performSearch(search, filteredRecords, fieldNames);
		setEmailRecords(searchedValue);
	};
	//Function to convert base64 document to a normal file if only one detail is present
	const converter = (mail_attachment, defaultFileName = 'file', defaultMimeType = 'application/octet-stream') => {
		try {
			const typeMatch = mail_attachment.match(/^data:([^;]+);base64,(.+)$/);
			if (!typeMatch || typeMatch.length !== 3) {
				throw new Error('Invalid attachment format');
			}
			const contentType = typeMatch[1];
			const base64Data = typeMatch[2];
			const extensionMatch = contentType.match(/\/([a-zA-Z0-9]+)$/); // Extract extension from content type
			const extension = extensionMatch ? `.${extensionMatch[1]}` : '';
			const fileNameWithExtension = defaultFileName + extension;
			const binaryString = atob(base64Data);
			const byteArray = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++) {
				byteArray[i] = binaryString.charCodeAt(i);
			}
			const blob = new Blob([byteArray], { type: contentType });
			const processFile = new File([blob], fileNameWithExtension, { type: contentType });
			return processFile;
		} catch (error) {
			return null;
		}
	};
	//Function to send email from table record
	const sendEmailRecord = async(record) => {
		setResendLoading(true)
		scrollUp()
		let mail_attachment = selectedRecord?.email_attachment ? selectedRecord?.email_attachment : null;
		let processFile;
		if (mail_attachment !== null) {
			processFile = converter(mail_attachment)
		}
		const mail_recipient = record.student_id ? record.student_id : record.faculty_id
		const formData = new FormData()
		formData.append('mail_content', selectedRecord.email_content);
		formData.append('mail_attachment', processFile)
		formData.append('email_id', record.email_id)
		formData.append('reset', 1)
		formData.append('mail_subject', selectedRecord.email_subject)
		formData.append('semester_type', 0)
		formData.append('mail_type', selectedRecord.email_type)
		formData.append('mail_to', selectedRecord.email_recipient)
		formData.append('mail_recipient[0]', mail_recipient)
		formData.append('is_student', record.is_student)
		formData.append('email_id_type', record.email_id_type)
		await sendEmail(formData, setResendLoading, form);
		getEmailList()
		getDetails(mailId)
	}
	//Columns of details table
	const detailColumns = [
		{
			title: constants.Name,
			dataIndex: 'full_name',
			width: '15%',
			sorter: (a, b) => a.full_name.localeCompare(b.full_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (fullName) => <HighlightedText text={fullName} highlight={modalText} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: constants.Sent,
			dataIndex: 'email_status',
			width: '10%',
			render: (email_status) => email_status !== '0' ? <Tooltip placement="top" title={constants.Sent}><p>{constants.Yes}</p></Tooltip> : <p>{constants.No}</p>
		},
		{
			title: `${constants.ResourceRole}s`,
			dataIndex: 'roles',
			key:'roles',
			render: (text,record) => {
				return(
			<HighlightedText text={record.roles !== 'Student'? record.roles : record.year} highlight={modalText} />	
			)
		},
			width: '20%',
			sorter: (a, b) => {
				const valueA = a.roles !== 'Student' ? a.roles : a.year;
				const valueB = b.roles !== 'Student' ? b.roles : b.year;
				return valueA.localeCompare(valueB);
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: `${constants.Email} ID`,
			width: '20%',
			render: (record) => {
				const emailIdType = record.email_id_type;
				const emailToDisplay = emailIdType === '1' ? record.personal_email : record.university_email;
				return <HighlightedText text={emailToDisplay} highlight={modalText} />;
			},
			sorter: (a, b) => a.personal_email.localeCompare(b.personal_email),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: constants.Date,
			dataIndex: `dt_created`,
			width: '20%',
			render: (dt_created) => <HighlightedText text={dayjs(dt_created).format(constants.DateAndTimeFormat)} highlight={modalText} />
		},
		{
			title: constants.Resend,
			width: '15%',
			render: (record) => <Button type="primary" size='small' className='action-btn' onClick={() => sendEmailRecord(record)} >{constants.Resend}</Button>
		},
	]

	const handleShowDetailModal = (id = null) => {
		let ID = id ? id : mailId
		setShowDetailModal(true);
		getDetails(ID)
	};
	const handleCloseDetailModal = () => {
		setShowDetailModal(false);
	};

	const handleRowSelection = (record) => {
		const key = record.id;
		if (mailId === key) {
			setMailId(null);
			setSelectedRecord([]);
		} else {
			setMailId(key);
			setSelectedRecord(record);
		}
	};
	const rowSelection = {
		selectedRowKeys: [mailId],
		onSelect: handleRowSelection,
	};
	const resendEmail = async() => {
		setResendLoading(true)
		setOnSaveLoad(true)
		scrollUp()
		let mail_attachment = selectedRecord?.email_attachment ? selectedRecord?.email_attachment : null;
		let processFile;
		if (mail_attachment !== null) {
			processFile = converter(mail_attachment)
		}
		let studentId
		let is_student
		let email_id_type;
		let facultyId
		if (emailRecords?.length > 0) {
			emailRecords.forEach(record => {
				studentId = record.student_id;
				email_id_type = record.email_id_type;
				facultyId = record.faculty_id
				if (studentId !== null) {
					is_student = 1
				} else {
					is_student = 0
				}
			});
		}
		const formData = new FormData()
		formData.append('mail_content', selectedRecord.email_content);
		formData.append('mail_attachment', processFile)
		formData.append('email_id', selectedRecord.id)
		formData.append('reset', 1)
		formData.append('mail_subject', selectedRecord.email_subject)
		formData.append('semester_type', 0)
		formData.append('mail_type', selectedRecord.email_type)
		formData.append('mail_to', selectedRecord.email_recipient)
		formData.append('mail_recipient[0]', studentId ? studentId : facultyId)
		formData.append('is_student', is_student)
		formData.append('email_id_type', email_id_type)
		await sendEmail(formData, setResendLoading, form)
		setOnSaveLoad(false)
		getEmailList()
		getDetails(mailId)
	}
	
	const onRowClick = (record) => {
    handleRowSelection(record);
  };

	useEffect(() => {
		getEmailList()
	}, [])

	return (
		<div className='email-container'>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
					<Row>
					<Col xl={12} lg={12} md={12} sm={12} xs={24}>
						<Flex gap="small" wrap="wrap">
							<Button className="text-capitalize btn-info" onClick={() => handleShowDetailModal()}
								disabled={selectedRecord.length < 1} >{constants.Details}</Button>
						</Flex>
					</Col>
					<Col xl={12} lg={12} md={12} sm={12} xs={24}>
						<Flex gap="small" align="flex-end" vertical>
							<Input placeholder='Search'
								className={isMobileDesktop?"w-48":'w-100 mt-1'}
								onChange={onSearchMail} />
						</Flex>
					</Col>
				</Row>
				<Spin tip={constants.PleaseWait} spinning={loading}>
					<Table
						rowSelection={{
							type: 'checkbox',
							...rowSelection,
							hideSelectAll: true,
						}}
						columns={columns} dataSource={emailData} bordered size="small" rowKey={(record) => record.id}
						className="records-table data_table mt-2" rowSelectedBg='#e6f4ff'
						pagination={{
							pageSize: constants.TablePageSize,
							showTotal: (total, range) => showTotalItems(total, range, emailData),
							itemRender: getNextAndPreviousButtons,
						}}
						searchableProps={{ fuzzySearch: true }}
						scroll={{
							x: 800,
							y: 'calc(100vh - 325px)'
						}}
						onRow={(record) => ({
							onClick: () => onRowClick(record),
							onDoubleClick: () => handleShowDetailModal(record?.id),
						})}
					/>
				</Spin>
			</Card>
			<Modal title="Email Information" className='!top-5' open={showDetailModal} onCancel={handleCloseDetailModal} width={800} footer={null}>
				<Row>
					<Title level={5}>{constants.Subject}: {selectedRecord.email_subject}</Title>
				</Row>
				{len === 1 ?
					<Spin tip={constants.PleaseWait} spinning={resendLoading}>
						<Row>
							<Col span={16}>
								<div>
									<ul className="no-decoration-list">
										<li className='no-underline'>{constants.Name}: {emailRecords?.[0]?.first_name} {emailRecords?.[0]?.middle_name} {emailRecords?.[0]?.last_name}</li>
										<li className='no-underline'>Mail Sent: {dayjs(emailRecords.dt_created).format(constants.DateAndTimeFormat)}</li>
										<li className='no-underline'>Email Id: {emailRecords?.[0]?.email_id_type === '1' ? emailRecords?.[0]?.personal_email : emailRecords?.[0]?.university_email}</li>
										{
											emailRecords?.[0]?.email_status === '0' ?
												<li className='no-underline'>Email status: Not Sent</li>
												: ''
										}
									</ul>
								</div>
							</Col>
							<Col span={8}>
								<Button disabled={onSaveLoad} type="primary" className='w-100' onClick={resendEmail} block>{constants.Resend}</Button>
							</Col>
						</Row>
						<br></br>
						</Spin>
					: ''
				}
				<Row>
					<Col span={24}>
					<Collapse
							expandIconPosition="right"
							className="collapse-custom"
							expandIcon={({ isActive }) => (
								<DownCircleFilled className="text-white" rotate={isActive ? 180 : 0} />
							)}
						>
							<Panel header={<strong>{constants.Content}</strong>}>
								<div
									style={{
										height: '200px', // Set the desired height
										overflowY: 'auto', // Add a vertical scrollbar if content overflows
									}}
									dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedRecord.email_content) }}
								></div>
							</Panel>
						</Collapse>
					</Col>
				</Row>
				{len > 1 ?
					<>
						<Row>
							<Col span={6} ><br></br> <Title level={5}>Recipients</Title></Col>
							<Col offset={10} span={8}>
								<br></br>
								<Flex gap="small" align="flex-end" vertical>
									<Input placeholder='Search' className='w-48'
										onChange={onSearchRecord} />
								</Flex>
							</Col>
						</Row>
						<Spin tip={constants.PleaseWait} spinning={resendLoading}>
						<Table columns={detailColumns} dataSource={emailRecords} bordered size="small" rowKey={(record) => record.key}
							className="records-table data_table mt-1" rowSelectedBg='#e6f4ff' pagination={{
								pageSize: 10,
								showTotal: (total, range) => showTotalItems(total, range, emailRecords),
								itemRender: getNextAndPreviousButtons,
							}}
							scroll={{
 								y: 'calc(100vh - 320px)',
  							x:700
							}}
							/>
							</Spin>
					</>
					: <br></br>}
			</Modal>
		</div>
	)
}
export default EmailReports
