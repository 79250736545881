import axios from "axios";
import constants from "../../Constants/constants";
import { COOKIE,getCookie, deleteCookie } from "../Cookie";
import { encodeURI } from "js-base64";
/**
 * @name fetchApi
 * @description will fetch the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
/**
 * @name fetchStaffApi
 * @description will fetch the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
//function to fetch student api
const cookiesToDelete=()=>{
	deleteCookie(COOKIE.UserId, "/");
	deleteCookie(COOKIE.UserName, "/");
	deleteCookie(COOKIE.FirstName, "/");
	deleteCookie(COOKIE.MiddleName, "/");
	deleteCookie(COOKIE.LastName, "/");
	deleteCookie(COOKIE.Token,"/");
	deleteCookie(COOKIE.FacultyIdFromEdit, "/");
	deleteCookie(COOKIE.IsActive, "/");
	deleteCookie(COOKIE.IsAdvisor, "/");
	deleteCookie(COOKIE.Role, "/");
	sessionStorage.clear();
}

const getRedirectRoute = () => {
	let route;
	let url = localStorage.getItem('surveyUrl') ? encodeURI(localStorage.getItem('surveyUrl')) : ''
	if(getCookie(COOKIE.Role) !== "0") {
		route = '/staff-login'
	}else {
		route = '/student-login'
	}
	if(url) route+=`?${url}`
	return route;
}

export const fetchApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.ApiUrl}${param}`,
		data: variables,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if(err.response.data.error_message==="Invalid token"){
					cookiesToDelete();
					let route = getRedirectRoute();
          window.location.href = constants.BaseUrl + route;
				}
				if (err.response.data.message) {
					return err.response.data;
				} else if(err.response.data.error_message){
					return err.response.data;
				}else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				}else if(err.response.data.error_message){
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});
export const fetchStaffApi = (param = null, method = null, variables = null, type = null) => 
	axios({
		method: method,
		url: type==='student'? `${constants.StudentApiUrl}${param}`:`${constants.StaffApiUrl}${param}`,
		data: variables,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if(err.response.data.error_message==="Invalid token"){
					cookiesToDelete();
					let route = getRedirectRoute();
					window.location.href = constants.BaseUrl + route;
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});

export const fetchStaffFormData = (url = null, method = null,formData = null, type=null) =>
	axios({
		method,
		url: type==='student'? `${constants.StudentApiUrl}${url}`: type==="common"?`${constants.ApiUrl}${url}`:`${constants.StaffApiUrl}${url}`,
		data: formData,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`,
			'Content-Type': 'multipart/form-data', // Make sure to set the content type for FormData
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if(err.response.data.error_message==="Invalid token"){
					cookiesToDelete();
					let route = getRedirectRoute();
					window.location.href = constants.BaseUrl + route;
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});

export const fetchStaffDownloadApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.StaffApiUrl}${param}`,
		data: variables,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
		responseType: 'blob'
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if(err.response.data.error_message==="Invalid token"){
					cookiesToDelete();
					let route = getRedirectRoute();
					window.location.href = constants.BaseUrl + route;
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});

export const fetchDownloadApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.ApiUrl}${param}`,
		data: variables,
		headers: {
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
		responseType: 'blob'
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if (err.response.data.error_message === "Invalid token") {
					cookiesToDelete();
					let route = getRedirectRoute();
					window.location.href = constants.BaseUrl + route;
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});
