import { Upload, message, Modal,Button, Space, Flex, Form } from 'antd';
import PropTypes from 'prop-types';
import constants from '../../Constants/constants';
import { useState } from 'react';

const FileUpload = ({
  name,
  listType,
  className,
  showUploadList,
  beforeUpload,
  onChange,
  renderContent,
  isMultiple,
  defaultFileList,
  onPreviewFile,
  onRemoveFile,
  fileCount,
  disabled,
  accept,
  useDragger,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [fileToRemove, setFileToRemove] = useState(null);
  const handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
    if (onChange) {
      onChange(info);
    }
  };

  const handleRemove = (file) => {
    // Display confirmation modal
    let index = defaultFileList.findIndex((val) => val?.uid === file?.uid);
    if(index > -1) {
      if(defaultFileList?.[index]?.originFileObj) {
        return true;
      }
    }
    setFileToRemove(file);
    setShowModal(true);
    return false;
	};
  
  const closeModal =() => {
    setShowModal(false);
  };

  const handleOk = () => {
    // User confirmed, remove the file
    if (fileToRemove) {
      onRemoveFile(fileToRemove);
      setShowModal(false);
      setFileToRemove(null);
    }
  };

  const customRequest = ({ _, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

  const UploadComponent = useDragger ? Upload.Dragger : Upload;

  return (
    <>
      <UploadComponent
        name={name}
        listType={listType}
        className={`${className}${!useDragger ? 'upload-file-list' : ''}`}
        showUploadList={showUploadList}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        multiple={isMultiple}
        fileList={defaultFileList}
        onPreview={onPreviewFile}
        onRemove={handleRemove}
        maxCount={fileCount}
        disabled={disabled}
        customRequest={customRequest}
        accept={accept}
      >
        {renderContent}
      </UploadComponent>
      <Modal
        title={'Warning'}
        open={showModal}
        footer={null}
        cancelText={constants.Close}
        onCancel={closeModal}
      >
        <div>
          {constants.UploadFileDeletePopupMessg}
          <Flex justify='flex-end'>
            <Space>
              <Form.Item >
                <Button
                  type='primary'
                  size="middle"
                  className="text-capitalize"
                  onClick={closeModal}
                >
                  {constants.Close}
                </Button>
              </Form.Item>
              <Form.Item >
                <Button
                  type='primary'
                  size="middle"
                  className="text-capitalize"
                  danger
                  onClick={handleOk}
                >
                  {constants.Delete}
                </Button>
              </Form.Item>
            </Space>
          </Flex>
        </div>
      </Modal>
    </>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  showUploadList: PropTypes.bool.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  renderContent: PropTypes.node.isRequired,
  maxCount: PropTypes.number,
  beforeUpload: PropTypes.func,
  onChange: PropTypes.func,
  defaultFileList: PropTypes.array,
  onPreviewFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
  accept: PropTypes.string,
  useDragger: PropTypes.bool
};

export default FileUpload;
