import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Input, Badge, Space, Divider, Tag } from 'antd';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { CustomSortIcon, HighlightedText, performSearch } from '../../GlobalFunctions/GlobalFunctions';
import { fetchStaffApi } from '../../Services/Api';
import constants from '../../Constants/constants';
const UserRole = () => {
	const [userRoles, setUserRoles] = useState([])
	const [searchText, setSearchText] = useState('');
	const [filteredData, setFilteredData] = useState(userRoles);
	const breadcrumbItems = [
		{
			label: constants.UserRoles,
			active: true
		}
	];
	useEffect(() => {
		getUserRoles();
	}, [])
	const getUserRoles = () => {
		fetchStaffApi(`/get-user-roles`, 'GET').then((res) => {
			if (res?.data) {
				setUserRoles(res?.data);
				setFilteredData(res?.data);
			} else {
				setUserRoles([]);
				setFilteredData([]);
			}
		});
	}
	const userRolesTabColumns = [
		{
			title: constants.RoleType,
			key: '1',
			width: '20%',
			render: (record) => {
				let facultyRoleName = record?.faculty_role_name;
				return <HighlightedText text={facultyRoleName} highlight={searchText} />
			},
			sorter: (a, b) => { return a.faculty_role_name.localeCompare(b.faculty_role_name) },
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.Staff,
			key: '2',
			render: (record) => {
				const activeFacs = record?.active || '';
				const inactiveFacs = record?.inactive || '';

				if (record?.active_count !== '0' && record?.inactive_count !== '0') {
					return {
						children:
						<>
							<div>
									<Space size={'small'} className='!items-start'>
										<Tag color="green" className='!mr-0'><div className='w-16 text-center'>{constants.Active}</div></Tag><Badge count={record?.active_count} style={{ backgroundColor:'rgb(101 163 13)'}}></Badge>:
										<HighlightedText text={activeFacs} highlight={searchText} />
								</Space>
							</div>
							<Divider className='!my-1' />
							<div>
									<Space size={'small'} className='!items-start'>
										<Tag className='!mr-0'><div className='w-16 text-center'>{constants.InActive}</div></Tag><Badge count={record?.inactive_count} style={{ backgroundColor:'rgb(156 163 175)'}}></Badge>:
										<HighlightedText text={inactiveFacs} highlight={searchText} />
								</Space>
							</div>
						</>,
						props:{rowSpan:1}
				};
				} else if (record?.active_count !== '0') {
					return (
						<div>
							<Space size={'small'}>
								<Tag color="green" className='!mr-0'><div className='w-16 text-center'>{constants.Active}</div></Tag><Badge count={record?.active_count} style={{backgroundColor: 'rgb(101 163 13)'}}></Badge>:
								<HighlightedText text={activeFacs} highlight={searchText} />
							</Space>
						</div>
					);
				} else if (record?.inactive_count !== '0') {
					return (
						<div>
							<Space size={'small'}>
								<Tag className='!mr-0'><div className='w-16 text-center'>{constants.InActive}</div></Tag><Badge count={record?.inactive_count} style={{ backgroundColor: 'rgb(156 163 175)' }}></Badge>:
								<HighlightedText text={inactiveFacs} highlight={searchText} />
							</Space>
						</div>
					);
				} else {
					return null;
				}
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		}
	]
	
	const onSearch = (event) => {
		const search = event.target.value;
		setSearchText(search);
		const searchFieldNames = [
			'faculty_role_name',
			'inactive',
			'active'
		];
		let searchedValue = performSearch(search, userRoles, searchFieldNames);
		setFilteredData(searchedValue);
	};
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.UserRoles} breadcrumbItems={breadcrumbItems} />
			<Row>
				<Col span={24}>
					<Card className="container-border-custom">
						<div className="flex justify-end">
							<Input className="w-48 mb-2.5" placeholder={constants.Search} onChange={onSearch} value={searchText} />
						</div>
						<Table
							className='records-table'
							columns={userRolesTabColumns}
							dataSource={filteredData}
							pagination={false}
							bordered
							size='small'
							scroll={{ x: 768, y: 'calc(100vh - 240px)' }}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default UserRole
