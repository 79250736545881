import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import constants from '../../../Constants/constants';
import { fetchApi, fetchStaffApi, fetchStaffDownloadApi, fetchStaffFormData, } from '../../../Services/Api';
import { Checkbox, Col, Divider, Form, Row, message, Table, Card, Space, Button, Modal, Flex } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { getStatusName, getNextAndPreviousButtons, getGpaLevelsAndModifyDataFormat } from '../../../GlobalFunctions/GlobalFunctions';
import FileUpload from '../../Component/FileUpload';
import dayjs from 'dayjs';
import { useFetchStates,useFetchPermaStates } from '../../Component/FacultyComponents/FetchStates';
import OtherHistoryArchived from './OtherHistoryArchived';

const ArchivedStudentDetails = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const [listCountries, setListCountries] = useState([]);
	const [showPermanentAdress, setShowPermanentAdress] = useState(false);
	const [showSummerColumns, setShowSummerColumns] = useState(false);
	const [statusTableData, setStatusTableData] = useState([]);
	const [studentDetails, setStudentDetails] = useState([]);
	const [imageURL, setImageURL] = useState("");
	const [profileFile, setProfileFile] = useState([]);
	const [uploadFileList, setUploadFileList] = useState([]);
	const [isActive, setIsActive] = useState('');
	const [isTransferChecked, setIsTransferChecked] = useState(false);
	const [currentStatus, setCurrentStatus] = useState("");
	const [mentorDetails,setMentorDetails] = useState([]);
	const { permaStates, fetchPermaStates } = useFetchPermaStates();
	const { states, fetchStates } = useFetchStates();
	const [academicSpecs, setAcademicSpecs] = useState([]);
	const [visibleUnarchiveModal,setVisibleUnarchiveModal]= useState(false);
	const [advisorDetails, setAdvisorDetails] = useState([]);
	const studentID = location?.state?.student_id
	const yearProgram={
		"1":constants.Freshman,
		"2":constants.Sophomore,
		"3":constants.Junior,
		"4":constants.GraduateYear1,
		"5":constants.GraduateYear2,
	}
	//setting form details for student.
	const setStudentFormDetails = (student_details, current_status) => {
		fetchStates(student_details?.student_current_country);
		fetchPermaStates(student_details?.student_permanent_country)
		setCurrentStatus(current_status);
		setIsActive(student_details?.is_active === '2' ? '0' : student_details?.is_active);
		setIsTransferChecked(student_details?.is_transfer === "1" ? true : false);
		getAllMentor(student_details?.semester)
		form.setFieldsValue({
			user_profile: student_details?.student_image,
			gpa: student_details?.student_gpa,
			transfer_details: student_details?.transfer_details,
			transfer_gpa: student_details?.transfer_gpa,
			transfer: student_details?.is_transfer === "1" ? true : false,
		});
	};

	//get student details
	const getStudentDetails = (id) => {
		getCountries();
		fetchStaffApi(`/get-student-data/${id}`, 'get').then((res) => {
			if (res?.code === "200") {
				let studentRecord = res?.data?.student_data[0]
				const currentStatus = res?.data?.status[0]?.new_status
				res?.data?.status?.shift() //REMOVE FIRST ELEMENT FROM ARRAY TO HIDE CURRENT STATUS FROM STATUS HISTORY TABLE
				setStatusTableData(res?.data?.status);
				setStudentFormDetails(studentRecord, currentStatus);
				setStudentDetails(studentRecord);
				setImageURL(studentRecord?.student_image);
				setUploadFileList(res?.data?.document);
				getAdvisorDetails(id, studentRecord?.year_in_program);
				getStudentGpaHistory(res?.data?.year_in_program_studied, studentRecord)
				//check for permanent adress same as current.
				if (res?.data?.student_data?.[0]?.student_permanent_address === res?.data?.student_data?.[0]?.student_address) {
					setShowPermanentAdress(true);
				} else {
					setShowPermanentAdress(false);
				}
			} else {
				setStudentDetails([]);
				setStatusTableData([]);
				setShowPermanentAdress(false);
			}
		});
	};

	useEffect(() => {
		getStudentDetails(studentID);
	}, [studentID]);

	const getStudentGpaHistory = (yearInPrograms, { year_in_program, semester }) => {
		function removeDuplicateYearPrograms(arr) {
			return arr?.map(item => item.year_in_program)
			.filter((value, index, self) => self.indexOf(value) === index)?.sort((a, b) => { return a - b })
		}

		let semesters = ['first', 'second', 'third', 'fourth', 'fifth']
		yearInPrograms = removeDuplicateYearPrograms(yearInPrograms)
		let payload =  {
			year: year_in_program, 
			semester: semester, 
			student_id: studentID 
		}
		fetchStaffApi(`/gpa-history`, 'POST', payload).then((res) => {
			let result = res?.data?.[0]
			if(!result) return
			
			let array = []
			let showSummerCols = false

			if(yearInPrograms?.length === 0) return
			yearInPrograms.forEach((item, index) => {
				let ind = parseInt(item)
				let semesterObj = {
					key: index + 1,
					year_in_program: ind,
					fall: constants.Fall,
					spring: constants.Spring,
					summerOne: constants.SummerOne,
					summerTwo: constants.SummerTwo,
					fallGpa: result[`${semesters[ind - 1]}_fall_gpa`],
					springGpa: result[`${semesters[ind - 1]}_spring_gpa`],
					summerGpaOne: result[`${semesters[ind - 1]}_summer_gpa_one`],
					summerGpaTwo: result[`${semesters[ind - 1]}_summer_gpa_two`],
				};
				array.push(semesterObj);
				if(!showSummerCols && (semesterObj.summerGpaOne || semesterObj.summerGpaTwo)) showSummerCols = true
			});
			setShowSummerColumns(showSummerCols)
			setAcademicSpecs(array)
		}).catch((err) => console.log(err))
	}
	const getAdvisorDetails = (studentId,yearInProgram) => {
		fetchStaffApi(`/students-advisor-list/${studentId}`, 'GET').then((res) => {
			if (res?.data) {
				const groupedData = {};
				// Group data by 'year_in_program'
				res?.data.forEach(item => {
					const key = item.year_in_program;
					if(key<=yearInProgram){
						if (!groupedData[key]){
							groupedData[key] = {
								key,
								year_in_program: `${item.year_in_program}`,
								fall: 'Fall',
								spring: 'Spring',
								summer: 'Summer',
								fallAdvisorName: "-",
								springAdvisorName: "-",
								summerAdvisorName: "-",
							};
						}
						// Populate Advisor values based on 'semester_type'
						switch (item.semester_type) {
							case '1':
								groupedData[key].fallAdvisorName = item.advisor_name?item.advisor_name:"-";
								break;
							case '2':
								groupedData[key].springAdvisorName = item.advisor_name?item.advisor_name:"-";
								break;
							case '3':
								groupedData[key].summerAdvisorName = item.advisor_name?item.advisor_name:"-";
								break;
							default:
								break;
						}
					}
				});
				const formattedData = Object.values(groupedData);
				setAdvisorDetails(formattedData);
			}
		});
	}
	const updateStatusToInactive=()=>{
		fetchStaffApi(`/unarchived-student/${studentID}`, 'PUT').then((res) => {
			if (res?.code==="200") {
				setVisibleUnarchiveModal(false);
				message.success(res?.message);
				navigate(`/student-listing`);
			}else{
				message.error(res?.message);
			}
		});
	}
	//get all country list
	const getCountries = () => {
		fetchApi(`/get-countries`, 'get').then((res) => {
			if (res?.code === "200") {
				setListCountries(res?.data?.countries);
			} else {
				setListCountries([]);
			}
		});
	};

	const getCountryNameById=(id)=>{
		const country = listCountries.find(country => country.id === id);
		return country ? country.country_name : "-";
	}
	const getStateNameById=(id,listOfStates)=>{
		const state = listOfStates.find(state => state.id === id);
		return state ? state.state_name : "-";
	}
	const statusColumn = [
		{
			title: <b>{constants.Status}</b>,
			dataIndex: 'new_status',
			key: 'new_status',
			render: (status_id) => <span>{getStatusName(status_id)}</span>
		},
		{
			title: <b>{constants.DateChanged}</b>,
			dataIndex: 'dt_updated',
			key: 'dt_updated',
			render: (dt_updated) => <span>{dt_updated ? dayjs(dt_updated).format(constants.DateFormat) : null}</span>
		},
		{
			title: <b>{constants.ChangedBy}</b>,
			key: 'changed_by',
			render: (val) => <span>{val?.faculty_first_name} {val?.faculty_last_name}</span>
		},
	];

	//collaps component
	const collapeComponent = (label, type) => {
		if (type === "table") {
				return (<Table
					size='small'
					className="records-table data_table"
					columns={statusColumn}
					dataSource={statusTableData}
					bordered
					pagination={{
						showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
						itemRender: getNextAndPreviousButtons,
						pageSize: '20'
					}}
					scroll={{
						y: '200px'
					}}
				/>)
		} else {
			return mentorCheck();
		}
	};
	const mentorCheck=()=>{
		let mentorId = studentDetails?.mentor_id;
		if(mentorId){
			return (
				<Row gutter={[24, 16]} className='mt-4'>
				<Col xs={24} md={5} xl={5}><label>{constants.Name}</label> </Col>
				<Col xs={24} md={7} xl={7}>
					{studentDetails?.mentor_first_name&&studentDetails?.mentor_last_name?`${studentDetails?.mentor_first_name} ${studentDetails?.mentor_last_name}`:"-"}
				</Col>
				<Col xs={24} md={5} xl={5}><label>{constants.Email}</label></Col>
					<Col xs={24} md={7} xl={7}>
						{studentDetails?.mentor_university_email?studentDetails?.mentor_university_email:"-"}
					</Col>
				<Col xs={24} md={5} xl={5}><label>{constants.ContactNo}</label></Col>
				<Col xs={24} md={7} xl={7}>
					{studentDetails?.mentor_mobile_number?studentDetails?.mentor_mobile_number:"-"}
				</Col>
			</Row>
			);
		}else{
			return constants.NoMentorAssigned;
		}
	}
	const uploadButton = (
		<UserOutlined className='fs-1' />
	);

	const profileRenderContent = imageURL ? (
		<img src={imageURL} alt="avatar" className='photoPick !w-32 !h-32' />
	) : (
		uploadButton
	);

	//download uploaded file function
	const downloadFile = (file) => {
		let fileExtention = file?.name?.split('.').pop();
		fetchStaffDownloadApi(`/download-student-document/${file?.id}`, "get", null).then(
			(res) => {
				if (res) {
					let url, a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application" }),
						{ type: `data:attachment/${fileExtention}` }
					);
					a = document.createElement("a");
					a.download = file?.name;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
				} else {
					message.error("Failed to download");
				}
			}
		);
	};

	const deleteFile = (file) => {
		fetchStaffApi(`/delete-student-document/${file?.id}`, 'delete').then((res) => {
			if (res?.code === "200") {
				message.success(res?.message);
				getStudentDetails(location.state?.student_id);
			} else {
				message.error(res?.message);
			}
		});
	};

	const getAllMentor = (semValue) => {
		let payload= {
			value: 1,
			semester: semValue
		}
		fetchStaffApi('/get-all-mentors','POST',payload).then((res)=> {
			if (res?.code === "200") {
				setMentorDetails(res.data)
			}
		});
	}

	function generateColumns() {
		const renderText = (text) => (
			<span className="numbers-alignment">{text || '-'}</span>
		)
		let cols = [
			{
				title: constants.Fall,
				render:(_, record) => renderText(record?.fallGpa)
			},
			{
				title: constants.Spring,
				render:(_,record) => renderText(record?.springGpa)
			}
		]
		if(!showSummerColumns) return cols
		cols.push(
			{
				title: constants.SummerOne,
				render:(_, record) => renderText(record?.summerGpaOne)
			},
			{
				title: constants.SummerTwo,
				render:(_, record) => renderText(record?.summerGpaTwo)
			}
		)
		return cols
	}

	const columnsGPALevel = [
		{ 
			title: constants.Years,
			key: '1',
			render: (record) => {
				return yearProgram[record.year_in_program] || '';
			},
		},
		{
			title: constants.Semester,
			children: generateColumns(),
		}
	];
	const columnsAdvisorDetails = [
		{ 
			title: constants.Years,
			key: '1',
			render: (record) => {
				return yearProgram[record.year_in_program] || '';
			},
		},
		{
			title: constants.Semester,
			children:[
				{
					title:constants.Fall,
					render:(_, record)=>(
						<span>
							{record?.fallAdvisorName}
						</span>
					)
				},
				{
					title: constants.Spring,
					render:(_,record)=>(
						<span>
							{record.springAdvisorName}
						</span>
					)
				},
			]
		}
	];

	const gpaTooltip = (studentDetails?.student_gpa && studentDetails?.grad_gpa) ? {
		title:
		<>
			<span>{`Undergrad GPA: ${parseFloat(studentDetails?.student_gpa)?.toFixed(2)}`}</span>
			<br />
			<span>{`Grad GPA: ${parseFloat(studentDetails?.grad_gpa)?.toFixed(2)}`}</span>
		</>,
		icon: <InfoCircleOutlined />,
		placement: 'right'
	} : null
	return (
		<div>
			<div className='student-form-header relative h-[100px] mt-2 banner-radius'>
				<div className='flex justify-end items-center mr-3'>
					<Space direction='vertical'>
						<Form.Item
							colon={false}
							label={<span className='w-11 inline-block text-white fw-bold'>{constants.Gpa}</span>}
							name="gpa"
							className='!mb-0 mt-2'
							tooltip={gpaTooltip}
						>
							<span className='w-11 inline-block text-white fw-bold'>
								{studentDetails?.grad_gpa ? parseFloat(studentDetails?.grad_gpa)?.toFixed(2) : 
								studentDetails?.student_gpa ? parseFloat(studentDetails?.student_gpa)?.toFixed(2) : 
								'N/A'}
							</span>
						</Form.Item>
						<Form.Item
							name="is_Active"
							valuePropName='checked'
							className='!mb-0'
						>
							<span className='p-2 text-white rounded-pill min-w-40 inline-block fw-bold'>
								<span className='w-11 inline-block'>{constants.Status}</span>: {
									constants.StatusList.map((item) => (
										item.value === studentDetails?.current_status ? item.label : ""
									))}
							</span>
						</Form.Item>
						</Space>
				</div>
			</div>
			<Form layout="vertical" form={form} scrollToFirstError={true}>
				<Form.Item
					name="user_profile"
					className='absolute z-3 left-[-2px] top-[-16px] !mb-0'
				>
					<FileUpload
						renderContent={profileRenderContent}
						name='avatar'
						listType='picture-circle'
						className='avatar-uploader avatar-upload-lg '
						showUploadList={false}
						isMultiple={false}
						disabled={true}
					/>
				</Form.Item>
			
			{isTransferChecked&&<Form.Item
					name="transfer"
					className='mb-0 flex justify-end'
				>
					<Checkbox
						checked={isTransferChecked}
						disabled={true}
					>
						{constants.Transfer}
					</Checkbox>
				</Form.Item>}
				<Row gutter={[24, 0]} className='mt-10'>
					<Col xl={12} md={24} sm={24} xs={24}>
						<Card
							className="mb-3 container-border-custom dashboard-card"
							title="Student Details"
						>
							<div>
								<Row gutter={[24, 16]}>
									<Col xs={24} md={5} xl={5}><label>{constants.Name}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_first_name} {studentDetails?.student_middle_name} {studentDetails?.student_last_name}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.StudentId}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_id_number?studentDetails?.student_id_number:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.Type}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_type ? constants.StudentTypeList.find((item) => item.value === studentDetails?.student_type)?.label :"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.YearProgram}</label> </Col>
									<Col xs={24} md={7} xl={7}>{yearProgram[studentDetails?.year_in_program]?yearProgram[studentDetails?.year_in_program]:""}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.DOB}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_dob?dayjs(studentDetails?.student_dob).format("MMM D, YYYY"):"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.AshaNumber}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_asha_number?studentDetails?.student_asha_number:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.PshaNumber}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_psha_number?studentDetails?.student_psha_number:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.CellPhone}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_mobile_number?studentDetails?.student_mobile_number:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.HomePhn}</label></Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_home_number?studentDetails?.student_home_number:"-"}</Col>
									<Col xs={24} md={5} xl={5}> <label>{constants.Username}</label></Col>
									<Col xs={24} md={7} xl={7}><span className='break-words'>{studentDetails?.student_username?studentDetails?.student_username:"-"}</span></Col>
									<Col xs={24} md={24} xl={5}><label>{constants.PersonalEmail}</label></Col>
									<Col xs={24} md={24} xl={7}><span className='break-words'>{studentDetails?.student_personal_email?studentDetails?.student_personal_email:"-"}</span></Col>
									<Col xs={24} md={24} xl={5}><label>{constants.MuEmail}</label></Col>
									<Col xs={24} md={24} xl={7}><span className='break-words'>{studentDetails?.student_university_email?studentDetails?.student_university_email:"-"}</span></Col>
									{studentDetails?.student_sat_combined_super||
									studentDetails?.student_sat_reading_super||
									studentDetails?.student_sat_math_super||
									studentDetails?.student_act_composite_super?
										<Divider className='mt-0 mb-3' />:""
									}
									{studentDetails?.student_sat_combined_super&&<>
										<Col xs={24} md={5} xl={5}><label>{constants.SatCombinedSuper}</label></Col>
										<Col xs={24} md={7} xl={7}>
											{studentDetails?.student_sat_combined_super}
										</Col>
									</>}
									{studentDetails?.student_sat_reading_super&&<>
										<Col xs={24} md={5} xl={5}><label>{constants.SatReadingSuper}</label> </Col>
										<Col xs={24} md={7} xl={7}>
											{studentDetails?.student_sat_reading_super}
										</Col>
									</>}
									{studentDetails?.student_sat_math_super&&<>
										<Col xs={24} md={5} xl={5}><label>{constants.SatMathSuper}</label> </Col>
										<Col xs={24} md={7} xl={7}>
											{studentDetails?.student_sat_math_super}
										</Col>
									</>}
									{studentDetails?.student_act_composite_super&&<>
										<Col xs={24} md={5} xl={5}><label>{constants.ActCompositeSuper}</label> </Col>
										<Col xs={24} md={7} xl={7}>
											{studentDetails?.student_act_composite_super}
										</Col>
									</>}
									<Divider className='mt-0 mb-3' />{/*ADDRESS DETAILS  */}
								</Row>
								<div className='mb-2'><label>{constants.CurrentAddress}</label></div><br/>
								<Row gutter={[24, 16]}>
									<Col xs={24} md={5} xl={5}><label>{constants.StreetAddress}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_address?studentDetails?.student_address:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.City}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_current_city?studentDetails?.student_current_city:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.Zip}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_zip?studentDetails?.student_zip:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.Country}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_current_country?getCountryNameById(studentDetails?.student_current_country,):"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.State}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_current_state?getStateNameById(studentDetails?.student_current_state,states):"-"}</Col>
								</Row><br/>
								<div>
									<Checkbox
										disabled={true}
										checked={showPermanentAdress}>
											{constants.AdressLabel}
									</Checkbox>
								</div>
								{!showPermanentAdress ?
								<>
									<br/>
									<div className='mb-2'><label>{constants.PermanentAddress}</label></div><br/>
									<Row gutter={[24, 16]}>
									<Col xs={24} md={5} xl={5}><label>{constants.StreetAddress}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_permanent_address?studentDetails?.student_permanent_address:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.City}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_permanent_city?studentDetails?.student_permanent_city:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.Zip}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_permanent_zip?studentDetails?.student_permanent_zip:"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.Country}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_permanent_country?getCountryNameById(studentDetails?.student_permanent_country):"-"}</Col>
									<Col xs={24} md={5} xl={5}><label>{constants.State}</label> </Col>
									<Col xs={24} md={7} xl={7}>{studentDetails?.student_permanent_state?getStateNameById(studentDetails?.student_permanent_state,permaStates):"-"}</Col>
								</Row>
								</> :
								null}
							</div>
						</Card>
						{isTransferChecked&&<Card
							className="mb-3 mt-4 container-border-custom dashboard-card"
							title={constants.TransferDetails}
						>
							<Row gutter={[24, 16]}>
								<Col xs={24} md={5} xl={5}><label>{constants.Details}</label></Col>
								<Col xs={24} md={7} xl={19}>{studentDetails?.transfer_details?studentDetails?.transfer_details:"-"}</Col>
							
								<Col xs={24} md={5} xl={5}><label>{constants.Date}</label></Col>
								<Col xs={24} md={7} xl={19}>{studentDetails?.transfer_date?dayjs(studentDetails?.transfer_date).format("MMM D, YYYY"):"-"}</Col>
							</Row>
						</Card>}
					</Col>
					<Col xl={12} md={24} sm={24} xs={24}>
						<Card
						className="mb-3 container-border-custom dashboard-card"
						title={constants.GpaHistory}
						>
							<Table
								dataSource={academicSpecs}
								columns={columnsGPALevel}
								bordered
								size="small"
								className='records-table data_table table-middle'
								pagination={false}
							/>
						</Card>
						<Card
							className="mb-3 mt-4 container-border-custom dashboard-card"
							title={constants.MentorDetls}
							>
							{collapeComponent(constants.MentorDetls, "mentor")}
						</Card>
						<Card
							className="mb-3 mt-4 container-border-custom dashboard-card"
							title={constants.AdvisorDetls}
						>
							<Table
								dataSource={advisorDetails}
								columns={columnsAdvisorDetails}
								bordered
								size="small"
								className='records-table data_table table-middle'
								pagination={false}
							/>
						</Card>
					</Col>
				</Row>
				<Row gutter={[24, 0]} className='mt-4'>
				<Col xl={12} lg={12} md={24} sm={24} xs={24}>
					<Card              
						className="mb-3 container-border-custom dashboard-card"
						title={"Documents"}>
							<OtherHistoryArchived studentId={location.state?.student_id}/>
					</Card>
					</Col>
				<Col xl={12} lg={12} md={24} sm={24} xs={24}>
					<Card              
						className="mb-3 container-border-custom dashboard-card"
						title={"Status History"}>
							{collapeComponent(constants.StatusHistory, "table")}
					</Card>
				</Col>
				</Row>
				<div className='mt-2 float-end'>
					<Space wrap>
						<Button
							type="primary" danger
							onClick={() =>
								{navigate(`/student-listing`);
								setVisibleUnarchiveModal(false);
							}}
						>
							{constants.Cancel}
						</Button>
						{studentDetails?.semester === '0' && <Button
							type="primary"
							// htmlType="submit"
							onClick={()=>setVisibleUnarchiveModal(true)}
						>
							{constants.Unarchive}
						</Button>}
					</Space>
				</div>
				<Modal
					width={600}
					title={constants.PleaseNote}
					open={visibleUnarchiveModal}
					// onOk={()=>setVisibleUnarchiveModal(false)}
					onCancel={()=>setVisibleUnarchiveModal(false)}
					footer={[
						<Button key="back" onClick={()=>setVisibleUnarchiveModal(false)} type='primary' danger className='mb-3'>
							{constants.Cancel}
						</Button>,
						<Button key="save" onClick={()=>updateStatusToInactive()} className='btn-info me-3 mb-3'>
							{constants.Unarchive}
						</Button>,
					]}
					>
						{constants.ConfirmUnarchiveModalText}
				</Modal>
			</Form>
		</div>
	)
};

export default ArchivedStudentDetails;