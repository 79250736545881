import InitialImportDoc from '../Assets/Document/initial_import_sample.xls'
import StudentIdImportDoc from '../Assets/Document/student_id_import_sample.xls'
import StudentAshaPshaImportDoc from '../Assets/Document/student_asha_psha_sample.xls'

const constants = {
	//API environment constants
	BaseUrl: process.env.REACT_APP_URLS_FOR_BASE,
	EnviromentName: process.env.REACT_APP_ENV,
	ApiUrl: process.env.REACT_APP_API_URL,
	StaffApiUrl: process.env.REACT_APP_API_STAFF_URL,
	StudentApiUrl: process.env.REACT_APP_API_STUDENT_URL,
	Login: 'login',
	ForgotPsd: 'forgot password?',
	Advising: 'Advising',
	AssignAdvisors: 'Assigning Advisors',
	AdvisingDocs: 'Advising Documents',
	Assign: 'assign',
	Reassign: 're-assign',
	View: 'view',
	Schedule: 'schedule',
	Faculty: 'Faculty',
	Notes: 'Notes',
	AdvisingNotes: 'Advising Notes',
	ViewChecklist: 'View Checklist',
	checklistTitle: 'student file - advising document inventory and advising checklist',
	checklistTitleForArchived: 'student file - advising document checklist',

	PlanOfStudyAdvisement: 'Plan of Study Advisement',
	StudentsAssigned: 'Students Assigned',
	FutureStudentsAssigned: 'Future Students Assigned',
	StudentsAssignedMsg: 'Student(s) assigned to advisor successfully',
	AssignToAdvisor: 'Assign Student(s) to Advisor -',
	ReassignToAdvisor: 'Re-Assign Student(s) from Advisor -',
	ViewStudentAssigned: 'View Student(s) Assigned to Advisor -',
	SelectFacAdvisor: 'Select Faculty Advisor:',
	Student: 'Student',
	YearProgram: 'Year in Program',
	Type: 'Type',
	Mentor: 'Mentor',
	Freshman: 'Freshman',
	Sophomore: 'Sophomore',
	Junior: 'Junior',
	Senior: 'Senior',
	Graduate: 'Graduate',
	FullTime: 'Full Time',
	PartTime: 'Part Time',
	Undeclared: 'Undeclared',
	Prerequisites: 'Prerequisites only',
	UploadSchedule: 'Upload Schedule',
	Upload: 'Upload',
	Previous: 'Previous',
	Next: 'Next',
	//Notifications
	SentNotifications: "Sent Notifications",
	Active: "Active",
	UpdatedOn: "Updated On",
	Notifications: "Notifications",
	StartDate:'Start Date',
	EndDate:'End Date',
	List: "List",
	Add: "Add",
	Edit: "Edit",
	Details: "Details",
	MyView: "My Notifications",
	DateFormat: "MMM DD, YYYY",
	ShowHiddenNtfn: "Show Hidden Notifications",
	ShowExpiredNotifications: "Show Expired",
	EditNotification: "Edit Notification",
	NotificationVisibility: "Visibility",
	Priority: "Priority",
	High: "High",
	Medium: "Medium",
	Low: "Low",
	NotificationText: "Notification Text",
	Save: "Save",
	SaveChanges: 'Save changes',
	Submit: "Submit",
	Hidden: "Hidden",
	Visible: "Visible",
	VisibleFrom: "From: ",
	VisibleTo: "To: ",
	Always:'Always',
	Email: 'Email',
	Phone: 'Phone',
	Position: 'Position',
	//Student Listing
	StudentListing: "Student Listing",
	Listing: "Listing",
	SelectType: "Type",
	SelectYear: "Year",
	SelectStatus: "Status",
	SortBy: "Sort By",
	Select: "Select",
	Break: "Break",
	Dismissed: "Dismissed",
	LeftProgram: "Left Program",
	Probation: "Probation",
	SlpMajor: "SLP Major",
	FirstName: "First Name",
	LastName: "Last Name",
	OnCampus: "On Campus",
	Online: "Online",
	ShowAllStudents: "Show All Students",
	ShowActiveStudents: "Show Active Students",
	ShowInactiveStudents: "Show Inactive Students",
	ShowNotLoggedInStudents: "Show Not Logged In Students",
	ShowWithConfiNotes: "Show with Confidential Notes",
	ShowHideColumn: "Show / Hide Columns",
	Photo: "Photo",
	Id: "ID",
	StudentType: "Student Type",
	PersonalEmail: "Personal Email",
	YrInPrg: "Yr in Prog",
	OnProbation: "On Probation",
	OffProbation: "Off Probation",
	Transfer: "Transfer",
	CellPhone: "Cell Phone",
	Gpa: "GPA",
	TransferGpa: "Transfer GPA",
	JoiningDate: "Joining Date",
	DOB: "Date of Birth",
	City: "City",
	State: "State",
	UniversityEmail: "MU Email",
	ConfidentialNotes: "Confidential Notes",
	ConfidentialNote: "Confidential Note",
	Print: "Print",
	Notification: "Notification",
	Yes: "Yes",
	No: "No",
	GoBack: "Go Back",
	IsMentor:"Is a Mentor",
	NotMentor:"Not a Mentor",
	Search: "Search",
	ClearAll: "Clear all",
	ListStudents: "List of Students",
	PleaseWait: "Please wait...",
	TransferDate: "Date of Transfer",
	PrintCssHeader: `
	@media print {
		.page  {
			size: landscape
		},
		.page-header {
			display: block;
			position: fixed;
			top: 0;
			width: 100%;
			text-align: center;
			padding: 10px;
		}
	}
`,
	StudentTypeList: [
		{ value: "1", label: "Full Time" },
		{ value: "2", label: "Part Time" },
		{ value: "5", label: "Prerequisites only" },
		{ value: "3", label: "Undeclared" },
	],
	YearList: [
		{ value: "1", label: "Freshman" },
		{ value: "2", label: "Sophomore" },
		{ value: "3", label: "Junior" },
		{ value: "4", label: "Senior" },
		{ value: "5", label: "Graduate" }
	],
	StatusList: [
		{ value: "4", label: 'Break' },
		{ value: "1", label: 'Dismissed' },
		{ value: "3", label: 'Left Program' },
		{ value: "10", label: 'Graduated' },
		{ value: "2", label: 'Probation' },
		{ value: "6", label: 'SLP Major' },
		{ value: "9", label: 'Undeclared' }
	],
	StudentSemList: [{
		key: '1',
		value: 'Fall'
	}, {
		key: '2',
		value: 'Spring'
	}, {
		key: '3',
		value: 'Summer Session I'
	}, {
		key: '4',
		value: 'Summer Session II'
	}
	],
	FullYearList: [
		{ value: "1", label: "Freshman" },
		{ value: "2", label: "Sophomore" },
		{ value: "3", label: "Junior" },
		{ value: "4", label: "Senior" },
		{ value: "5", label: "Graduate" },
		{ value: "6", label: "Part Time" },
	],
	//Manage Courses
	showDiscontinuedArea: 'Show Discontinued Areas',
	discontinue: 'Discontinued',
	disconBtn:'Discontinue',
	AreasCourses: 'Areas - Courses',
	EditArea: 'Edit Area - ',
	DiscontinueArea: 'Discontinue Area - ',
	AddNewArea: 'Add New Area',
	AddAshaCourse: "Add ASHA Course",
	errorMessage: "Course area already exists.",
	successMessage: "Course added successfully.",
	EditSuccMsg:"Course updated successfully.",
	CourseSaved:"Course saved successfully!",
	CourseFailedSave: "Failed to save course. Please try again later.",
	Coursedelete: "select course to delete.",
	Areas: "Areas",
	Area:"Area",
	TotalCourses: 'Total No. of Course(s)',
	AshaRequired: "No. of ASHA Required Course(s)",
	AshaCourse: "ASHA Course",
	AddAPIError: "Failed to add data",
	EditAPIError: "Failed to edit data",
	DiscontinueAPIError: "Failed to delete data",
	//Create semesters
	SemType: [
		{ semester_id: "1", semester_name: "Fall" },
		{ semester_id: "2", semester_name: "Spring" },
		{ semester_id: "3", semester_name: "Summer Session I" },
		{ semester_id: "4", semester_name: "Summer Session II" },
	],
	FallType: [{ semester_id: "1", semester_name: "Fall" }],
	Year5Options: [
		{ Year_id: "0", year_name: "Year" },
		{ Year_id: "1", year_name: "Freshman" },
		{ Year_id: '2', year_name: "Sophomore" },
		{ Year_id: "3", year_name: "Junior" },
		{ Year_id: "4", year_name: "Senior" },
		{ Year_id: "5", year_name: "Graduate" },
	],
	YearOptions: [
		{ Year_id: "1", year_name: "Freshman" },
		{ Year_id: '2', year_name: "Sophomore" },
		{ Year_id: "3", year_name: "Junior" },
		{ Year_id: "4", year_name: "Senior" },
		{ Year_id: "5", year_name: "Graduate" },
		{ Year_id: "6", year_name: "Part Time" }],
	StatusOptions: [
			// { id: "0", name: '--- All ---' },
			{ id: "1", name: 'Dismissed' },
			{ id: "2", name: 'Probation' },
			{ id: "3", name: 'Left Program' },
			{ id: "4", name: 'Break' },
			{ id: "6", name: 'SLP Major' }],
	CreateSemester: 'Create Semester',
	ActiveCourses: 'Active Courses',
	DefaultTruncateTable: ['notification_details', 'activitylogger', 'advisor_signup'],
	Semesters: [
		{
			value: "activitylogger",
			label: "Activity Logs",
			isdisable: true
		},
		{
			value: "emails",
			label: "Email",
			isdisable: false
		},
		{
			value: "advisor_signup",
			label: "Advising Signups",
			isdisable: true
		},
		{
			value: "notification_details",
			label: "Notifications",
			isdisable: true
		}
	],
	Info: "You can select Freshman, Sophomore, Junior, Senior and Graduate students to add them to the Summer Semester.",
	CurrentSem: "Current semester",
	FutureSem: "Future semester",
	SelectStudent: "Select Students",
	Err1: "Class end date should be greater than semester start date",
	Err2: "Grade entry date should be greater than class end date",
	Err3: "Semester end date should be greater than grade entry date",
	Err4: 'Error creating new semester',
	Err5: 'Error creating new semester',
	One: "One",
	Two: "Two",
	successMessage1: "Semester Created Successfully",
	successMessage2: "Fall semester set to current Semester",
	successMessage3: "Fall semester created!",
	successMessage4: "Fall semester updated!",
	successMessage5: "Semester Ended Successfully",
	successMessage6: "Semester updated!",
	
	//assessments
	AssessmentAddNew:"Add New Assessment" ,
	AssessmentEdit:"Edit Assessment - ",
	AssessmentDelete:"Delete Assessment - ",
	AssessmentAdd: 'Assessment added successfully',
	AssessmentUpdated: 'Assessment updated successfully',
	//SLP courses
	CourseNum: "Course Number",
	SlpAdd:"Add New Course" ,
	SlpEdit:"Edit Course - ",
	CourseName: "Course Name",
	Credits: "Credits",
	Sections: "Sections",
	SLPDiscontinueTitle: "Discontinue Course - ",
	EditSucessMessage: "Course updated Successfully",
	DiscontinueSucessMessage: "Course discontinued Successfully",
	DiscontinueModalBodyText: ' Are you sure you want to discontinue this Area?',
	DiscontinueCourse: ' Are you sure you want to discontinue this course?',
	DiscontinueCourseActiveSurveys: 'The following survey(s) will be hidden if you wish to discontinue this course:',
	AshaMessage: "ASHA Required Course(s) should be equal to or less than Total no. of Course(s).",
	TotalNoCourseValMessage:"Total No. of Course(s) should be greater than ASHA required course(s).",
	NoAshaReqCourse:"No. of ASHA Required Course(s):",
	//free electives
	FreeElectives: "Free Electives",
	sectionsDropdownOptions: ['1', '2', '3', '4', '5', '6', '7', '8'],
	//SLP courses
	dropdownOptions:[
		{id:"1",name:'SLP Undergraduate'},
		{id:"2",name:'Free Electives'},
		{id:"3",name:'SLP Graduate'}
	],
	dropdownOption:[
		{id:"1",name:'SLP Undergraduate'},
		{id:"3",name:'SLP Graduate'}
	],
	//faculty course
	fromSemOptions: [
		{id:1, semId:4, name: 'Fall'},
		{id:2, semId:1, name:'Spring'},
		{id:3, semId:2,name:'Summer Session I'},
		{id:4, semId:3,name:'Summer Session II'}
	],
	
	//assessments
	Assessments: "Assessments",
	showDeletedAssessments: "Show Deleted Assessments",
	delete: "Deleted",
	deleteBtn:"Delete",
	deleteCourse:"Course deleted successfully",
	ScheduledFor: "Scheduled For",
	PassingScore: "Passing Score",
	DeleteTitle: "Delete Assessment",
	DeleteBody: "Are you sure you want to delete this Assessment?",
	DeleteSucessMessage: "Assessment deleted successfully.",
	//student dashboard
	welcomeText: "Welcome to the Department of Speech Language Pathology!",
	dashboardText: "We are excited to have you join us. This portal will be your go-to resource. Please log in to the portal every day to stay updated.",
	//Faculty courses assignment
	FacultyCoursesAssignment: 'Faculty Courses Assignment',
	FacultyCoursesAssignmentHistory:"Faculty Courses Assignment History",
	fallInfo:"You can select the students who are not in the current Summer Semester to add them to the Fall Semester. If no students are selected they will be promoted with other students, who are currently in Summer Semester.",

	FacultyFirstName: ' faculty_first_name',
	FacultyPersonalEmail: 'faculty_personal_email',
	FacultyMobileNumber: 'faculty_mobile_number',
	UserRole: 'user_role',
	IsActive: 'is_active',
	//Email
	Content: 'Content',
	MenteeAssigned:'Mentee(s) Assigned',
	EmailContentMsg: 'Body (Change the Email Content if required)',
	MaxFileMsg: 'Maximum File Size : 10 MB',
	SelectRecipient: 'Select Recipient(s)',
	SendEmail: 'Send Email',
	Resend: 'Resend',
	ContentNotFound: 'Content Not Found!',
	Subject: 'Subject',
	Recipient: 'Recipient(s)',
	EmailType: 'Email Type',
	StaffType:'Staff Type',
	ToList: [
		{
			key: 1,
			option: 'Freshman',
		},
		{
			key: 2,
			option: 'Sophomore',
		},
		{
			key: 3,
			option: 'Junior'
		},
		{
			key: 4,
			option: 'Senior',
		},
		{
			key: 5,
			option: 'Graduate'
		},
		{
			key: 6,
			option: 'Part Time',
		},
		{
			key: 7,
			option: 'Staff',
		},
		{
			key: 9,
			option: 'Inactive Students',
		},
		{
			key: 10,
			option: 'Non-Logged in Students',
		},
		{
			key: 11,
			option: 'Transfer Students',
		},
		{
			key: 12,
			option: 'Archived Students',
		}
	],
	MuEmail: 'MU Email',
	StudentToList: [
		{
			key: 1,
			option: 'Freshman',
		},
		{
			key: 2,
			option: 'Sophomore',
		},
		{
			key: 3,
			option: 'Junior'
		},
		{
			key: 4,
			option: 'Senior',
		},
		{
			key: 5,
			option: 'Graduate'
		},
		{
			key: 6,
			option: 'Part Time',
		},
		{
			key: 9,
			option: 'Inactive Students',
		},
		{
			key: 10,
			option: 'Non-Logged in Students',
		},
		{
			key: 11,
			option: 'Transfer Students',
		},
		{
			key: 12,
			option: 'Archived Students',
		}
	],
	NewFromList: [
		{
			label: <span>Staff</span>,
			title: 'Staff',
			options: [
				{
					label: <span>Advising Students</span>,
					value: 'Staff - Advising Students',
					key: 3
				},
				{
					label: <span>Username/Password</span>,
					value: 'Staff - Username/Password',
					key: 10
				},
			]
		},
		{
			label: <span>Student</span>,
			title: 'Student',
			options: [
				{
					label: <span>Advisor Details</span>,
					value: 'Student - Advisor Details',
					key: 7
				},
				{
					label: <span>Dismissal</span>,
					value: 'Student - Dismissal',
					key: 2
				},
				{
					label: <span>Mentee Assigned</span>,
					value: 'Student - Mentee Assigned',
					key: 4
				},
				{
					label: <span>Mentor Details</span>,
					value: 'Student - Mentor Details',
					key: 6
				},
				{
					label: <span>Mentor Request</span>,
					value: 'Student - Mentor Request',
					key: 8
				},
				{
					label: <span>Probation</span>,
					value: 'Student - Probation',
					key: 5
				},
				{
					label: <span>Username/Password</span>,
					value: 'Student - Username/Password',
					key: 1
				},
			]
		},
		{
			label: <span>General</span>,
			title: 'General',
			options: [
				{
					label: <span>Other</span>,
					value: 'General - Other',
					key: 9
				}
			]
		}
	],
	StudentFromList: [{
		label: <span>Student</span>,
		title: 'Student',
		options: [
			{
				label: <span>Advisor Details</span>,
				value: 'Student - Advisor Details',
				key: 7
			},
			{
				label: <span>Mentee Assigned</span>,
				value: 'Student - Mentee Assigned',
				key: 4
			},
			{
				label: <span>Dismissal</span>,
				value: 'Student - Dismissal',
				key: 2
			},
			{
				label: <span>Mentor Details</span>,
				value: 'Student - Mentor Details',
				key: 6
			},
			{
				label: <span>Mentor Request</span>,
				value: 'Student - Mentor Request',
				key: 8
			},
			{
				label: <span>Probation</span>,
				value: 'Student - Probation',
				key: 5
			},
			{
				label: <span>Username/Password</span>,
				value: 'Student - Username/Password',
				key: 1
			},
		]
	},
	{
		label: <span>General</span>,
		title: 'General',
		options: [
			{
				label: <span>Other</span>,
				value: 'General - Other',
				key: 9
			}
		]
	}
	],
	AssignMentorsPendingRejectedList: [{
		label: <span>Student</span>,
		title: 'Student',
		options: [
			{
				label: <span>Mentor Request</span>,
				value: 'Student - Mentor Request',
				key: 8
			},
		]
	},
	{
		label: <span>General</span>,
		title: 'General',
		options: [
			{
				label: <span>Other</span>,
				value: 'General - Other',
				key: 9
			}
		]
	}
	],
	AssignMentorsAcceptedList: [{
		label: <span>Student</span>,
		title: 'Student',
		options: [
			{
				label: <span>Mentee Assigned</span>,
				value: 'Student - Mentee Assigned',
				key: 4
			},
		]
	},
	{
		label: <span>General</span>,
		title: 'General',
		options: [
			{
				label: <span>Other</span>,
				value: 'General - Other',
				key: 9
			}
		]
	}
	],
	advisorFromOptions: [
		{
			label: <span>Staff</span>,
			title: 'Staff',
			options: [
				{
					label: <span>Advising Students</span>,
					value: 'Staff - Advising Students',
					key: 3
				},
				{
					label: <span>Username/Password</span>,
					value: 'Staff - Username/Password',
					key: 10
				},
			]
		},
		{
			label: <span>General</span>,
			title: 'General',
			options: [
				{
					label: <span>Other</span>,
					value: 'General - Other',
					key: 9
				}
			]
		}
	],
	facultyFromOption: [{
		label: <span>Staff</span>,
		title: 'Staff',
		options: [
			{
				label: <span>Username/Password</span>,
				value: 'Staff - Username/Password',
				key: 10
			},
		]
	},
	{
		label: <span>General</span>,
		title: 'General',
		options: [
			{
				label: <span>Other</span>,
				value: 'General - Other',
				key: 9
			}
		]
	}],
	OnlyOtherOption: [{
		label: <span>General</span>,
		title: 'General',
		options: [
			{
				label: <span>Other</span>,
				value: 'General - Other',
				key: 9
			}
		]
	}],
	NonLoggedInTypeOptions:[
		{
			label: <span>Student</span>,
			title: 'Student',
			options: [
				{
					label: <span>Username/Password</span>,
					value: 'Student - Username/Password',
					key: 1
				},
			]
		},
		{
			label: <span>General</span>,
			title: 'General',
			options: [
				{
					label: <span>Other</span>,
					value: 'General - Other',
					key: 9
				}
			]
		}
	],
	TotalEntries: [
		{
			key: 1,
			option: 10
		},
		{
			key: 2,
			option: 25
		},
		{
			key: 3,
			option: 50
		},
		{
			key: 4,
			option: 100
		}
	],
	SemesterList: [
		{
			key: 0,
			option: 'Current'
		},
		{
			key: 2,
			option: 'Fall'
		}
	],
	facultyType: [
		{
			key: 1,
			option: 'Admin'
		},
		{
			key: 4,
			option: 'Adjunct Faculty'
		},
		{
			key: 5,
			option: 'Clinical Supervisor'
		},
		{
			key: 2,
			option: 'Faculty'
		},
		{
			key: 3,
			option: 'System Administrator'
		},
	],

	emailType: [
		{
			key: 1,
			option: 'Personal Email'
		},
		{
			key: 2,
			option: 'University Email'
		},
	],

	SubjectList: [
		{
			key: 1,
			subject: 'Welcome to Misericordia University'
		},
		{
			key: 2,
			subject: 'Dismissal Notification'
		},
		{
			key: 3,
			subject: 'Faculty Advisor for students'
		},
		{
			key: 4,
			subject: 'Mentor for students'
		},
		{
			key: 5,
			subject: 'Probation Notification'
		},
		{
			key: 6,
			subject: 'Mentor Information'
		},
		{
			key: 7,
			subject: 'Faculty Advisor Information'
		},
		{
			key: 8,
			subject: 'Request to be a mentor for students'
		},
		{
			key: 9,
			subject: 'Other'
		}
	],
	studentToOptions: [
		{
			key: 1,
			option: 'Freshman',
		},
		{
			key: 2,
			option: 'Sophomore',
		},
		{
			key: 3,
			option: 'Junior'
		},
		{
			key: 4,
			option: 'Senior',
		},
		{
			key: 5,
			option: 'Graduate'
		},
		{
			key: 6,
			option: 'Part Time',
		},
		{
			key: 10,
			option: 'Non-Logged in Students',
		},
		{
			key: 11,
			option: 'Transfer Students',
		},

	],
	facultyToOption: [
		{
			key: 7,
			option: 'Staff',
		},
	],
	probationToOption: [{
		key: 1,
		option: 'Freshman',
	},
	{
		key: 2,
		option: 'Sophomore',
	},
	{
		key: 3,
		option: 'Junior'
	},
	{
		key: 4,
		option: 'Senior',
	},
	{
		key: 5,
		option: 'Graduate'
	},
	{
		key: 6,
		option: 'Part Time',
	},
	{
		key: 11,
		option: 'Transfer Students',
	},],
	mentorToOption: [{
		key: 3,
		option: 'Junior'
	},
	{
		key: 4,
		option: 'Senior',
	},],
	studentAdvisorToOption: [
		{
			key: 1,
			option: 'Freshman',
		},
		{
			key: 11,
			option: 'Transfer Students',
		},
	],
	advisorFacultyOptions: [
		{
			key: 2,
			option: 'Faculty'
		},
		{
			key: 5,
			option: 'Clinical Supervisor'
		},
		{
			key: 3,
			option: 'System Administrator'
		},
	],
	NonLoggedInStudentOptions: [{
		key: 1,
		option: 'Freshman',
	},
	{
		key: 2,
		option: 'Sophomore',
	},
	{
		key: 3,
		option: 'Junior'
	},
	{
		key: 4,
		option: 'Senior',
	},
	{
		key: 5,
		option: 'Graduate'
	},
	{
		key: 6,
		option: 'Part Time',
	},],
	StudentCourseFromList: [{
		key: 1,
		option: 'Username/Password-Student'
	},
	{
		key: 2,
		option: 'Dismissal',
	},
	{
		key: 4,
		option: 'Mentor',
	},
	{
		key: 5,
		option: 'Probation',
	},
	{
		key: 6,
		option: 'Students(Advisor/Mentor)',
	},
	{
		key: 7,
		option: 'Students(Faculty Advisor)',
	},
	{
		key: 8,
		option: 'Mentor Request',
	},
	{
		key: 9,
		option: 'Other'
	}
	],

  EmailSuccess: 'Email has been sent. Check Email Reports for more details.',
  PartialError: 'Some email(s) could not be sent. Check Email Reports for more details.',
  NotFound: 'Mail not found! Check Email Reports for more details.',
  GpaFilter: "GPA Filter",
  StudentDetails: "Student Details",
  //Edit Student
  Courses: "Courses",
  SemesterCourses: "Semester Courses",
  CourseType: "Course Type",
  PlanOfStudy: "Plan of Study",
  Checklist: "Checklist",
  History: "History",
  InActive: "Inactive",
  RequiredField: "This field is required.",
  MiddleName: "Middle Name",
  StudentId: "Student ID",
  Year: "Year",
  GraduateYear1: "Graduate Year I (Senior Year)",
  GraduateYear2: "Graduate Year II (Fifth Year)",
  AshaNumber: "ASHA Number",
  PshaNumber: "PSHA Number",
  HomePhn: "Home Phone",
  Username: "Username",
  UserEmail: "Username / MU Email",
  IdentifyAs: "Identify As",
  SatCombinedSuper: "SAT Combined Super",
  SatReadingSuper: "SAT Reading Super",
  SatMathSuper: "SAT Math Super",
  ActCompositeSuper: "ACT Composite Super",
  CurrentAddress: "Current Address",
  StreetAddress: "Street Address",
  Country: "Country",
  Zip: "Zip",
  AdressLabel: "Permanent Address is Same as Current Address",
  PermanentAddress: "Permanent Address",
  UploadDoc: "Upload Document",
  ChooseFile: "Choose File",
  AdvisorDetls: "Advisor Details",
  MentorDetls: "Mentor Details",
  ContactNo: "Contact No.",
  StudentStatus: "Student Status",
  CurrentStatus: "Current Status",
  StatusHistory: "Status History",
  Status: "Status",
  DateChanged: "Date Changed",
  ChangedBy: "Changed By",
  Cancel: "Cancel",
  TransferDetails: "Transfer Details",
	TransferInfo: "Transfer Information",
	GoToGPA: "Go to Student GPA",
  DateAndTimeFormat: 'MMM DD, YYYY h:mm A',
  NotificationRecipients: 'Notification Recipient(s)',
  NotificationToOptions: ['Freshman', 'Sophomore', 'Junior', 'Senior', 'Graduate', 'Part Time', 'Staff'],
  FacultyTypeOptions: ['Admin', 'Faculty', 'System Administrator', 'Adjunct Faculty', 'Clinical Supervisor'],
  PriorityOptions: ['High', 'Medium', 'Low'],
  Name: 'Name',
  ResourceRole: 'Year in Program / Role',
  NotificationViewedDate: 'Date Viewed',
  SelectRecipients: 'Select Recipient(s)',
  StudentYearOptions: ['Freshman', 'Sophomore', 'Junior', 'Senior', 'Graduate'],
  StudentYearOps: ['Freshman', 'Sophomore', 'Junior', 'Senior', 'Graduate', 'Part Time'],
  StudentStatusOptions: [
    {
      status: 'Break',
      value: 4,
    },
    {
      status: 'Dismissed',
      value: 1,
    },
    {
      status: 'Left program',
      value: 3,
    },
    {
      status: 'Inactive',
      value: 5,
    },
    {
      status: 'Probation',
      value: 2,
    },
    {
      status: 'SLP Major',
      value: 6,
    },
  ],
  Home: 'Home',
  NotificationList: 'Notification List',
  NotesInfo: 'Kindly resubmit if any changes are made for notes.',
  NoCourseData: 'No courses added for this semester',
  UserRoles: 'User Roles',
  Roles: 'Roles',
  RoleType: 'Role Type',
  Admin: 'Admin',
  SystemAdministrator: 'System Administrator',
  AdjunctFaculty: 'Adjunct faculty',
  BadgeColor: '#777777',
  Years: 'Years',
  Semester: 'Semester',
  GpaLevel: 'GPA Level',
  AddNotifications: 'Add Notification',
  ActiveSlpMajorMessage: ["This will make the student ",<b key='active'>Active</b>," and set the current status to ",<b key='slp_major'>SLP Major</b>,"."],
  ArchiveStudent: ["Doing this will ",<b key='archive'>Archive</b>," the student and set the current status to ",<b key='left_program'>Left Program</b>,"."],
  Send: 'Send',
  TinyMcePlugins: ['lists', 'advlist', 'textcolor', 'colorpicker'],
  TinyMceToolbar: "undo redo | bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | forecolor backcolor | removeformat ",
  TinyMceContentStyle: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  TinyMcePlaceholder: "Notification text...",
  SwitchOptions: [
    {
      value: 'visible',
      label: 'Visible',
    },
    {
      value: 'hidden',
      label: 'Hidden',
    },
  ],
  VisibleFromAndTo: 'Visible from-to',
  FacultyType: 'Staff Type',
  NotificationTo: 'Notification To',
  PleaseSelectRecipients: 'Please Select Recipients',
  AcademicSpecification: 'Academic Specification',
  Pending: 'Pending',
  ReSent: 'Re-sent',
  ReSentOn: 'Resent On',
  Answered: 'Answered',
  StudentGPA: 'Student GPA',
  DismissedStudents: 'Dismissed Students',
  GpaHistory: 'GPA History',
  AssigningMentors: 'Assigning Mentors',
  DeActivate: 'Deactivate',
  Fall: 'Fall',
  Spring: 'Spring',
  Summer: 'Summer',
  SummerOne: 'Summer Session 1',
  SummerTwo: 'Summer Session 2',
  AssignBtnLabel: 'Assign',
  ReAssignBtnLabel: 'Re-Assign',
  ViewBtnLabel: 'View',
  ChangeStatus: 'Change Status',
  PendingRequests: 'Pending Requests',
  AcceptedRequests: 'Accepted Requests',
  RejectedRequests: 'Rejected Requests',
  FallSemester: 'Fall Semester',
  CurrentSemester: 'Current Semester (Spring)',
  DissmissedList: 'Dismissed List',
  ManageSurveys: 'Manage Surveys',
  SurveysType: 'Surveys Type',
	MySurveys:"My Surveys",
	Surveys:"Surveys",
	LearningOut:"Learning Outcomes",
  Delete: 'Delete',
  Questions: 'Questions',
  Question: 'Question',
  Result: 'Results',
  Report: 'Report',
  Staff:'Staff',
  Yeartype:{'1':'Freshman','2':'Sophomore','3':'Junior','4':'Senior','5':'Graduate','6':"Staff"},
  YeartypeCourses:{'1':'Freshman','2':'Sophomore','3':'Junior','4':'Senior','5':'Graduate','6':"Part Time"},
	SurveySentSuccessfully:'Survey sent successfully',
  SurveyTypeOptions: [
    {
      type: 'Select',
      value: 0
    },
    {
      type: 'Archive',
      value: 1
    },
    {
      type: 'Courses',
      value: 2
    },
    {
      type: 'Faculty Review',
      value: 3
    },
    {
      type: 'Instructor',
      value: 4
    },
    {
      type: 'Mentors',
      value: 5
    }
  ],
  TypesForCustom: [
		{ value: '1', label: 'Freshman' },
		{ value: '2', label: 'Sophomore' },
		{ value: '3', label: 'Junior' },
		{ value: '4', label: 'Senior' },
		{ value: '6', label: 'Staff' }
	],
	TypesForMentors: [
		{ value: '1', label: 'Freshman' },
		{ value: '2', label: 'Sophomore' },
		{ value: '3', label: 'Junior' },
		{ value: '4', label: 'Senior' },
	],
	CoursesOptions: [
		{
			option_id: '1',
			option_name: 'Excellent',
		},
		{
			option_id: '2',
			option_name: 'Good',
		},
		{
			option_id: '3',
			option_name: 'Fair',
		},
		{
			option_id: '4',
			option_name: 'Poor',
		},
		{
			option_id: '5',
			option_name: 'N/A',
		}
	],
  ShowHiddenSurveys: 'Show Hidden',
  DateCreated: 'Date Created',
  SurveyTypes: 'Survey Types',
  SurveyTitle: 'Survey Title',
  Deadline: 'Deadline',
  requiredFieldRules: [{ required: true, message: 'This field is required' }],
  AddSurveyType: 'Add Survey Type',
  EditSurveyType: 'Edit Survey Type',
  courseTypes: ['Select', 'Courses', 'Graduate', 'Instructor', 'Mentors'],
  AddSurvey: 'Add Survey',
  EditSurvey: 'Edit Survey',
  NotificationSent:'Notification Sent',
  SendSurvey: 'Send Survey',
  CourseCode: 'Course Code',
  ShowSent: 'Show sent',
  ShowNotSent: 'Show not sent',
	DeleteSurveyType: 'Delete Survey Type - ',
  Confirm: 'Confirm',
  DeleteConfirmationMessage: 'Are you sure you want to hide this survey?',
  SurveyInUseMessage: 'This survey is currently active and cannot be deleted. Please set the survey to Inactive and try again.',
  SureSubmitSurvey: 'Once the survey is submitted, the answers will be final. Would you like to proceed?',
  DeleteSurvey: 'Delete Survey',
  Proceed: 'Would you like to proceed?',
  Hide: 'Hide',
  SurveyWithQuestionMessage: 'This survey has questions, do you want to hide this survey?',
  SurveyInUseAndHideMessage: 'This survey is in use, do you want to hide this survey.',
	SurveyUnideCourseConfirm:"This survey cannot be unhidden as the respective course is discontinued.",
	SurveyUnideConfirm:"Are you sure you want to unhide this survey? You will need to resend this survey if required.",
  SurveyDeletedSuccessfully: 'Survey deleted successfully',
  SurveyTypeDeletedSuccessfully: 'Survey type deleted successfully',
  SurveySent: 'Survey Sent',
  SurveySentTo: 'Survey Sent To',
  AssignStudentsToMentors: 'Assign Student(s) to Mentor',
  ReAssignStudentsFromMentors: 'Re-Assign Student(s) from Mentor',
  ViewStudentsAssignedToMentors: 'View Student(s) Assigned to Mentor',
  Warning: 'Warning',
  PleaseNote: 'Please Note!',
  Preview: 'Preview',
  Back: 'Back',
  Both: 'Both (Student and Faculty)',
  SurveySentToOptions: [{ label: 'Student', value: "0" }, { label: 'Staff', value: "1" }],
  MentorWithNoStudentMessage: 'Are you sure you want to change the status of this Mentor? This Student will no longer be a Mentor. Click Yes to proceed.',
  MentorWithStudentMessage: 'Cannot change status of the Mentor. Assign all Students under this Mentor to another Mentor and try again.',
  RejectedRequestStatusChangeMessage: 'Are you sure you want to change the status of this Student? The Student will get a Mentor status. Click Yes to proceed.',
  Activate: 'Activate',
  SurveyQuestions: 'Survey Questions',
  SurveyResult: 'Survey Result',
  Survey: 'Survey',
  Section: 'Section',
  ResetFilters: 'Reset Filters',
  NotSubmitted: 'Not Submitted',
  SendToFaculty: 'Send To Faculty',
  MailToFaculty: 'Mail To Faculty',
  AnswerOptions: 'Answer Options',
  ResponsePercent: 'Response Percent',
  ResponseCount: 'Response Count',
  SurveysReport: 'Surveys Report',
  Tab1: 'Freshman',
  Tab2: 'Sophomore',
  Tab3: 'Junior',
  Tab4: 'Senior',
  Tab5: 'Graduate',
  Clined: 'Clin-Ed',
  ClinicalSupervisor: 'Clinical Supervisor',
  SentDate: 'Sent Date',
  Download: 'Download',
  SaveOrder: 'Save Order',
  Options: 'Options',
  AddOption: 'Add Option',
  Comment: 'Comment',
  Text: 'Text',
  StudentsList: 'Students list',
  PromoteStudents: 'Promote Students',
  Promote: 'Promote',
  SaveAndContinue: 'Save & Continue',
  DataSaved: "Data Saved Successfully",
  DeleteQuestionConfirmationMessage: 'Are you sure you want to delete this Question?',
  EditQuestionTitle: 'Edit Question',
  AddQuestionTitle: 'Add Question',
  DataUpdated: "Data Updated Successfully",
  SemesterTypeList: [
    'Select',
    'Fall',
    'Spring',
    'Summer Session I',
    'Summer Session II',
  ],
  Session1: 'Session I',
  Session2: 'Session II',
  ClearTransferTitle: "Clear Transfer Information",
  ClearTransferBody: "All related transfer information will be deleted. Are you sure you want to uncheck the transfer option?",
  Close: "Close",
  WarningStatusInActiveMsg: ["You have to change the status to ",<b key='break'>Break</b>," or ",<b key='dismissed'>Dismissed</b>," before making the student inactive."],
  WarningStatusActiveMsg: ["You have to change the status to ",<b key='slp_major'>SLP Major</b>,", ",<b key='probation'>Probation</b>," or ",<b key='undeclared'>Undeclared</b>," before making the student active."],
  WarningStatusArchiveMsg: ["You have to change the status to ",<b key='graduated'>Graduated</b>,", ",<b key='left_program'>Left Program</b>," or ",<b key='dismissed'>Dismissed</b>," before archiving the student."],
  StudentStatusInActive: "The student has to be made Inactive.",
  StudentStatusActive: "The student has to be made Active.",
  StudentStatusUndeclared: "Student type needs to be changed to Undeclared before changing the Student status to Undeclared.",
  InvalidFileFormat: "Invalid file format",
  ValidFileType: [<strong key='valid_file_types'>Valid File Types : </strong>, "png, jpg, jpeg, pdf, doc, docx, xls, xlsx."],
  ValidFileSize: [<strong key='maximum_file_size'>Maximum File Size : </strong>, "2 MB"],
  Registration: "Registration",
  StudentRegistration: "Student Registration",
  AshaValidateMessage: "ASHA Number should be 8 digit number",
  PshaValidateMessage: "PSHA Number should be 5 digit number",
  MobileValidateMessage: "Cell Phone should be 10 digit number",
  ZipCodeValidateMsg: "Zip Code should be 5 digit number",
  FieldRequired: "This field is required",
  SLPcourses: "SLP Courses",
  //Faculty
  StaffListing: 'Staff Listing',
  StaffRegistration: 'Staff Registration',
  StaffEdit: 'Staff Edit',
  StaffName: 'Staff Name',
  NotActive: 'Account not Activated',
  ListFaculties: "List of Faculties",
  NotificationSucess: 'Notification Sent',
  OfficePhone: 'Office Phone should be 10 digit number',
  HomePhone: 'Home Phone should be 10 digit number',
  InvalidFormat: 'Invalid format.',
  FileName: 'Filename',
  Actions: 'Actions',
  ShowDeactivated: 'Show Archived',
  Archived:'Archived',
  NotLoggedIn:'Not Logged In',
  ScrollValue:'50vh',
  ShowStatusOptions:[
    {
      label: 'Active',
      value: '1',
    },
    {
      label: 'Inactive',
      value: '2',
    },
    {
      label: 'Archived',
      value: '3',
    },
  ],
  //Forgot Password
	ResetPassword: 'Reset Password',
  ForgotSuccess: 'Success! Reset link is sent to your Email',
  Title: "Title",
  AddedBy: "Added by",
  Date: "Date",
  Description: "Description",
  UploadFile: "Upload file",
  AddConfidentialNotes: "Add Confidential Note",
  EditConfidentialNotes: "Edit Confidential Note",
  DeleteConfidentialNotes: "Delete Confidential Note",
  DeleteConfidentialNotesAlrt: "Do you want to delete this record?",
  GpaFilterType: [
    {
      label: "Equal to",
      value: "="
    },
    {
      label: "Greater than",
      value: ">"
    },
    {
      label: "Greater than or equal to",
      value: ">="
    },
    {
      label: "Less than",
      value: "<"
    },
    {
      label: "Less than or equal to",
      value: "<="
    }
  ],
  Filter: "Filter",
  Value: "Value",
  ChangeFile: "Change File",
  ForgotError: 'Error! Username not found',
  ResetSuccess: 'Saved Successfully',
  validatePasswordLength: "Password must be between 8 to 20 characters long.",
  validatePasswordDigit: 'Password must contain at least one digit.',
  validatePasswordUpperLetter: 'Password must contain at least one uppercase.',
  validatePasswordLowerLetter: 'Password must contain at least one lowercase.',
  validateSpecialCharacters: 'Password must contain special characters from @#$%&.',
  validateSpace: 'No space allowed',
  validatePasswordMsg: 'Passwords do not match',
  PasswordInfo: 'Password length should be minimum 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.',
  PasswordCheck: 'New password cannot be same as old password.',
	ExpiryMsg:'We are unable to change the password. It appears that you already changed the password or the link might have expired.',
  //Student Imports
  StudentImports: 'Student Imports',
  Initial: 'Initial',
  ImportHeader: 'Make sure that your file is ready for import by checking the file type and organizing the information.',
  StudentInitialImport: 'Student Initial Import',
  ImportExcel: 'Import Excel',
	Rank:'Rank',
  InitialImportContent: <ul><li>Before you import a file with student details, it's a good idea to make sure
    you have the right file type and that the data is formatted correctly.</li><li>Email column in the excel import
    file is mandatory as the other imports are dependent on this personal email.</li> <li><a className='text-decoration-none' href={InitialImportDoc} download="initial_import_sample.xls">Click here</a> to download the sample excel file for the student Initial import.</li> </ul>,
  StudentIdMuEmailContent: <ul>
    <li>Before you import a file with all student ID, it's a good idea to make sure you
    have the right file type and that the data is formatted correctly.</li> 
    <li>Email column in the excel import file is
    mandatory as the other imports are dependent on this personal email.</li> 
    <li>This Email has to be the same email as
    the one added during initial import.</li> <li><a className='text-decoration-none' href={StudentIdImportDoc} download="student_id_import_sample.xls">Click here</a> to download the sample excel file for the student ID import.</li>
  </ul>,
  StudentIDMUEmail: 'Student ID / MU Email',
  AshaPsha: 'ASHA / PSHA',
  ImportedSuccessfully: 'Imported Successfully',
  ValidEmailAddress: 'Please enter a valid email address.',
  AshaPshaImportContent: <ul><li>Personal Email and University Email columns in the excel import file are mandatory
    as these columns are used to link the student records.</li><li>This Personal Email has to be the same email as
    the one added during initial import.</li><li><a className='text-decoration-none' href={StudentAshaPshaImportDoc} download="student_asha_psha_sample.xls" >Click here</a> the sample excel file for student ASHA and PSHA import.</li> </ul>,
  StudentAshaPshaImport: 'Student ASHA / PSHA Import',
  SpringSemesterMsg: "Since the current semester is spring, the imports are disabled. Imports can only be used in fall semester.",
  MiniPlugin: `'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
  'autoresize'`,
  MiniToolbar: 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
  Error: 'Error',
  Required: "Required",
  Waived: "Waived",
  Recommended: "Recommended",
	SocialSecurityNumber:'Social Security Number',
  //AdvisorSignup
  AdvisorSignup: "Advisor Signup",
  Sent: "Sent",
  Timings: "Timings",
  Spots: "No. of Spots",
  StudentsEnrolled: "Students Enrolled",
  SentMessage: "Sent successfully",
  DeleteMessage: "Deleted successfully",
  SaveMessage: "Saved successfully",
  UpdateMessage: "Data updated successfully",
  RequestMessage: "Do you want to delete this record?",
  AddAdvisingTimes: "Add Advising Times",
  EditAdvisorTimings: "Edit Advisor Timings",
  From: "From",
  To: "To",
  DeleteUserMessage: "Students are already enrolled in this time slot. Do you still want to delete it?",
  ErrorMessage: "Something Went Wrong",
  IncorrectTimeError: "Time From cannot be later than Time To",
  EqualTimeError: "Time From cannot be equal to Time To",
  MoreTimeError: "Selected time should be later than current time",
  TimeOverlapError: "Time overlaps with an existing record on the same day",
  TimeFormat: "HH:mm",
  TwelveHourTimeFormat: "h:mm A",
  //StudentAdvisorSignup
  StudentErrorMessage: "You have entered your name in more than one field",
  StudentsFieldError: "Select a slot",
  Reschedule: "Reschedule",
  NoDataMessage: "You have not been assigned any Advising Times.",
  SendMessage: "Click Send button to make the timings available to  students.",
  SelectSlotMessage: "Select your time slot.",
  PreviousSlot: "Previously selected time slot:",
  //Profile
  Profile: 'Profile',
  ChangePassword: 'Change Password',
  CurrentPassMsg: 'Enter your Current Password',
  SignOut: 'Sign Out',
  StudentProfile: 'Student Profile',
  ProfileUpdateSuccess: 'Profile Updated Successfully',
  ConfirmPassMsg: 'Re-enter password for confirmation',
  //Other History
  NoFileSelected: 'No File Selected',
  HistoryAddedSuccess: 'History Added Successfully',
  //Probation History
  ProbationHistory: 'Probation History',
  DateOfProbation: 'Date of Probation',
  //StudentNotes
  Note: "Note",
  ESign: "e-sign By",
  DateUpdated: "Date Updated",
  Esign: "e-sign",
  DateSigned: "Date Signed",
  Reset: "Reset",
  SignSuccessful: "e-sign Successful",
  Advisor: "Advisor",
  AdvisorESign: "Advisor/Admin e-sign",
  StudentEsign: "Student e-sign",
  SignedOn: "Signed on",
  SignatureRequired: "Signature is required",
  //Notifications
  ConfirmDeleteMessage: "Are you sure you want to delete?",
	RemoveNotificationMessage:'Clicking on the Remove button, implies that you have read this notification. Would you like to proceed?',
	RemoveButtonText:'Remove',
  ViewMore: "View More Notifications",
  NoNotificationsMessage: "No Notifications To Display",
  //StudentMentorRequest
  MentorRequest: "Mentor Request",
  GPAMessage: "Would you be interested in mentoring some students from the Freshman class and/or Transfer students?",
  all: "All",
	ThankYouResponse: "Thank you for your response.",
  //Reset Password
  CurrentPassword: 'Current Password',
  NewPassword: 'New Password',
  ConfirmPassword: 'Confirm Password',
  InvalidCurrentPassword: 'Invalid Current Password',
  //My Courses
  MyCourses: "My Courses",
  CourseNo: "Course No.",
  ShowActive: 'Show Active',
  ShowInactive: 'Show Inactive',
  ShowArchived: 'Show Archived',
  ShowDeactivited:'Show Deactivited',
  ShowAll:'Show All',
  ShowNotLoggedIn:'Show Not Logged In',
  CoursePlanNotes:'Course Plan Notes',
  NoMentorAssigned:'No mentor assigned',
  NoAdvisorAssigned:'No advisor assigned',
	SectionCourses: 'Section(s)',
	StartYear: 'Start Year',
	StudentNames: 'Student Name(s)',
	StudentCount: "Student Count",

	//Advisement Documentation Form
	AdvisementNotes:
	<>
		<ul>
			<li>Freshmen need to read and sign the 5-page advisee sheet if not done already. Transfers also need to do this. Once they are done, <b>please sign it too.</b></li>
			<li>Remind all students to <span className='underline'>keep enough cores or free electives for their junior year</span> and especially the spring semester of the junior year so that they can maintain full time status.</li>
			<li>All students need to <span className='error-icon'>sign the new sheet</span> – indicating that they have read the handbook, etc.</li>
			<li>For students going on Study Abroad in their Sophomore Year, <b>remind them</b> that they need to take SLP 220 in the Freshmen Year and SLP 260 in their Junior Year. This means that they need to fill their Sophomore year abroad with core and free electives. They should keep note of their credits so that they graduate on time when they return so they should take an appropriate load.</li>
			<li>Advising Sign-up sheet</li>
			<li>SIGN OFF ON ALL ITEMS!!!!!!!!!!!!!!!!!!!! (Students & Faculty)</li>
			<li>Make sure to have them sign up for the CPR class (<span className='success-icon'>Course number HP 999</span>) (sophomore, juniors, seniors)</li>
			<li>Check grid on cover page of folder – make sure to check the grid</li>
			<li>Make sure they complete their grades</li>
			<li>Make sure to sign their advising sheet (document form)</li>
			<li><b>Make sure to go online after they come for advising and APPROVE them to register</b></li>
			<li>Have them read and sign the advising sheet. Also, note that SLP 310 (Research is now designated a writing intensive class).</li>
			<li>For 4th year (seniors), during spring advising, inform them that on-campus housing may be free for them in summer if they already live on campus; and if not, it is about $75 per week. They should check with the housing office Angelo Nudo – anudo@misericordia.edu) to confirm this information.</li>
			<li>For seniors, after spring advising, tell them not to sign up for summer and fall classes until after graduation in May.</li>
			<li><b>Remind students to do a degree audit and check to make sure all core classes and free electives are done.</b></li>
		</ul>
		<p className='error-icon'>Off-Campus Course Transfer Policy – ask them to check the catalog</p>
	</>,
	Clear: "Clear",
	Reminders: "Reminders",
	AdvisementForm: "Advisement Documentation Form",
	ConfidentialNoteAdded: "(Confidential Note Added)",
	ShowingNotesForAllStudents: "Showing notes for all Students",
	EditAdvisingNotes: "Edit Advising Notes",
	EditNotAllowed: "Edit not allowed after Student has signed the note",
	ViewNotes: "View Notes",
	FacultyNotAssigned: "The Student has not been assigned a Faculty",
	PermissionForDelete:"You do not have permission to delete this note.",

	//Student Notes
	StudentNotes: "Student Notes",

	//CLINICS
	Clearances: "Clearances",
	FormItemCriminalBackground: "Criminal background check",
	UploadFileDeletePopupMessg: <div>Record once deleted will be lost forever.<br />
		Are you sure you want to delete?
	</div>,
	SemesterSchedule: "Semester Schedule",
	Remove: "Remove",
	CreditValue:[
		{
			value: '1',
			label: '1',
		},
		{
			value: '2',
			label: '2',
		},
		{
			value: '3',
			label: '3',
		},
		{
			value: '4',
			label: '4',
		}
	],
	SlpGraduteStatus:[
		{
			value: '3',
			label: 'Recommended',
		},
		{
			value: '0',
			label: 'Required',
		},
		{
			value: '2',
			label: 'Transfer',
		},
		{
			value: '1',
			label: 'Waived',
		}
	],
	StudentCourse: [
		{
			corse_title:"Core Curriculum"
		},
		{
			corse_title:"SLP Undergraduate Requirement"
		},
		{
			corse_title:"Free Electives"
		},
		{
			corse_title:"SLP Graduate Requirement"
		},
		{
			corse_title:"Assessments"
		}
	],
	NotePlaceHolder:"Enter Note...",
	Grade:"Grade",
	GradeDropDown:[
		{
			label:"A",
			value:"1"
		},
		{
			label:"A-",
			value:"2"
		},
		{
			label:"B",
			value:"3"
		},
		{
			label:"B+",
			value:"4"
		},
		{
			label:"B-",
			value:"5"
		},
		{
			label:"C",
			value:"6"
		},
		{
			label:"C+",
			value:"7"
		},
		{
			label:"C-",
			value:"8"
		},
		{
			label:"D",
			value:"9"
		},
		{
			label:"F",
			value:"10"
		},
		{
			label:"IP",
			value:"11"
		},
		{
			label:"W",
			value:"12"
		},
		{
			label:"I",
			value:"13"
		},
		{
			label:"P/F",
			value:"14"
		}
	],
	extraSections: [
		{
			id: 9,
			name: 'RS',
		},
		{
			id: 10,
			name: 'R1',
		},
		{
			id: 11,
			name: 'R2',
		},
	],
	gradeMapping: {
		"1": "A",
		"2": "A-",
		"3": "B",
		"4": "B+",
		"5": "B-",
		"6": "C",
		"7": "C+",
		"8": "C-",
		"9": "D",
		"10": "F",
		"11": "IP",
		"12": "W",
		"13": "I",
		"14": "P/F"
	},
	MentorName:"Mentor's Name",
	AdvisementDocumentation:"Advisement Documentation",
	AdvisorName:"Advisor's Name",
	ConfirmUnarchiveModalText:"Are you sure you want to unarchive the student?",
	Archive:'Archive',
	Unarchive:"Unarchive",
	UploadedBy:"Uploaded by",
	TablePageSize:20,
	Approve:"Approve",
	NotApprove:"Not Approve",
	Approved:"Approved",
	NotApproved:"Not Approved",
	ApprovelConfirmation:"Are you sure you want to approve this assessment?",
	NotApprovelConfirmation:"Are you sure you don't want to approve this assessment?",
	CourseNotApprovedMessageAdvisor:"To enable this page, please approve the previous semester in the Semester Courses section.",
	CourseNotApprovedMessageStudent:"To enable this page, please ensure that the advisor approves the previous semester in the Semester Courses section.",
	ActivityLogs:"Activity Logs",
	Dashboard:'Dashboard',
	MaxFileSizeMessage:"Maximum file size accepted is 2MB.",
	AdvisorSchedule:"Advisor Schedule",
	ActiveSemesterCourses:"Active Courses for this Semester",
	// TextEditorApiKey:"zaw3s37cr24m27ftuj0h47ej11xcaom68qhcdne4dlkm9ko2",
	TextEditorSrcPath:`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`,
	CoreCourseWarningMessage:"Once you've decided on your Core course, please select it and click on the remove button. Remember to substitute this course.",
	EnterGPAErrorMessage:'Enter GPA between 0.00 to 4.00',
	CourseApproved:"Course Approved",
	CourseFailedToApprove:"Course Failed to Approve",
	AddEditQuestionsNote:"This survey is currently Active, To add or edit question update the status of survey to Inactive.",
	DeleteNoteConfirmationMessage:"Are you sure you want to delete this note?",
	DeleteNote:"Delete Note",
	DownloadWithoutComments:"Download without comments",
	DownloadExcel:"Download Excel",
	ResendModalText:"This survey is currently inactive. By clicking on continue button, this survey will be made active and will be resent to all the recipients.",
	AddSchedule:"Add Schedule",
	AdvisingSchedule:"Advising Schedule",
	NoSheduleToDisplay:"No Schedule To Display",
	UploadedSchedule:"Uploaded schedule",
	surveySentTo:{
		'0': "Student",
		'1': "Staff",
	},
	ActiveSurveyText:"Are you sure you want to make the survey Inactive?",
	SurveyResultDetails:"Survey Result Details",
	DownloadPDF:"Download PDF",
	DownloadPreviousYear:"Download Previous Results",
	FacultyNotAssignedTitle:"Faculty not assigned to section.",
	FacultyNotAssignedText:"This section is not assigned to any faculty. You can assign a faculty to this section in faculty course allotment page.",
	GoToFacultyAssign:"Go to Faculty Course Allot",
	Graduated:"Graduated",
	Confirmation:"Confirmation",
	GraduateStudentConfirmationMessage:"Are you sure you want to graduate these student(s)?",
	StudentName: "Student Name",
	Criteria: "Criteria",
	UnHide: "Unhide",
	MakeSurveyActiveText:"This survey is currently inactive. By clicking on the Send button the survey will be activated and sent to the selected recipients.",
	MakeSurveyActiveResendText:"This survey is currently inactive. By clicking on the Resend button the survey will be activated and sent to the selected recipients.",
	PastResults: "Past Results",
	MentorsListTitle:"Mentors",
	StudentsListTitle:"Students",
	ResultSentToFaculty:"Survey result sent to faculty.",
	NoQuestions:"This survey does not contain any questions.",
	EnterGPA:"Enter GPA for the selected student(s).",
	PageNotFoundText:"Sorry, the page you visited does not exist.",
	NotAuthorizedText:"Sorry, you are not authorized to access this page.",
	SendAutoText:"Send Automatically",
	//Add Survey recipients constants
	RecipientTypes:[
		{ id: "1", label: "Freshman" },
		{ id: "2", label: "Sophomore" },
		{ id: "3", label: "Junior" },
		{ id: "4", label: "Senior" },
		{ id: "5", label: "Graduate" },
		{ id: "6", label: "Part Time" },
		{ id: "7", label: "Admin" },
		{ id: "8", label: "Faculty" },
		{ id: "9", label: "System Administrator" },
		{ id: "10", label: "Adjunct Faculty" },
		{ id: "11", label: "Clinical Supervisor" }
	],
	HideSurvey:"Hide Survey",
	UnhideSurvey:"Unhide Survey",
	OptionMandatory:"Enter the options",
	facultyMapping:{
		'1': 'Admin',
		'2': 'Faculty',
		'3': 'System Administrator',
		'4': 'Adjunct Faculty',
		'5': 'Clinical Supervisor'
	},
	ResendModalConfirmText:"Resending this survey will clear any responses already submitted. Are you sure you want to continue?",
}
export default constants;
