import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Input, Form, message } from 'antd';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import {getModal, showModal, closeModal, CustomSortIcon, getNextAndPreviousButtons, showTotalItems, handleRowSelect, performSearch, HighlightedText } from '../../../GlobalFunctions/GlobalFunctions';
import ModalContent from '../SurveyTypes/ModalContent';
import constants from '../../../Constants/constants';
import { fetchStaffApi } from '../../../Services/Api';
import dayjs from 'dayjs';
import { COOKIE, getCookie } from '../../../Services/Cookie';

const SurveyTypes = () => {
	const [form] = Form.useForm();
	const [showAddModal,setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [saveDisabled, setSaveDisabled] = useState(false);
	const [selectedRecord,setSelectedRecord] = useState({});
	const [surveys,setSurveys] = useState([]);
	const [filteredSurveys, setFilteredSurveys] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
	const breadcrumbItems = [
		{
			label: constants.SurveyTypes,
			active: true
		}
	];
	useEffect(()=>{
	 getAllSurveys()
	},[])
	const surveyTypeTabColumns = [
		{
			title:constants.Name,
			key:'1',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => { return a.content.localeCompare(b.content)},
			render:(record)=><HighlightedText text={record?.content?record.content:"-"} highlight={searchText} />,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.DateCreated,
			key: '2',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.date_created_text.localeCompare(b.date_created_text),
			render:(record)=><HighlightedText text={record?.date_created_text?record?.date_created_text:"-"} highlight={searchText} />,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.SurveySentTo,
			key: '3',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.survey_send_to_text.localeCompare(b.survey_send_to_text),
			render:(record)=><HighlightedText text={record?.survey_send_to_text} highlight={searchText} />,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
	]
	const mapSurveySendTo = (survey_send_to) => {
		return survey_send_to.map(key => constants.surveySentTo[key]).join(", ");
	};
	const getAllSurveys = ()=>{
		fetchStaffApi(`/get-survey-types`, 'GET').then((res) => {
			if(res?.data.length>0){
				let data = res.data.map(survey => ({
					...survey,
					survey_send_to_text: survey.survey_send_to?mapSurveySendTo(survey.survey_send_to):"-",
					date_created_text:survey?.dt_created?dayjs(survey.dt_created).format(constants.DateFormat):null,
				}));
				setSurveys(data);
				setFilteredSurveys(data);
			}
		});
	}
	const onSearch = (e) => {
		const search = e;
		setSearchText(search);
		const searchFieldNames = [
			"content",
			'survey_send_to_text',
			'date_created_text',
		];
		let searchedValue = performSearch(search, surveys, searchFieldNames);
		setFilteredSurveys(searchedValue);
	}
	const submitNewSurveyDetails = (values)=>{
		setSaveDisabled(true)
		let payload = null;
		payload={
			content: values?.survey_title,
			admin_id: getCookie(COOKIE.UserId),
			survey_send_to:values?.survey_sent
		}
		fetchStaffApi(`/add-survey-type`, 'POST', payload).then((res) => {
			if(res?.code === '200'){
				closeModal(setShowAddModal);
				message.success(constants.DataSaved);
				getAllSurveys();
			} else if (res?.code === 400){
				closeModal(setShowAddModal);
				message.error(res?.message);
				getAllSurveys();
			}else{
				closeModal(setShowAddModal);
				message.error(constants.Error);
				getAllSurveys();
			}
			setSaveDisabled(false)
		}).catch((error) => {
			setSaveDisabled(false)
		});
	}
	const submitEditedDetails = (values)=>{
		setSaveDisabled(true)
		let payload = null;
		payload = {
			content: values?.survey_title,
			admin_id: getCookie(COOKIE.UserId),
			survey_send_to: values?.survey_sent,
		}
		fetchStaffApi(`/edit-survey-type/${selectedRecord?.id}`,'PUT', payload).then((res) => {
			if (res?.code === '200') {
				closeModal(setShowEditModal);
				message.success(constants.DataSaved);
				getAllSurveys();
				setRowKeys([]);
				setRowData([]);
			} else {
				closeModal(setShowEditModal);
				message.error(constants.Error);
				getAllSurveys();
				setRowKeys([]);
				setRowData([]);
			}
			setSaveDisabled(false)
		}).catch((error) => {
			setSaveDisabled(false)
		});
	}
	const deleteSelectedSurvey = ()=>{
		setSaveDisabled(true)
		fetchStaffApi(`/delete-survey-type/${selectedRecord?.id}`, 'DELETE').then((res) => {
			if (res?.code === '200') {
				closeModal(setShowDeleteModal);
				message.success(constants.SurveyTypeDeletedSuccessfully);
				getAllSurveys();
				setRowKeys([]);
				setRowData([]);
			} else {
				closeModal(setShowDeleteModal);
				message.error(constants.Error);
				getAllSurveys();
				setRowKeys([]);
				setRowData([]);
			}
			setSaveDisabled(false)
		}).catch((error) => {
			setSaveDisabled(false)
		});
	}
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
		)
		let selectedData= record?record:[...selectedRowData]
		setSelectedRecord(selectedData); // Update selectedRecord if a row is selected
  };
	const rowSelection = {
		fixed: 'left',
		// columnWidth: '2px',
		selectedRowKeys: rowKeys,
		onSelect: (record, selected, selectedRows) => onSelectChange( 
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			filteredSurveys?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
		hideSelectAll:true,
		getCheckboxProps: (record) => ({
			// Hide radio button for rows with id greater than 3
			style: { opacity: parseInt(record.id) > 3 ? 1 : 0, pointerEvents: parseInt(record.id) > 3 ? 'auto' : 'none' }
		}),
		// renderCell:(checked, record, index, originNode)=>{
		// 	return parseInt(record.id) > 3 ?<Checkbox/>:null
		// }

	};
	const openEditDetailsModal = () => {
		showModal(setShowEditModal);
		form.setFieldsValue({ survey_title: selectedRecord?.content, survey_sent: selectedRecord?.survey_send_to });
	}
	const openAddModal = ()=>{
		showModal(setShowAddModal);
		form.resetFields();
	}

	const clearKeys = () => {
		setRowKeys([]);
		setRowData([]);
	}
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.SurveyTypes} breadcrumbItems={breadcrumbItems} />
			<Card className='container-border-custom position-relative'>
			<div className='d-flex mainFilters'>
				<div className='d-flex'>
					<Button disabled={rowKeys.length > 0} className='btn-info text-sm no-padding'  onClick={openAddModal}>{constants.Add}</Button>
					<Button disabled={rowKeys.length === 0} className='btn-info text-sm ms-2 no-padding' onClick={openEditDetailsModal}>{constants.Edit}</Button>
					<Button className='text-sm btn-info ms-2 no-padding' disabled={rowKeys.length === 0} onClick={() => showModal(setShowDeleteModal)}>{constants.Delete}</Button>
				</div>
					<div><Input placeholder={constants.Search} className='w-48' onChange={(e)=>{onSearch(e.target.value)}} value={searchText}></Input></div>
			</div>
				<Table
					bordered
					className='records-table mt-2 surveyTypeTable data_table'
					columns={surveyTypeTabColumns}
					dataSource={filteredSurveys}
					scroll={{ x: 768}}
					size='small'
					rowKey={(record) => record?.id}
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, filteredSurveys),
						itemRender: getNextAndPreviousButtons
					}}
					//rowSelection={{type: "checkbox",...tableRowSelection}}
					onRow={(record, rowIndex) => {
						if(parseInt(record.id) > 3){
							return {
								onClick: () => onSelectChange(null, null, record, rowIndex),
								onDoubleClick: () => openEditDetailsModal(),
							}
						}
					}}
					rowSelection={rowSelection}
				>
				</Table>
				{
						(showAddModal || showEditModal || showDeleteModal)
					&& getModal(
						(showAddModal ? constants.AddSurveyType : showEditModal ? `${constants.EditSurveyType} - ${selectedRecord?.content}`:
							selectedRecord && selectedRecord?.is_active === 0 ? constants.Confirm : `${constants.DeleteSurveyType}${selectedRecord?.content}`),
							<ModalContent
							type={showAddModal ? 1 : showEditModal ? 2 : 3}
							form={form}
							closeModal={closeModal}
							setShowAddModal={setShowAddModal}
							setShowEditModal={setShowEditModal}
							submitNewSurveyDetails={submitNewSurveyDetails}
							submitEditedDetails={submitEditedDetails}
							setShowDeleteModal={setShowDeleteModal}
							selectedRecord={selectedRecord}
							deleteSelectedSurvey={deleteSelectedSurvey}
							setSelectedRow={setRowKeys}
							clearKeys={clearKeys}
							saveDisabled={saveDisabled}
						/>,
						(showAddModal || showEditModal || showDeleteModal),
						closeModal, 
						(showAddModal ? setShowAddModal : showEditModal ? setShowEditModal : setShowDeleteModal), () => clearKeys()
						)
				}
			</Card>
		</div>
	)
}

export default SurveyTypes
