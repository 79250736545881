import React, { useState, useEffect } from "react";
import { Card, Table, Space, Input, Button, Select, Checkbox, Col, Row, Spin } from "antd";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import constants from "../../Constants/constants";
import { fetchStaffApi } from '../../Services/Api';
import { COOKIE, createCookie, deleteCookie, getCookie } from '../../Services/Cookie';
import { performSearch, HighlightedText, CustomSortIcon, getNextAndPreviousButtons } from '../../GlobalFunctions/GlobalFunctions';
import { useNavigate } from 'react-router';

const { Option } = Select;

function getCustomSortIcon(sortOrder) {
	return <CustomSortIcon sortOrder={sortOrder} />
}

const AdvisingDocs = () => {
	const navigate = useNavigate();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(true);
	const pageTitle = 'Advising Documents'; 
	const breadcrumbItems = [
		{ label: 'Advising Documents', href: '/', active: true },
	];
	const [reload, setReload] = useState(false);
	const [listingPayload, setListingPayload] = useState({
		year: 0,
		type: 0,
		status: 0,
		faculty_id: getCookie(COOKIE.UserId),
		show_all: 0
	});
	const [searchValue, setSearchValue] = useState("");
	const [tableData, setTableData] = useState([]);
	const [filterTableData, setFilterTableData] = useState([]);
	const [record, setRecord] = useState(null);
	const [isYearDisabled, setIsYearDisabled] = useState(false);
	const [yearValue, setYearValue] = useState(null);
	const facultyRole = getCookie(COOKIE.Role)

	const AdvisingDocsCols = [
		{
			title: `${constants.Student}`,
			dataIndex: 'student',
			key: 'student',
			sorter: (a, b) => a.student.localeCompare(b.student),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (student,record) => <span onDoubleClick={()=>viewSemCourses(record)}><HighlightedText text={student} highlight={searchValue} /></span>,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: `${constants.YearProgram}`,
			dataIndex: 'year_in_program_text',
			key: 'year_in_program_text',
			sorter: (a, b) => a.year_in_program_text.localeCompare(b.year_in_program_text),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (year_in_program_text) => <HighlightedText text={year_in_program_text} highlight={searchValue} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: 'Student Type',
			dataIndex: 'student_type_value',
			key: 'student_type_value',
			sorter: (a, b) => a.student_type_value.localeCompare(b.student_type_value),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (student_type_value) => <HighlightedText text={student_type_value} highlight={searchValue} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
	];

	useEffect(() => {
		advisingDocList();
	}, [reload]);

	useEffect(() => {
		deleteCookie(COOKIE.FacultyIdFromEdit, "/");
	}, []);

	const advisingDocList = () => {
		let formData = {
			year: listingPayload.year,
			type: listingPayload.type,
			status: listingPayload.status,
			faculty_id: listingPayload.faculty_id,
			show_all: listingPayload.show_all,
		}
		setLoading(true);
		fetchStaffApi('/display-faculty-students', 'POST', formData).then((res) => {
			if (res?.code === "200") {
				setTableData(res?.data);
				setFilterTableData(res?.data);
			} else {
				setTableData([]);
				setFilterTableData([]);
			}
			setLoading(false);
		});
	};

	// onchange function for type | year | status | showall
	const onChangeSelect = (e, selectType) => {
		setReload(!reload);
		if (selectType === "type") {
			setListingPayload({
				...listingPayload,
				type: e || 0
			});
			// Disable the year filter if Student Type is "Part Time"
			if (e === "2") {
				setIsYearDisabled(true);
				setYearValue(null);
			} else {
				setIsYearDisabled(false);
			}
		} else if (selectType === "year") {
			setListingPayload({
				...listingPayload,
				year: e || 0
			});
			setYearValue(e);
		} else if (selectType === "status") {
			setListingPayload({
				...listingPayload,
				status: e || 0
			});
		} else if (selectType === "show_all") {
			setListingPayload({
				...listingPayload,
				show_all: e.target.checked === true ? '2' : '0'
			});
		}
	};

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search);
		const searchFieldNames = [
			'student',
			'year_in_program_text',
			'student_type_value',
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setTableData(searchedValue);
	};

	const viewChecklist = () => {
		createCookie("facultyId", record?.faculty_id, null, "/");
		navigate(`/student-information`, {
			state: {
				student_id: record?.student_id,
				tabkey: "Checklist",
				from: constants.AdvisingDocs,
				from_route: "/advising-documents",
				tab_title: constants.Checklist,
				student_name: record?.student
			}
		});
	};

	const viewCourses = () => {
		createCookie("facultyId", record?.faculty_id, null, "/");
		navigate(`/student-information`, {
			state: {
				student_id: record?.student_id,
				tabkey: "courses",
				tab_title: constants.Courses,
				from: constants.AdvisingDocs,
				from_route: "/advising-documents",
				student_name: record?.student
			}
		});
	};

	const viewPlanOfStudy = () => {
		createCookie("facultyId", record?.faculty_id, null, "/");
		navigate(`/student-information`, {
			state: {
				student_id: record?.student_id,
				tabkey: "PlanOfStudy",
				tab_title: constants.PlanOfStudy,
				from: constants.AdvisingDocs,
				from_route: "/advising-documents",
				student_name: record?.student
			}
		});
	};

	const viewHistory = () => {
		createCookie("facultyId", record?.faculty_id, null, "/");
		navigate(`/student-information`, {
			state: {
				student_id: record?.student_id,
				tabkey: "History",
				tab_title: constants.History,
				from: constants.AdvisingDocs,
				from_route: "/advising-documents",
				student_name: record?.student
			}
		});
	};

	const viewSemCourses = (value) => {
		createCookie("facultyId", record?.faculty_id, null, "/");
		navigate(`/student-information`, {
			state: {
				student_id: value?value?.student_id:record?.student_id,
				tabkey: "semester-courses",
				tab_title: constants.SemesterCourses,
				from: constants.AdvisingDocs,
				from_route: "/advising-documents",
				student_name: value?value?.student:record?.student,
				sem_id: value?value?.sem_id:record?.sem_id,
				year_in_program:value?value?.year_in_program:record?.year_in_program,
			}
		});
	};

	//checkbox working like radio parent table.
	const rowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
		onChange: (selectedRowKeys, selectedRows) => {
			if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = selectedRows.slice(-1);
			}
			setSelectedRowKeys(selectedRowKeys);
			setRecord(selectedRows[0]);
		},
		selectedRowKeys: selectedRowKeys
	};
	const onRowClick = (record) => {
		const key = record.student_id;
		let newSelectedRowKeys = [...selectedRowKeys];

		if (newSelectedRowKeys.includes(key)) {
			newSelectedRowKeys = [];
		} else {
			newSelectedRowKeys = [key];
		}

		setSelectedRowKeys(newSelectedRowKeys);
		setRecord(newSelectedRowKeys.length > 0 ? record : null);
	};
	const viewAdvisingNotes = () => {
		createCookie("facultyId", record?.faculty_id, null, "/");
		navigate(`/student-information`, {
			state: {
				student_id:record.student_id,
				tabkey: "advising-notes",
				tab_title: constants.AdvisingNotes,
				from: constants.AdvisingDocs,
				from_route: "/advising-documents",
				student_name: record?.student,
				sem_id: record?.sem_id,
				year_in_program: record?.year_in_program
			}
		});
	};

	return (
		<div>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<div>
				<Card className="container-border-custom">
					<div>
						<Row>
							<Col xs={24} lg={12}>
								<Space wrap className='mb-2'>
									<Select
										allowClear
										className="selectCompWidth"
										onChange={(e) => onChangeSelect(e, "type")}
										placeholder={constants.SelectType}
										showSearch
										filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
									>
										{
											constants.StudentTypeList.map((record) =>
												<Option value={record.value} key={record.value} >{record.label}</Option>
											)
										}
									</Select>
									<Select
										allowClear
										className="selectCompWidth"
										onChange={(e) => onChangeSelect(e, "year")}
										placeholder={constants.SelectYear}
										disabled={isYearDisabled}
										value={yearValue}
										showSearch
										filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
									>
										{
											constants.YearList.map((record) =>
												<Option value={record.value} key={record.value}>{record.label}</Option>
											)
										}
									</Select>
									<Select
										allowClear
										className="selectCompWidth"
										onChange={(e) => onChangeSelect(e, "status")}
										placeholder={constants.SelectStatus}
										showSearch
										filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
										options={[
											{
												value: '1',
												label: `${constants.Break}`,
											},
											{
												value: '2',
												label: `${constants.Dismissed}`,
											},
											{
												value: '3',
												label: `${constants.LeftProgram}`,
											},
											{
												value: '4',
												label: `${constants.Probation}`,
											},
											{
												value: '5',
												label: `${constants.SlpMajor}`,
											},
											{
												value: '6',
												label: `${constants.Undeclared}`,
											},
										]}
									/>
								</Space>
							</Col>
							{facultyRole === '3' && (
								<Col xs={24} lg={12} className="grid justify-items-end">
									<div>
										<Checkbox onChange={(e) => onChangeSelect(e, "show_all")}>{constants.ShowAllStudents}</Checkbox>
									</div>
								</Col>)}
						</Row>
						
						<div className="flex justify-between flex-wrap gap-2">
							<Space size={5} wrap>
								<Button className="text-capitalize btn-info" onClick={viewAdvisingNotes} disabled={!record}>{constants.AdvisingNotes}</Button>
								<Button className="text-capitalize btn-info" onClick={viewCourses} disabled={!record}>{constants.Courses}</Button>
								<Button className="text-capitalize btn-info" onClick={()=>viewSemCourses(null)} disabled={!record}>{constants.SemesterCourses}</Button>
								<Button className="text-capitalize btn-info" onClick={viewPlanOfStudy} disabled={!record}>{constants.PlanOfStudyAdvisement}</Button>
								<Button className="text-capitalize btn-info" onClick={viewChecklist} disabled={!record}>{constants.Checklist}</Button>
								<Button className="text-capitalize btn-info" onClick={viewHistory} disabled={!record}>{constants.History}</Button>
							</Space>
							<Input className="w-48 h-8" placeholder={constants.Search} onChange={onSearch} />
						</div>
						<div className="mt-2">
							<Spin tip={constants.PleaseWait} spinning={loading}>
								<Table
									className="records-table data_table"
									rowSelection={{
										...rowSelection,
										hideSelectAll: true,
									}}
									columns={AdvisingDocsCols}
									dataSource={tableData}
									rowKey={(record) => record.student_id}
									bordered
									scroll={{ x: 768, y: 'calc(100vh - 320px)' }}
									size="small"
									pagination={{
										className:"!mb-0",
										showTotal: (total, range) => <span>{`Showing ${range[0]}-${range[1]} of ${total} entries`}</span>,
										itemRender: getNextAndPreviousButtons,
										pageSize: constants.TablePageSize,
									}}
									onRow={(record) => ({
										onClick: () => onRowClick(record),
									})}
								/>
							</Spin>
						</div>
					</div>
				</Card>
			</div>
		</div>
	)
}

export default AdvisingDocs