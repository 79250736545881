import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Layouts from '../Views/Common/Layout';
import StudentLayout from '../Views/StudentSection/Common/Layout';
import { COOKIE, getCookie } from '../Services/Cookie';

const ConditionalLayout = () => {
	/* logic to determine if user is a student, e.g., user role check */;
	const [isStudent, setIsStudent] = useState(false);
	useEffect(() => {
		getUserRole()
	}, []);
	const getUserRole = () => {
		let user = {
			userId:getCookie(COOKIE.UserId),
			role:getCookie(COOKIE.Role),
		}
		if(user?.role==="0"){
			setIsStudent(true);
		}else{
			setIsStudent(false);
		}
	};
	// Render the layout based on the condition
	return (
		<>
			{isStudent ? (
				<StudentLayout>
					<Outlet />
				</StudentLayout>
			) : (
				<Layouts>
					<Outlet />
				</Layouts>
			)}
		</>
	);
};
export default ConditionalLayout;
