import React, { useState, useEffect } from 'react'
import { Modal, Table, Spin } from 'antd'
import constants from '../../Constants/constants';
import PropTypes from 'prop-types';
import { fetchStaffApi } from '../../Services/Api';
import dayjs from 'dayjs'
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { useNavigate } from 'react-router';
import { showTotalItems,getNextAndPreviousButtons, CustomSortIcon } from '../../GlobalFunctions/GlobalFunctions';

const ProbationHistory = ({ openModal, closeModal }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [probationData, setProbationData] = useState([])

  const handleEdit = (studentId,studentName,tabKey)=>{
    navigate(`/student-information`, {
      state: { 
				student_id: studentId,
				student_semester: tabKey,
        from: constants.StudentListing,
				from_route: "/student-listing",
				tabkey:"StudentDetails",
        tab_title: constants.StudentDetails,
				student_name:studentName
			}
    });
  }
  const columns = [
    {
      title: constants.Student,
      dataIndex: 'student_name',
      render:(student_name,record)=><span className='cursor-pointer' onClick={()=>handleEdit(record.student_id,student_name)}>{student_name}</span>,
      sorter: (a, b) => a.student_name?.localeCompare(b.student_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
      width:'25%'
    },
    {
      title: 'ID',
      dataIndex: 'student_id_number',
      sorter: (a, b) => a.student_id_number?.localeCompare(b.student_id_number),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
      width:'10%'
    },
    {
      title: constants.YearProgram,
      dataIndex: 'year_in_program',
      sorter: (a, b) => a.year_in_program?.localeCompare(b.year_in_program),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
      width:'20%'
    },
    {
      title: constants.DateOfProbation,
      dataIndex: 'dt_updated',
      render: (dt_updated) => <>{dayjs(dt_updated).format(constants.DateFormat)}</>,
      sorter: (a, b) => a.dt_updated?.localeCompare(b.dt_updated),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
      width:'20%'
    },
    {
      title: constants.ChangedBy,
      dataIndex: 'changed_by',
      sorter: (a, b) => a.changed_by?.localeCompare(b.changed_by),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
      showSorterTooltip: { show: true, placement: 'bottom' },
      width:'25%'
    },
  ]
 
  const getProbationData = () => {
    setLoading(true)
    fetchStaffApi(`/get-students-history-of-probation`, 'GET')
      .then((res) => {
        if (res?.code === '200') {
          setProbationData(res.data);
          setLoading(false)
        } else {
          setProbationData([]);
          setLoading(false)
        }
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getProbationData()
  }, [])
  return (
    <Modal open={openModal} onCancel={closeModal} title={constants.ProbationHistory}
    className='!top-5' footer={null} width={600}>
      <Spin tip={constants.PleaseWait} spinning={loading}>
      <Table columns={columns} dataSource={probationData} bordered size="small" rowKey={(record) => record.id}
        className="records-table data_table" rowSelectedBg='#e6f4ff' pagination={{
          pageSize: 10,
          showTotal: (total, range) => showTotalItems(total,range,probationData),
          itemRender: getNextAndPreviousButtons,
          }} searchableProps={{ fuzzySearch: true }}
        	scroll={{
            y: 350,
            x: 500
          }} />
          </Spin>
    </Modal>
  )
}
ProbationHistory.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  tabKey:PropTypes.string.isRequired
}
export default ProbationHistory