import { message } from "antd";
import { getBase64 } from "../../../GlobalFunctions/GlobalFunctions";
export const validateNumber = async (event) => {
	if (event.key === 'Backspace' || /[0-9]/.test(event.key)) {

		const value = event.target.value;
		const numericValue = value.replace(/\D/g, '');

		let formattedNumber = '';
		for (let i = 0; i < numericValue.length; i++) {
			if (i === 3 || i === 6) {
				formattedNumber += '-';
			}
			formattedNumber += numericValue[i];
		}
		event.target.value = formattedNumber;
	} else {
		event.preventDefault();
	}
};

export const validateNonphone = async (event) => {
	const value = event.target.value;
	const numericValue = value.replace(/\D/g, '');
	event.target.value = numericValue;
};

export const handleUpload = (info, setImageFile, form, setLoading, setImageText) => {
	if (info.file.status === 'uploading') {
		setLoading(true);
		return;
	}
	if (info.file.status === 'done') {
		setLoading(false)
		getBase64(info.file.originFileObj, (url) => {
			setLoading(false);
			setImageText(url);
		});
		setImageFile(info.file.originFileObj)
	} else if (info.file.status === 'error') {
		message.error(`${info.file.name} file upload failed.`);
	}
};

export const scrollUp = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
}