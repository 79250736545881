import React, { useEffect, useState, useRef } from 'react'
import { Card, Col, Row, Form, Input, DatePicker, Select, Flex, Button, Checkbox, Radio } from 'antd'
import constants from '../../../Constants/constants';
import { handleUpload } from './FacultyFunctions';
import PropTypes from 'prop-types';
import FacultyAddressField from './FacultyAddressField';
import { Link } from 'react-router-dom';
import { useFetchCountries } from './FetchCountries';
import { useFetchStates, useFetchPermaStates } from './FetchStates';
import { disableSpaceBar, handleKeyPressHandler, allowAlphabets, beforeProfileUpload, checkEmptySpace } from '../../../GlobalFunctions/GlobalFunctions';
import dayjs from 'dayjs'
import FileUpload from '../FileUpload';
import { LoadingOutlined, UserAddOutlined } from '@ant-design/icons';

const FacultyFormItem = ({ edit, searchStates, searchPermaStates, onFinish, form, imageFile, setImageFile,
	active, setActive, display, setDisplay, type, setType, isProfile, isAdvisor,
	advisorCheck, setAdvisorCheck, headerContainer, imageText, setImageText, saveLoader }) => {
	const { countries, fetchCountries } = useFetchCountries();
	const { states, fetchStates } = useFetchStates();
	const { permaStates, fetchPermaStates } = useFetchPermaStates();
	const [loading, setLoading] = useState(false)
	const [emailCheckbox, setEmailCheckbox] = useState(false);
	const [isSearchStates, setIsSearchStates] = useState(true)
	const [isPermaSearchStates, setIsPermaSearchStates] = useState(true)
	const [disableAll, setDisableAll] = useState(false)
	const facultyType = constants.facultyType;
	const formRef = useRef(null)
	const currentValues = form.getFieldsValue();
	const currentpos = currentValues['faculty_roles'];

	const uploadHandler = (info) => {
		handleUpload(info, setImageFile, form, setLoading, setImageText);
	};

	const handleSwitchChange = ({ target: { value } }) => {
		setActive(value);
		if (value === '3') {
			setDisableAll(true)
		} else {
			setDisableAll(false)
		}
	};
	const handleChange = (e) => {
		form.setFieldsValue({ faculty_current_state: "" });
		setIsSearchStates(false)
		fetchStates(e)
	}
	const handlePermaChange = (e) => {
		form.setFieldsValue({ faculty_permanent_state: "" });
		setIsPermaSearchStates(false)
		fetchPermaStates(e)
	}
	const disabledDate = current => {
		// If the current date is after the date being checked, disable it
		return current && current > dayjs().endOf('day');
	};
	// const scrollToRequiredField = (errorFields) => {
	// 	if (errorFields && errorFields.length > 0) {
	// 		const firstRequiredField = errorFields.find(field => field.errors.some(error => error.includes('required')));
	// 		if (firstRequiredField) {
	// 			const fieldName = firstRequiredField.name;
	// 			const fieldElement = formRef.current.getFieldInstance(fieldName);
	// 			if (fieldElement) {
	// 				fieldElement.focus();
	// 				fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
	// 			}
	// 		}
	// 	}
	// };
	// const handleFinishFailed = (errorInfo) => {
	// 	scrollToRequiredField(errorInfo.errorFields);
	// };
	const handleCellPhoneInputChange = (e, formval) => {
		const inputValue = e.target.value;
		// const formattedInput = phoneNumberFormatter(inputValue);
		form.setFieldsValue({ [formval]: inputValue })
	};
	const handlePosition = (e) => {
		if (e === 4) {
			setEmailCheckbox(true)
		} else {
			setEmailCheckbox(false)
		}
	}

	useEffect(() => {
		fetchCountries()
	}, [])

	useEffect(() => {
		if (active === '3') {
			setDisableAll(true)
		}
	}, [active])

	useEffect(() => {
		if (currentpos === 4) {
			setEmailCheckbox(true)
		}
	}, [currentpos])

	const uploadButton = (
		<button
			className='bg-transparent border-0'
			type="button"
		>
			{loading ? <LoadingOutlined /> : <UserAddOutlined className='fs-1' />}
		</button>
	);
	const profileRenderContent = imageText ? (
		<img src={imageText} alt="avatar" className='photoPick' />
	) : (
		uploadButton
	);

	const normalField = (name, isRequired, labelText, isDisable) => {
		return (
			<Col xl={8} lg={8} md={8} sm={24} xs={24}>
				<Form.Item
					label={labelText}
					name={`faculty_${name}`}
					rules={[
						{
							required: isRequired,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					{	name==='first_name' || name==='middle_name' || name==='last_name'?
						<Input 
						 placeholder={labelText} 
						 disabled={isDisable} 
						 onKeyPress={(event) => allowAlphabets(event)}
						 onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`faculty_${name}`))}
						/>
					:
						<Input 
							placeholder={labelText} 
							disabled={isDisable}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`faculty_${name}`))}
						/>
					}
				</Form.Item>
			</Col>
		)
	}

	const phoneField = (name, isRequired, labelText, isDisable, validateMsg) => {
		return (
			<Col xl={8} lg={8} md={8} sm={24} xs={24}>
				<Form.Item
					label={labelText}
					name={`faculty_${name}`}
					rules={[
						{
							required: isRequired,
							message: constants.RequiredField,
							whitespace: isRequired,
						}, 
						// {
						// 	min: 12,
						// 	message: validateMsg,
						// }
					]}
				>
					<Input placeholder={labelText} disabled={isDisable}
						onKeyPress={(e) => handleKeyPressHandler(e, null)}
						onChange={(e) => handleCellPhoneInputChange(e, `faculty_${name}`)} />
				</Form.Item>
			</Col>
		)
	}

	const emailField = (name, isRequired, labelText, isDisable) => {
		return (
			<Col xl={8} lg={8} md={8} sm={24} xs={24}>
				<Form.Item
					label={labelText}
					name={`faculty_${name}`}
					rules={[
						{
							type: 'email',
							message: constants.InvalidFormat,
						},
						{
							required: isRequired,
							message: constants.RequiredField,
						},
					]}
				>
					<Input onKeyDown={disableSpaceBar} placeholder={labelText} disabled={isDisable} />
				</Form.Item>
			</Col>
		)
	}

	const specialNumField = (name, labelText, isDisable, len, validateMsg, colSpan) => {
		return (
			<Col xl={colSpan} lg={colSpan} md={colSpan} sm={24} xs={24}>
				<Form.Item
					label={labelText}
					name={`faculty_${name}`}
					rules={[
						{
							required: false,
						},
						{
							min: len,
							message: validateMsg
						}
					]}
				>
					<Input placeholder={labelText}
						onKeyPress={(e) => handleKeyPressHandler(e, len)}
						maxLength={len} disabled={isDisable}
					/>
				</Form.Item>
			</Col>
		)
	}

	return (
		<Card title="" className="container-border-custom px-3">
			<Form layout="vertical" form={form} onFinish={onFinish} scrollToFirstError ref={formRef}>
				{headerContainer}
				<Flex align='center' justify='center'>
					<Form.Item
						className='position-absolute z-3 mt-2'
					>
						<FileUpload
							renderContent={profileRenderContent}
							name='avatar'
							listType='picture-circle'
							className='avatar-uploader'
							showUploadList={false}
							beforeUpload={beforeProfileUpload}
							onChange={uploadHandler}
							isMultiple={false}
							disabled={disableAll}
						/>
					</Form.Item>
				</Flex>
				<br></br>
				{edit && !isProfile ?
					<Flex gap="small" align="flex-end" vertical className='mt-4' >
						<Form.Item
							name="is_active"
							valuePropName='checked'
						>
							<Radio.Group
								className='radioSwitch'
								options={constants.ShowStatusOptions}
								onChange={handleSwitchChange}
								optionType="button"
								buttonStyle="solid"
								value={active}
							/>
						</Form.Item>
					</Flex> : ' '
				}
				{
					isProfile && isAdvisor ?
						<Flex gap="small" align="flex-end" vertical >
							<br></br>
							<Form.Item
								label=""
							>
								<Checkbox checked={advisorCheck}
									onChange={(e) => setAdvisorCheck(e.target.checked)} >Show cell phone number to students</Checkbox>
							</Form.Item>
						</Flex>
						: ''
				}
				<br></br>
				<Row gutter={24}>
					{normalField('first_name', true, constants.FirstName, isProfile || disableAll)}
					{normalField('middle_name', false, constants.MiddleName, isProfile || disableAll)}
					{normalField('last_name', true, constants.LastName, isProfile || disableAll)}
					<Col xl={8} lg={8} md={8} sm={24} xs={24}>
						<Form.Item
							name="faculty_roles"
							label="Position"
							rules={[{
								required: true,
								message: constants.RequiredField,
							}]}
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							<Select placeholder="--Select--" disabled={isProfile || disableAll}
								onChange={handlePosition}
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
							>
								{facultyType.map((options) =>
									<Select.Option value={options.key} key={options.key}>{options.option}</Select.Option>
								)}
							</Select>
						</Form.Item>
					</Col>
					<Col xl={8} lg={8} md={8} sm={24} xs={24}>
						<Form.Item
							label="Start Date"
							name="faculty_date_joining"
							rules={[
								{
									required: true,
									message: constants.RequiredField,
								},
							]}
						>
							<DatePicker className='w-100' placeholder='Start Date' format={constants.DateFormat}
								disabled={isProfile || disableAll}
								disabledDate={disabledDate} />
						</Form.Item>
					</Col>
					{normalField('identify', false, constants.IdentifyAs, disableAll)}
					{phoneField('mobile_number', true, constants.CellPhone, disableAll, constants.MobileValidateMessage)}
					{phoneField('office_number', true, 'Office Phone', disableAll, constants.OfficePhone)}
					{phoneField('home_number', false, constants.HomePhn, disableAll, constants.HomePhone)}
					{emailField('personal_email', true, constants.PersonalEmail, disableAll)}
					{emailField('university_email', false, constants.UniversityEmail, isProfile || disableAll)}
					<Col xl={8} lg={8} md={8} sm={24} xs={24}>
						<Form.Item
							label="Notes"
							name="faculty_note"
							rules={[
								{
									required: false,
								},
							]}
						>
							<Input.TextArea placeholder="Notes" disabled={disableAll} />
						</Form.Item>
					</Col>
					{
						emailCheckbox ?
							<Col xs={24} xl={24}>
								<Form.Item
									label=""
								>
									<Checkbox
										name='email_type' checked={type} onChange={(e) => setType(e.target.checked)} disabled={isProfile}><strong>Use Email for personal communication</strong></Checkbox>
								</Form.Item>

							</Col>
							: ''
					}
					{specialNumField('asha_number', constants.AshaNumber, disableAll, 8, constants.AshaValidateMessage, edit ? 8 : 12)}
					{specialNumField('psha_number', constants.PshaNumber, disableAll, 5, constants.PshaValidateMessage, edit ? 8 : 12)}
					{edit ?
						<Col xl={8} lg={8} md={8} sm={24} xs={24}>
							<Form.Item
								label="Username"
								name="faculty_username"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input placeholder='Username' disabled />
							</Form.Item>
						</Col> : ''
					}
				</Row>
				<hr></hr>
				<FacultyAddressField form={form} countries={countries} states={isSearchStates ? searchStates : states} handleChange={handleChange}
					field={'faculty_current'} title={'Current'} disableAll={disableAll} />
				<Row>
					<Checkbox checked={display}
						onChange={(e) => setDisplay(e.target.checked)} name='addressCheck' >{constants.AdressLabel}</Checkbox>
				</Row>
				<br></br>
				{
					!display ?
						<FacultyAddressField countries={countries} states={isPermaSearchStates ? searchPermaStates : permaStates}
							handleChange={handlePermaChange} field={'faculty_permanent'} title={'Permanent'} disableAll={disableAll} />
						: ''
				}
				<br></br>
				<Flex gap="small" align="flex-end" vertical className='faculty-action-container'>
					<Flex gap="small" wrap="wrap">
						<Button type="primary" className='action-btn' danger>
							<Link to="/staff-list" className='link-text'>
								{constants.Cancel}
							</Link>
						</Button>
						<Button loading={saveLoader} disabled={saveLoader} type="primary" className='action-btn' htmlType='submit'>
							{constants.Save}
						</Button>
					</Flex>
				</Flex>
			</Form>
		</Card>
	)
}
FacultyFormItem.propTypes = {
	edit: PropTypes.bool.isRequired,
	searchStates: PropTypes.array.isRequired, // Update with the correct prop type
	searchPermaStates: PropTypes.array.isRequired,
	onFinish: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	imageFile: PropTypes.object.isRequired,
	setImageFile: PropTypes.func.isRequired,
	active: PropTypes.bool,
	setActive: PropTypes.func,
	display: PropTypes.bool.isRequired,
	setDisplay: PropTypes.func.isRequired,
	type: PropTypes.bool,
	setType: PropTypes.func,
	isProfile: PropTypes.bool.isRequired,
	isAdvisor: PropTypes.bool,
	advisorCheck: PropTypes.bool,
	setAdvisorCheck: PropTypes.func,
	headerContainer: PropTypes.node,
	imageText: PropTypes.string,
	setImageText: PropTypes.func,
};
export default FacultyFormItem
