import constants from "../../../Constants/constants";
import { scrollUp } from "../FacultyComponents/FacultyFunctions";
import { fetchStaffFormData } from "../../../Services/Api";
import {message} from 'antd'
export const sendEmail = async (formData, setLoading,form) => {
  await fetchStaffFormData('/send-mail', 'POST', formData)
      .then((res) => {
          if (res?.code === "200") {
              setLoading(false);
              const statuses = res.message.map((item) => item.status);
              const isSuccess = statuses.includes("success");
              const isFailure = statuses.includes("failure");

              if (isSuccess && isFailure) {
                  scrollUp();
                  message.error(constants.PartialError);
                  form.resetFields()
              } else if (isSuccess) {
                  scrollUp();
                  message.success(constants.EmailSuccess)
              } else if (isFailure) {
                  scrollUp();
                  message.error(constants.NotFound);          
              } else {
                  scrollUp();
                  message.error(constants.ErrorMessage);
              }
          }else{
            setLoading(false)
            message.error(constants.ErrorMessage);
          }
      })
      .catch((error) => {});
};
