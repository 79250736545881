import React, { useEffect, useState } from 'react'
import {Button, Row, Col, Form, message, Spin,Upload} from 'antd'
import constants from '../../../Constants/constants'
import { fetchStaffFormData,fetchApi } from '../../../Services/Api'
import { getCookie, COOKIE } from '../../../Services/Cookie'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import {InboxOutlined,CheckCircleFilled} from '@ant-design/icons'
const { Dragger } = Upload;
const ImportCard = ({ CardContent, ButtonText, SubmitData, tabKey }) => {
	const [loading, setLoading] = useState(false)
	const [fileUpload,setFileUpload] = useState(false)
	const [isSpringSemester, setIsSpringSemester] = useState(true)
	const [file,setFile] = useState(null)
	const [form] = Form.useForm()
	const navigate = useNavigate()
	let user = getCookie(COOKIE.UserId);
	
	const beforeUploadCheckFileType = (file) => {
		const fileExtension = file.name.split('.').pop(); // Get the file extension
		// Check if the file extension is one of the allowed types
		if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
			message.error(constants.InvalidFileFormat);
			return false;
		}
		return true;
	}
	const RemoveFile = () => {
    form.setFieldsValue({
      importExcel: null,
    });
    setFile(null);
    setFileUpload(false);
  };
	const uploadHandler = (info) => {
		let fileUrl = null;
		if (info.file.status === 'done') {
			fileUrl = info.file.originFileObj;
			// Clear previous file before setting the new one
			setFile(fileUrl)
			form.setFieldValue({
				importExcel: fileUrl
			});
			setFileUpload(true)
			return;
		} else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}
	};
	const onSubmit = (formData) => {
		setLoading(true)
		formData.importExcel = file
		if(SubmitData===1){
			formData = {
				...formData,
				admin_id: user
			}
	}
		switch (SubmitData) {
			case 1:
				fetchStaffFormData(`/student-initial-import`, 'POST', formData)
					.then((res) => {
						form.resetFields()
						setFileUpload(false)
						if (res?.code === "200" && res?.message === 'Data added successfully') {
							message.success(constants.DataSaved)
							setLoading(false)
							navigate('/student-listing')
							form.resetFields()

						} else if (res.code === '200' && res?.message === 'Failed Import') {
							navigate('/failed-import', { state: { importForm: 1, importData: res?.data } });
							form.resetFields()
							setLoading(false)
						}else if(res.code === 400){
							message.error(res?.message)
							setLoading(false)
							form.resetFields()
						}
						else{
							message.error(constants.ErrorMessage)
							setLoading(false)
							form.resetFields()
						}
					}).catch((error) => {
					})
				break;
			case 2:
				fetchStaffFormData(`/student-id-import`, 'POST', formData)
					.then((res) => {
						form.resetFields()
						setFileUpload(false)
						if (res?.code === "200" && res?.message === 'Data updated successfully') {
							message.success(constants.DataSaved)
							setLoading(false)
							navigate('/student-listing')
						} else if (res.code === '200' && res?.message === 'Failed Import') {
							setTimeout(() => {
								navigate('/failed-import', { state: { importForm: 2, importData: res?.data } });
							}, 2000)
							setLoading(false)
						}else if(res.code === 400){
							message.error(res?.message)
							setLoading(false)
						}
						else{
							message.error(constants.ErrorMessage)
							setLoading(false)
						}
					}).catch((error) => {
					})
				break;
			case 3:
				fetchStaffFormData(`/student-asha-psha-import`, 'POST', formData)
					.then((res) => {
						form.resetFields()
						setFileUpload(false)
						if (res?.code === "200" && res?.message === "Data added successfully") {
							message.success(constants.DataSaved)
							setLoading(false)
							navigate('/student-listing')
						} else if (res.code === '200' && res?.message === 'Failed Import') {
							navigate('/failed-import', { state: { importForm: 3, importData: res?.data } });
							setLoading(false)
						}else if(res.code === 400){
							message.error(res?.message)
							setLoading(false)
						}
						else{
							message.error(constants.ErrorMessage)
							setLoading(false)
						}
					}).catch((error) => {
					})
				break;
			default:
				break;
		}
	}
	const getSemester = async () => {
    await fetchApi(`/semester-data`, 'GET')
      .then(res => {
        if (res?.data) {
          res.data.map((record)=>record.semester_type==='1'?  setIsSpringSemester(false):'')
        }
      })
      .catch(error => {
      });
  };
  useEffect(() => {
    getSemester()
  }, [])

	useEffect(()=>{
		form.resetFields()
		setFileUpload(false)
	},[tabKey])

	return (
		<>
				{isSpringSemester ?
						<p><strong>{constants.SpringSemesterMsg}</strong></p> :	
					<>
					<p><strong>{constants.ImportHeader}</strong></p>
					<div className='my-1'>{CardContent}</div>
					<Spin tip={constants.PleaseWait} spinning={loading}>
						<Form form={form} onFinish={onSubmit} >
							<Row>
								<Col span={24}>
									<Form.Item
										name='importExcel'
										rules={[{
											required: true,
											message:constants.RequiredField
										}]}
										className='excelInput !mb-3'
									>
										<Dragger multiple={false} onChange={uploadHandler} showUploadList={{ showRemoveIcon: true }}
										listType='picture' onRemove={RemoveFile} beforeUpload={beforeUploadCheckFileType} className='import-dragger'
										>
											<p className="ant-upload-drag-icon">
												{fileUpload?<CheckCircleFilled />:<InboxOutlined />}
											</p>
											<p className="ant-upload-text">{!fileUpload?'Click or drag file to this area to upload':'File Uploaded Successfully'}</p>
											<p className="ant-upload-hint">
												Only excel files are supported .i.e xls and xlsx.
											</p>
										</Dragger>
									</Form.Item>
								</Col>
								<Col span={24} className='text-right'>
										<Button className={fileUpload?'text-capitalize btn-info import-btn':'text-capitalize btn-info'} htmlType='submit' >{constants.ImportExcel}</Button>									
								</Col>
							</Row>
						</Form>
						</Spin>
				</>
				}
			</>
	)
}
ImportCard.propTypes = {
  CardContent: PropTypes.node.isRequired, 
  ButtonText: PropTypes.string.isRequired,
  SubmitData: PropTypes.number.isRequired,
	tabKey: PropTypes.string.isRequired,
};
export default ImportCard
