// BreadcrumbHeader.js
import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { COOKIE, getCookie } from '../../Services/Cookie';

const BreadcrumbHeader = ({ pageTitle, breadcrumbItems }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-lg-center justify-content-between flex-lg-row flex-column mb-3">
      <h4 className="mb-lg-0">{pageTitle}</h4>
      {breadcrumbItems && (
        <Breadcrumb className="current-page-link py-1 ps-3">
          <Breadcrumb.Item key="home" onClick={()=>navigate(getCookie(COOKIE.Role)!=="0" ?"/dashboard":"/student-dashboard")}>
            Home
          </Breadcrumb.Item>
          {breadcrumbItems.map((item) => (
            <Breadcrumb.Item key={item.label} onClick={()=>item.href?navigate(item.href):false} active={item.active}>
              {item.label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

BreadcrumbHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ),
};

export default BreadcrumbHeader;
