import React, { useState, useEffect } from 'react';
import { Button, Space, List, Skeleton } from 'antd';
import { fetchStaffApi, fetchStaffDownloadApi } from '../../Services/Api';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import { useLocation } from "react-router-dom";
import OtherHistory from './OtherHistory';

const History = ({ student_id, student_name }) => {
	const location = useLocation();
	const [historyData, setHistoryData] = useState([]);
	const [visibleRecords, setVisibleRecords] = useState(3);
	const [loading, setLoading] = useState(false);
	const [showOtherHistoryModal,setShowOtherHistoryModal] = useState(false);
	const [pdfDownload, setPdfDownload] = useState(false);
	let isArchived=location?.state?.isArchived;
	const getPlanOfStudy = () => {
		setLoading(true);
		let payload = {
			studentid: student_id,
		};
		fetchStaffApi(`/get-student-note-list`, 'POST', payload)
			.then((res) => {
				setLoading(false);
				if (res?.code === "200") {
					setHistoryData(res?.data);
				} else {
					setHistoryData([]);
				}
			});
	};

	useEffect(() => {
		getPlanOfStudy();
	}, []);

	const onLoadMore = () => {
		setLoading(true);
		setVisibleRecords(prevVisibleRecords => prevVisibleRecords + 3);
		setLoading(false);
	};

	const PrintButton = () => {
		const handlePrint = () => {
		  if (!pdfDownload) {
				setPdfDownload(true);
				const withPdfPayload = {
					print: true,
					studentid: student_id,
				};
				fetchStaffDownloadApi(`/get-student-note-list`, 'POST', withPdfPayload).then((res) => {
					if (res) {
						const blob = new Blob([res], { type: 'application/pdf' });
						const url = URL.createObjectURL(blob);
						window.open(url, '_blank');
					}
					setPdfDownload(false);
				});
			}
		};
	  
		return !isArchived&&<Button className='btn-info' onClick={handlePrint} disabled={historyData.length === 0}>Print</Button>;
	  };

	  const closeModal = () =>{
		setShowOtherHistoryModal(false)
	  }

	  const openOtherHistoryModal = ()=>{
		setShowOtherHistoryModal(true)
	  }

	return (
		<div>
			<div className='flex items-center justify-end'>
				<Space>
					<PrintButton />
					{!isArchived&&<Button className='btn-info' onClick={()=>openOtherHistoryModal()}>Other History</Button>}
				</Space>
			</div>
			<div className='mt-3'>
				<List
					bordered
					className='list-border-dark print-data'
					dataSource={historyData.slice(0, visibleRecords)}
					renderItem={(item) => (
						<List.Item>
							<div className='w-full'>
								<div className='mt-2'><strong>Note:-</strong></div>
								<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note) }} />
								{item.confidentiality_note && (
									<div>
										<div className='mt-2'><strong>Confidential Note:-</strong></div>
										<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.confidentiality_note) }} />
									</div>
								)}
								<div className='flex justify-between items-center'>
									{item?.faculty_sign ?
										<div className='mt-2'>
											<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
												{item?.faculty_sign || ""}
											</div>
											<div>Signed by <strong>{item.faculty}</strong> on <strong>{dayjs(item.faculty_sign_dt).format('MMM D, YYYY')}</strong></div>
										</div> : null}
									{item?.student_sign ?
										<div className='mt-2'>
											<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
												{item?.student_sign || ""}
											</div>
											<div>Signed by <strong>{item.student}</strong> on <strong>{dayjs(item.student_sign_dt).format('MMM D, YYYY')}</strong></div>
										</div> : null}
								</div>
							</div>
						</List.Item>
					)}
				/>
				{loading && (
					<Skeleton active />
				)}
				{!loading && visibleRecords < historyData.length && (
					<div className="text-center mt-4">
						<Button onClick={onLoadMore} className='btn-info'>Load More</Button>
					</div>
				)}
			</div>
			<OtherHistory displayModal={showOtherHistoryModal} closeModal={closeModal} studentId={student_id}/>
		</div>
	)
}
export default History;

