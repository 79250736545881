import { Button, Card, Checkbox, Col, Flex, Form, Input, Modal, Row, Spin, message } from "antd";
import React, { useRef, useState } from "react";
import constants from "../../Constants/constants";
import { Editor } from "@tinymce/tinymce-react";
import { fetchStaffApi } from "../../Services/Api";
import { checkEmptySpace, handleValidateTextEditor, removeExtraSpaces } from "../../GlobalFunctions/GlobalFunctions";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { useForm } from "antd/es/form/Form";
const { TextArea } = Input;

const AdvisementDoc = ({ isModalOpen, handleCancel, selectedRecord, studentList }) => {
  const [isConfidential, setIsConfidential] = useState(false);
  const [loading, setloading] = useState(false);
  const editorRef = useRef(null);
  const confidentialEditorRef = useRef(null);
  const facultyId = getCookie(COOKIE.UserId);
  const [form] = useForm()
  const handleCancelBtn = () => {
    handleCancel();
    form.resetFields()
    setIsConfidential(false);
  };

  const handleCheckboxChange = (e) => {
    setIsConfidential(e.target.checked);
    if(!e.target.checked) form.setFieldsValue({ 
			confidentiality_note: '',
			editor_text_confi: '',
		})
  };

  const handleSaveRecord = (value) => {
    setloading(true);
    const noteContent = editorRef.current ? editorRef.current.getContent() : '';
    const confidentialityNoteContent =
      isConfidential && confidentialEditorRef.current
        ? confidentialEditorRef.current.getContent()
        : '';

    if (value?.sign) {
      const payload = {
        student_id: selectedRecord.student_id,
        faculty_id: facultyId,
        note: removeExtraSpaces(noteContent || ''),
        faculty_sign: removeExtraSpaces(value?.sign),
        is_editable: 1,
        advisor_id: selectedRecord.faculty_id,
      };
      if(noteContent) {
        payload.note = removeExtraSpaces(noteContent || '')
      }
      if (confidentialityNoteContent) {
        payload.confidentiality_note = removeExtraSpaces(confidentialityNoteContent);
      }

      fetchStaffApi("/add-student-note", "POST", payload)
        .then((res) => {
          if (res.code === "200") {
            form.resetFields()
            setIsConfidential(false);
            message.success(constants.SaveMessage);
            handleCancelBtn();
            setloading(false);
            studentList();
          } else {
            message.error(constants.ErrorMessage);
            setIsConfidential(false);
            form.resetFields()
            handleCancelBtn();
            setloading(false);
          }
        })
        .catch(() => {
          message.error(constants.ErrorMessage);
          setloading(false);
        });
    } else {
      setloading(false);
    }
  };

  const handleEditorChange = (content, editField, hiddenField) => {
		form.setFieldsValue({ 
			[editField]: content || '',
			[hiddenField]: content || '',
		})
	};

  return (
    <Spin tip={constants.PleaseWait} spinning={loading}>
      <Modal
        title={constants.AdvisementForm}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancelBtn}
        width={900}
        className="!top-5"
        destroyOnClose={true}
      >
        <div className="form-padding">
          <Form 
            layout="vertical" 
            form={form} 
            onFinish={handleSaveRecord}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={constants.Advisor}>
                  <Input readOnly value={selectedRecord?.faculty} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label={constants.Student}>
                  <Input readOnly value={selectedRecord?.student} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="note"
                  label={constants.Note}
                  rules={[
                    {
                      required: isConfidential === false,
                      message: ' ',
                    },
                    {
                      validator: (rule, value, validator) => {
                        // Only trigger validation if the condition is met
                        if (isConfidential === false) {
                          return handleValidateTextEditor(rule, value, validator, 'editor_text_note', form);
                        }
                        // If the condition is not met, return a resolved Promise to bypass validation
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Editor
                    textareaName="body"
                    tinymceScriptSrc={constants.TextEditorSrcPath}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    onEditorChange={(content)=>{
                      form.setFieldsValue({
                        note: content || '',
                        editor_text_note: content || '',
                      })
                    }}
                    init={{
                      menubar: false,
                      branding: false,
                      plugins: constants.TinyMcePlugins,
                      toolbar: constants.TinyMceToolbar,
                      content_style: constants.TinyMceContentStyle,
                      statusbar:false,
                      height: 200,
                      toolbar_mode: 'sliding'
                    }}
                    onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text_note'))}
                  />
                </Form.Item>
                {isConfidential === false &&
                <Form.Item 
                  name='editor_text_note'
                  hidden>
                </Form.Item>}
              </Col>
              <Col span={24}>
                <Card
                  title={constants.Reminders}
                  className="advisement-card"
                  size="small"
                >
                  {constants.AdvisementNotes}
                </Card>
              </Col>
              <Col span={24}>
                <div className="flex-container">
                  <Checkbox
                    checked={isConfidential}
                    onChange={handleCheckboxChange}
                  />
                  <p class="font-bold pt-3 pl-2">
                    {constants.ConfidentialNotes}
                  </p>
                </div>
              </Col>
              {isConfidential && (
                <Col span={24}>
                  <Form.Item
                    name="confidentiality_note"
                    rules={[
                      {
                        required: isConfidential,
                        message: ' ',
                      },
                      { validator: (rule, value, validator) => handleValidateTextEditor(rule, value, validator, 'editor_text_confi', form) },
                    ]}
                  >
                    <Editor
                      textareaName="body"
                      tinymceScriptSrc={constants.TextEditorSrcPath}
                      onInit={(evt, editor) => {
                        confidentialEditorRef.current = editor;
                      }}
                      init={{
                        readonly: true,
                        statusbar: false,
                        menubar: false,
                        height: 200,
                        branding: false,
                        plugins: constants.TinyMcePlugins,
                        toolbar: constants.TinyMceToolbar,
                        content_style: constants.TinyMceContentStyle,
                        toolbar_mode: 'sliding'
                      }}
                      onEditorChange={(e) => handleEditorChange(e, 'confidentiality_note', 'editor_text_confi')}
                      onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text_confi'))}
                    />
                  </Form.Item>
                  <Form.Item 
                    name='editor_text_confi'
                    hidden>
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item
                  name="sign"
                  label={constants.AdvisorESign}
                  rules={[
                    {
                      required: true,
                      message: constants.FieldRequired,
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea 
                    maxLength={25} 
                    autoSize={{ maxRows: 1 }} 
                    placeholder={constants.AdvisorESign}
                    onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('sign'))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Flex
              gap="small"
              align="flex-end"
              className="action-button-container"
            >
              <Flex gap="small" wrap="wrap">
                <Button
                  type="primary"
                  className="action-btn"
                  danger
                  onClick={handleCancelBtn}
                >
                  {constants.Cancel}
                </Button>
                <Button loading={loading} type="primary" className="action-btn" htmlType="submit" disabled={loading}>
                  {constants.Save}
                </Button>
              </Flex>
            </Flex>
          </Form>
        </div>
      </Modal>
    </Spin>
  );
};

export default AdvisementDoc;
