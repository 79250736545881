import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { COOKIE, getCookie } from '../Services/Cookie';
// Utility function to get the user role from your authentication context or state
const getUserRole = () => {
	let user = {
		userId:getCookie(COOKIE.UserId),
		token:getCookie(COOKIE.Token),
		role:getCookie(COOKIE.Role),
	}
	return user;
};
// Component to protect admin routes
export const AdminRoute = ({ children }) => {
	const userRole = getUserRole();
	const location = useLocation();
	if(userRole?.userId&&userRole?.token){
		if (userRole?.role === '0'){
			// Redirect to home page or any other page if the user is not an admin
			return <Navigate to="/student-dashboard" state={{ from: location }} replace />;
		}
	}else{
		return <Navigate to="/staff-login" state={{ from: location }} replace />;
	}
	return children;
};
// Component to protect routes for students (e.g., prevent access to admin routes)
export const StudentRoute = ({ children }) => {
	const userRole = getUserRole();
	const location = useLocation();
	if(userRole?.userId&&userRole?.token){
		if (userRole?.role !== '0'){
			// Redirect to a staff dashboard page or show an error message if a student is trying to access admin routes
			return <Navigate to="/dashboard" state={{ from: location }} replace />;
		}
	}else{
		return <Navigate to="/student-login" state={{ from: location }} replace />;
	}
	return children;
};
// Component to protect routes for types of admin users based on role
export const RoleProtectedRoute = ({allowedRoles, children }) => {
	const userRole = getUserRole();
	if (allowedRoles.includes(userRole?.role)){
		return children ? children : <Outlet/>;
	} else {
		return <Navigate to="/page-not-found" state={{ fromLocation:"fromRoutes" }} />;
	}
};
