import React, { useContext, useRef } from 'react';
import { Form, Input, Tooltip } from 'antd';
import constants from '../../../Constants/constants';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleErrorStatus,
  colSpan,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const toggleEdit = () => {
    form.setFieldsValue({ [record?.id ? record?.id:record?.uniqueid]: record[record?.course_text] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
      // onChangeCoreCurriculumRow(record?.course_text,record,"course_text",0)
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    let placeholder;
    if (parseInt(record?.asha_required_courses)>=1) {
      placeholder = constants.CourseName + "(ASHA)"
    } else {
      placeholder = constants.CourseName
    }
    childNode = !record?.course_name ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          onChange={toggleEdit}
          status={handleErrorStatus(record, record[dataIndex])}
          placeholder={placeholder}
          disabled={record?.is_add?true:false}
          suffix={record?.course_text && parseInt(record?.asha_required_courses)>=1?"(ASHA)":""}
        />
      </Form.Item>
     ) 
    : (
        <Tooltip title={record?.course_text}><span>{children}</span></Tooltip>
    );
  }

  return <td {...restProps} colSpan={colSpan}>{childNode}</td>;
};
