import React, { useEffect, useState } from 'react'
import logo from '../../Assets/header-logo4.png'
import Glyphicon from '@strongdm/glyphicon';
import Form from 'react-bootstrap/Form';
import { fetchApi } from '../../Services/Api';
import { Base64 } from 'js-base64';
import constants from '../../Constants/constants';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import {message} from 'antd'
import {EyeOutlined,EyeInvisibleOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';

const ResetPassword = () => {
	const [userName, setUserName] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [resetData, setresetData] = useState({})
	const [token, setToken] = useState('')
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate()
	const [validated, setValidated] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [passwordMatch, setPasswordMatch] = useState('')
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
	const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

	useEffect(() => {
		const url = window.location.href
		const queryString = url.split("?")[1];
		const decodedresetData = Base64.decode(queryString)
		const finalresetData = JSON.parse(decodedresetData)
		const expiryTime = dayjs.unix(finalresetData.expiry).format('MMMM D, YYYY h:mm A')
		const currentTime =dayjs().format('MMMM D, YYYY h:mm A')
		if(currentTime>expiryTime){
			navigate('/link-expired')
		}
		setresetData(finalresetData)
		setToken(queryString)
	}, [])

	useEffect(() => {
		setUserName(resetData?.username)
	}, [resetData])

	const handlePasswordReset = (e) => {
		if (password.trim() === '' && confirmPassword.trim === '') {
			setErrorMsg(constants.RequiredField)
			setPasswordMatch(constants.RequiredField)
		}
		e.preventDefault()
		if (userName && password && confirmPassword && validated) {

			setLoading(true)
			let payload = null;
			payload = {
				password: password,
				confirm_password: confirmPassword,
				type: resetData?.type,
				token: token
			};
			fetchApi(`/reset-password`, 'POST', payload)
				.then((res) => {
					if (res?.code === "200") {
						message.success(constants.ResetSuccess)
						setLoading(false)
						const routeText =resetData?.type==='staff'?'/staff-login':'/student-login'
						setTimeout(() => {
							navigate(routeText);
						}, 3000)
					} else if (res?.code === 400) {
						setLoading(false)
						message.error(constants.PasswordCheck)
					}else{
						setLoading(false)
						message.error(constants.ErrorMessage)
					}
				}).catch((error) => {
				})
		} else if (password.trim() === '') {
			setErrorMsg(constants.RequiredField)
		} else if (confirmPassword.trim() === '') {
			setPasswordMatch(constants.RequiredField)
		}
	}

	const onChangeUserDetails = (e, fieldName) => {
		if (fieldName === "username") {
			setUserName(e.target.value);
		} else if (fieldName === "pass") {
			const incomingValue = e.target.value
			setPassword(incomingValue);
			const validationMessage = validatePassword(incomingValue);
			setErrorMsg(validationMessage);
		} else {
			const incomingValue = e.target.value
			setConfirmPassword(incomingValue);
			const validationMessage = validateConfirmPassword(incomingValue);
			setPasswordMatch(validationMessage)
			if (validationMessage) {
				setValidated(false)
			} else {
				setValidated(true)
			}
		}
	};

	const validateConfirmPassword = (confirmPassword) => {
		if (confirmPassword.trim() === '') {
			setValidated(false)
			return constants.RequiredField;
		} else if (password !== confirmPassword) {
			setValidated(false)
			return constants.validatePasswordMsg;
		} else {
			setValidated(true)
			return null;
		}
	};

	const validatePassword = (password) => {
		if (password.trim() === '') {
			return constants.RequiredField;
		}
		if (password.length < 8 || password.length > 20) {
			return constants.validatePasswordLength;
		}
		if (!/[A-Z]/.test(password)) {
			return constants.validatePasswordUpperLetter;
		}
		if (!/[a-z]/.test(password)) {
			return constants.validatePasswordLowerLetter;
		}
		if (!/\d/.test(password)) {
			return constants.validatePasswordDigit;
		}
		if (!/[@#$%&]/.test(password)) {
			return constants.validateSpecialCharacters;
		}
		if (/\s/.test(password)) {
			return constants.validateSpace;
		}
		return null;
	}

	return (
		<div className="mx-auto min-vh-100 d-flex justify-content-center align-items-center p-3 p-md-0">
			{
				loading && <Spinner animation="border" role="status" className="d-flex justify-content-center align-items-center position-absolute z-3"></Spinner>
			}
			<div className="card shadow-lg login-card mb-5 z-2 rounded-0">
				<img src={logo} className="card-img-top rounded-0" alt="Logo" />
				<Form noValidate >
					<div className="card-body pt-0">
						<div className="px-0 px-md-4 pt-3">

							<Form.Group md="4" controlId="validationCustomUsername" >
								<div className="input-group mb-3">
									<span className="input-group-text" id="basic-addon1">
										<Glyphicon glyph='user' />
									</span>
									<Form.Control
										type="email"
										placeholder="Username"
										name='username'
										aria-describedby="inputGroupPrepend"
										className="rounded-end shadow-none no-exclamation block"
										value={userName}
										disabled
									/>
									<Form.Control.Feedback type="invalid">
										This field is required.
									</Form.Control.Feedback>
								</div>
							</Form.Group>
							<Form.Group md="4" controlId="validationCustomPassword">
								<div className="input-group mb-3 ">
									<span className="input-group-text" id="basic-addon1">
										<Glyphicon glyph='lock' />
									</span>
									<Form.Control
  									type={showPassword ? "text" : "password"}
										placeholder="New Password"
										aria-describedby="inputGroupPrepend"
										required
										className="shadow-none no-exclamation block rounded-end"
										name='pass'
										onChange={(e) => onChangeUserDetails(e, "pass")}
									/>
								<span className="input-group-text eye-icon-span " onClick={toggleShowPassword}>
									{showPassword ?<EyeOutlined className='eye-icon'/>:<EyeInvisibleOutlined className='eye-icon'/>}
								</span>
									<Form.Control.Feedback type="invalid">
										This field is required.
									</Form.Control.Feedback>
								</div>
								{errorMsg && <p className='reset-password-field-errors'>{errorMsg}</p>}
							</Form.Group>
							<Form.Group md="4" controlId="validationCustomPassword">
								<div className="input-group mb-3 rounded-end">
									<span className="input-group-text" id="basic-addon1">
										<Glyphicon glyph='lock' />
									</span>
									<Form.Control
  									type={showConfirmPassword ? "text" : "password"}
										placeholder="Confirm Password"
										aria-describedby="inputGroupPrepend"
										required
										className="shadow-none no-exclamation block rounded-end"
										name='confirm_pass'
										onChange={(e) => onChangeUserDetails(e, "confirm_pass")}
									/>
								<span className="input-group-text eye-icon-span rounded-end" onClick={toggleShowConfirmPassword}>
									{showConfirmPassword ?<EyeOutlined className='eye-icon'/>:<EyeInvisibleOutlined className='eye-icon'/>}
								</span>
									<Form.Control.Feedback type="invalid">
										This field is required.
									</Form.Control.Feedback>
								</div>
								{passwordMatch && <p className='reset-password-field-errors'>{passwordMatch}</p>}
							</Form.Group>
						</div>
						<div className="d-grid gap-2 px-0 px-md-3 mt-2">
							<button className="btn btn-primary text-capitalize" onClick={handlePasswordReset} >{constants.Reset}</button>
						</div>
						<br></br>

					</div>
				</Form>
			</div>
		</div>
	)
}

export default ResetPassword