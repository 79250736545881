import React from 'react'
import SemesterCourses from '../Students/SemesterCourses';
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Card } from 'antd';

const StudentSemesterCourses = () => {
  const pageTitle = 'Semester Courses';
	const breadcrumbItems = [
		{ label: 'Semester Courses', href: '/', active: true },
	]
  return (
    <div>
      <BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card
				className="container-border-custom"
			>
        <SemesterCourses />
      </Card>
    </div>
  )
}

export default StudentSemesterCourses