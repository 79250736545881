import React, { useState } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { Card, Form, Row, Col, Input, Space, Button, Tooltip, Spin, message } from 'antd'
import { fetchApi } from '../../Services/Api'
import constants from '../../Constants/constants'
import { getCookie, COOKIE } from '../../Services/Cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const ChangePassword = () => {
  const pageTitle = constants.ChangePassword
  const [form] = Form.useForm()
  const breadcrumbItems = [{ label: constants.ChangePassword, active: true }]
  const userType = getCookie(COOKIE.Role);
  const [loading, setLoading] = useState(false)
  const Icon = <FontAwesomeIcon icon={faCircleInfo} className='text-primary-pale h5 mt-2' />

  const changePassword = (values) => {
    let userId = getCookie(COOKIE.UserId)
    setLoading(true)
    values = {
      ...values,
      adminid: userId,
    }
    if (userType !== '0') {
      values = {
        ...values,
        is_staff: "1",
      }
    }
    fetchApi(`/change-password`, 'PUT', values)
    .then((res) => {
      if (res?.code === "200" && res?.message !== 404) {
        form.resetFields()
        message.success(constants.ResetSuccess)
      } else {
        message.error(constants.InvalidCurrentPassword)
      }
      setLoading(false)
    }).catch((err) => {
      console.log(err);
      
    })
  }

  const validateConfirmPassword = (_, value) => {
    const newPassword = form.getFieldValue('pass');
    if(!value || value?.trim() === '') {
      return Promise.reject(new Error(constants.RequiredField));
    }
    else if (newPassword !== value) {
      return Promise.reject(new Error('The two passwords that you entered do not match!'));
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = (password,type) => { 
    if (!password || password.trim() === '') {
      return constants.RequiredField;
    }
    if(type === 'old') return
    if (password.length < 8 || password.length > 20) {
      return constants.validatePasswordLength;
    }
    if (!/[A-Z]/.test(password)) {
      return constants.validatePasswordUpperLetter;
    }
    if (!/[a-z]/.test(password)) {
      return constants.validatePasswordLowerLetter;
    }
    if (!/\d/.test(password)) {
      return constants.validatePasswordDigit;
    }
    if (!/[@#$%&]/.test(password)) {
      return constants.validateSpecialCharacters;
    }
    if (/\s/.test(password)) {
      return constants.validateSpace;
    }
    if(type === 'new') {
      const oldPassword = form.getFieldValue('oldpass');
      if (oldPassword === password) {
        return 'The new password cannot be same as old password';
      }
    }
    return null;
  }

  return (
    <div>
      <BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="container-border-custom px-3" >
      <Spin tip={constants.PleaseWait} spinning={loading}>
        <Form layout='vertical' form={form} onFinish={changePassword}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={<Space size={4} direction='horizontal'><span><strong>{constants.CurrentPassword}</strong></span>
                  <Tooltip placement="top" title={constants.CurrentPassMsg} >
                    {Icon}
                </Tooltip>
                </Space>}
                name="oldpass"
                rules={[
                  {
                    validator: (_, value) => {
                      const errorMessage = validatePassword(value,'old');
                      if (errorMessage) {
                        return Promise.reject(errorMessage);
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input.Password type='password' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<Space><span><strong>{constants.NewPassword}</strong></span>
                  <Tooltip placement="top" title={constants.PasswordInfo} >
                  {Icon}</Tooltip>
                </Space>}
                name="pass"
                dependencies={['oldpass']}
                rules={[
                  {
                    validator: (_, value) => {
                      const errorMessage = validatePassword(value,'new');
                      if (errorMessage) {
                        return Promise.reject(errorMessage);
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }
                ]}
              >
                <Input.Password type='password' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<Space><span><strong>{constants.ConfirmPassword}</strong></span>
                  <Tooltip placement="top" title={constants.ConfirmPassMsg} >
                  {Icon}
                  </Tooltip>
                </Space>}
                name="confirm_pass"
                rules={[
                  {
                    validator: validateConfirmPassword
                  }
                ]}
                dependencies={['pass']}
              >
                <Input.Password type='password' />
              </Form.Item>
            </Col>
            <Col xs={8} md={6} lg={4}>
              <Button type="primary" className='action-btn' htmlType='submit' block>
                {constants.Submit}
              </Button>
            </Col>
          </Row>
        </Form>
        </Spin>
      </Card>
    </div>
  )
}
export default ChangePassword
