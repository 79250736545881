import React,{useState} from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import ImportCard from '../Component/StudentImportComponents/ImportCard'
import constants from '../../Constants/constants'
import {Tabs,Card} from 'antd'
const StudentInitialImport = () => {
  const pageTitle = 'Student Import'
  const breadcrumbItems = [
    { label: 'Student Import', active: true },]
  const [tabKey,setTabKey] = useState('1')
  const tabList = [
    {
      key:1,
      label:'Initial',
      children:<ImportCard CardContent={constants.InitialImportContent} ButtonText={constants.StudentInitialImport}
      SubmitData={1} tabKey={tabKey}/>
    },
    {
      key:2,
      children:<ImportCard CardContent={constants.StudentIdMuEmailContent} ButtonText={constants.StudentIDMUEmail} 
      SubmitData={2} tabKey={tabKey}/>,
      label:'ID/MU Email',
    },
    {
      key:3,
      children:<ImportCard CardContent={constants.AshaPshaImportContent} ButtonText={constants.AshaPsha} 
      SubmitData={3} tabKey={tabKey}/>,
      label:'ASHA/PSHA'
    }
  ]
  
  return (
    <div>
      <BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="container-border-custom px-3 fs-6">
      {/* <ImportCard CardContent={constants.InitialImportContent} ButtonText={constants.StudentInitialImport}
        SubmitData={1} /> */}
         <Tabs items={tabList}
           type="card"
    defaultActiveKey="1" className='newTab' onChange={(key)=>setTabKey(key)}/>
    </Card>
    </div>
  )
}
export default StudentInitialImport
