import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Flex, Form, Modal, Row, Select, TimePicker, message } from "antd";
import constants from "../../Constants/constants";
import { fetchStaffApi } from "../../Services/Api";
import dayjs from "dayjs";
import { Option } from "antd/es/mentions";
import { COOKIE, getCookie } from "../../Services/Cookie";

const AdvisorTimings = ({
  visible,
  onCancel,
  onList,
  formType,
  selectedRowKeys,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const facultyId = getCookie(COOKIE.UserId);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    onCancel();
    if (formType === 1) {
      form.resetFields(); 
    }
  };

  useEffect(() => {
    if (formType === 1) {
      form.resetFields(); 
    } else if (selectedRow && selectedRow.timings) {
      const [time_from, time_to] = selectedRow.timings.split(" - ");
      form.setFieldsValue({
        date: dayjs(selectedRow.advising_date),
        spots: selectedRow.spots,
        from_time: dayjs(time_from, constants.TimeFormat),
        to_time: dayjs(time_to, constants.TimeFormat),
      });
    }
  }, [formType, selectedRow, form]);

  const handleSaveRecord = (values) => {
    setLoading(true);
    const formattedValues = {
      date: dayjs(values.date).format(constants.DateFormat),
      time_from: `${dayjs(values.from_time).format(constants.TimeFormat)}`,
      time_to: `${dayjs(values.to_time).format(constants.TimeFormat)}`,
      spots: values.spots,
      faculty_id: facultyId,
    };

    let payload = {
      form_type: formType,
      ...formattedValues,
    };

    if (formType === 0) {
      payload.id = selectedRowKeys;
    }

    fetchStaffApi("/manage-my-advisor-timings", "POST", payload)
      .then((res) => {
        if (res.code === "200") {
          formType === 1 ? message.success(constants.SaveMessage) : message.success(constants.UpdateMessage);
          form.resetFields();
          onCancel();
          onList();
        } else {
          message.error(constants.TimeOverlapError, [5])
        }
      })
      .catch(() => {
        message.error(constants.ErrorMessage)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Disable dates before today
  const disabledDate = (current) => {
    return current && dayjs(current).isBefore(dayjs().startOf("day"));
  };

  const validateTimeTo = (_, value, callback) => {
    const fromTime = form.getFieldValue("from_time");
    const toTime = dayjs(value).format("HH:mm");
    const fromTimeFormatted = dayjs(fromTime).format("HH:mm");
    if (!fromTime) {
      callback();
      return;
    }
    if (dayjs(toTime, "HH:mm").isBefore(dayjs(fromTimeFormatted, "HH:mm"))) {
      callback(constants.IncorrectTimeError);
    } else if (dayjs(toTime, "HH:mm").isSame(dayjs(fromTimeFormatted, "HH:mm"))) {
      callback(constants.EqualTimeError);
    } else {
      callback();
    }
  };

  const validateTimeFrom = (_, value, callback) => {
    const currentTime = dayjs().format("HH:mm");
    const selectedDate = form.getFieldValue("date");
    const selectedTime = dayjs(value).format("HH:mm");
    if (!selectedDate) {
      callback();
      return;
    }
    if (selectedDate.isSame(dayjs(), 'day') && selectedTime < currentTime) {
      callback(constants.MoreTimeError);
      return;
    }
    callback()
  };

  return (
    <Modal
        title={formType === 1 ? constants.AddAdvisingTimes : constants.EditAdvisorTimings}
        width={600}
        footer={null}
        open={visible}
        onCancel={handleCancel}
        className="records-popup"
      >
        <Form layout="vertical" onFinish={handleSaveRecord} form={form}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="date"
                label= {constants.Date}
                rules={[{ required: true, message: constants.FieldRequired }]}
              >
                <DatePicker format={constants.DateFormat} className="w-100" disabledDate={disabledDate} disabled={formType === 1 ? false : selectedRow && selectedRow.student_names}/>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="spots"
                label={constants.Spots}
                rules={[{ required: true, message: constants.FieldRequired }]}
              >
                <Select
                  showSearch
                  placeholder={constants.Select}
                  maxTagCount="responsive"
                  optionFilterProp="children"
                >
                  {[2, 3, 4, 5, 6, 7, 8].map((number) => (
                    <Option key={number} value={number} disabled={formType === 1 ? false : selectedRow && selectedRow.student_names && number <= selectedRow.spots}>
                      {number}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="from_time"
                label={constants.From}
                rules={[
                  { required: true, message: constants.FieldRequired },
                  { validator: validateTimeFrom },
                ]}
              >
                <TimePicker changeOnScroll needConfirm={false} showNow={false} use12Hours format={constants.TwelveHourTimeFormat} className="w-100" disabled={formType === 1 ? false : selectedRow && selectedRow.student_names}/>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="to_time"
                label={constants.To}
                rules={[
                  { required: true, message: constants.FieldRequired },
                  { validator: validateTimeTo },
                ]}
              >
                <TimePicker changeOnScroll needConfirm = {false} showNow={false} use12Hours format={constants.TwelveHourTimeFormat} className="w-100" disabled={formType === 1 ? false : selectedRow && selectedRow.student_names}/>
              </Form.Item>
            </Col>
          </Row>
          <Flex
            gap="small"
            align="flex-end"
            className="action-button-container"
          >
            <Flex gap="small" wrap="wrap">
              <Button
                type="primary"
                className="action-btn"
                danger
                onClick={handleCancel}
              >
                {constants.Cancel}
              </Button>
              <Button type="primary" className="action-btn" htmlType="submit" disabled={loading} loading={loading}>
                {constants.Save}
              </Button>
            </Flex>
          </Flex>
        </Form>
    </Modal>
  );
};

export default AdvisorTimings;
