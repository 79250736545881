import React, { useState, useEffect } from 'react'
import constants from '../../../Constants/constants';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import { fetchStaffApi } from '../../../Services/Api';
import { CustomSortIcon,  performSearch, HighlightedText, getNextAndPreviousButtons, showTotalItems,handleKeyPressHandler, removeExtraSpaces, handleRowSelect, checkEmptySpace } from '../../../GlobalFunctions/GlobalFunctions';
import { Modal, Card, Input, Table, Button, Checkbox, Space, Flex, Col, Row, Form, InputNumber, message, Spin } from 'antd'

function Assessments() {

	const breadcrumbItems = [{ label: 'Assessments', active: true },]

	const [dataSource, setDataSource] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [form] = Form.useForm();
	const [modalMode, setModalMode] = useState("add");
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showDeleted, setShowDeleted] = useState(false);
	const [isDeletedChecked, setIsDeletedChecked] = useState(false);
	const [record, setRecord] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [filterTableData, setFilterTableData] = useState([]);
	const [rowData,setRowData]=useState([]);
	const [isDisable, setIsDisable] = useState(false);

	const rowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
			onSelect: (recordData, selected, selectedRows) =>{ 
			onSelectChange(
				// selectedRows?.map((item) => item.id) || [], // Ensure it is an array
				// selectedRows || [], // Ensure it is an array
				null,
				null,
				recordData, // Current selected record
				dataSource?.findIndex((item) => item.id === recordData.id) // Index of the currently selected record from table data
		);},
		selectedRowKeys: selectedRowKeys
	};

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], recordData = null, index = null) => {
	
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			recordData, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedRowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'single' //SELECTION TYPE
		)
		setRecord(recordData)
  };

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search)
		const searchFieldNames = [
			"assessment",
			"scheduled_for",
			"passing_score"
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setDataSource(searchedValue);
	};


	useEffect(() => {
		getAllAreaList();
	}, []);

	//fetching all area data list
	const getAllAreaList = (checked = false) => {
		setIsDisable(true)
		let payload = {
			type: 3,
			value: checked ? '1' : '0',
		}
		fetchStaffApi('/get-all-courses', 'POST', payload).then((res) => {
			if (res?.code !== "200") {
				setDataSource([])
				setFilterTableData([])
				setIsDisable(false)
				return
			}
			const modifiedData = res?.data?.length > 0 ? res.data : []
			setDataSource(modifiedData);
			setFilterTableData(modifiedData)
			setIsDisable(false)
		}).catch((err) => {
			setIsDisable(false)
		});
	};
	const columns = [
		{
			title: 'Assessments',
			dataIndex: 'assessment',
			width:"50%",
			sorter: (a, b) => a.assessment?.localeCompare(b.assessment),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (assessment) => <div onDoubleClick={handleEdit}><HighlightedText text={assessment} highlight={searchValue} /></div>
		},
		{
			title: 'Passing Score',
			dataIndex: 'passing_score',
			width:"20%",
			sorter: (a, b) => a.passing_score - b.passing_score,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (total_no_courses) => (
				<Flex className="table-number-align numbers-alignment">
				  <HighlightedText text={total_no_courses} highlight={searchValue} />
				</Flex>
			),
		},
	]
	const handleDeleteCheckboxChange = (e) => {
		setIsDeletedChecked(e.target.checked)
	}
	const handleAdd = () => {
		setModalMode("add");
		setShowModal(true);
	}
	//edit
	const handleEdit = () => {
		const isDeleted = record && record.is_hidden === "1";
		setShowDeleted(isDeleted);
		setIsDeletedChecked(isDeleted)
		setModalMode("edit");
		setShowModal(true);
		
		form.setFieldsValue({
			assessment: record?.assessment,
			passing_score: record?.passing_score,
		});

	}
	const handleCloseModal = () => {
		setShowModal(false);
		form.resetFields();
	}
	const handleSaveRecord = (values) => {
		setIsDisable(true);
		//on submit of add
		if (modalMode === "add") {
			const payload = {
				adminid: 1,
				type: 3,
				assessment: removeExtraSpaces(values.assessment),
				passing_score: values.passing_score
			};
			fetchStaffApi('/add-course', 'POST', payload).then((res) => {
				if (res?.code === "200") {
					message.success(constants.AssessmentAdd);
					setIsDisable(false);
					handleCloseModal()
					setSelectedRowKeys([]);
					setRecord([]);
					getAllAreaList();
				}else{
					setIsDisable(false);
					message.error(res?.message)
				}
			}).catch((err) => {
				setIsDisable(false)
			});
		}
		//on submit of edit
		else if (modalMode === "edit") {
			const payload = {
				adminid: "1",
				type: "3",
				assessment_id: record.id,
				assessment: removeExtraSpaces(values.assessment),
				passing_score: values.passing_score,
				is_hidden: isDeletedChecked ? "1" : "0",
			};

			fetchStaffApi('/edit-course', 'PUT', payload).then((res) => {
				if (res?.code === "200") {
					message.success(constants.AssessmentUpdated);
					setIsDisable(false);
					handleCloseModal()
					setSelectedRowKeys([]);
					setRecord([]);
					getAllAreaList(showDeleted === true);
				} else {
					message.error(res?.message);
					setIsDisable(false);
				}
			}).catch((err) => {
				setIsDisable(false)
			});
		}
	}
	const handleDelete = () => {
		setShowDeleteModal(true)
	}
	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false)
	}
	const handleConfirmDelete = () => {
		setIsDisable(true);
		const payload = {
			adminid: 1,
			type: 3,
			assessment_id: record.id
		}
		// Call the API to discontinue the area
		fetchStaffApi('/delete-course', 'PUT', payload).then((res) => {
			if (res?.code === '200') {
				message.success(constants.DeleteSucessMessage);
				setIsDisable(false);
				setShowDeleteModal(false);
				setSelectedRowKeys([]);
				setRecord([]);
				getAllAreaList(showDeleted === true);
			}
			else {
				setIsDisable(false);
				message.error(constants.AddAPIError)
			}
		}).catch((err) => {
			setIsDisable(false)
		});
	}

	return (
		<Spin spinning={isDisable}>
			<div>
				<BreadcrumbHeader pageTitle={constants.Assessments} breadcrumbItems={breadcrumbItems} />
				<Card className="container-border-custom">
					<Row gutter={[10, 5]}>
						<Col lg={12} xs={24}>
							<Space wrap>
								<Button className=" text-capitalize btn-info"
									hidden={showDeleted}
									disabled={selectedRowKeys.length > 0 || showDeleted}
									onClick={handleAdd}>{constants.Add}
								</Button>
								<Button className="text-capitalize btn-info"
									disabled={selectedRowKeys.length === 0}
									onClick={() => {
										if (selectedRowKeys.length > 0) {
											handleEdit();
										}
									}}>{constants.Edit}
								</Button>
								<Button className="text-capitalize btn-info"
									hidden={showDeleted}
									disabled={selectedRowKeys.length > 0 ? false : true || showDeleted}								
									onClick={handleDelete} >{constants.deleteBtn}
								</Button>
							</Space>
						</Col>

						<Col lg={12} xs={24} className='d-flex justify-content-end'>
								<Row gutter={[5, 5]}>
									<Col className='checkbox-align' >
									<Checkbox 
										checked={showDeleted}
										onChange={(e) => {
										setShowDeleted(e.target.checked);
										setSelectedRowKeys([]);
										setRecord([]);
										getAllAreaList(e.target.checked === true)
										}}
									>{constants.showDeletedAssessments}
									</Checkbox>
									</Col>
									<Col>
										<Input className="w-48" placeholder="Search" onChange={onSearch} />
									</Col>
								</Row>
							</Col>
					</Row>


					<div className="mt-2">
						<Table rowSelection={{...rowSelection, hideSelectAll:true }}
							rowKey={(record) => record.id}
							columns={columns}
							dataSource={dataSource}
							bordered size="small"
							className="records-table data_table" rowSelectedBg='#e6f4ff'
							pagination={{
								className:"!mb-0",
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range, dataSource),
								itemRender: getNextAndPreviousButtons,
							}}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => onSelectChange(null, null, record, rowIndex),
								}
							}}
							scroll={{ x: 768, y: 'calc(100vh - 280px)' }}
						/>
					</div>
				</Card>

				{/*Add and Edit modal*/}
				<Modal title={modalMode === "add" ? constants.AssessmentAddNew : `${constants.AssessmentEdit}${record?.assessment}` } open={showModal} onCancel={handleCloseModal} width={600} footer={null}>
					<Form form={form} layout="vertical" onFinish={handleSaveRecord}>
						<Row gutter={[10, 0]}>
							<Col span={12}>
								<Form.Item name="assessment" label={constants.Assessments}
									rules={[{
										required: true,
										message: constants.FieldRequired,
										whitespace: true,
									}]}>
									<Input 
										placeholder={constants.Assessments} 
										disabled={rowData.length===1 && (record?.assessment || showDeleted)}
										onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('assessment'))}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="passing_score" label={constants.PassingScore}
									rules={[{ required: true, message: constants.FieldRequired }]}>
									<InputNumber placeholder={constants.PassingScore} onKeyPress={(e) => handleKeyPressHandler(e, 3)} min={1} 
									disabled={showDeleted}  className='w-100' />
								</Form.Item>
							</Col>
						</Row>
						<div className='d-flex justify-content-between pb-2.5'>
							{
								showDeleted ?
									<Form.Item name="delete">
										<Checkbox 
											checked={isDeletedChecked}
											onChange={handleDeleteCheckboxChange}
										>{constants.delete}</Checkbox>
									</Form.Item>
									: <div></div>
							}
							<Flex gap="small">
								<Button type="primary" className='action-btn' danger
									onClick={handleCloseModal}>
									{constants.Cancel}</Button>
								<Button 
									type="primary" 
									className='action-btn'
									htmlType='submit' 
									disabled={isDisable}
									loading={isDisable}
									>
										{constants.Save} 
									</Button>
							</Flex>
						</div>
					</Form>

				</Modal>
				{/*Discontinue modal*/}
				<Modal title={`${constants.AssessmentDelete}${record?.assessment}`} open={showDeleteModal} onCancel={handleCloseDeleteModal} width={600} footer={null}>
					<b>{constants.DeleteBody}</b>
					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Button type="primary" className='action-btn' danger
								onClick={handleCloseDeleteModal} >
								{constants.Cancel}</Button>
							<Button type="primary" className='action-btn'
								htmlType='submit' disabled={isDisable} onClick={handleConfirmDelete}>{constants.deleteBtn}</Button>
						</Flex>
					</Flex>
				</Modal>
			</div>
		</Spin>
	)
}

export default Assessments;