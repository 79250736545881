import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Button, Table, Typography, Flex, message, Tooltip } from 'antd'
import constants from '../../../Constants/constants'
import { fetchStaffApi, fetchStaffFormData, fetchStaffDownloadApi } from '../../../Services/Api'
import { getCookie, COOKIE } from '../../../Services/Cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs'
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { EyeFilled } from '@ant-design/icons';
import { getNextAndPreviousButtons, showTotalItems } from '../../../GlobalFunctions/GlobalFunctions'
import PropTypes from 'prop-types';
import { useForm } from 'antd/es/form/Form'
import { useMediaQuery } from 'react-responsive'
const { Title } = Typography;

const OtherHistoryArchived = ({ displayModal, closeModal, studentId, isActive }) => {
	const [form] = useForm()
	const [file, setFile] = useState(false)
	const [uploadFileList, setUploadFileList] = useState([])
	const [listData, setListData] = useState([])
	const [stateMsg, setStateMsg] = useState(constants.NoFileSelected)
	const [previewModal, setPreviewModal] = useState(false)
	const [loading,setLoading] = useState(false)
	const isDesktopTablet = useMediaQuery({
		query: '(min-width: 853px)'
	})
	const colSpan = isDesktopTablet ? 12 : 24;
	const userId = getCookie(COOKIE.UserId)
	const getListData = () => {
		setLoading(true)
		fetchStaffApi(`/other-history-notes-list/${studentId}`, 'GET')
			.then((res) => {
				if (res?.data) {
					setListData(res.data)
				} else {
					setListData([]);
				}
				setLoading(false)
			})
			.catch((error) => {
			});
	}
	const downloadData = (download) => {
		fetchStaffDownloadApi(`/download-notes-documents/${download.id}`, 'GET')
			.then((res) => {
				if (res) {
					let fileExtension = download.upload_name.split('.').pop().toLowerCase();
					// Determine the appropriate MIME type based on the file extension
					let mimeType;
					switch (fileExtension) {
						case 'png':
							mimeType = 'image/png';
							break;
						case 'jpg':
						case 'jpeg':
							mimeType = 'image/jpeg';
							break;
						case 'pdf':
							mimeType = 'application/pdf';
							break;
						case 'doc':
						case 'docx':
							mimeType = 'application/msword';
							break;
						case 'xls':
						case 'xlsx':
							mimeType = 'application/vnd.ms-excel';
							break;
						default:
							mimeType = 'application/octet-stream';
					}
					let url = window.URL.createObjectURL(new Blob([res], { type: mimeType }));
					let a = document.createElement("a");
					a.download = download?.upload_name;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
					URL.revokeObjectURL(url);
				} else {
					message.error("Failed to download");
				}
			})
			.catch((error) => {
				message.error("Failed to download");
			});
	};

	const showPreview = (incomingImage) => {
		if (colSpan === 24) {
			setPreviewModal(true)
		}
		if (incomingImage === 'File not Viewable') {
			setStateMsg(incomingImage)
			setFile(false)
		} else {
			setStateMsg(constants.NoFileSelected)
			setFile(incomingImage)
		}
	}

	const columnsHistoryTable = [{
		title: constants.FileName,
		dataIndex: 'upload_name',
    ellipsis:true,
		width: '40%',
    render:(text)=>(     
      <Tooltip title={text}>{text}</Tooltip>
    ),
	},
	{
		title: constants.Date,
		dataIndex: 'dt_created',
		render: (dt_created) => <>{dayjs(dt_created).format(constants.DateFormat)}</>,
		width: '30%'
	},
	{
		title: constants.UploadedBy,
		dataIndex: `uploaded_by_faculty`,
		width: '30%'
	},
	{
		title: constants.Actions,
		render: (record) => (
			<Flex align='center' justify='center'>
				{/* <Button type="text" icon={<EyeFilled className='text-primary-pale' />} onClick={() => showPreview(record.upload_image)} /> */}
				<Tooltip title={constants.Download}>
          <Button type="text" icon={<FontAwesomeIcon icon={faDownload} className='text-primary-pale' />} onClick={() => downloadData(record)} />
        </Tooltip>
			</Flex>
		),
		width: '10%'
	},
	]

	const handleFilesChange = (info) => {
		if (info?.fileList) {
			setUploadFileList(info.fileList);
		}
	};

const handleRemove = (file) => {
	// Remove the file from the file list
	const filteredFileList = uploadFileList.filter(item => item.uid !== file.uid);
	setUploadFileList(filteredFileList);
};
	const uploadData = () => {
		if (uploadFileList.length > 0) {
			setLoading(true)
			const formData = new FormData();
			//Appending Upload file.
			uploadFileList.forEach((file, idx) => {
				// Extracting only the file data
				const fileData = new Blob([file.originFileObj], { type: file.type });
				formData.append(`student_upload[${idx}]`, fileData, file.name);
			});
			formData.append(`student_id`, studentId);
			formData.append(`admin_id`, userId);
			fetchStaffFormData(`/save-student-notes-history`, 'POST', formData)
				.then((res) => {
					if (res?.code === "200") {
						form.resetFields()
						getListData()
						setUploadFileList([])
						setLoading(false)
						message.success(constants.HistoryAddedSuccess)
					}else{
						setLoading(false)
					}
				}).catch((error) => {
				})
		} else {
			message.error(constants.NoFileSelected)
		}
	}

	useEffect(() => {
		getListData()
	}, [])

	// const previewComponent = <Flex justify={'center'} align={'center'} className={colSpan === 24 ? 'p-2' : ''}>
	// 	{file ? <img src={file} alt='missing' width={200} /> : <Title>{stateMsg}</Title>}
	// </Flex>;
	const otherHistoryTable=()=>(
		<Col span={24}>
		<Table
			columns={columnsHistoryTable} dataSource={listData} bordered size="small" rowKey={(record) => record.key}
			className="records-table data_table" rowSelectedBg='#e6f4ff' searchableProps={{ fuzzySearch: true }}
			scroll={{ x: '50vh' }}
			pagination={{
				pageSize: 10,
				showTotal: (total, range) => showTotalItems(total, range, listData),
				itemRender: getNextAndPreviousButtons,
			}}
		/>
	</Col>
	)
	return (
			otherHistoryTable()
	)
}
OtherHistoryArchived.propTypes = {
	studentId: PropTypes.number.isRequired,
}
export default OtherHistoryArchived