import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { fetchApi } from "../../../Services/Api";
import BreadcrumbHeader from "../../Component/BreadcrumbHeader";
import { Button, Card, Col, Form, Input, Radio, Row, Select, Space, Modal, Divider, message, Spin, Result } from "antd";
import constants from "../../../Constants/constants";
import { COOKIE, getCookie } from "../../../Services/Cookie";
import { decode } from "js-base64";
import { removeExtraSpaces } from "../../../GlobalFunctions/GlobalFunctions";

const { TextArea } = Input
const { Option, OptGroup } = Select

const criteriaOptions = [
  {
    id: 1,
    title: 'Met',
  },
  {
    id: 2,
    title: 'Partially Met',
  },
  {
    id: 3,
    title: 'Did not Meet',
  },
]
const filterOption = (input, option) => {
	return option?.children?.toLowerCase().includes(input?.toLowerCase())
}
const filterSort = (optionA, optionB) => {
	return optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
}
const AnswerSurvey = () => {
  const [form] = Form.useForm()
  const [criteriaForm] = Form.useForm()
  const [surveyProps, setSurveyProps] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submitPopupLoading, setSubmitPopupLoading] = useState(false)
  const [studentCriteriaPopupLoading, setStudentCriteriaPopupLoading] = useState(false)
  const [surveyData, setSurveyData] = useState(null)
  const [studentCriteriaDetails, setStudentCriteriaDetails] = useState(null)
  const [studentCriteriaPopupOpen, setStudentCriteriaPopupOpen] = useState(false)
  const [submitPopupOpen, setSubmitPopupOpen] = useState(false)
  const [hideSubmitButton, setHideSubmitButton] = useState(false)
  const [show404Page, setShow404Page] = useState(false)
  const ID = parseInt(getCookie(COOKIE.UserId))
  const userRoleId = getCookie(COOKIE.Role);
  const isStudent = userRoleId === '0'
  const navigate = useNavigate();
  const URL = window.location.search
  const [, updateNotificationData] = useOutletContext();

  useEffect(() => {
    checkURLParams()
  }, [URL])

  const checkURLParams = () => {
    try {
      let queryString = decode(URL);
      const urlParams = new URLSearchParams(queryString);
      const surveyID = urlParams.get('survey_id')
      const surveyType = urlParams.get('survey_type')
      const role = urlParams.get('role')
      const login = urlParams.get('login') === 'true' || urlParams.get('login') === '0'
      const isRoleMatching = userRoleId === '0' ? 's' : 'f'
      
      let allParamsPresent = surveyID && surveyType && role && login ? true : false
      if((isRoleMatching === role) && allParamsPresent) {
        let survey = {
          id: surveyID,
          type: surveyType,
          role: role
        }
        fetchSurveyData(survey)
        setSurveyProps(survey)
      } else setShow404Page(true)
    } catch (error) {
      setShow404Page(true)
    }
  }

  const modifySurveyData = (data, type) => {
    switch (type) {
      case '1': //MENTOR
        return data?.map((item) => {
          return {
            ...item,
            options : item?.options.map((opt) => {
              if(opt.id === item?.answers?.[0]?.option_id)
                opt.details = item?.answers?.[0]?.details
              return {
                ...opt
              }
            })
          }
        })
      case '2': //COURSE
        return data?.map((item) => {
          return {
            ...item,
            options: constants.CoursesOptions
          }
        })
      case '3': //INSTRUCTOR
      return data?.map((item) => {
        return {
          ...item,
          student_list: item.student_criteria?.map((section) => {
            return {
              ...section,
              students: section.students?.map((stud) => {
                let title = stud.student_name;
                if (stud?.options)
                  title += ` - ${criteriaOptions.find((i) => i.id === parseInt(stud.options))?.title}`;
                if (stud?.comment)
                  title += ` - (${stud.comment})`;
                return {
                  ...stud,
                  dropdown_title: title,
                  section: section?.section,
                };
              })
            };
          })
        };
      });
      default:
        return data?.map((item) => {
          return {
            ...item,
            options : item?.options.map((opt) => {
              if(opt.id === item?.answers?.[0]?.option_id)
                opt.details = item?.answers?.[0]?.details
              return {
                ...opt
              }
            })
          }
        })
    }
  }

  const fetchSurveyData = (props = null) => {
    setLoading(true)
    let key = props?.role === 'f' ? 'faculty_id' : 'student_id'
    let payload = {
      survey_id: props?.id,
      survey_type: props?.type,
      [key]: parseInt(ID),
      semester: 0
    }
    fetchApi('/view-survey-details', 'post', payload).then((res)=> {
      setLoading(false)
      if(!res?.data && !res?.data?.survey_id) {
        setShow404Page(true)
        return
      }
      if(!res?.data?.is_active){
        setShow404Page(true)
        return
      }
      let modifiedData = modifySurveyData(res?.data?.questions, props?.type)
      res.data.questions = modifiedData
      setSurveyData(res.data.questions?.length > 0 ? res.data : null)
      setHideSubmitButton(res.data.is_submitted === 'true')
      localStorage.removeItem('surveyUrl')
    }).catch(() => setLoading(false))
  }

  const onFinish = async (_, isSubmit = false) => {
    const checkLoaderType = (flag) => {
      if(submitPopupOpen) return setSubmitPopupLoading(flag)
      if(studentCriteriaPopupOpen) return setStudentCriteriaPopupLoading(flag)
      return setLoading(flag)  
    }

    checkLoaderType(true)
    let data = {...surveyData}
    let { questions } = data
    let key = surveyProps?.role === 'f' ? 'faculty_id' : 'student_id'
    let payload = {
      survey_id: parseInt(getSurveyDetails('survey_id')),
      survey_type: parseInt(getSurveyDetails('survey_type')),
      isSubmit: isSubmit,
      [key]: ID,
      semester_details_id: getSurveyDetails('semester_id'),
      answers: null,
    }

    const generateMentorAndCustomSurveyPayload = () => {
      let answerArr = []
      questions.forEach((item) => {
        let clearDetails = false
        let checkDuplicateDetails = item?.question_type !== '2' && item?.options?.filter((it) => it?.details)
        if(checkDuplicateDetails?.length === 0) clearDetails = true
        answerArr.push({
          answer_id: item.answers?.[0]?.answer_id || null,
          question_id: item.id,
          details: clearDetails ? null : (removeExtraSpaces(item.answers?.[0]?.details) || null),
          option_id: item.answers?.[0]?.option_id || null,
        })
      })
      payload.answers = answerArr
      saveAPI()
    }

    const generateCourseSurveyPayload = () => {
      let answerArr = []
      questions.forEach((item) => {
        answerArr.push({
          answer_id: item.answers?.[0]?.answer_id || null,
          question_id: item.id,
          details: item.answers?.[0]?.details || null,
        })
      })
      payload.answers = answerArr
      payload.section_assigned = surveyData?.section_assigned
      payload.faculty_id = surveyData?.faculty?.faculty_id
      saveAPI()
    }

    const generateInstructorSurveyPayload = () => {
      criteriaForm.validateFields().then(() => {
        if(studentCriteriaDetails) {
          let { arrIdx, secIdx, stdIdx } = studentCriteriaDetails
          questions[arrIdx].student_list[secIdx].students[stdIdx].comment = removeExtraSpaces(_?.criteria_text || '')
          questions[arrIdx].student_list[secIdx].students[stdIdx].options = _?.criteria_radio
        }
        let answersObj = {}
        questions.forEach((surv) => {
          answersObj[surv.question_id] = {
            answer_id: parseInt(surv.answer_id),
            student_criteria: surv?.student_list?.reduce((acc, sec) => {
              sec.students.forEach((stud) => {
                acc.push({
                  section: sec?.section || '',
                  comment: removeExtraSpaces(stud.comment) || '',
                  options: stud?.options || '',
                  student_id: stud.student_id,
                  student_name: stud.student_name,
                });
              });
              return acc;
            }, []),
            details: removeExtraSpaces(surv?.answers?.[0]?.details) || null,
          }
        })
        payload.answers = answersObj
        saveAPI()
      })
    }

    const saveAPI = () => {
      fetchApi('/save-survey-answers', 'post', payload).then((res)=> {
        checkLoaderType(false)
        if(res?.code === '200') {
          message.success({
            type: 'Success',
            content: res?.message,
          })
          closePopup(true)
          if(isSubmit) {
            updateNotificationData()
            navigate(getReturnRoute())
          }
          return
        }
        message.error({
          type: 'Error',
          content: res?.message || constants.ErrorMessage,
        })
      }).catch(() => checkLoaderType(false))
    }

    switch (getSurveyDetails('survey_type')) {
      case '2':
        generateCourseSurveyPayload()
        break;
      case '3':
        generateInstructorSurveyPayload()
        break;
      default:
        generateMentorAndCustomSurveyPayload()
        break;
    }
  }

  const handleRadioChange = (e, arrayIndex) => {
    let data = {...surveyData}
    let { questions } = data
    //IF ANSWERS ARRAY NOT PRESENT CREATE AND STORE RADIO ELSE JUST STORE VALUE
    let fieldType = getSurveyDetails('survey_type') === '2' ? 'details' : 'option_id'
    if(questions[arrayIndex].answers?.length > 0) {
      questions[arrayIndex].answers[0][fieldType] = e

      //CLEAR THE DETAILS EVERYTIME THE RADIO OPTION CHANGES
      if(getSurveyDetails('survey_type') !== '2')
        questions[arrayIndex].answers[0].details = null
    }
    else {
      let answersObj = {
        answer_id: null,
        question_id: questions[arrayIndex].id,
        option_id: null,
        details: null,
        is_submitted: '0',
        semester_id: null,
      }
      answersObj[fieldType] = e
      questions[arrayIndex].answers = [answersObj]
    }
    setSurveyData(data)
  }

  const handleInputChange = (e, arrayIndex, optionIndex = -1) => {
    let data = {...surveyData}
    let { questions } = data
    //IF ANSWERS ARRAY NOT PRESENT CREATE AND STORE INPUT ELSE JUST STORE VALUE
    if(questions[arrayIndex].answers?.length > 0) {
      questions[arrayIndex].answers[0].details = e
    } else {
      let answersObj = {
        answer_id: null,
        question_id: questions[arrayIndex].id,
        option_id: null,
        details: null,
        is_submitted: '0',
        semester_id: null,
      }
      answersObj.details = e
      questions[arrayIndex].answers = [answersObj]
    }

    //SET VALUE FOR INPUT
    if(optionIndex > -1) {
      let optionId = questions[arrayIndex].options[optionIndex].id
      questions[arrayIndex].options[optionIndex].details = e
      questions[arrayIndex].answers[0].option_id = optionId

      //SET RADIO BUTTON AUTO SELECT ON INPUT FIELD ENTRY
      let formKey = questions[arrayIndex].id
      form.setFieldsValue({ [formKey]: optionId })
    }
    setSurveyData(data)
  }

  const handleStudentChange = (e, arrayIndex, sectionIndex, studentIndex) => {
    let data = {...surveyData}
    let { questions } = data
    let criteriaObject = {
      details: questions[arrayIndex].student_criteria[sectionIndex].students[studentIndex],
      arrIdx: arrayIndex,
      secIdx: sectionIndex,
      stdIdx: studentIndex,
    }
    setStudentCriteriaDetails(criteriaObject)
    setStudentCriteriaPopupOpen(true)
  }

  const getSurveyComponent = (surveyObj, arrayIndex) => {
    let { question_type, options, student_list } = surveyObj
    switch (question_type) {
      case '1': //OPTIONS
        return (
          <Radio.Group
            onChange={(e) => handleRadioChange(e.target.value, arrayIndex)} 
            className="!w-full"
          >
            {
              options.map((item, optionIndex) => (
                  <Row gutter={[8, 8]}>
                    <Col className="gutter-row" span={24}>
                    {
                      getSurveyDetails('survey_type') === '2' ? 
                      (
                        <Radio value={item.option_id}>{item.option_name}</Radio>
                      ) : (
                        <Radio value={item.id}>{item.option_text}</Radio>
                      )
                    }
                    </Col>
                    <Col className="gutter-row" span={24}>
                    <div className="ml-6">
                      {item.option_detail && (
                        <Space direction="vertical" className="!w-full">
                          <div className="!text-grey-300">{item.option_detail}:</div>
                          <TextArea
                            value={item.id === surveyObj?.answers?.[0]?.option_id ? surveyObj?.answers?.[0]?.details : null}
                            onChange={(e) => handleInputChange(e.target.value, arrayIndex, optionIndex)} 
                            className="mb-3 w-full"
                          />
                        </Space>
                      )}
                      </div>
                    </Col>
                  </Row>
              ))
            }
          </Radio.Group>
        )
      case '2': //TEXT
        return <TextArea onChange={(e) => handleInputChange(e.target.value, arrayIndex)} />
      case '3': //STUDENT LIST
        return (
          <Select 
            showSearch={true} 
            optionFilterProp="children"
						filterOption={(input, option) => filterOption(input, option)}
						filterSort={(optionA, optionB) => filterSort(optionA, optionB)}
            placeholder={constants.SelectStudent}
            onChange={(e, { sectionIndex, studentIndex }) => handleStudentChange(e, arrayIndex, sectionIndex, studentIndex)}
          >
            { 
              student_list.map((section, sectionIndex) => (
                <OptGroup label={`Section ${section.section}`}>
                  {
                    section.students.map((student, studentIndex) => (
                      <Option
                        sectionIndex={sectionIndex}
                        studentIndex={studentIndex} 
                        key={student.student_id}
                        value={student.student_id}
                      >
                        {student.dropdown_title}
                      </Option>
                    ))
                  }
                </OptGroup>
              ))
            }
          </Select>
        )
      default:
        break;
    }
  }

  const initialValues = (surveyObj) => {
    if(surveyObj.question_type === '1') {
      if(getSurveyDetails('survey_type') === '2') //IF COURSE SURVEY
        return surveyObj?.answers?.[0]?.details //RADIO FIELD FOR COURSE SURVEY
      return surveyObj?.answers?.[0]?.option_id //RADIO FIELD FOR OTHER SURVEYS
    }
    if(surveyObj.question_type === '2')
      return removeExtraSpaces(surveyObj?.answers?.[0]?.details) //TEXT FIELD
    return null
  }

  const checkStudentCriteriaPopup = () => {
    let studentCriteria = { ...studentCriteriaDetails }

    criteriaForm.setFieldsValue({
      criteria_text: studentCriteria?.details?.comment,
      criteria_radio: studentCriteria?.details?.options,
    })

    return (
        <Modal
          centered
          title={`Criteria - ${studentCriteria?.details?.student_name}`}
          open={studentCriteriaPopupOpen}
          onCancel={() => closePopup(false)}
          footer={null}
          maskClosable={false}
        >  
          <Spin tip={constants.PleaseWait} spinning={studentCriteriaPopupLoading} 
            className={`absolute top-0 left-0 flex justify-center items-center ${studentCriteriaPopupLoading ? 'bg-white opacity-50 w-full h-full z-50 rounded-lg' : ''}`}
          >
          </Spin>
          <Form onFinish={onFinish} form={criteriaForm} layout="vertical">
            <Form.Item name="criteria_text">
              <TextArea
                rows={4} 
                placeholder="Enter your comments here" 
              />
            </Form.Item>
            <Form.Item 
              label='Criteria' 
              name="criteria_radio" 
              rules={[{ required: true, message: 'Please select an option' }]}
            >
              <Radio.Group>
                {
                  criteriaOptions.map((item) => (
                    <Radio value={item.id} key={item.id}>{item.title}</Radio>
                  ))
                }
              </Radio.Group>
            </Form.Item>
            <Space>
              <Button onClick={() => closePopup(false)}>{constants.Close}</Button>
              <Button htmlType="submit" type="primary">{constants.SaveChanges}</Button>
              <Divider />
            </Space>
          </Form>
        </Modal>
    )
  }

  const checkFormSubmit = () => {
    form.validateFields().then(() => {
      form.submit()
    }).catch((err) => {
      if(err?.errorFields?.length > 0) {
        const firstError = err?.errorFields?.[0]?.name?.[0]
        if(!firstError) return
        form.scrollToField(firstError)
        setSubmitPopupOpen(false)
      }
    })
  }

  const submitPopup = () => {
    return (
      <Modal
        centered
        title={constants.Warning}
        open={submitPopupOpen}
        onCancel={() => setSubmitPopupOpen(false)}
        onOk={() => checkFormSubmit()}
        okText={constants.Yes}
        cancelText={constants.GoBack}
        maskClosable={false}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Spin tip={constants.PleaseWait} spinning={submitPopupLoading}
          className={`absolute top-0 left-0 flex justify-center items-center ${submitPopupLoading ? 'bg-white opacity-50 w-full h-full z-50 rounded-lg' : ''}`}
        >
        </Spin>
        <p>{constants.SureSubmitSurvey}</p>
      </Modal>
    )
  }

  const closePopup = (apiCall = false) => {
    setStudentCriteriaPopupOpen(false)
    setSubmitPopupOpen(false)
    setStudentCriteriaDetails(null)
    form.resetFields()
    criteriaForm.resetFields()
    if(apiCall) fetchSurveyData(surveyProps)
  }

  const getReturnRoute = () => {
    if(isStudent)
      return "/all-surveys"
    return "/my-survey"
  }

  const getSurveyDetails = (key) => {
    if(!surveyData) return ''
    let data = {...surveyData}
    return data?.[key] || ''
  }

  return (
    <div>
			<BreadcrumbHeader pageTitle={getSurveyDetails('survey_name')} />
        <Spin tip={constants.PleaseWait} spinning={loading}>
          <div>
            <Card className="container-border-custom">
              {show404Page ? 
                <Result
                  status="403"
                  title={constants.NotAuthorizedText}
                  extra={<Button onClick={() => navigate(getReturnRoute())} type="primary">Back to surveys</Button>}
                />
              : <div>
                <Form 
                    onFinish={() => onFinish(null, true)}
                    form={form}
                    scrollToFirstError
                    layout='vertical'
                  >
                    {
                      surveyData?.questions?.map((surveyObj, index) => (
                        <Form.Item 
                          key={index}
                          initialValue={initialValues(surveyObj)}
                          name={surveyObj.id || surveyObj.question_id}
                          label={`${index + 1}. ${surveyObj.question_text}`}
                          rules={[
                            {
                              required: surveyObj.is_mandatory === '1' && surveyObj.question_type !== '3' ? true : false,
                              message: constants.RequiredField,
                            },
                          ]}
                        >
                          {getSurveyComponent(surveyObj, index)}
                        </Form.Item>
                      ))
                    }
                    <Space>
                      <Link to={getReturnRoute()}>
                        <Button 
                          type="primary" 
                          danger
                        >{constants.Cancel}
                        </Button>
                      </Link>
                      {
                        !hideSubmitButton && 
                        <>
                          <Button
                            type="primary"
                            onClick={onFinish}
                          >{constants.Save}</Button>
                          <Button
                            className='bgGreen500'
                            onClick={() => setSubmitPopupOpen(true)}
                          >{constants.Submit}</Button>
                        </>
                      }   
                    </Space>
                </Form>
              </div>}
            </Card>
            {studentCriteriaPopupOpen && checkStudentCriteriaPopup()}
            {submitPopupOpen && submitPopup()}
          </div>
        </Spin>
    </div>
  );
}
 
export default AnswerSurvey;