/*
File Name: FacultyRegistration.js
Author: Saish Sawant
Modified On: 16/1/2024
Description: Faculty Registration
API's used:
1) /get-faculty-list
2) /add-faculty
3) /get-states/1
4) /get-countries
*/
import React, { useState, useRef } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { Form, message, Spin } from 'antd';
import { fetchStaffFormData } from '../../Services/Api';
import FacultyFormItem from '../Component/FacultyComponents/FacultyFormItem';
import constants from '../../Constants/constants';
import { removeExtraSpaces } from '../../GlobalFunctions/GlobalFunctions';

const FacultyRegistration = () => {
	const pageTitle = constants.StaffRegistration
	const breadcrumbItems = [
		{ label: constants.StaffRegistration, active: true },]
	const [form] = Form.useForm();
	const [imageFile, setImageFile] = useState(null);
	const [duplicate, setDuplicate] = useState(false)
	const [type, setType] = useState(false)
	const [display, setDisplay] = useState(true)
	const [imageText, setImageText] = useState(null)
	const [loading,setLoading] = useState(false)
	const errorRef = useRef(null)

	const addFaculty = (formData) => {
		let payload = display ? '1' : '0';
		setLoading(true)
		if (!formData.faculty_university_email) {
			delete formData.faculty_university_email;
		}
		formData.faculty_identify=removeExtraSpaces(formData.faculty_identify)
		formData.faculty_current_city_text=removeExtraSpaces(formData.faculty_current_city_text)
		formData.faculty_current_address=removeExtraSpaces(formData.faculty_current_address)
		formData.faculty_personal_email=removeExtraSpaces(formData.faculty_personal_email)
		formData.faculty_identify=removeExtraSpaces(formData.faculty_identify)
		formData.faculty_note=removeExtraSpaces(formData.faculty_note)
		if(formData.faculty_permanent_address && !display){
			formData.faculty_permanent_city_text=removeExtraSpaces(formData.faculty_permanent_city_text)
			formData.faculty_permanent_address=removeExtraSpaces(formData.faculty_permanent_address)
		}
		formData = {
			...formData,
			faculty_current_city: '0'
		};
		if (type) {
			formData = {
				...formData,
				email_type: 'on'
			};
		}
		if (!duplicate) {
			if (imageFile) {
				formData = { ...formData, faculty_image: imageFile }
			}
			fetchStaffFormData(`/add-faculty?addressCheck=${payload}`, 'POST', formData)
				.then((res) => {
					errorRef.current.scrollIntoView({ behavior: "smooth" });
					if (res?.code === "200") {
						setLoading(false)
						message.success(constants.DataSaved)
						form.resetFields();
						setImageFile(null)
					} else if (res?.code === 400) {
						setLoading(false)
						message.error(res?.message)
						setDuplicate(true)
						setTimeout(() => {
							setDuplicate(false)
						}, 5000)
					}else{
						setLoading(false)
						message.error(constants.ErrorMessage)
					}
				}).catch((error) => {
				})
		}
	}

	const headerContainer = <div className='student-form-header position-relative rounded h-150px mt-2 pb-5'>
		<h3 className='p-4'>{''}</h3>
	</div>
	return (
		<div ref={errorRef}>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<div>
			<Spin tip={constants.PleaseWait} spinning={loading}>
				<FacultyFormItem edit={false} searchStates={null} searchPermaStates={null}
					onFinish={addFaculty} form={form} imageFile={imageFile} setImageFile={setImageFile} display={display}
					setDisplay={setDisplay} type={type} setType={setType} headerContainer={headerContainer}
					imageText={imageText} setImageText={setImageText}
				/>
			</Spin>
			</div>
		</div>
	)
}
export default FacultyRegistration
