import React, { useState, useEffect, useRef, useContext } from 'react'
import constants from '../../../Constants/constants'
import BreadcrumbHeader from '../../Component/BreadcrumbHeader'
import { Card, Form, Flex, Col, Row, Input, DatePicker, Checkbox, Collapse, Button,message, Table, Tooltip, Divider } from 'antd'
import FileUpload from '../../Component/FileUpload'
import { beforeProfileUpload, allowAlphabets, handleKeyPressHandler, getBase64, removeExtraSpaces, getStatusName, getNextAndPreviousButtons, disableSpaceBar, checkEmptySpace } from '../../../GlobalFunctions/GlobalFunctions'
import { LoadingOutlined, UserAddOutlined,DownCircleFilled } from '@ant-design/icons';
import FacultyAddressField from '../../Component/FacultyComponents/FacultyAddressField'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useFetchCountries } from '../../Component/FacultyComponents/FetchCountries'
import { useFetchStates, useFetchPermaStates } from '../../Component/FacultyComponents/FetchStates'
import { getCookie, COOKIE } from '../../../Services/Cookie'
import { fetchStaffFormData, fetchStaffApi } from '../../../Services/Api'
import { UserContext } from '../../../Context'

const { Panel } = Collapse;

const StudentProfile = () => {
  const { setProfileImage } = useContext(UserContext);
  const pageTitle = constants.StudentProfile
  const errorRef = useRef(null);
  const [form] = Form.useForm();
  const breadcrumbItems = [
    { label: constants.StudentProfile, active: true },]
  const [loading, setLoading] = useState(false)
  const [showPermanentField, setShowPermanentField] = useState(true)
  const [file, setFile] = useState(null)
  const { countries, fetchCountries } = useFetchCountries();
  const { states, fetchStates } = useFetchStates();
  const { permaStates, fetchPermaStates } = useFetchPermaStates();
  const [studentData, setStudentData] = useState([])
  const [statusTableData, setStatusTableData] = useState([]);
  const [listAdvisors, setListAdvisors] = useState([]);
  const [mentorDetails,setMentorDetails] = useState([]);
  const [imageUrl, setImageURL] = useState('');
  const [transferDetails, setTransferDetails] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  let userId = getCookie(COOKIE.UserId);
  const facultyRoleId = getCookie(COOKIE.Role);
  const isAdminOrSysAdmin=(facultyRoleId==="1"||facultyRoleId==="3")?true:false;

  useEffect(() => {
    fetchCountries()
    getStudentData()
  }, [])

  const handleCellPhoneInputChange = (e, formval) => {
    const inputValue = e.target.value;
    // const formattedInput = phoneNumberFormatter(inputValue);
    form.setFieldsValue({ [formval]: inputValue })
  };
  const getSimilerForm = (label, formfield, is_required, check_alphabet = null, isdisable) => {
    if (is_required) {
      return <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={label}
          name={formfield}
          rules={[
            {
              required: true,
              message: constants.RequiredField,
              whitespace: true,
            },
          ]}
        >
          {check_alphabet !== null ? 
            <Input 
              onKeyPress={(event) => check_alphabet(event)} 
              placeholder={label}
              disabled={isdisable}
              onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
            />
            : 
            <Input 
              placeholder={label} 
              disabled={isdisable}
              onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
            />}
        </Form.Item>
      </Col>
    } else {
      return <Col xl={8} lg={8} md={8} sm={24} xs={24}>
        <Form.Item
          label={label}
          name={formfield}
        >
          {check_alphabet !== null ? <Input onKeyPress={(event) => check_alphabet(event)} placeholder={label} disabled={isdisable} />
            : <Input placeholder={label} disabled={isdisable} />}
        </Form.Item>
      </Col>
    }
  };
  const phoneFormField = (isRequired, field_name, label, validateMsg) => {
    return <Col xl={12} lg={12} md={12} sm={24} xs={24}>
      <Form.Item
        label={label}
        name={`student_${field_name}`}
        rules={[
          {
            required: isRequired,
            message: constants.RequiredField,
            whitespace: true,
          },
          // {
          //   min: 12,
          //   message: validateMsg
          // },
          // {
          //   max: 12,
          //   message: validateMsg
          // }
        ]}
      >
        <Input 
          placeholder={label} 
          onKeyPress={(e) => handleKeyPressHandler(e, null)}
          onChange={(e) => handleCellPhoneInputChange(e, `student_${field_name}`)} 
        />
      </Form.Item>
    </Col>
  }
  const emailFormField = (isRequired, field_name, label, isdisable) => {
    return <Col xl={12} lg={12} md={12} sm={24} xs={24}>
      <Form.Item
        label={label}
        name={`student_${field_name}`}
        rules={[
          {
            required: isRequired,
            message: constants.RequiredField,
          }, {
            type: "email",
            message: constants.ValidEmailAddress
          }
        ]}
      >
        <Input onKeyDown={disableSpaceBar} placeholder={label} disabled={isdisable} />
      </Form.Item>
    </Col>
  }
  const disableFutureDate = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const setFormFieldData = (student_details) => {
    setStudentData(student_details);
    let cell_no = { target: { value: student_details?.student_mobile_number } };
    let home_phn = { target: { value: student_details?.student_home_number } };
    handleCellPhoneInputChange(cell_no, "student_mobile_number");
    handleCellPhoneInputChange(home_phn, "student_home_number");
    setTransferDetails({
      checked: student_details?.is_transfer === "1",
      date: student_details?.transfer_date ? dayjs(student_details?.transfer_date).format(constants.DateFormat) : '',
    })
    form.setFieldsValue({
      student_first_name: student_details?.student_first_name,
      student_last_name: student_details?.student_last_name,
      student_middle_name: student_details?.student_middle_name,
      student_id_number: student_details?.student_id_number,
      student_asha_number: student_details?.student_asha_number,
      student_psha_number: student_details?.student_psha_number,
      student_home_number: student_details?.student_home_number,
      student_personal_email: student_details?.student_personal_email,
      student_university_email: student_details?.student_university_email,
      student_identify: student_details?.student_identify,
      student_permanent_country: student_details?.student_permanent_country,
      student_permanent_state: student_details?.student_permanent_state,
      student_permanent_address: student_details?.student_permanent_address,
      student_permanent_city_text: student_details?.student_permanent_city,
      student_permanent_zip: student_details?.student_permanent_zip,
      student_current_country: student_details?.student_current_country,
      student_current_state: student_details?.student_current_state,
      student_current_address: student_details?.student_address,
      student_current_city_text: student_details?.student_current_city,
      student_current_zip: student_details?.student_zip,
      student_dob: student_details?.student_dob ? dayjs(student_details?.student_dob) : null,
      type: constants.StudentTypeList?.find((item) => item.value === student_details?.student_type)?.label,
      year_in_program: constants.YearList?.find((item) => item.value === student_details?.year_in_program)?.label || constants.PartTime,
      student_username: student_details?.student_username,
      mentor: student_details?.mentor_id,
			advisor: student_details?.faculty_id,
    });
  }
  const getStudentData = () => {
    fetchStaffApi(`/get-student-data/${userId}`, 'get', null, 'student').then((res) => {
      if (res?.code === "200") {
        let result = res?.data?.student_data[0]
        setCurrentStatus(constants.StatusList?.find((item) => item?.value === result?.current_status)?.label)
        getMentors(result?.semester, result?.id);
        getAdvisorList(result);
        setStatusTableData(res?.data?.status);
        setImageURL(result?.student_image);
        fetchStates(result?.student_current_country)
        fetchPermaStates(result?.student_permanent_country)
        setFormFieldData(result)
      } else {
        setStudentData([]);
      }
    });
  }
  const getMentors = (semValue, mentorId) =>{
		let payload={
			value: 1,
			semester: semValue,
			mentor_id: mentorId,
		}
		fetchStaffApi('/get-all-mentors','POST',payload).then((res)=> {
			if (res?.code === "200") {
				setMentorDetails(res.data)
        // let findIndex = res?.data?.findIndex((advisor) => advisor.id === faculty_id);
        // if(findIndex > -1) {
        //   console.log(res?.data?.[findIndex]);
        //   form.setFieldsValue({
        //     mentor_name: res?.data?.[findIndex]?.faculty,
        //     mentor_contact_no: res?.data?.[findIndex]?.faculty_mobile_number,
        //     mentor_email: res?.data?.[findIndex]?.faculty_university_email,
        //   })
        // }
			}
		});
	}
  const getAdvisorList = (studentObject) => {
    let { faculty_id, faculty_office_number, is_visible_mobile_number } = studentObject
		fetchStaffApi(`/get-advising-faculty-details`, 'post').then((res) => {
			if (res?.code === "200") {
				setListAdvisors(res?.data);
				let findIndex = res?.data?.findIndex((advisor) => advisor.id === faculty_id);
        if(findIndex > -1) {
          let contact = is_visible_mobile_number === '1' ? res?.data?.[findIndex]?.faculty_mobile_number : faculty_office_number
          form.setFieldsValue({
            advisor_name: res?.data?.[findIndex]?.faculty,
            advisor_contact_no: contact,
            advisor_email: res?.data?.[findIndex]?.faculty_university_email,
          })
        }
			} else setListAdvisors([]);
		});
	};
  const handleProfileChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false)
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageURL(url);
      });
      setFile(info.file.originFileObj)
    }
  };
  const handleChange = (e) => {
    fetchStates(e)
    form.setFieldsValue({
      student_current_state: ''
    })
  }
  const handlePermaChange = (e) => {
    fetchPermaStates(e)
    form.setFieldsValue({
      student_permanent_state: ''
    })
  }
  const handleSubmit = (formData) => {
    let payload = {
      student_identify: removeExtraSpaces(formData?.student_identify) || '',
      student_address: removeExtraSpaces(formData.student_current_address) || '',
      student_current_city_text: removeExtraSpaces(formData?.student_current_city_text) || '',
      student_current_country: formData?.student_current_country || '',
      student_current_state: formData?.student_current_state || '',
      student_zip: formData?.student_current_zip || '',
      student_dob: dayjs(formData?.student_dob).format('YYYY-MM-DD')|| '',
      student_home_number: formData?.student_home_number || '',
      student_mobile_number: formData?.student_mobile_number || '',
      student_personal_email: removeExtraSpaces(formData?.student_personal_email) || '',
    }
    if(file) payload.student_profile = file
    fetchStaffFormData(`/update-student-profile/${userId}`, 'POST', payload, 'student').then((res) => {
      if(res?.code === "200") {
        sessionStorage.setItem('profile_image', res?.data?.image_url)
        localStorage.setItem('profile_image', res?.data?.image_url)
        setProfileImage(res?.data?.image_url || '')
        message.success(constants.ProfileUpdateSuccess)
        errorRef.current.scrollIntoView({ behavior: "smooth" });
        getStudentData()
      } else message.error(res?.message)
    }).catch((error) => {
    })
  }
  const advisorAndMentorData=(label, type, optionvalue)=>{
    let formLabel = type === "advisor" ? "Advisor's Name" : "Mentor's Name"
    let name = type === "advisor" ? "advisor_name" : "mentor_name"
    let email = type === "advisor" ? "advisor_email" : "mentor_email"
    let contact = type === "advisor" ? "advisor_contact_no" : "mentor_contact_no"
    return (
      <Row gutter={[24, 0]} className='mt-4'>
        <Col xs={24} lg={8} xl={8} md={24} sm={24}>
          <Form.Item
            label={formLabel}
            name={name}
          >
            <Input disabled placeholder={constants.AdvisorName} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={constants.Email}
            name={email}
          >
            <Input disabled className="faculty-inp" placeholder={constants.Email} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={constants.ContactNo}
            name={contact}
          >
            <Input disabled className="faculty-inp" placeholder={constants.ContactNo} />
          </Form.Item>
        </Col>
      </Row>
    )
  };
  const advisorAndMentorCheck=(label, type, optionvalue)=>{
		let advisorId = form.getFieldValue("advisor");
		let mentorId = form.getFieldValue("mentor");
		if (isAdminOrSysAdmin || (type === "advisor" && advisorId) || (type === "mentor" && mentorId)) {
				return advisorAndMentorData(label, type, optionvalue);
		} else {
				return type === "advisor" ? constants.NoAdvisorAssigned : constants.NoMentorAssigned;
		}
	}
	const collapeComponent = (label, type, optionvalue) => {
		let collapseChildren;
		if (type === "table") {
			collapseChildren = (
				<Table
					size='small'
					className="records-table"
					columns={statusColumn}
					dataSource={statusTableData}
					bordered
					pagination={{
						showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
						itemRender: getNextAndPreviousButtons,
						pageSize: constants.TablePageSize
					}}
					scroll={{
						y: '200px'
					}}
				/>
			)
		} else {
			collapseChildren = advisorAndMentorCheck(label, type, optionvalue)
		}
		return <Collapse
			expandIconPosition="right" 
			className='collapse-custom'
			size='small'
			expandIcon={({ isActive }) => <DownCircleFilled className='text-white' rotate={isActive ? 180 : 0} />}
			items={[{ key: '1', label: <span className='text-white'><strong>{label}</strong></span>, children: collapseChildren }]} />
	};
  const statusColumn = [
		{
			title: <b>{constants.Status}</b>,
			dataIndex: 'new_status',
			key: 'new_status',
			render: (status_id) => <span>{getStatusName(status_id)}</span>
		},
		{
			title: <b>{constants.DateChanged}</b>,
			dataIndex: 'dt_updated',
			key: 'dt_updated',
			render: (dt_updated) => <span>{dt_updated ? dayjs(dt_updated).format(constants.DateFormat) : null}</span>
		},
		{
			title: <b>{constants.ChangedBy}</b>,
			key: 'changed_by',
			render: (val) => <span>{val?.faculty_first_name} {val?.faculty_last_name}</span>
		},
	];
  const uploadButton = (
    <button
      className='bg-transparent border-0'
      type="button"
    >
      {loading ? <LoadingOutlined /> : <UserAddOutlined className='fs-1' />}
    </button>
  );
  const profileRenderContent = imageUrl ? <img src={imageUrl} alt="avatar" className='photoPick' /> : uploadButton;
  return (
    <div ref={errorRef}>
      <BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="container-border-custom px-3">
        <div className='student-form-header position-relative rounded h-150px mt-2 pb-5'>
          <h3 className='p-4'>{studentData.student_first_name} {studentData.student_middle_name} {studentData.student_last_name}</h3>
        </div>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Flex align='center' justify='center'>
            <Form.Item
              name="student_profile"
              className='position-absolute z-3'
            >
              <FileUpload
                renderContent={profileRenderContent}
                name='avatar'
                listType='picture-circle'
                className='avatar-uploader'
                showUploadList={false}
                beforeUpload={beforeProfileUpload}
                onChange={handleProfileChange}
                isMultiple={false}
              />
            </Form.Item>
          </Flex>
          <div className='py-4'></div>
          <Form.Item
            name="is_transfer"
            className='mb-0'
          >
            <Tooltip title={transferDetails?.date || ''}>
                <Checkbox checked={transferDetails?.checked}>
                  {constants.Transfer}
                </Checkbox>
              </Tooltip>
          </Form.Item>
          <div className='py-4'></div>
          <Row gutter={24}>
            {getSimilerForm(constants.FirstName, "student_first_name", false, allowAlphabets, true)}
            {getSimilerForm(constants.MiddleName, "student_middle_name", false, allowAlphabets, true)}
            {getSimilerForm(constants.LastName, "student_last_name", false, allowAlphabets, true)}
         
            {getSimilerForm(constants.StudentId, "student_id_number", false, null, true)}
            {getSimilerForm(constants.Type, "type", false, null, true)}
            {getSimilerForm(constants.YearProgram, "year_in_program", false, null, true)}
            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                label={constants.DOB}
                name='student_dob'
                rules={[
                  {
                    required: true,
                    message: constants.RequiredField,
                  },
                ]}

              >
                <DatePicker className="w-100" disabledDate={disableFutureDate} placeholder={constants.DOB} format={constants.DateFormat} />
              </Form.Item>
            </Col>
            {getSimilerForm(constants.Username, "student_username", false, null, true)}
            {getSimilerForm(constants.IdentifyAs, "student_identify", false)}
            {phoneFormField(true, 'mobile_number',constants.CellPhone, constants.MobileValidateMessage)}
            {phoneFormField(false, 'home_number', constants.HomePhn, constants.HomePhone)}
            {emailFormField(true, 'personal_email', constants.PersonalEmail, false)}
            {emailFormField(false, 'university_email', constants.MuEmail, true)}
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={constants.AshaNumber}
                name='student_asha_number'
                rules={[
                  {
                    required: false,
                    message: constants.RequiredField,
                  },
                  {
                    min: 8,
                    message: constants.AshaValidateMessage
                  },
                  {
                    max: 8,
                    message: constants.AshaValidateMessage
                  }
                ]}
              >
                <Input placeholder={constants.AshaNumber} onKeyPress={(e) => handleKeyPressHandler(e, 8)}
                  disabled />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={constants.PshaNumber}
                name='student_psha_number'
                rules={[
                  {
                    required: false,
                    message: constants.RequiredField,
                  },
                  {
                    min: 5,
                    message: constants.PshaValidateMessage
                  },
                  {
                    max: 5,
                    message: constants.PshaValidateMessage
                  }
                ]}
              >
                <Input placeholder={constants.PshaNumber} onKeyPress={(e) => handleKeyPressHandler(e, 5)}
                  disabled />
              </Form.Item>
            </Col>
          </Row>
          <hr></hr>
          <Divider />
          <FacultyAddressField countries={countries} states={states} handleChange={handleChange} field={'student_current'} title={'Current'} />
          <Row>
            <Checkbox checked={showPermanentField}
              onChange={(e) => setShowPermanentField(e.target.checked)} name='addressCheck' >{constants.AdressLabel}</Checkbox>
          </Row>
          {
            !showPermanentField ?
              <>
                <br></br>
                <FacultyAddressField countries={countries} states={permaStates} handleChange={handlePermaChange} field={'student_permanent'} title={'Permanent'} />
                <br></br>
              </>
              : ''
          }
          {/* <Row gutter={10}>
            {
              studentData?.faculty_id ?
                <Col lg={12} xs={24}>
                  <Collapse expandIconPosition="right" className='collapse-custom'
                  	expandIcon={({ isActive }) => <DownCircleFilled className='text-white' rotate={isActive ? 180 : 0} />}
                  >
                    <Panel header={constants.AdvisorDetls}>
                      <p><strong>{constants.AdvisorName} : </strong> {studentData.faculty_first_name} {studentData.faculty_middle_name} {studentData.faculty_last_name}</p>
                      <p><strong>{constants.Email} : </strong> {studentData.advisor_email}</p>
                      <p><strong>{constants.ContactNo} : </strong>{studentData.advisor_phone_no}</p>
                    </Panel>
                  </Collapse>
                </Col>
                : ''
            }
            {
              studentData?.mentor_id ?
                <Col lg={12} xs={24}>
                  <Collapse expandIconPosition="right" className='collapse-custom'
                  	expandIcon={({ isActive }) => <DownCircleFilled className='text-white' rotate={isActive ? 180 : 0} />}
                  >
                    <Panel header={constants.MentorDetls}>
                      <p><strong>{constants.MentorName} :</strong> {studentData.mentor_first_name} {studentData.mentor_middle_name} {studentData.mentor_last_name}</p>
                      <p><strong>{constants.Email} :</strong>{studentData.mentor_university_email}</p>
                      <p><strong>{constants.ContactNo} : {studentData.mentor_mobile_number}</strong></p>
                    </Panel>
                  </Collapse>
                </Col> : ''
            }
          </Row> */}
          <Divider />
          <Row gutter={[16, 16]}>
					  <Col xl={12} lg={12} md={24} sm={24} xs={24}>{collapeComponent(constants.AdvisorDetls, "advisor", listAdvisors)}</Col>
					  <Col xl={12} lg={12} md={24} sm={24} xs={24} >{collapeComponent(constants.MentorDetls, "mentor",mentorDetails)}</Col>
				  </Row>
          <Divider />
					<div className='mb-3'>
						<label>{constants.StudentStatus}</label>
					</div>
					<Row gutter={[16, 0]}>
						<Col span={8}>
							<label className='mb-2'>{constants.CurrentStatus}</label>
							<Input value={currentStatus} placeholder={constants.StudentStatus} disabled />
						</Col>
						<Col span={16}>
							<div className='mt-6'>{collapeComponent(constants.StatusHistory, "table", null)}</div>
						</Col>
					</Row>
          <br></br>
          <Flex gap="small" align="flex-end" vertical className='faculty-action-container'>
            <Flex gap="small" wrap="wrap">
              <Button type="primary" className='action-btn' danger  >
                <Link to="/student-dashboard" className='link-text'>{constants.Cancel}</Link></Button>
              <Button type="primary" className='action-btn' htmlType='submit'>
                {constants.Save}
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Card>
    </div>
  )
}
export default StudentProfile
