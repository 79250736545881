/*
File Name: CLearances.js
Author: Akshay Lotlikar
Modified On: 30/04/2024
Description: Display list of tabs based on user roles.
API's used:
*/
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import { Card, Tabs } from 'antd';
import React, { useEffect } from 'react';
import constants from '../../../Constants/constants';
import Background from '../Background';

const Clearances = () => {
	const breadcrumbItems = [{ label: constants.Clearances, href: '/', active: true }];
	const items = [
		{
			key: '1',
			label: 'Initial Health',
			children: 'Content of Tab Pane 1',
		},
		{
			key: '2',
			label: 'Background',
			children: <Background/>,
		},
		{
			key: '3',
			label: 'Follow Up Health',
			children: 'Content of Tab Pane 3',
		},    {
			key: '4',
			label: 'Fieldwork',
			children: 'Content of Tab Pane 3',
		},
	];
	useEffect(() => {}, [])
	const onChange = (key) => {
		console.log(key);
	};
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.Clearances} breadcrumbItems={breadcrumbItems}/>
			<div className="row">
				<div className="col">
					<Card className="container-border-custom">
						<Tabs
							defaultActiveKey="2" 
							items={items} 
							onChange={onChange} 
							type="card"
							className="card-tabs mt-3 newTab"
						/>
					</Card>
				</div>
			</div>
		</div>
	)
};
export default Clearances
