import React, { useState, useEffect } from "react";
import constants from "../../Constants/constants";
import { Card, Table, Space, Input, Button, Tabs, Modal, Select, Tooltip, Spin, message, Checkbox } from "antd";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { fetchApi, fetchStaffApi } from '../../Services/Api';
import { performSearch, HighlightedText, CustomSortIcon, getNextAndPreviousButtons, handleRowSelect, showTotalItems } from '../../GlobalFunctions/GlobalFunctions';
import { useNavigate } from 'react-router';
const { TabPane } = Tabs;
const { Option } = Select;

const renderYearInProgram = (year) => {
	return constants.FullYearList.find(item => item.value === year?.toString())?.label || '-';
};

const renderType = (type) => {
	return constants.StudentTypeList.find(item => item.value === type)?.label || '-';
};

function getCustomSortIcon(sortOrder) {
	return <CustomSortIcon sortOrder={sortOrder} />
}

const AssignAdvisors = () => {
	const navigate = useNavigate();
	const [semester, setSemester] = useState(1);
	const [listData, setListData] = useState({
		advisingStudentData: [],
		advisingFacultyData: [],
	});
	const [studentFilteredData, setStudentFilteredData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [typeValue, setTypeValue] = useState(0);
	const [yearValue, setYearValue] = useState(0);
	const [loading, setLoading] = useState(true);
	const [popupLoading, setPopupLoading] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [assignModalContent, setAssignModalContent] = useState(null);
	const [modalType, setModalType] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [searchStudent, setSearchStudent] = useState("");
	const [currentSemesterType, setCurrentSemesterType] = useState(null);
	const [futureSemesterType, setFutureSemesterType] = useState(null);
	const [showTab, setShowTab] = useState(false);
	const [record, setRecord] = useState(null);
	const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
	const [selectedFacultyId, setSelectedFacultyId] = useState(null);
	const [isYearDisabled, setIsYearDisabled] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [activeTab, setActiveTab] = useState('current');
	const [sameAsCurrentChecked, setSameAsCurrentChecked] = useState(false);
	const pageTitle = 'Assigning Advisors';
	const breadcrumbItems = [{ label: 'Assigning Advisors', href: '/', active: true }]

	useEffect(() => {
		advisingFacultyList();
		getSemesterData();
	}, []);

	// Advising faculty list report API;
	const advisingFacultyList = (id = 1) => {
		setLoading(true);
		let formData = { semester: id }
		setSemester(id);
		
		fetchStaffApi('/get-advising-faculty-details', 'POST', formData).then((res) => {
			setLoading(false);
			if (res?.code === "200") {
				let data = res?.data?.map((item) => {
					return {
						...item,
						student_count: parseInt(item?.student_count || 0),
						future_student_count: parseInt(item?.future_student_count || 0)
					}
				})
				setListData(prevState => ({ ...prevState, advisingFacultyData: data }));
				setFilteredData({ advisingFacultyData: data });
				return
			}
			setListData(prevState => ({ ...prevState, advisingFacultyData: [] }));
			setFilteredData([]);
		}).catch(() => setLoading(false));
	};

	const getSemesterData = () => {
		fetchApi('/semester-data', 'get').then((res) => {
			if (res?.code === "200") {
				if (res?.data?.length > 1) {
					const findSemesterByStatus = (status) => res?.data?.find((val) => val.semester_is_ended === status)?.semester_type;
					setCurrentSemesterType(constants.SemesterTypeList[findSemesterByStatus("0")]);
					setFutureSemesterType(constants.SemesterTypeList[findSemesterByStatus("2")]);
					setShowTab(true);
				} else {
					setShowTab(false);
					setFutureSemesterType(null);
					setCurrentSemesterType(null);
				}
				return
			}
			setShowTab(false);
		});
	}

	const handleRowClick = (record) => {
		const selectedKey = record.id;
		const selected = selectedRowKeys.includes(selectedKey);
		const newSelectedRowKeys = selected ? [] : [selectedKey];
		const newSelectedRows = selected ? [] : [record];
	
		setSelectedRowKeys(newSelectedRowKeys);
		setRecord(newSelectedRows[0]);
		checkButtonEnabled(newSelectedRows)
	};

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		setSameAsCurrentChecked(false)
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
			'sem_id',//ROW KEY ID
		)
  };

	const checkButtonEnabled = (rows) => {
		let disabled = true
		let hasStudents = rows?.[0]?.student_count > 0 || rows?.[0]?.future_student_count > 0
		if (hasStudents)
			disabled = false;
		setIsButtonDisabled(disabled);
	}

	//checkbox working like radio parent table.
	const rowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
		hideSelectAll: true,
		onChange: (selectedRowKeys, selectedRows) => {
			if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = selectedRows.slice(-1);
			}
			setSelectedRowKeys(selectedRowKeys);
			setRecord(selectedRows[0]);
			checkButtonEnabled(selectedRows)
		},
		selectedRowKeys: selectedRowKeys
	};

	// Row selection for modals
	const rowSelectionStudent = modalType === 'view' ? undefined : {
		type: 'checkbox',
		selectedRowKeys: rowKeys,
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? studentFilteredData : []
			onSelectChange(
				data?.map((item) => item.sem_id),
				data,
				null,
				null,
				true
			)
		},
		onSelect: (record, selected, selectedRows) => onSelectChange(
			// selectedRows?.map((item) => item.sem_id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			studentFilteredData?.findIndex((item) => item.sem_id === record.sem_id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
	};

	const fetchAdvisingStudentList = async (year, type, sem = 'current', faculty_id = null) => {
		setPopupLoading(true);
		setShowAssignModal(true);
		let payload = {
			year: year,
			type: type,
			semester: semester,
			status: 0,
		}
		if(faculty_id) payload.faculty_id = faculty_id
		
		const url = sem === 'current' ? '/get-advising-student-details' : '/get-future-advising-students'
		fetchStaffApi(url, 'POST', payload).then((res) => {
			setPopupLoading(false);
			if (res?.code === "200") {
				setListData(prevState => ({ ...prevState, advisingStudentData: res?.data }));
				setStudentFilteredData(res?.data)
				return
			}
			setListData(prevState => ({ ...prevState, advisingStudentData: [] }));
			setStudentFilteredData([]);
		}).catch(() => setPopupLoading(false));
	};

	const handleShowAssignModal = (content) => {
		setModalType('assign');
		setAssignModalContent(content);
		fetchAdvisingStudentList(0, 0, activeTab);
	};

	const handleShowReassignModal = (content) => {
		setModalType('reassign');
		setAssignModalContent(content);
		setRowKeys([]);
		if (selectedRowKeys.length > 0) {
			fetchAdvisingStudentList(0, 0, activeTab, record?.id);
		}
	};

	const handleViewModal = (content) => {
		setModalType('view');
		setAssignModalContent(content);
		if (selectedRowKeys.length > 0) {
			fetchAdvisingStudentList(0, 0, activeTab, record?.id);
		}
	};

	// Function to clear advising student data
	const clearAdvisingStudentData = () => {
		setListData(prevState => ({
			...prevState,
			advisingStudentData: []
		}));
		setStudentFilteredData([])
	};

	const handleCloseAssignModal = () => {
		setModalType(null);
		setAssignModalContent(null);
		setShowAssignModal(false);
		clearAdvisingStudentData();
		setIsYearDisabled(false);
		setTypeValue(0); // Reset typeValue
		setRowKeys([]); // Clear selected rows
		setRowData([]);
		setSearchStudent(''); // Clear search input
		setYearValue(0); // Reset yearValue
		setSelectedFacultyId(null); // Reset selected faculty
		setActiveTab('current')
	};

	// onchange function for type | year | showall
	const onChangeSelect = (e, selectType) => {
		let value = e && e !== undefined ? e : 0 //UNDEFINED CHECK IF USER HAS REMOVED A DROPDOWN FILTER
		let facultyId = modalType !== "assign" ? record?.id : null
		if (selectType === 'type') setTypeValue(value)
		if (selectType === 'year_in_program') setYearValue(value)
		if (selectType === 'type') {
			setIsYearDisabled(value === "2");
			fetchAdvisingStudentList(yearValue, value, activeTab, facultyId);
		}else{
			fetchAdvisingStudentList(value, typeValue, activeTab, facultyId);
		}
	};

	// Save assignments
	const handleSaveAssignments = async () => {
		// Extracting the faculty_id from the selected parent table row
		const facultyId = rowSelection.selectedRowKeys[0];

		// Extracting student data from selected assignments
		const students = rowKeys.map((key) => {
			const student = listData.advisingStudentData.find((item) => item.sem_id === key);
			return { student_id: student.student_id, sem_id: student.sem_id };
		});

		const payload = {
			faculty: facultyId,
			students: students.map(student => student.student_id),
			semids: students.map(student => student.sem_id),
		};

		if(activeTab === 'future')
			payload.future_advisor = 1

		try {
			setPopupLoading(true)
			const res = await fetchStaffApi(`/save-advising-student-details`, 'POST', payload);
			if (res?.code === '200') {
				message.success(constants.StudentsAssignedMsg);
				await fetchAdvisingStudentList(0, 0, activeTab);
				advisingFacultyList(semester);
				handleCloseAssignModal();
				// Uncheck selected row
				setSelectedRowKeys([]);
				setRecord(null);
				setIsButtonDisabled(true);
			} else {
				message.error(constants.Error);
			}
			setPopupLoading(false)
		} catch (error) {
			message.error(constants.Error);
			setPopupLoading(false)
		} finally {
			setPopupLoading(false)
		}
	};

	// Function to handle reassignment
	const handleReassign = async () => {
		// Extracting student data from selected assignments
		const students = rowKeys?.map((key) => {
			const student = listData?.advisingStudentData.find((item) => item.sem_id === key);
			return { student_id: student.student_id, sem_id: student.sem_id };
		});

		const payload = {
			faculty: selectedFacultyId,
			students: students?.map(student => student.student_id),
			semids: students?.map(student => student.sem_id),
		};

		if(activeTab === 'future')
			payload.future_advisor = 1

		try {
			setPopupLoading(true)
			const res = await fetchStaffApi(`/save-advising-student-details`, 'POST', payload);
			if (res?.code === '200') {
				message.success(constants.StudentsAssignedMsg);
				// Refetching the advising faculty & reassigned student data
				advisingFacultyList(semester);
				if (selectedRowKeys?.length > 0) {
					const selectedUserId = listData.advisingFacultyData.find(item => item.id === selectedRowKeys[0])?.id;
					fetchAdvisingStudentList(0, 0, activeTab, selectedUserId);
				}
				// Clear the selected value from the dropdown
				setSelectedFacultyId(null);
				setRowKeys([]);
				handleCloseAssignModal();
			} else message.error(constants.Error);
			setPopupLoading(false)
		} catch (error) {
			setPopupLoading(false)
			message.error(constants.Error);
		} finally {
			setPopupLoading(false)
		}
	};

	const isReassignDisabled = !selectedFacultyId || rowKeys?.length === 0;
	const isAssignDisabled = rowKeys?.length === 0;

	const onSearch = (event) => {
    const search = event.target.value;
    const fieldNames = ["faculty"];
    const searchedValue = performSearch(search, filteredData.advisingFacultyData, fieldNames);
    setListData({advisingFacultyData: searchedValue});
    setSearchValue(search);
  };

	const onSearchStudent = (event) => {
		const search = event.target.value;
		setSearchStudent(search);
		const searchFieldNames = [
			"student",
		];
		let searchStudent = performSearch(search,listData.advisingStudentData,searchFieldNames);
		setStudentFilteredData(searchStudent);
	};

	const AdvisorsFacultyCols = [
		{
			title: `${constants.Faculty}`,
			dataIndex: 'faculty',
			key: 'faculty',
			sorter: (a, b) => a?.faculty?.localeCompare(b?.faculty),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (faculty) => <HighlightedText text={faculty} highlight={searchValue} />,
		},
		{
			title: `${constants.StudentsAssigned}`,
			dataIndex: 'student_count',
			key: 'student_count',
			width: '20%',
			sorter: (a, b) => a?.student_count - b?.student_count,
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (_, record) => <span className="numbers-alignment">{record?.student_count}</span>,
		},
		{
			title: `${constants.FutureStudentsAssigned}`,
			dataIndex: 'future_student_count',
			key: 'future_student_count',
			width: '20%',
			sorter: (a, b) => a?.future_student_count - b?.future_student_count,
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (_, record) => <span className="numbers-alignment">{record?.future_student_count}</span>,
		},
	];

	const AssignCols = [
		{
			title: `${constants.Student}`,
			dataIndex: 'student',
			width: "40%",
			sorter: (a, b) => a.student.localeCompare(b.student),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (student) => { return <HighlightedText text={student} highlight={searchStudent} /> },
		},
		{
			title: `${constants.YearProgram}`,
			dataIndex: 'year_in_program',
			width: '25%',
			sorter: (a, b) => a.year_in_program?.toString().localeCompare(b.year_in_program?.toString()),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (_, record) => renderYearInProgram(record?.year_in_program),
		},
		{
			title: `${constants.Type}`,
			dataIndex: 'type',
			width: '20%',
			sorter: (a, b) => a.type.localeCompare(b.type),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (_, record) => renderType(record?.type),
		},
		{
			title: `${constants.Mentor}`,
			dataIndex: 'ismentor',
			width: '15%',
			sorter: (a, b) => {
				const aValue = a.ismentor || '';
				const bValue = b.ismentor || '';
				return aValue.localeCompare(bValue);
			},
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (ismentor) => (
				ismentor === "1" ? <Tooltip title={constants.IsMentor}>{constants.Yes}</Tooltip> : <Tooltip title={constants.NotMentor}>{constants.No}</Tooltip>
			),
		},
	]

	const handleOpenAssignModal = () => {
		if (record)
			handleShowAssignModal(`${constants.AssignToAdvisor} ${record.faculty}`);
	};

	const handleDoubleClick = (record) => {
		if (record) {
			handleShowAssignModal(`${constants.AssignToAdvisor} ${record.faculty}`);
			setShowAssignModal(true);
		}
	};

	const handleOpenReassignModal = () => {
		if (record)
			handleShowReassignModal(`${constants.ReassignToAdvisor} ${record.faculty}`);
	};

	const handleOpenViewAssignModal = () => {
		if (record)
			handleViewModal(`${constants.ViewStudentAssigned} ${record.faculty}`);
	};

	const facultyOptions = () => {
		return listData?.advisingFacultyData
		.filter(faculty => faculty.faculty !== record.faculty)
		.map(faculty => ({
			value: faculty.id,
			label: faculty.faculty
		})) || []
	}
	
	const handleCheckCurrentSemStudents = (e) => {
		setSameAsCurrentChecked(e)
		if(e) {
			//MAKE API CALL HERE TO FETCH SORTED LIST OF CURRENT SEM STUDENTS
			//AND KEEP THE RESULT SELECTED WITH CHECKBOX SELECTION
			let data = [...listData?.advisingStudentData]
			let updatedRows = data?.filter((item) => item.is_current_sem)
			setRowKeys(updatedRows.map((it) => it.sem_id));
			setRowData(updatedRows);
			return
		}
		setRowKeys([])
		setRowData([])
	}

	const AssigningAdvisorsComp = (type) => (
		<>
			{modalType === 'reassign' && (
				<Space className="mb-2">
					<label className="ml-1">{constants.SelectFacAdvisor}</label>
					<Select
						className="w-[18.5rem]"
						placeholder={constants.Faculty}
						allowClear
						options={facultyOptions()}
						onChange={(selectedValue) => setSelectedFacultyId(selectedValue)}
						value={selectedFacultyId}
					/>
				</Space>
			)}
			<div className="flex flex-wrap gap-2 justify-between">
				<Space>
					<Select
						allowClear
						className="w-36"
						onChange={(e) => onChangeSelect(e, "type")}
						placeholder={constants.SelectType}
						value={typeValue === 0 ? null : typeValue}
					>
						{
							constants.StudentTypeList.map((record) =>
								<Option value={record.value} key={record.value} >{record.label}</Option>
							)
						}
					</Select>
					<Select
						allowClear
						className="w-36"
						onChange={(e) => onChangeSelect(e, "year_in_program")}
						placeholder={constants.SelectYear}
						disabled={isYearDisabled}
						value={yearValue === 0 ? null : yearValue}
					>
						{
							constants.YearList.map((record) =>
								<Option value={record.value} key={record.value}>{record.label}</Option>
							)
						}
					</Select>
				</Space>
				<Input className="w-48" placeholder={constants.Search} value={searchStudent} onChange={onSearchStudent} />
			</div>
			{(modalType !== 'view' && activeTab === 'future') && (
				<div className="row mt-2">
					<Checkbox 
						checked={sameAsCurrentChecked}
						onChange={(e) => handleCheckCurrentSemStudents(e.target.checked)}
					>
						Same as current semester
					</Checkbox>
				</div>
			)}
			<div className="row mt-2">
				<Spin tip={constants.PleaseWait} spinning={popupLoading}>
					<Table
						className="records-table data_table !pb-2 mb-0"
						rowSelection={rowSelectionStudent}
						rowKey={"sem_id"}
						columns={AssignCols}
						dataSource={studentFilteredData?.length > 0 ? studentFilteredData : []}
						bordered
						size="small"
						scroll={{ x:510, y:'50vh' }}
						pagination={{
							showTotal: (total, range) => showTotalItems(total, range, studentFilteredData?.length > 0 ? studentFilteredData : []),
							itemRender: getNextAndPreviousButtons,
							pageSize: constants.TablePageSize,
						}}
						onRow={(record, rowIndex) => {
							return {
								onClick: () => onSelectChange(null, null, record, rowIndex),
							}
						}}
						rowClassName={(record)=> getRowClassName(record)}
					/>
					<div className="flex justify-end mb-3">
						{modalType !== 'view' ? (
							<div className="flex justify-end">
								{modalType === 'reassign' ?
									<Button className="text-capitalize btn-info" disabled={popupLoading || isReassignDisabled} onClick={handleReassign}>{constants.Reassign}</Button> :
									<Button className="text-capitalize btn-info" disabled={popupLoading || isAssignDisabled} onClick={handleSaveAssignments}>{constants.Assign}</Button>}
							</div>
						) : null}
					</div>
				</Spin>
			</div>
		</>
	)

	const handleTabChange = (e) => {
		setSelectedFacultyId(null)
		setTypeValue(0)
		setYearValue(0)
		setSearchValue('')
		setSearchStudent('')
		setRowData([])
		setRowKeys([])
		setSameAsCurrentChecked(false)
		setActiveTab(e)
		let facultyId = modalType !== "assign" ? record?.id : null
		fetchAdvisingStudentList(0, 0, e, facultyId)
	}

	const getRowClassName = (record) => {
		return record?.is_current_sem && 'advisor-row';
	};

	return (
		<>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<div>
				<Card className="container-border-custom">
					{showTab ?
						<Tabs
							defaultActiveKey="current-sem-advisor"
							type="card"
							onChange={(activeKey) => {
								setSelectedRowKeys([]);
								setRecord(null);
								checkButtonEnabled([])
								advisingFacultyList(activeKey?.includes('current') ? 1 : 2)
							}}
						>
							<TabPane key="current-sem-advisor" tab={<span className=" text-capitalize fs-7">current semester ({currentSemesterType})</span>} className="py-2">
								<div>
									<div className="flex justify-between flex-wrap gap-2">
										<Space wrap>
											<Button className="text-capitalize btn-info" disabled={selectedRowKeys.length === 0} onClick={handleOpenAssignModal}>{constants.Assign}</Button>
											<Button className="text-capitalize btn-info" disabled={isButtonDisabled} onClick={handleOpenReassignModal}>{constants.Reassign}</Button>
											<Button className="text-capitalize btn-info" disabled={isButtonDisabled} onClick={handleOpenViewAssignModal}>{constants.View}</Button>
											<Button onClick={() => navigate('/add-schedule', {
												state: {
													faculty_id: record?.id,
													faculty_name: record?.faculty
												}
											})} className="text-capitalize btn-info" disabled={selectedRowKeys.length === 0}>{constants.Schedule}</Button>
										</Space>
										<Input className="w-48" placeholder="Search" onChange={onSearch} value={searchValue} />
									</div>
									<div className="mt-2">
										<Spin tip={constants.PleaseWait} spinning={loading}>
											<Table
												className="records-table data_table"
												rowSelection={{ ...rowSelection, }}
												columns={AdvisorsFacultyCols}
												dataSource={listData.advisingFacultyData}
												rowKey={(record) => record.id}
												bordered
												scroll={{ x: 768,y: 'calc(100vh - 350px)' }}
												size="small"
												pagination={{
													className:"!mb-0",
													showTotal: (total, range) => showTotalItems(total, range, listData.advisingFacultyData),
													itemRender: getNextAndPreviousButtons,
													pageSize: constants.TablePageSize,
												}}
												onRow={(record) => ({
													onClick: () => handleRowClick(record),
													onDoubleClick: () => {
														handleRowClick(record); // Select the row on double click
														handleDoubleClick(record); // Open the modal
													},
												})}
											/>
										</Spin>
									</div>
								</div>
							</TabPane>
							<TabPane key="future-sem-advisor" tab={<span className=" text-capitalize fs-7">{futureSemesterType} semester</span>} className="py-2">
								<div>
									<div className="flex justify-between flex-wrap gap-2">
										<Space wrap>
											<Button className="text-capitalize btn-info" disabled={selectedRowKeys.length === 0} onClick={handleOpenAssignModal}>{constants.Assign}</Button>
											<Button className="text-capitalize btn-info" disabled={isButtonDisabled} onClick={handleOpenReassignModal}>{constants.Reassign}</Button>
											<Button className="text-capitalize btn-info" disabled={isButtonDisabled} onClick={handleOpenViewAssignModal}>{constants.View}</Button>
											<Button onClick={()=> navigate('/add-schedule',{ state: { faculty_id:record?.id, faculty_name: record?.faculty } })} className="text-capitalize btn-info" disabled={selectedRowKeys.length === 0}>{constants.Schedule}</Button>
										</Space>
										<Input className="w-48" placeholder="Search" onChange={onSearch} value={searchValue} />
									</div>
									<div className="mt-2">
										<Spin tip={constants.PleaseWait} spinning={loading}>
											<Table
												className="records-table data_table"
												rowSelection={{ ...rowSelection, }}
												columns={AdvisorsFacultyCols}
												dataSource={listData.advisingFacultyData}
												rowKey={(record) => record.id}
												bordered
												scroll={{ x: 768,y: 'calc(100vh - 350px)' }}
												size="small"
												pagination={{
													className:"!mb-0",
													showTotal: (total, range) => showTotalItems(total, range, listData.advisingFacultyData),
													itemRender: getNextAndPreviousButtons,
													pageSize: constants.TablePageSize,
												}}
												onRow={(record) => ({
													onClick: () => handleRowClick(record),
													onDoubleClick: () => {
														handleRowClick(record); // Select the row on double click
														handleDoubleClick(record); // Open the modal
													},
												})}
											/>
										</Spin>
									</div>
								</div>
							</TabPane>
						</Tabs>
						:
						<div>
							<div className="flex justify-between flex-wrap gap-2">
								<Space wrap>
									<Button className="text-capitalize btn-info" disabled={selectedRowKeys.length === 0} onClick={handleOpenAssignModal}>{constants.Assign}</Button>
									<Button className="text-capitalize btn-info" disabled={isButtonDisabled} onClick={handleOpenReassignModal}>{constants.Reassign}</Button>
									<Button className="text-capitalize btn-info" disabled={isButtonDisabled} onClick={handleOpenViewAssignModal}>{constants.View}</Button>
									<Button onClick={()=> navigate('/add-schedule',{ state: { faculty_id:record?.id, faculty_name: record?.faculty } })} className="text-capitalize btn-info" disabled={selectedRowKeys.length === 0}>{constants.Schedule}</Button>
								</Space>
								<Input className="w-48" placeholder="Search" onChange={onSearch} value={searchValue} />
							</div>
							<div className="mt-2">
								<Spin tip={constants.PleaseWait} spinning={loading}>
									<Table
										className="records-table data_table"
										rowSelection={{ ...rowSelection, }}
										columns={AdvisorsFacultyCols}
										dataSource={listData.advisingFacultyData}
										rowKey={(record) => record.id}
										bordered
										scroll={{ x: 768,y: 'calc(100vh - 280px)' }}
										size="small"
										pagination={{
											className:"!mb-0",
											showTotal: (total, range) => showTotalItems(total, range, listData.advisingFacultyData),
											itemRender: getNextAndPreviousButtons,
											pageSize: constants.TablePageSize,
										}}
										onRow={(record) => ({
											onClick: () => handleRowClick(record),
											onDoubleClick: () => {
												handleRowClick(record); // Select the row on double click
												handleDoubleClick(record); // Open the modal
											},
										})}
									/>
								</Spin>
							</div>
						</div>
					}
				</Card>
			</div>

			<Modal
				open={showAssignModal}
				onCancel={handleCloseAssignModal}
				title={<span className="pr-3 lg:pr-0">{assignModalContent}</span>}
				className="!top-5"
				footer={null}
				width={700}
			>
				<Tabs 
					activeKey={activeTab} 
					onChange={(e) => handleTabChange(e)}
					type="card"
				>
					<TabPane tab={constants.CurrentSem} key='current'>
						{activeTab === 'current' && AssigningAdvisorsComp('current')}
					</TabPane>
					<TabPane tab={constants.FutureSem} key='future'>
						{activeTab === 'future' && AssigningAdvisorsComp('future')}
					</TabPane>
				</Tabs>			
			</Modal>
		</>
	)
};
export default AssignAdvisors;
