import React, { useState } from 'react';
const UserContext = React.createContext();

function ContextProvider(props) {
	localStorage.setItem('surveyUrl', window.location.search || '');
  const [collapsed, setCollapsed] = useState(false);
  const [profileImage, setProfileImage] = useState(localStorage.getItem("profile_image") || '');
	
  const toggleCollapsed = () => setCollapsed(prev => !prev)

	let contextProps = {
		collapsed,
		toggleCollapsed,
		profileImage,
		setProfileImage
	}
	
	return (
		<UserContext.Provider value={{ ...contextProps }}>
			{props.children}
		</UserContext.Provider>
		)
}
export {ContextProvider,UserContext}