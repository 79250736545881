import React, { useState, useEffect } from 'react';
import constants from '../../Constants/constants';
import { Table, Card, Space, Flex, Button } from 'antd';
import { fetchStaffApi, fetchStaffDownloadApi } from '../../Services/Api';
import { useLocation } from "react-router-dom";
import { DownloadOutlined } from '@ant-design/icons';
import { COOKIE, getCookie } from '../../Services/Cookie';

function getSectionNo(sectionNumber) {
	let section = parseInt(sectionNumber)
	let sectionNo = section > 8 ?
	constants.extraSections?.find((sec) => sec.id === section)?.name : 
	sectionNumber
	return sectionNo
}
const PlanOfStudy = ({ student_id = getCookie(COOKIE.UserId) }) => {
	const location = useLocation();
  const [planOfStudyData, setPlanOfStudyData] = useState([]);
	const [pdfDownload, setPdfDownload] = useState(false);
  const isArchived = location?.state?.isArchived;
  const isStudent = getCookie(COOKIE.Role) === '0';

  const getPlanOfStudy = () => {
    let payload = null;
    payload = { student_id: student_id }

    let url = isStudent ? '/plan-of-study-student' : '/plan-of-study-staff'
    fetchStaffApi(url, 'POST', payload, isStudent ? 'student' : null).then((res) => {
      if (res?.code === "200") {
        setPlanOfStudyData(res?.data);
      } else {
        setPlanOfStudyData([]);
      }
    });
  };

  useEffect(() => {
    getPlanOfStudy();
  }, []);

  const downloadPdf = (type,id) => {
		if (!pdfDownload) {
      let withPdfPayload = null;
      let url = ``;
      let method="";
      if(type==="export"){
        withPdfPayload = {
          generate_pdf:1,
          student_id: student_id,
        };
        url=`/plan-of-study-staff`;
        method="POST"
      }else{
        url=`/download-assessment-document/${id}`
        method="GET"
      }
			setPdfDownload(true);
			fetchStaffDownloadApi(url, method, withPdfPayload).then((res) => {
				if (res) {
					const blob = new Blob([res], { type: 'application/pdf' });
					const url = URL.createObjectURL(blob);
					window.open(url, '_blank');
				}
				setPdfDownload(false);
			});
		}
	};

  const { coreCourses, slpUnderGradCourses, slpGradCourses, assessments, freeElective } = planOfStudyData;

  const columns1 = [
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      width:'10%'
    },
    {
      title: 'Course',
      dataIndex: 'course_name',
      key: 'course_name',
      width:'40%'
    },
    {
      title: 'Credits',
      dataIndex: 'course_credits',
      key: 'course_credits',
      align:'right'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Approved By',
      dataIndex: 'approved_by_name',
      key: 'approved_by_name',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Semester Completed',
      dataIndex:'sem_schdeule_name',
      key: 'SemesterCompleted',
    },
    {
      title: 'Grade',
      dataIndex: 'course_grade',
      key: 'course_grade',
      render: (text) => (text?.trim() ? text : '-'),
    },
  ];
  const columns2 = [
    {
      title: 'Course Number',
      dataIndex: 'course_code',
      key: 'course_code',
      width:'10%'
    },
    {
      title: 'Course',
      dataIndex: 'course_name',
      key: 'course_name',
      width:'40%'
    },
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      align:'right',
      render: (_, record) => (<span>{getSectionNo(record?.section_number)}</span>)
    },
    {
      title: 'Credits',
      dataIndex: 'course_credit',
      key: 'course_credit',
      align:'right'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Approved By',
      dataIndex: 'approved_by_name',
      key: 'approved_by_name',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Semester Completed',
      dataIndex: 'sem_schdeule_name',
      key: 'SemesterCompleted',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => (text?.trim() ? text : '-'),
    },
  ];
  const columns3 = [
    {
      title: 'Area',
      dataIndex: 'course_code',
      key: 'course_code',
      width:'10%'
    },
    {
      title: 'Course',
      dataIndex: 'course_name',
      key: 'course_name',
      width:'40%'
    },
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      align:'right',
      render: (_, record) => (<span>{getSectionNo(record?.section_number)}</span>)
    },
    {
      title: 'Credits',
      dataIndex: 'course_credit',
      key: 'course_credit',
      align:'right'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Approved By',
      dataIndex: 'approved_by_name',
      key: 'approved_by_name',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Semester Completed',
      dataIndex:'sem_schdeule_name',
      key: 'SemesterCompleted',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => (text?.trim() ? text : '-'),
    },
  ];
  const columns4 = [
    {
      title: 'Course Number',
      dataIndex: 'area_name',
      key: 'area_name',
      width:'10%'
    },
    {
      title: 'Course',
      dataIndex: 'course_name',
      key: 'course_name',
      width:'40%'
    },
    {
      title: 'Credits',
      dataIndex: 'course_credits',
      key: 'course_credits',
      align:'right'
    },
    {
      title: 'Semester',
      dataIndex: 'sem_schdeule_name',
      key: 'sem_schdeule_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Approved By',
      dataIndex: 'approved_by_name',
      key: 'approved_by_name',
      render: (text) => (text?.trim() ? text : '-'),
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => (text?.trim() ? text : '-'),
    },
  ];
  const columns5 = [
    {
      title: 'Assessment (Test Code #)',
      dataIndex: 'assessment',
      key: 'assessment',
      width:'30%'
    },
    {
      title: 'Scheduled For',
      dataIndex: 'scheduled_for',
      key: 'scheduled_for',
      width:'15%'
    },
    {
      title: 'Date Taken',
      dataIndex: 'date_taken',
      key: 'date_taken',
    },
    {
      title: 'Passing Score',
      dataIndex: 'passing_score',
      key: 'passing_score',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Download Document',
      key: 'document',
      render: (data) => (data?.document ? <Button icon={<DownloadOutlined className='!stroke-2'/>} type="text" onClick={() => downloadPdf("assessment",data?.id)}>
          {data?.document}
        </Button>  : '-'),
    },
  ];
  return (
    <div>
      <Flex justify='flex-end' className='mb-2'>
        {(!isStudent && !isArchived)&&
        <Button type='primary' onClick={()=>downloadPdf("export",null)}>
          Export PDF
        </Button>}
      </Flex>
      <Card className='grey-card mb-3'>
        <div className='mb-2'>I. Core Curriculum</div>
        <Table dataSource={coreCourses} columns={columns1} scroll={{x: 768}} className="records-table" size='small' pagination={false} />
      </Card>
      <Card className='grey-card mb-3'>
        <div className='mb-2'>II. SLP Undergraduate Requirements</div>
        <Table dataSource={slpUnderGradCourses} columns={columns2} scroll={{x: 768}} className="records-table" size='small' pagination={false} />
      </Card>
      <Card className='grey-card'>
        <div className='mb-2'>III. SLP Graduate Requirements</div>
        <Table dataSource={slpGradCourses} columns={columns3} scroll={{x: 768}} className="records-table" size='small' pagination={false} />
      </Card>
      {isArchived?
        <>
          <Card className='grey-card'>
            <div className='mb-2'>IV. Free Electives</div>
            <Table dataSource={freeElective} columns={columns4} scroll={{x: 768}} className="records-table" size='small' pagination={false} />
          </Card>
          <Card className='grey-card'>
            <div className='mb-2'>V. Assessments</div>
            <Table dataSource={assessments} columns={columns5} scroll={{x: 768}} className="records-table" size='small' pagination={false} />
          </Card>
        </>
      :<></>}
    </div>
  )
}

export default PlanOfStudy