import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Card, Row, Col, Popover, Space, Divider, Input, Modal, Form, Select, message, Spin, Popconfirm, DatePicker } from 'antd';
import { PlusCircleOutlined, InfoCircleFilled, DeleteOutlined, SettingOutlined, CheckOutlined, LikeOutlined } from '@ant-design/icons';
import constants from '../../Constants/constants';
import { fetchStaffApi, fetchStaffDownloadApi } from '../../Services/Api';
import { useLocation, useNavigate } from "react-router-dom";
import { removeExtraSpaces } from "../../GlobalFunctions/GlobalFunctions";
import moment from 'moment/moment';
import { COOKIE, getCookie } from '../../Services/Cookie';

const { TextArea } = Input;
const { Option, OptGroup } = Select;
const legends = (
	<div>
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-1'></div>
			{constants.Required}
		</div>
		<Divider className='my-2' />
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-2'></div>
			{constants.Waived}
		</div>
		<Divider className='my-2' />
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-3'></div>
			{constants.Transfer}
		</div>
		<Divider className='my-2' />
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-4'></div>
			{constants.Recommended}
		</div>
	</div>
);
let locale = {
	emptyText: <span className='text-secondary'>{constants.NoCourseData}</span>,
};
const formItemsInline = {
	labelCol: { span: 9 },
	wrapperCol: { span: 15 }
}
const formItemsHorizontal = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 }
}

function getAllSections(sections) {
	return Array.from({ length: sections }, (_, i) => {
		return {
			id: i + 1,
			name: i + 1
		}
	}).concat(constants.extraSections)
}

function getSectionNo(sectionNumber) {
	let section = parseInt(sectionNumber)
	let sectionNo = section > 8 ?
		constants.extraSections?.find((sec) => sec.id === section)?.name :
		sectionNumber
	return sectionNo
}

const SemesterCourses = ({ student_id = getCookie(COOKIE.UserId), sem_id, year_in_program, student_advisor_id, student_name }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [semCoursesData, setsemCoursesData] = useState([]);
	const [semCoursesDataPopup, setSemCoursesDataPopup] = useState([]);
	const [pdfDownload, setPdfDownload] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [modalTitle, setModalTitle] = useState('');
	const [summerYear, setSummerYear] = useState('');
	const [modalYear, setModalYear] = useState('');
	const [modalYearTitle, setModalYearTitle] = useState('');
	const [modalYearID, setModalYearID] = useState('');
	const [modalYearSummer, setModalYearSummer] = useState('');
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [isCourseCreditDisabled, setIsCourseCreditDisabled] = useState(false);
	const [disableButton, setDisableButton] = useState(true);
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();
	const notesRef = useRef('')
	const role = getCookie(COOKIE.Role);
	let isArchived = location?.state?.isArchived;
	const isStudent = role === '0' ? 'student' : null

	useEffect(() => {
		getSemCourses();
	}, []);

	const handleStatusChange = (value) => {
		if (value === '1') {
			form2.setFieldsValue({
				course_credit: '0'
			});
			setIsCourseCreditDisabled(true);
		} else {
			setIsCourseCreditDisabled(false);
			const selected = selectedOption?.course_credit || '';
			form2.setFieldsValue({
				course_credit: selected
			});
		}
	};

	const getSemCourses = () => {
		setLoading(true);
		let payload = null;
		let method = ''
		let url = ''
		if(isStudent) {
			url = `/student-semester-courses/${student_id}`
			method = 'GET'
		} else {
			url = '/student-semester-courses'
			method = 'POST'
			payload = { student_id: student_id };
		}
		fetchStaffApi(url, method, payload, isStudent)
			.then((res) => {
				if (res?.code === "200") {
					setsemCoursesData(res?.data);
				} else {
					setsemCoursesData([]);
				}
				setLoading(false);
			});
	};

	const getSemCoursesPopup = (title, year, yearTitle, yearID, summer, summerYear) => {
		const payload = {
			student_id: student_id,
			// previous_courses: 1,
			year: year_in_program,
			sem: sem_id
		};

		fetchStaffApi(`/get-semester-course`, 'POST', payload, isStudent)
			.then((res) => {
				if (res?.code === "200") {
					setSemCoursesDataPopup(res?.data);
					setModalTitle(title);
					setModalYear(year);
					setModalYearTitle(yearTitle);
					setModalYearID(yearID);
					setModalYearSummer(summer);
					setSummerYear(summerYear);
					setIsModalOpen(true); // Open modal on successful API call
				} else {
					setSemCoursesDataPopup([]);
					message.error('Error fetching semester courses: ' + res?.message || 'Unexpected error');
				}
			})
			.catch(error => {
				message.error('Error fetching semester courses' + error.message);
			});
	};

	const getEditSemCoursesPopup = (title, year, yearTitle, yearID, summer, record) => {
		setModalTitle(title);
		setModalYear(year);
		setModalYearTitle(yearTitle);
		setModalYearID(yearID);
		setModalYearSummer(summer);
		setIsEditModalOpen(true);
		setSelectedRecord(record);
		form3.setFieldsValue({
			course_area: record?.course_area || '',
			course_text: record?.course_text || '',
			status: String(record?.status) || '',
			year: record?.year || '',
			number_of_sections: record?.section_number || '',
			course_credit: record?.course_credit || '',
		});
	};

	const handleSaveRecord = (values) => {
		let payload = null;
		payload = {
			student_notes: removeExtraSpaces(values.notes),
			student_id: student_id,
			student_sem_id: sem_id,
			student_year: year_in_program,
			student_advisor_id: student_advisor_id,
			is_saved: 1,
			agreeCheckbox: 0,
			faculty_id: getCookie(COOKIE.UserId)
		};
		fetchStaffApi(`/save-student-notes`, 'POST', payload)
			.then((res) => {
				if (res?.code === "200") {
					message.success('Note saved successfully!');
					notesRef.current = removeExtraSpaces(values.notes);
					setDisableButton(true);
					form1.resetFields();
				} else {
					message.error('Failed to save note. Please try again later.');
				}
			});
	};

	const handleSaveCourse = (values) => {
		setLoading(true);
		let courseSem =
			modalTitle === constants.Fall ? 1 :
				modalTitle === constants.Spring ? 2 :
					summerYear;

		const calendarYearScheduled = values.year ? values.year.year() : undefined;
		const isExisting = selectedOption?.is_taken === 1;
		const isCore = selectedOption?.id === "34" || selectedOption?.course_type === 0;
		const isFreeElective = selectedOption?.course_type === "2";
		const isSLP = selectedOption?.course_type === "1" || selectedOption?.course_type === "3";


		let payload = {
			course_year: parseInt(modalYearID),
			course_semester: courseSem,
			student_id: student_id,
			course_credit: parseInt(values.course_credit),
			status: values.status,
			is_taken: selectedOption?.is_taken,
			course_type: selectedOption?.course_type
		};

		if (isCore) {
			payload.id = selectedOption?.id;
			payload.core_name = removeExtraSpaces(values?.core_name);
			payload.area = selectedOption?.area;
			if (isExisting) {
				payload.core_curriculum_id = selectedOption?.core_curriculum_id;
			}
		} else if (isFreeElective) {
			if (isExisting) {
				payload.course_code = selectedOption?.course_code;
				payload.slp_id = selectedOption?.slp_id;
			} else {
				payload.id = selectedOption?.id;
				payload.course_code = selectedOption?.course_code;
				payload.course_text = removeExtraSpaces(values?.core_name);
				payload.section_number = values?.number_of_sections;
			}
		} else if (isSLP) {
			payload.id = selectedOption?.id;
			payload.course_code = selectedOption?.course_code;
			payload.core_name = removeExtraSpaces(values?.core_name);
			payload.section_number = values.number_of_sections;
			if (isExisting) {
				payload.slp_id = selectedOption?.slp_id;
			}
		}

		payload.calendar_year_scheduled = calendarYearScheduled;
		
		fetchStaffApi(`/add-semester-course`, 'POST', payload, isStudent)
		.then((res) => {
				if (res?.code === "200") {
					message.success(constants.CourseSaved);
					getSemCourses();
					handleCancel();
				} else {
					message.error(constants.CourseFailedSave);
				}
		})
		.finally(() => {
			setLoading(false);
		});
	};

	const handleDeleteRecord = (id, courseType) => {
		let payload = null;
		payload = {
			id: id,
			course_type: courseType,
		};
		fetchStaffApi(`/delete-semester-course`, 'POST', payload, isStudent)
			.then((res) => {
				if (res?.code === "200") {
					message.success(constants.deleteCourse);
					getSemCourses();
				} else {
					message.error(constants.DiscontinueAPIError);
				}
			});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		form2.resetFields();
	};

	const handleEditCancel = () => {
		setIsEditModalOpen(false);
		setSelectedRecord(null);
	};

	const handleApprove = () => {
		let payload = null;
		payload = {
			student_id: student_id,
			faculty_id: getCookie(COOKIE.UserId),
			studentPreviousSem: semesters?.previous_semester,
			studentPreviousYear: semesters?.previous_year
		};
		fetchStaffApi(`/approve-previous-courses`, 'POST', payload)
			.then((res) => {
				if (res?.code === "200") {
					message.success(constants.CourseApproved);
					getSemCourses();
				} else {
					message.error(constants.CourseFailedToApprove);
				}
			});
	};

	const handleEditRecord = (values) => {
		setLoading(true);
		let payload = null;
		let isCourseTextRequired = selectedRecord?.area_id && (selectedRecord?.course_type === 0 || selectedRecord?.course_type === 2)
		payload = {
			id: selectedRecord?.id,
			status: values.status,
			course_type: selectedRecord?.course_type,
			core_name: isCourseTextRequired ? selectedRecord?.course_text : ''
		};
		
		fetchStaffApi(`/edit-semester-course`, 'POST', payload, isStudent)
		.then((res) => {
			if (res?.code === "200") {
				message.success(constants.EditSuccMsg);
				getSemCourses();
				handleEditCancel();
			} else {
				message.error(constants.EditAPIError);
			}
		})
		.finally(() => {
			setLoading(false);
		});
	};

	const downloadPdf = () => {
		if (!pdfDownload) {
			setPdfDownload(true);
			const withPdfPayload = {
				generate_pdf: 1,
				student_id: student_id,
			};
			fetchStaffDownloadApi(`/student-semester-courses`, 'POST', withPdfPayload).then((res) => {
				if (res) {
					const blob = new Blob([res], { type: 'application/pdf' });
					const url = URL.createObjectURL(blob);
					window.open(url, '_blank');
				}
				setPdfDownload(false);
			});
		}
	};

	const renderOptGroup = (label, courseType) => {
		const filteredItems = [...areaItems, ...courseNameItems].filter(item => item.course_type === courseType);

		if (filteredItems.length === 0) {
			return null;
		}

		return (
			<OptGroup label={label}>
				{filteredItems.map(item => (
					<Option
						courseType={item.course_type}
						courseCode={item.course_code}
						isTaken={item.is_taken}
						courseCredit={item.course_credit}
						key={item.id}
						value={item.area || item.course_name}
					>
						<span className={item.is_taken === 1 ? 'text-red-500' : ''}>
							{item.area || item.course_name}
						</span>
					</Option>
				))}
			</OptGroup>
		);
	};

	const {
		freshman_fall,
		freshman_spring,
		freshman_summer_session1,
		freshman_summer_session2,

		sophomore_fall,
		sophomore_spring,
		sophomore_summer_session1,
		sophomore_summer_session2,

		junior_fall,
		junior_spring,
		junior_summer_session1,
		junior_summer_session2,

		senior_fall,
		senior_spring,
		senior_summer_session1,
		senior_summer_session2,

		grad_fall,
		grad_spring,
		grad_summer_session1,
		grad_summer_session2,

		years,
		semesters,
		approved_semesters
	} = semCoursesData;

	const SemesterTable = ({ data = [], year, title, checked, hideAction, index, yearTitle, yearID, prevSem, isCurrentAndFutureApproved }) => {
		// Function to calculate total course_credit excluding records with status 1
		const calculateTotalCredits = () => {
			return data
				.filter(record => record.status !== 1) // Exclude records with status 1
				.reduce((total, record) => total + record.course_credit, 0); // Sum up the course_credit values
		};
		// Function to calculate total course_credit excluding records with status equal to 1 or 3
		const totalCreditsInt = () => {
			return data
				.filter(record => record.status !== 1 && record.status !== 3) // Exclude records with status 1 and 3
				.reduce((total, record) => total + record.course_credit, 0); // Sum up the course_credit values
		};
		const columns = [
			{
				title: <Space>
					{`${title}`} Semester {year && `${year}`}
					{
						(checked || (prevSem && checked)) ?
							<CheckOutlined className='green-text h5 mb-0 mt-1' /> :
							(
								((semesters?.current_year === "6" && !isArchived) || (isCurrentAndFutureApproved)) ?
									<PlusCircleOutlined className='cursor-pointer h5 mb-0 mt-1' onClick={() => getSemCoursesPopup(title, year, yearTitle, yearID, null, null)} /> :
									(prevSem && !checked) ?
										<>
											<PlusCircleOutlined className='cursor-pointer h5 mb-0 mt-1' onClick={() => getSemCoursesPopup(title, year, yearTitle, yearID, null, null)} />
											{role === "0" ? null : (
												<span>
													{data.length > 0 ?
														<Popconfirm
															title="Approve Course"
															description="Are you sure you want to approve this course?"
															onConfirm={() => handleApprove()}
															okText="Yes"
															cancelText="No"
														>
															<LikeOutlined className='h5 mb-0' />
														</Popconfirm>
														: null}
												</span>
											)}
										</> :
										null
							)
					}
					<span className='d-none'>{yearTitle}</span>
				</Space>,
				dataIndex: 'course_name',
				key: 'fallSem',
				width: '50%'
			},
			{
				title: 'Credits',
				dataIndex: 'course_credit',
				key: 'credits',
				width: '15%',
				align: 'right',
				render: (text, record) => {
					const displayText = record.status === 1 ? 0 : text;
					return <span>{record.status === 3 ? `(${displayText})` : displayText}</span>;
				},
			},
			{
				title: 'Section',
				dataIndex: 'section_number',
				key: 'section',
				align: 'right',
				render: (_, record) => <span>{record?.section_number ? getSectionNo(record?.section_number) : '-'}</span>
			},
			{
				title: 'Grade',
				dataIndex: 'grade',
				key: 'grade',
				render: (text) => <span>{text?.trim() ? constants.gradeMapping[text] : '-'}</span>,
			},
			...(hideAction || checked ? [] : [
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					width: '5%',
					render: (_, record) => {
						if (record.area_id === 34) {
							return (
								<Popconfirm
									title="Record once deleted will be lost forever."
									description="Are you sure to delete this record?"
									onConfirm={() => handleDeleteRecord(record.id, record.course_type)}
									okText="Yes"
									cancelText="No"
								>
									<Button icon={<DeleteOutlined />} type='text' />
								</Popconfirm>
							);
						} else {
							return (
								<Space size='small'>
									<Popconfirm
										title="Record once deleted will be lost forever."
										description="Are you sure to delete this record?"
										onConfirm={() => handleDeleteRecord(record.id, record.course_type)}
										okText="Yes"
										cancelText="No"
									>
										<Button icon={<DeleteOutlined />} type='text' />
									</Popconfirm>
									<Button icon={<SettingOutlined />} type='text' onClick={() => getEditSemCoursesPopup(title, year, yearTitle, yearID, null, record)} />
								</Space>
							);
						}
					},
				},
			]),
		];

		return (
			<div>
				<Table dataSource={data} columns={columns} scroll={{ x: 375 }} locale={locale} className="records-table" size='small' pagination={false} rowClassName={record => `status-${record.status}`}
					summary={() => {
						if (!data || data.length === 0) {
							return null;
						}
						return (
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
								<Table.Summary.Cell index={1} align='right'><span><strong>{totalCreditsInt()} ({calculateTotalCredits()})</strong></span></Table.Summary.Cell>
								<Table.Summary.Cell index={2} colSpan={2}></Table.Summary.Cell>
							</Table.Summary.Row>
						);
					}}
				/>
			</div>
		);
	};

	const SummerTable = ({ data = [], year, title, checked, hideAction, index, yearTitle, yearID, summer, summerYear, prevSem, isCurrentAndFutureApproved }) => {

		// Function to calculate total course_credit excluding records with status 1
		const calculateTotalCredits = () => {
			return data
				.filter(record => record.status !== 1) // Exclude records with status 1
				.reduce((total, record) => total + record.course_credit, 0); // Sum up the course_credit values
		};
		// Function to calculate total course_credit excluding records with status equal to 1 or 3
		const totalCreditsInt = () => {
			return data
				.filter(record => record.status !== 1 && record.status !== 3) // Exclude records with status 1 and 3
				.reduce((total, record) => total + record.course_credit, 0); // Sum up the course_credit values
		};
		const summerCols = [
			{
				title: <Space>
					{`${title}`}{year && `${year}`}
					{
						(checked || (prevSem && checked)) ?
							<CheckOutlined className='green-text h5 mb-0 mt-1' /> :
							(
								((semesters?.current_year === "6" && !isArchived) || (isCurrentAndFutureApproved)) ?
									<PlusCircleOutlined className='cursor-pointer h5 mb-0 mt-1' onClick={() => getSemCoursesPopup(title, year, yearTitle, yearID, summer, summerYear)} /> :
									(prevSem && !checked) ?
										<>
											<PlusCircleOutlined className='cursor-pointer h5 mb-0 mt-1' onClick={() => getSemCoursesPopup(title, year, yearTitle, yearID, summer, summerYear)} />
											{role === "0" ? null : (
												<span>
													{data.length > 0 ?
														<Popconfirm
															title="Approve Course"
															description="Are you sure you want to approve this course?"
															onConfirm={() => handleApprove()}
															okText="Yes"
															cancelText="No"
														>
															<LikeOutlined className='h5 mb-0' />
														</Popconfirm>
														: null}
												</span>
											)}
										</> :
										null
							)
					}
					<span className='d-none'>{summer}{yearTitle}</span>
				</Space>,
				dataIndex: 'course_name',
				key: 'course_name',
				width: '50%'
			},
			{
				title: 'Credits',
				dataIndex: 'course_credit',
				key: 'course_credit',
				width: '15%',
				align: 'right',
				render: (text, record) => {
					const displayText = record.status === 1 ? 0 : text;
					return <span>{record.status === 3 ? `(${displayText})` : displayText}</span>;
				},
			},
			{
				title: 'Section',
				dataIndex: 'section_number',
				key: 'section_number',
				align: 'right',
				render: (_, record) => <span>{record?.section_number ? getSectionNo(record?.section_number) : '-'}</span>
			},
			{
				title: 'Grade',
				dataIndex: 'grade',
				key: 'grade',
				render: (text) => <span>{text?.trim() ? constants.gradeMapping[text] : '-'}</span>,
			},
			...(hideAction || checked ? [] : [
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					width: '5%',
					render: (_, record) => {
						if (record.area_id === 34) {
							return (
								<Popconfirm
									title="Record once deleted will be lost forever."
									description="Are you sure to delete this record?"
									onConfirm={() => handleDeleteRecord(record.id, record.course_type)}
									okText="Yes"
									cancelText="No"
								>
									<Button icon={<DeleteOutlined />} type='text' />
								</Popconfirm>
							);
						} else {
							return (
								<Space size='small'>
									<Popconfirm
										title="Record once deleted will be lost forever."
										description="Are you sure to delete this record?"
										onConfirm={() => handleDeleteRecord(record.id, record.course_type)}
										okText="Yes"
										cancelText="No"
									>
										<Button icon={<DeleteOutlined />} type='text' />
									</Popconfirm>
									<Button icon={<SettingOutlined />} type='text' onClick={() => getEditSemCoursesPopup(title, year, yearTitle, yearID, summer, record)} />
								</Space>
							);
						}
					},
				},
			]),
		];
		return (
			<div>
				<Table dataSource={data} columns={summerCols} scroll={{ x: 375 }} locale={locale} className="records-table" size='small' pagination={false} rowClassName={record => `status-${record.status}`}
					summary={() => {
						if (!data || data.length === 0) {
							return null;
						}
						return (
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
								<Table.Summary.Cell index={1} align='right'><span><strong>{totalCreditsInt()} ({calculateTotalCredits()})</strong></span></Table.Summary.Cell>
								<Table.Summary.Cell index={2} colSpan={2}></Table.Summary.Cell>
							</Table.Summary.Row>
						);
					}}
				/>
			</div>
		);
	};

	const handlePrevSem = (year_sem) => {
		if (semesters) {
			if ((`${year_sem}`) === (`${semesters?.previous_year}${semesters?.previous_semester}`)) {
				return true;
			} else {
				return false;
			}
		}
	};
	let no;

	const handleIsCurrentAndFutureSem = (year_sem, isApproved, tableNo) => {
		if (semesters) {
			let prevyear = handlePrevSem(year_sem);
			if (prevyear && isApproved) {
				no = tableNo
			}
			if (no) {
				if (tableNo > no) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		}
	};

	const semLoop = [
		{
			yearID: '1',
			yearTitle: <span>{constants.Freshman} {constants.Year}</span>,
			fallData: freshman_fall,
			springData: freshman_spring,
			fallYear: years?.freshman_fall,
			springYear: years?.freshman_spring,
			summerSession1: freshman_summer_session1,
			summerSession2: freshman_summer_session2,
			summerSessionYr1: years?.freshman_summer_session1,
			summerSessionYr2: years?.freshman_summer_session2,
			approvedSemsFall: approved_semesters?.freshman_fall,
			approvedSemsSpring: approved_semesters?.freshman_spring,
			approvedSession1: approved_semesters?.freshman_summer_session1,
			approvedSession2: approved_semesters?.freshman_summer_session2,
			isPrevSemsFall: handlePrevSem("11"),
			isPrevSemsSpring: handlePrevSem("12"),
			isPrevSession1: handlePrevSem("13"),
			isPrevSession2: handlePrevSem("14"),
			isCurrentAndFutureSemFall: handleIsCurrentAndFutureSem("11", approved_semesters?.freshman_fall, 1),
			isCurrentAndFutureSpring: handleIsCurrentAndFutureSem("12", approved_semesters?.freshman_spring, 2),
			isCurrentAndFutureSemSession1: handleIsCurrentAndFutureSem("13", approved_semesters?.freshman_summer_session1, 3),
			isCurrentAndFutureSemSession2: handleIsCurrentAndFutureSem("14", approved_semesters?.freshman_summer_session2, 4),
		},
		{
			yearID: '2',
			yearTitle: <span>{constants.Sophomore} {constants.Year}</span>,
			fallData: sophomore_fall,
			springData: sophomore_spring,
			fallYear: years?.sophomore_fall,
			springYear: years?.sophomore_spring,
			summerSession1: sophomore_summer_session1,
			summerSession2: sophomore_summer_session2,
			summerSessionYr1: years?.sophomore_summer_session1,
			summerSessionYr2: years?.sophomore_summer_session2,
			approvedSemsFall: approved_semesters?.sophomore_fall,
			approvedSemsSpring: approved_semesters?.sophomore_spring,
			approvedSession1: approved_semesters?.sophomore_summer_session1,
			approvedSession2: approved_semesters?.sophomore_summer_session2,
			isPrevSemsFall: handlePrevSem("21"),
			isPrevSemsSpring: handlePrevSem("22"),
			isPrevSession1: handlePrevSem("23"),
			isPrevSession2: handlePrevSem("24"),
			isCurrentAndFutureSemFall: handleIsCurrentAndFutureSem("21", approved_semesters?.sophomore_fall, 5),
			isCurrentAndFutureSpring: handleIsCurrentAndFutureSem("22", approved_semesters?.sophomore_spring, 6),
			isCurrentAndFutureSemSession1: handleIsCurrentAndFutureSem("23", approved_semesters?.sophomore_summer_session1, 7),
			isCurrentAndFutureSemSession2: handleIsCurrentAndFutureSem("24", approved_semesters?.sophomore_summer_session2, 8),
		},
		{
			yearID: '3',
			yearTitle: <span>{constants.Junior} {constants.Year}</span>,
			fallData: junior_fall,
			springData: junior_spring,
			fallYear: years?.junior_fall,
			springYear: years?.junior_spring,
			summerSession1: junior_summer_session1,
			summerSession2: junior_summer_session2,
			summerSessionYr1: years?.junior_summer_session1,
			summerSessionYr2: years?.junior_summer_session2,
			approvedSemsFall: approved_semesters?.junior_fall,
			approvedSemsSpring: approved_semesters?.junior_spring,
			approvedSession1: approved_semesters?.junior_summer_session1,
			approvedSession2: approved_semesters?.junior_summer_session2,
			isPrevSemsFall: handlePrevSem("31"),
			isPrevSemsSpring: handlePrevSem("32"),
			isPrevSession1: handlePrevSem("33"),
			isPrevSession2: handlePrevSem("34"),
			isCurrentAndFutureSemFall: handleIsCurrentAndFutureSem("31", approved_semesters?.junior_fall, 9),
			isCurrentAndFutureSpring: handleIsCurrentAndFutureSem("32", approved_semesters?.junior_spring, 10),
			isCurrentAndFutureSemSession1: handleIsCurrentAndFutureSem("33", approved_semesters?.junior_summer_session1, 11),
			isCurrentAndFutureSemSession2: handleIsCurrentAndFutureSem("34", approved_semesters?.junior_summer_session2, 12),
		},
		{
			yearID: '4',
			yearTitle: <span>{constants.GraduateYear1}</span>,
			fallData: senior_fall,
			springData: senior_spring,
			fallYear: years?.senior_fall,
			springYear: years?.senior_spring,
			summerSession1: senior_summer_session1,
			summerSession2: senior_summer_session2,
			summerSessionYr1: years?.senior_summer_session1,
			summerSessionYr2: years?.senior_summer_session2,
			approvedSemsFall: approved_semesters?.senior_fall,
			approvedSemsSpring: approved_semesters?.senior_spring,
			approvedSession1: approved_semesters?.senior_summer_session1,
			approvedSession2: approved_semesters?.senior_summer_session2,
			isPrevSemsFall: handlePrevSem("41"),
			isPrevSemsSpring: handlePrevSem("42"),
			isPrevSession1: handlePrevSem("43"),
			isPrevSession2: handlePrevSem("44"),
			isCurrentAndFutureSemFall: handleIsCurrentAndFutureSem("41", approved_semesters?.senior_fall, 13),
			isCurrentAndFutureSpring: handleIsCurrentAndFutureSem("42", approved_semesters?.senior_spring, 14),
			isCurrentAndFutureSemSession1: handleIsCurrentAndFutureSem("43", approved_semesters?.senior_summer_session1, 15),
			isCurrentAndFutureSemSession2: handleIsCurrentAndFutureSem("44", approved_semesters?.senior_summer_session2, 16)
		},
		{
			yearID: '5',
			yearTitle: <span>{constants.GraduateYear2}</span>,
			fallData: grad_fall,
			springData: grad_spring,
			fallYear: years?.grad_fall,
			springYear: years?.grad_spring,
			summerSession1: grad_summer_session1,
			summerSession2: grad_summer_session2,
			summerSessionYr1: years?.grad_summer_session1,
			summerSessionYr2: years?.grad_summer_session2,
			approvedSemsFall: approved_semesters?.grad_fall,
			approvedSemsSpring: approved_semesters?.grad_spring,
			approvedSession1: approved_semesters?.grad_summer_session1,
			approvedSession2: approved_semesters?.grad_summer_session2,
			isPrevSemsFall: handlePrevSem("51"),
			isPrevSemsSpring: handlePrevSem("52"),
			isPrevSession1: handlePrevSem("53"),
			isPrevSession2: handlePrevSem("54"),
			isCurrentAndFutureSemFall: handleIsCurrentAndFutureSem("51", approved_semesters?.grad_fall, 17),
			isCurrentAndFutureSpring: handleIsCurrentAndFutureSem("52", approved_semesters?.grad_spring, 18),
			isCurrentAndFutureSemSession1: handleIsCurrentAndFutureSem("53", approved_semesters?.grad_summer_session1, 19),
			isCurrentAndFutureSemSession2: handleIsCurrentAndFutureSem("54", approved_semesters?.grad_summer_session2, 20)
		}
	];

	const extractText = (reactNode) => {
		if (typeof reactNode === 'string') {
			return reactNode;
		} else if (React.isValidElement(reactNode)) {
			return extractText(reactNode.props.children);
		} else if (Array.isArray(reactNode)) {
			return reactNode.map(child => extractText(child)).join('');
		}
		return '';
	};

	const filterOption = (input, option) => {
		const label = extractText(option.label) || '';
		return label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};
	const filterOptionChild = (input, option) => {
		const children = extractText(option.children) || '';
		return children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	const handleSelectChange = (_, obj) => {
		let payload = {
			area: obj?.value,
			id: obj?.key,
			course_type: obj?.courseType,
			course_code: obj?.courseCode,
			is_taken: obj?.isTaken,
			course_credit: obj?.courseCredit
		}
		setSelectedOption(payload);
		form2.setFieldsValue({
			course_credit: obj?.courseCredit || '',
			status: undefined
		});
	};

	const onChangeNotes = (value) => {
		const currentValue = value
		setDisableButton(removeExtraSpaces(currentValue) === notesRef.current);
	}

	const filteredData = selectedOption?.area
		? semCoursesDataPopup.filter(item => item.area === selectedOption?.area || item.course_name === selectedOption?.area)
		: [];
	const areaItems = semCoursesDataPopup.filter(item => item.area);
	const courseNameItems = semCoursesDataPopup.filter(item => !item.area);

	return (
		<div>
			<div className='text-end mb-2'>
				{(!isStudent && !isArchived) && 
				<Button type='primary' onClick={downloadPdf}>
					Export PDF
				</Button>}
			</div>
			<Spin tip={constants.PleaseWait} spinning={loading}>
				{semLoop.map((item, index) => (
					(item.fallData?.length > 0 || item.springData?.length > 0 || item.summerSession1?.length > 0 || item.summerSession2?.length > 0 || !isArchived) &&
					<div className='border border-primary p-2 mb-3' key={index}>
						{(item.fallData?.length > 0 || item.springData?.length > 0 || !isArchived) &&
							<Card
								className={`mb-3 ${semesters?.current_year == index + 1 ? 'current-year' : 'gray-title'}`}
								size='small'
								title={
									<div className='text-center h5 text-primary-pale mb-0'>
										<Space>
											{item.yearTitle}
											<Popover content={legends} placement="right">
												<InfoCircleFilled className='cursor-pointer' />
											</Popover>
										</Space>
									</div>
								}>
								<Row gutter={[16, 16]}>
									{(item.fallData || !isArchived) && <Col xs={24} lg={12}>
										<SemesterTable
											yearID={item.yearID}
											data={item.fallData}
											year={item.fallYear}
											yearTitle={item.yearTitle}
											title={constants.Fall}
											checked={item.approvedSemsFall}
											hideAction={index + 1 < semesters?.current_year || isArchived}
											index={index}
											prevSem={item.isPrevSemsFall}
											isCurrentAndFutureApproved={item.isCurrentAndFutureSemFall}
										/>
									</Col>}
									{(item.springData || !isArchived) && <Col xs={24} lg={12}>
										<SemesterTable
											yearID={item.yearID}
											data={item.springData}
											year={item.springYear}
											yearTitle={item.yearTitle}
											title={constants.Spring}
											checked={item.approvedSemsSpring}
											hideAction={index + 1 < semesters?.current_year || isArchived}
											index={index}
											prevSem={item.isPrevSemsSpring}
											isCurrentAndFutureApproved={item.isCurrentAndFutureSpring}
										/>
									</Col>}
								</Row>
							</Card>
						}
						{(item.summerSession1?.length > 0 || item.summerSession2?.length > 0 || !isArchived) &&
							<Card className='mb-0'>
								<div className='text-center h5 text-primary-pale'>
									<Space>Summer Semester
										<Popover content={legends} placement="right">
											<InfoCircleFilled className='cursor-pointer' />
										</Popover>
									</Space>
								</div>
								<Row gutter={[16, 16]}>
									{(item.summerSession1 || !isArchived) && <Col xs={24} lg={12}>
										<SummerTable
											yearID={item.yearID}
											data={item.summerSession1}
											year={item.summerSessionYr1}
											yearTitle={item.yearTitle}
											summer={constants.Summer}
											summerYear={3}
											title={constants.Session1}
											checked={item.approvedSession1}
											hideAction={index + 1 < semesters?.current_year || isArchived}
											index={index}
											prevSem={item.isPrevSession1}
											isCurrentAndFutureApproved={item.isCurrentAndFutureSemSession1}
										/>
									</Col>}
									{(item.summerSession2 || !isArchived) && <Col xs={24} lg={12}>
										<SummerTable
											yearID={item.yearID}
											data={item.summerSession2}
											year={item.summerSessionYr2}
											yearTitle={item.yearTitle}
											summer={constants.Summer}
											summerYear={4}
											title={constants.Session2}
											checked={item.approvedSession2}
											hideAction={index + 1 < semesters?.current_year || isArchived}
											index={index}
											prevSem={item.isPrevSession2}
											isCurrentAndFutureApproved={item.isCurrentAndFutureSemSession2}
										/>
									</Col>}
								</Row>
							</Card>
						}
					</div>
				))}
			</Spin>
			{!isArchived && <div className='border border-primary p-2'>
				<Form onFinish={handleSaveRecord} form={form1} initialValues={{ notes: notesRef.current }}>
					<Card className='grey-card mb-3'>
						<div className='d-flex justify-content-between align-items-center mb-2'>
							<span className='h5 mb-0'>Notes</span>
							<Space>
								Click on history to see all notes.
								<Button className='btn-info' size='small' onClick={() => navigate("/course-plan-notes", { state: { student_id: student_id, student_name: student_name } })}>{constants.History}</Button>
							</Space>
						</div>
						<div>
							<Form.Item name="notes" rules={[{ required: true, message: 'Please enter a note' }]}>
								<TextArea rows={4} onChange={(e) => onChangeNotes(e.target.value)} />
							</Form.Item>
						</div>
					</Card>
					<div className='d-flex align-items-center justify-content-end'>
						<Space>
							<Button className='btn-info' onClick={() => form1.submit()} disabled={disableButton}>{constants.Save} {constants.Note}</Button>
						</Space>
					</div>
				</Form>
			</div>}
			<Modal
				title={<span>{modalYearTitle} - {`${modalTitle} ${modalYearSummer ? modalYearSummer : ''} Semester ${modalYear ? modalYear : ''}`}</span>}
				width={600}
				open={isModalOpen}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel} type='primary' danger>
						{constants.Close}
					</Button>,
					<Button key="save" disabled={loading} onClick={() => form2.submit()} loading={loading} className='btn-info'>
						{constants.Save}
					</Button>,
				]}
			>
				<Form
					autoComplete="off"
					layout='vertical'
					form={form2}
					onFinish={handleSaveCourse}
				>
					<Form.Item
						label={<span>{constants.Areas} / {constants.Courses}</span>}
						rules={[
							{
								required: true,
								message: 'This field is required.',
							},
						]}
						name="course_area"
					>
						<Select
							allowClear
							showSearch
							className="w-100"
							placeholder="Search to Select"
							optionFilterProp="children"
							filterOption={filterOptionChild}
							onChange={(e, f) => handleSelectChange(e, f)}
						>
							{renderOptGroup('Area', 0)}
							{renderOptGroup('Slp Undergraduate', '1')}
							{renderOptGroup('Free Electives', '2')}
							{renderOptGroup('Slp Graduate', '3')}
						</Select>
					</Form.Item>
					{selectedOption?.area && filteredData.length > 0 && (
						<>
							{selectedOption?.area && semCoursesDataPopup.find(item => item.area === selectedOption?.area) && (
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This field is required.',
										},
									]}
									name="core_name"
								>
									<Input placeholder={constants.CourseName} />
								</Form.Item>
							)}
							<Row gutter={[8, 0]}>
								<Col lg={7}>
									<Form.Item
										label={constants.Year}
										rules={[
											{
												required: true,
												message: 'This field is required.',
											},
										]}
										className='mb-0'
										name="year"
									>
										<DatePicker
											picker="year"
											className='w-100'
											disabled={!filteredData.some(item => item.year)} // Disable if year is not present
											value={filteredData.length > 0 ? moment(filteredData[0].year, 'YYYY') : undefined} // Set value if year data exists
										/>
									</Form.Item>
								</Col>
								{filteredData.some(item => item.number_of_sections) && (
									<Col lg={5}>
										<Form.Item
											label={constants.Section}
											rules={[
												{
													required: true,
													message: 'This field is required.',
												},
											]}
											className='mb-0'
											name="number_of_sections"
										>
											<Select
												showSearch
												className='w-100'
												optionFilterProp="label"
												filterOption={filterOption}
												placeholder={constants.Sections}
											>
												{filteredData?.length > 0 && getAllSections(filteredData[0]?.number_of_sections).map((item) => (
													<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								)}
								<Col lg={8}>
									<Form.Item
										label={constants.Status}
										rules={[
											{
												required: true,
												message: 'This field is required.',
											},
										]}
										className='mb-0'
										name="status"
									>
										<Select
											showSearch
											className='w-100 status-select'
											optionFilterProp="label"
											filterOption={filterOption}
											onChange={handleStatusChange}
											placeholder={constants.Status}
											options={[
												{
													value: '0',
													label: <Space><div className='box legend-color-1'></div>{constants.Required}</Space>,
												},
												{
													value: '1',
													label: <Space><div className='box legend-color-2'></div>{constants.Waived}</Space>,
												},
												{
													value: '2',
													label: <Space><div className='box legend-color-3'></div>{constants.Transfer}</Space>,
												},
												{
													value: '3',
													label: <Space><div className='box legend-color-4'></div>{constants.Recommended}</Space>,
												},
											]}
										/>
									</Form.Item>
								</Col>
								<Col lg={4}>
									{selectedOption?.area && semCoursesDataPopup.find(item => item.area === selectedOption.area) ? (
										<Form.Item
											label={constants.Credits}
											rules={[
												{
													required: true,
													message: 'This field is required.',
												},
											]}
											className='mb-0'
											name="course_credit"
										>
											<Select
												showSearch
												className='w-100'
												optionFilterProp="label"
												filterOption={filterOption}
												placeholder={constants.Credits}
												disabled={isCourseCreditDisabled}
												options={[
													{
														value: 1, label: "1",
													},
													{
														value: 2, label: "2",
													},
													{
														value: 3, label: "3",
													},
													{
														value: 4, label: "4",
													},
												]}
											/>
										</Form.Item>
									) : (
										<Form.Item
											label={constants.Credits}
											className='mb-0'
											name="course_credit"
										>
											<Input value="" disabled />
										</Form.Item>
									)}
								</Col>
							</Row>
						</>
					)}
				</Form>
			</Modal>
			{/* Edit records */}
			<Modal
				title={<span><strong className='font-semibold'>Edit : </strong> {modalYearTitle} - {`${modalTitle} ${modalYearSummer ? modalYearSummer : ''} Semester ${modalYear ? modalYear : ''}`}</span>}
				width={600}
				open={isEditModalOpen}
				onCancel={handleEditCancel}
				footer={[
					<Button key="back" onClick={handleEditCancel} type='primary' danger>
						{constants.Close}
					</Button>,
					<Button key="save" disabled={loading} onClick={() => form3.submit()} loading={loading} className='btn-info'>
						{constants.Save}
					</Button>,
				]}
			>
				<Form
					autoComplete="off"
					form={form3}
					onFinish={handleEditRecord}
				>
					<Form.Item
						label={<span>{constants.Areas} / {constants.Courses}</span>}
						name="course_area"
						{...formItemsHorizontal}
						className='!mb-3'
					>

						<span className='text-lg'>
							{selectedRecord?.area ? selectedRecord.area : selectedRecord?.course_name}
						</span>
					</Form.Item>
					<Row gutter={[8, 0]}>
						{selectedRecord?.course_type === 0 ? (
							<Col lg={16}>
								<Form.Item
									label={constants.CourseName}
									rules={[
										{
											required: true,
											message: 'This field is required.',
										},
									]}
									{...formItemsHorizontal}
									name="course_text"
								>
									<Input placeholder={constants.CourseName} />
								</Form.Item>
							</Col>
						) : null}
						<Col lg={8}>
							<Form.Item
								label={constants.Status}
								rules={[
									{
										required: true,
										message: 'This field is required.',
									},
								]}
								name="status"
								{...formItemsHorizontal}
							>
								<Select
									showSearch
									className='w-100 status-select'
									optionFilterProp="label"
									filterOption={filterOption}
									onChange={handleStatusChange}
									value={selectedRecord?.status}
									options={[
										{
											value: '0',
											label: <Space><div className='box legend-color-1'></div>{constants.Required}</Space>,
										},
										{
											value: '1',
											label: <Space><div className='box legend-color-2'></div>{constants.Waived}</Space>,
										},
										{
											value: '2',
											label: <Space><div className='box legend-color-3'></div>{constants.Transfer}</Space>,
										},
										{
											value: '3',
											label: <Space><div className='box legend-color-4'></div>{constants.Recommended}</Space>,
										},
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Card className='text-center'>
						<Card.Grid hoverable={false} className='w-2/6'>
							<Form.Item
								label={constants.Year}
								className='!mb-0'
								name="calendar_year_scheduled"
								{...formItemsInline}
							>
								<span className='text-lg'>{selectedRecord?.calendar_year_scheduled}</span>
							</Form.Item>
						</Card.Grid>
						<Card.Grid hoverable={false} className='w-2/6'>
							<Form.Item
								label={constants.Section}
								className='!mb-0'
								name="number_of_sections"
								{...formItemsInline}
							>
								<span className='text-lg'>{getSectionNo(selectedRecord?.section_number)}</span>
							</Form.Item>
						</Card.Grid>
						<Card.Grid hoverable={false} className='w-2/6'>
							<Form.Item
								label={constants.Credits}
								className='!mb-0'
								name="course_credit"
								{...formItemsInline}
							>
								{selectedRecord?.status !== 1 ? (
									<span className='text-lg'>{selectedRecord?.course_credit}</span>
								) : 0}
							</Form.Item>
						</Card.Grid>
					</Card>
				</Form>
			</Modal>
		</div>
	)
}

export default SemesterCourses
